import React, { useState, useEffect, useReducer } from 'react';
import { Grid, Typography, Tabs, Tab, AppBar, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';

import ArrowBack from '@material-ui/icons/ArrowBack';

import { a11yProps } from '../tabs/TabPanel';
import * as API from '../../lib/api';

import {
    OriginalProductTab,
    ProductCategoriesTab,
    ProductDetailTab,
    ProductEpisodesTab,
    ProductLinksTab,
    ProductPhotoGroupsTab,
    ProductSearchMetadataTab,
    ProductVendorTab
} from './productTabs';

import { API as AmplifyAPI, graphqlOperation } from 'aws-amplify';
import { onUpdateProduct } from '../../graphql/subscriptions';

const useStyles = makeStyles((theme) => ({
    mainBar: {
        backgroundColor: 'black',
    },
    tab: {
        minWidth: 0
    }
}));

const initialState = {
    product: undefined,
    customerID: undefined,
    loading: true,
    error: false
}

function reducer(state, action) {
    switch (action.type) {
        case 'fetchProductSuccess':
            return {
                ...state,
                product: action.product,
                loading: false
            }
        case 'updateProductFromSubscription':
            return {
                ...state,
                product: action.product
            }
        case 'setCustomerID':
            return {
                ...state,
                customerID: action.customerID
            }
        default:
            throw new Error();
    }
}

export function Product(props: ProductProps) {
    const classes = useStyles();
    const history = useHistory();
    const [tab, setTab] = useState(0);
    const [productState, dispatch] = useReducer(reducer, initialState);

    const handleChange = (event, newValue) => {
        setTab(newValue);
    };

    useEffect(() => {
        if (!props.customerID) return;

        if (!productState.customerID) {
            dispatch({
                type: 'setCustomerID',
                customerID: props.customerID
            })

            return;
        }

        if (productState.customerID !== props.customerID) history.push('/products/list');
        if (productState.product && productState.product.customerID !== props.customerID) history.push('/products/list');
    }, [props.customerID])

    useEffect(() => {
        const fetchProduct = async () => {
            const productResult = await API.getProduct({ id: props.match.params.productID });
            dispatch({
                type: 'fetchProductSuccess',
                product: productResult
            })
        }

        fetchProduct();

        // @ts-ignore - for some reason it doesn't like the .subscribe.... 
        const onUpdateProductSubscription = AmplifyAPI.graphql(graphqlOperation(onUpdateProduct, { id: props.match.params.productID })).subscribe({
            next: (data) => {
                let updatedProduct = data.value.data.onUpdateProduct;
                dispatch({
                    type: 'updateProductFromSubscription',
                    product: updatedProduct
                })
            },
            error: (error) => {
                console.error(error);
            }
        });

        return function cleanup() {
            // Stop receiving data updates from the subscription
            onUpdateProductSubscription.unsubscribe();
        };
    }, [props.match.params.productID])

    const tabs = [
        { key: 'productDetail', label: 'Product Detail', disabled: false },
        { key: 'productLinks', label: 'Product Links', disabled: false },
        { key: 'productCategories', label: 'Product Categories', disabled: false },
        { key: 'productSearchMetadata', label: 'Search Metadata', disabled: false },
        { key: 'productEpisodes', label: 'Product Episodes', disabled: false },
        { key: 'productPhotoGroups', label: 'Product Photo Groups', disabled: false },
        { key: 'productVendor', label: 'Product Vendor', disabled: false },
        { key: 'originalProduct', label: 'Original Product Info', disabled: !productState.product?.replacementProduct },
    ]

    const onBackClick = () => {
        if (props.location.state && props.location.state.showTab) {
            history.push({pathname: props.location.state.prevPath, state: props.location.state});
        } else {
            history.push('/products/list');
        }
    }

    return (
        <Grid container spacing={1}>
            <Grid item container spacing={1} direction="row" alignItems="center">
                <Grid item>
                    <IconButton onClick={onBackClick}>
                        <ArrowBack />
                    </IconButton>
                </Grid>
                <Grid item>
                    <Typography variant="h5">Product Detail: {productState.product?.name}</Typography>
                </Grid>
            </Grid>
            <br />
            <Grid item xs={12}>
                <AppBar position="static" className={classes.mainBar}>
                    <Tabs value={tab} onChange={handleChange} aria-label="product tabs" variant="scrollable" scrollButtons="on">
                        {
                            tabs.map((tab, index) => {
                                return (
                                    <Tab key={tab.key} label={tab.label} {...a11yProps(index)} className={classes.tab} disabled={tab.disabled} />
                                )
                            })
                        }
                    </Tabs>
                </AppBar>
            </Grid>
            <ProductDetailTab tab={tab} index={0} product={productState.product} />
            <ProductLinksTab tab={tab} index={1} product={productState.product} />
            <ProductCategoriesTab tab={tab} index={2} product={productState.product} />
            <ProductSearchMetadataTab tab={tab} index={3} product={productState.product} />
            <ProductEpisodesTab tab={tab} index={4} product={productState.product} />
            <ProductPhotoGroupsTab tab={tab} index={5} product={productState.product} />
            <ProductVendorTab tab={tab} index={6} product={productState.product} />
            <OriginalProductTab tab={tab} index={7} product={productState.product} />
        </Grid>
    )
}

interface ProductProps {
    customerID: number
    match: { params: { productID } }
    location: { search, state }
}