import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Checkbox, Grid, FormControlLabel, LinearProgress, IconButton, TextField, Typography } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ArrowBack from '@material-ui/icons/ArrowBack';

import { BannerAd, BannerAdLinkType, BannerAdType, CreateBannerAdInput, UpdateBannerAdInput, Maybe, VendorAd } from '../../types';
import * as API from '../../lib/api';
import useSnackbars from '../../hooks/useSnackbars';

import { API as AmplifyAPI, graphqlOperation } from 'aws-amplify';
import { onUpdateCategory } from '../../graphql/subscriptions';

import { FileUploadButton } from '../shared/FileUploadButton';
import { uploadPresignedFile, notEmpty } from '../../lib/util';
import path from 'path';

interface BannerAdSelect {
    name: string;
    value: string;
}

const bannerAdLinkTypeSelectOptions = Object.entries(BannerAdLinkType).map(type => {
    return { name: type[1].toString(), value: type[1] }
})

const bannerAdTypeSelectOptions = Object.entries(BannerAdType).map(type => {
    return { name: type[1].toString(), value: type[1] }
})

export function ProductCategory(props: ProductCategoryProps) {
    const history = useHistory();
    const { createSuccessSnack, createErrorSnack } = useSnackbars();

    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [active, setActive] = useState(false);
    const [imageURL, setImageURL] = useState('');
    const [thumbnailURL, setThumbnailURL] = useState('');

    const [progress, setProgress] = useState(0);
    const [uploading, setUploading] = useState(false);

    const [vendorAds, setVendorAds] = useState<Array<VendorAd>>([]);
    const [selectedVendorAd, setSelectedVendorAd] = useState<Maybe<VendorAd>>(null);
    const [bannerLinkType, setBannerLinkType] = useState(bannerAdLinkTypeSelectOptions[0]);
    const [bannerAdUrl, setBannerAdUrl] = useState<string | null>(null);
    const [replaceAd, setReplaceAd] = useState(false);
    const [currentAd, setCurrentAd] = useState<Maybe<BannerAd>>(null);

    useEffect(() => {
        if (!props.customerID) return;

        const fetchCategory = async () => {
            const categoryResult = await API.getCategory({ id: props.match.params.categoryID });
            if (categoryResult) {
                setName(categoryResult.name);
                setDescription(categoryResult.description);
                setActive(categoryResult.active || false);
                setImageURL(categoryResult.imageURL);
                setThumbnailURL(categoryResult.thumbnailURL);
            }
        }

        const fetchCategoryAd = async () => {
            const categoryAdsResult = await API.listBannerAdsByCategory({ categoryID: props.match.params.categoryID });
            const categoryAd = categoryAdsResult[0];

            if (categoryAd) {
                setCurrentAd(categoryAd);
                setReplaceAd(true);

                if (categoryAd.linkType) {
                    const linkType = bannerAdLinkTypeSelectOptions.find((linkType) => linkType.value === categoryAd.linkType);
                    setBannerLinkType(linkType ?? bannerAdLinkTypeSelectOptions[0]);
                }

                if (categoryAd.vendorAd) {
                    const selected = vendorAds.find(ad => ad.id === categoryAd.vendorAd?.id);
                    setSelectedVendorAd(selected);
                }
            }
        }

        const fetchVendorAds = async () => {
            const vendorAdsResult = await API.listVendorAds({});

            // Only show poster banners for now
            const filtered = vendorAdsResult.filter(notEmpty).filter(ad => ad.type === BannerAdType.Poster);
            setVendorAds(filtered);

            await fetchCategoryAd();
        }

        fetchCategory();
        fetchVendorAds();

        // @ts-ignore - for some reason it doesn't like the .subscribe.... 
        const onUpdateCategorySubscription = AmplifyAPI.graphql(graphqlOperation(onUpdateCategory, { id: props.match.params.categoryID, customerID: props.customerID })).subscribe({
            next: (data) => {
                let updatedCategory = data.value.data.onUpdateCategory;
                setImageURL(updatedCategory.imageURL);
            },
            error: (error) => {
                console.error(error);
            }
        });

        return function cleanup() {
            // Stop receiving data updates from the subscription
            onUpdateCategorySubscription.unsubscribe();
        };
    }, [props.match.params.categoryID, props.customerID])

    const onBackClick = () => {
        history.goBack()
    }

    const updateCategory = async () => {
        try {
            const input = {
                id: props.match.params.categoryID,
                customerID: props.customerID,
                name: name,
                description: description,
                active: active
            }
            const updateResult = await API.updateCategory(input);
            if (updateResult) {
                createSuccessSnack('Successfully updated category');
            } else {
                createErrorSnack('Failed to update category');
            }
        } catch (err) {
            console.error(err);
            createErrorSnack('Failed to update category');
        }
    }

    const updateCategoryPhoto = async ({ target }) => {
        if (!props.match.params.categoryID) return;

        const file = target.files[0];
        if (!file) return;

        const fileExtension = path.extname(file.name).toUpperCase();

        const now = new Date().getTime();
        const filename = `CATEGORY-${props.customerID}-${props.match.params.categoryID}-${now}${fileExtension}`;

        const presignedUrl = await API.generatePresignedRawMediaURL({
            operation: 'putObject',
            key: filename
        })

        var options = {
            headers: {
                'Content-Type': file.type
            },
            onUploadProgress: function (progressEvent) {
                var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                setProgress(percentCompleted);
            }
        };

        setUploading(true);
        let uploadResponse = await uploadPresignedFile(presignedUrl, file, options);
        if (uploadResponse.status === 200) {
            createSuccessSnack('Successfully updated category photo');
        } else {
            createErrorSnack('Error uploading photo');
        }

        setUploading(false);
        setProgress(0);
    }

    const onAdChanged = (event, ad) => {
        setSelectedVendorAd(ad);
    }

    const onLinkTypeChanged = (event, linkType) => {
        setBannerLinkType(linkType);
    }

    const createCategoryAd = async () => {
        if (!props.customerID || !props.match.params.categoryID) return;

        if (bannerLinkType.value !== "VENDOR" || !selectedVendorAd) {
            createErrorSnack('URL type ads currently not supported');
            return;
        }

        const input: CreateBannerAdInput = {
            customerID: props.customerID,
            categoryID: props.match.params.categoryID,
            linkType: bannerLinkType.value,
            type: BannerAdType.Poster,
            bannerAdVendorAdId: selectedVendorAd?.id
        }

        if (replaceAd) {
            createErrorSnack('Currently can only create one ad');
            return;
        }

        try {
            const createResult = await API.createBannerAd(input);
            if (createResult) {
                createSuccessSnack('Successfully created category banner ad.')
                setCurrentAd(createResult);
                setReplaceAd(true);
            } else {
                createErrorSnack('Failed to create category banner ad.');
            }
        } catch (err) {
            console.error(err);
            createErrorSnack('Failed to create category banner ad.');
        }
    }

    const updateCategoryAd = async () => {
        if (!props.customerID || !props.match.params.categoryID || !currentAd?.id) return;

        if (bannerLinkType.value !== "VENDOR" || !selectedVendorAd) {
            createErrorSnack('URL type ads currently not supported');
            return;
        }

        const input: UpdateBannerAdInput = {
            id: currentAd.id,
            linkType: bannerLinkType.value,
            type: BannerAdType.Poster,
            bannerAdVendorAdId: selectedVendorAd?.id
        }

        try {
            const updateResult = await API.updateBannerAd(input);
            if (updateResult) {
                createSuccessSnack('Successfully updated category banner ad.')
                setCurrentAd(updateResult);
                setReplaceAd(true);
            } else {
                createErrorSnack('Failed to update category banner ad.');
            }
        } catch (err) {
            console.error(err);
            createErrorSnack('Failed to update category banner ad.');
        }
    }

    const removeCategoryAd = async () => {
        if (!props.customerID || !props.match.params.categoryID || !currentAd?.id) return;

        try {
            const deleteResult = await API.deleteBannerAd({ id: currentAd.id });
            if (deleteResult) {
                setCurrentAd(null);
                setReplaceAd(false);
                createSuccessSnack('Successfully removed category banner ad.')
            } else {
                createErrorSnack('Failed to remove category banner ad.');
            }
        } catch (err) {
            console.error(err);
            createErrorSnack('Failed to remove category banner ad.');
        }
    }

    return (
        <Grid item container spacing={2} direction="row">
            <Grid item container spacing={1} direction="row" alignItems="center">
                <Grid item>
                    <IconButton onClick={onBackClick}>
                        <ArrowBack />
                    </IconButton>
                </Grid>
                <Grid item>
                    <Typography variant="h5">Category Detail: {name}</Typography>
                </Grid>
            </Grid>
            <Grid item container spacing={2} direction="column" xs={6}>
                <Grid item>
                    <TextField variant="outlined"
                        value={name}
                        label="Category Name"
                        onChange={(e) => setName(e.target.value)}
                        placeholder="Name"
                        fullWidth
                    />
                </Grid>
                <Grid item>
                    <TextField variant="outlined"
                        value={description}
                        label="Description"
                        onChange={(e) => setDescription(e.target.value)}
                        placeholder="Description"
                        fullWidth
                    />
                </Grid>
                <Grid item>
                    <FormControlLabel
                        control={<Checkbox checked={active} onChange={(e) => setActive(e.target.checked)} name="active" />}
                        label="Active"
                    />
                </Grid>
                <Grid item>
                    <Button variant="contained" color="secondary" onClick={updateCategory}>
                        Update Category
                    </Button>
                </Grid>
                <br />
                <Grid item>
                    <Typography variant="h5">Category Ad</Typography>
                </Grid>
                <Grid item>
                    <Autocomplete id="vendor-ad-link-type-combo-box"
                        options={bannerAdLinkTypeSelectOptions}
                        getOptionLabel={(option: BannerAdSelect) => option.name}
                        value={bannerLinkType}
                        onChange={onLinkTypeChanged}
                        renderInput={(params) => <TextField {...params} label="Link Type" variant="outlined" />}
                    />
                </Grid>
                {
                    bannerLinkType.value === "URL" &&
                    <>
                        <Grid item>
                            <TextField id="banner-ad-url"
                                variant="outlined"
                                label="Banner URL"
                                placeholder="https://company.com/store"
                                value={bannerAdUrl}
                                style={{ width: 350 }}
                                onChange={(e) => setBannerAdUrl(e.target.value)}
                            />
                        </Grid>
                        <Grid item>
                            <Typography>Preview</Typography>
                            <img src={bannerAdUrl ?? ''} width={640} height={100} />
                        </Grid>
                    </>
                }
                {
                    bannerLinkType.value === "VENDOR" &&
                    <>
                        <Grid item>
                            <Autocomplete id="vendor-ads-combo-box"
                                options={vendorAds}
                                getOptionLabel={(option: VendorAd) => `${option.name} - ${option.description}`}
                                value={selectedVendorAd}
                                onChange={onAdChanged}
                                renderInput={(params) => <TextField {...params} label="Vendor Ad" variant="outlined" />}
                            />
                        </Grid>
                        <Grid item>
                            <Typography>Preview</Typography>
                            <img src={`https://s3.dualstack.us-east-2.amazonaws.com/${selectedVendorAd?.imageBucket}/${selectedVendorAd?.imageKey}`}
                                width={640}
                                height={100}
                            />
                        </Grid>
                    </>
                }
                <Grid item>
                    {
                        !replaceAd && !currentAd &&
                        <Button variant="contained"
                            color="secondary"
                            onClick={createCategoryAd}
                        >
                            Create Category Ad
                        </Button>
                    }
                    {
                        replaceAd && currentAd &&
                        <Button variant="contained"
                            color="secondary"
                            onClick={updateCategoryAd}
                        >
                            Update Category Ad
                        </Button>
                    }
                    {
                        replaceAd && currentAd &&
                        <Button variant="contained"
                            color="primary"
                            onClick={removeCategoryAd}
                        >
                            Remove Category Ad
                        </Button>
                    }
                </Grid>
            </Grid>
            <Grid item container spacing={2} direction="column" xs={6}>
                {
                    uploading &&
                    <Grid item>
                        <LinearProgress variant="determinate" value={progress} color="secondary" />
                    </Grid>
                }
                <Grid item>
                    <img src={imageURL} alt={name} width="600" />
                </Grid>
                <Grid item>
                    <FileUploadButton id="category-photo-upload"
                        accept="image/*"
                        text="Update Category photo"
                        onChange={updateCategoryPhoto}
                    />
                </Grid>
            </Grid>
        </Grid>
    )
}

export interface ProductCategoryProps {
    authData: any
    customerID: number
    match: { params: { categoryID } }
}