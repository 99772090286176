import React, { useState, useEffect } from 'react';
import { Grid, Card, Popover, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { Bar } from 'react-chartjs-2';

import * as API from '../../../lib/api';

const useStyles = makeStyles((theme) => ({
    paper: {
        backgroundColor: "transparent",
        boxShadow: "none",
        overflow: "hidden"
    }
}));

const title = 'Users by Device Type';

interface data {
    labels: any[]
    datasets: any[]
}

const emptyData = {
    labels: [] as any[],
    datasets: [{
        label: title,
        backgroundColor: 'rgb(0, 0, 90)',
        borderColor: 'rgb(0, 0, 90)',
        data: [] as any[]
    }]
}

export function UsersDeviceTypesChart(props: UsersDeviceTypesChartProps) {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const [loading, setLoading] = useState(true);

    const [metricsData, setMetricsData] = useState<data>(emptyData);

    const [options] = useState({
        scales: {
            yAxes: [{
                scaleLabel: {
                    labelString: 'Users',
                    display: true,
                    fontSize: 16
                }
            }]
        }
    });

    useEffect(() => {
        setMetricsData(emptyData);
        if (!props.customerID) return;

        const fetchDeviceInfo = async () => {
            setLoading(true);

            let data = emptyData;

            try {
                const queryArgs = {
                    customerID: props.customerID,
                    fromDate: props.dates.fromDate.toISOString(),
                    toDate: props.dates.toDate.toISOString(),
                }
                const deviceInfoResults = await API.getEngagementDeviceInfo(queryArgs);
    
                let countData: any[] = [];
    
                let labels = deviceInfoResults?.map(entry => {
                    console.log(entry);

                    countData.push(entry?.userCount || 0)
                    return `${entry?.deviceType} - ${entry?.os}`;
                }) || []
    
                data = {
                    labels: labels,
                    datasets: [{
                        label: title,
                        backgroundColor: 'rgb(0, 0, 90)',
                        borderColor: 'rgb(0, 0, 90)',
                        data: countData
                    }]
                }
            } catch (err) {
                console.error(err);
            }

            setMetricsData(data)
            setLoading(false);
        }

        fetchDeviceInfo();
    }, [props.customerID, props.dates])

    return (
        <>
            <Card ref={setAnchorEl}>
                <Grid container spacing={2} direction="column" xs>
                    <Grid item>
                        <Bar data={metricsData} options={options} height={75} />
                    </Grid>
                </Grid>
            </Card>
            <Popover id="loading-card-metrics"
                open={loading}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'center',
                    horizontal: 'center',
                }}
                PaperProps={{
                    classes: {
                        root: classes.paper
                    }
                }}
            >
                <CircularProgress size={100} />
            </Popover>
        </>
    )
}

export interface UsersDeviceTypesChartProps {
    customerID: number
    dates: { fromDate: Date, toDate: Date }
}