import React from 'react';
import { Button, Input, InputLabel } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
    input: {
        display: 'none'
    },
}));

export function FileUploadButton(props: FileUploadButtonProps) {
    const classes = useStyles();

    return (
        <>
            <Input id={props.id}
                className={classes.input}
                inputProps={{
                    accept: props.accept,
                    multiple: props.multiple ?? false
                }}
                onChange={props.onChange}
                type="file"
                disabled={props.disabled ?? false}
            />
            <InputLabel htmlFor={props.id}>
                <Button
                    color="secondary"
                    variant="contained"
                    component="span"
                    disabled={props.disabled ?? false}
                >
                    {props.text}
                </Button>
            </InputLabel>
        </>
    )
}

export interface FileUploadButtonProps {
    id: string
    onChange: any
    accept: string
    text: string
    disabled?: boolean
    multiple?: boolean
}