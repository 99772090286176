import React, { useState, useEffect } from 'react';
import * as Sentry from "@sentry/react";
import { Grid, TextField, Button } from '@material-ui/core';

import useSnackbars from '../../hooks/useSnackbars';

import * as API from '../../lib/api';

import { SelectWithLabel } from '../shared/SelectWithLabel';

const defaultOption = { name: '', value: -1 }

export function AddShow(props: AddShowProps) {
    const { createSuccessSnack, createErrorSnack } = useSnackbars();
    const [customerOptions, setCustomerOptions] = useState([defaultOption]);
    const [selectedCustomer, setSelectedCustomer] = useState(defaultOption);
    const [showName, setShowName] = useState('');
    const [appID, setAppID] = useState('');

    const reset = () => {
        setShowName('')
        setAppID('');
    }

    useEffect(() => {
        const fetchCustomers = async () => {
            const customerList = await API.listCustomers();
            let options = customerList.map(customer => {
                return { name: customer.shortName, value: customer.id }
            })
            setCustomerOptions(options);
            setSelectedCustomer(options[0]);
        }

        fetchCustomers();
    }, [])

    const addShow = async () => {
        const input = {
            customerID: selectedCustomer.value,
            nameShort: showName,
            nameLong: showName,
            appID: appID,
            hideInAdmin: false,
            active: true
        }

        try {
            await API.createShow(input);
            createSuccessSnack('Successfully added show');
            reset();
        } catch (err) {
            console.error(err);
            Sentry.captureException(err);
            createErrorSnack('Failed to add show');
        }
    }

    return (
        <Grid container spacing={3} direction="column">
            <Grid item>
                <SelectWithLabel id="customer-selector"
                    title="Customer"
                    value={selectedCustomer}
                    onChange={(e) => setSelectedCustomer(e.target.value)}
                    selectOptions={customerOptions}
                />
            </Grid>
            <Grid item>
                <TextField variant="outlined"
                    label="Show Name"
                    value={showName}
                    placeholder="Show Name"
                    onChange={(e) => setShowName(e.target.value)}
                />
            </Grid>
            <Grid item>
                <TextField variant="outlined"
                    label="App ID"
                    value={appID}
                    placeholder="App ID"
                    onChange={(e) => setAppID(e.target.value)}
                />
            </Grid>
            <Grid item>
                <Button variant="contained" color="secondary" onClick={addShow}>
                    Add Show
                </Button>
            </Grid>
        </Grid>
    )
}

export interface AddShowProps {
    authData: any
}