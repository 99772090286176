import React, { useState, useEffect, forwardRef } from 'react';
import { Grid } from '@material-ui/core';

import { format } from 'date-fns';

import { TabPanel } from '../../tabs/TabPanel';

import { Maybe, Brand, Product } from '../../../types';
import * as API from '../../../lib/api';

import MaterialTable from "material-table";
import { Icons } from 'material-table'; // typescript specific import https://github.com/mbrn/material-table/issues/1150

import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';

const tableIcons: Icons = {
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

const formatString = 'MMM dd yyyy';

export function BrandProductsTab(props: BrandProductsTabProps) {
    const [products, setProducts] = useState<Array<Product>>([]);

    useEffect(() => {
        if (!props.brand) return;

        const fetchBrandProducts = async () => {
            const productsResult = await API.listBrandProducts({ id: props.brand!.id, customerID: props.brand!.customerID });
            setProducts(productsResult);
        }

        fetchBrandProducts();
    }, [props.brand])

    const renderTableImage = (rowData) => {
        return (
            <img src={rowData.mainPhotoURL} width="50" height="50" alt={rowData.name} />
        )
    }

    const renderDateAdded = (rowData) => {
        const date = new Date(rowData.added);
        return format(date, formatString);
    }

    return (
        <TabPanel value={props.tab} index={props.index} direction="column" spacing={2}>
            <Grid item>
                <MaterialTable
                    title="Brand Products"
                    icons={tableIcons}
                    columns={[
                        { title: "Image", field: "mainPhotoURL", export: false, filtering: false, render: renderTableImage },
                        { title: "Brand", field: "brand" },
                        { title: "Name", field: "name" },
                        { title: "Approved", field: "approved", type: "boolean" },
                        { title: "Enabled", field: "active", type: "boolean" },
                        { title: "Is Replacement", field: "replacementProduct", type: "boolean" },
                        { title: "Date Added", field: "added", filtering: false, render: renderDateAdded, type: "datetime" },
                    ]}
                    data={products}
                    options={{
                        exportButton: true,
                        exportAllData: true,
                        pageSize: 25,
                        pageSizeOptions: [10, 25, 50, 100],
                        filtering: true,
                        actionsColumnIndex: -1
                    }}
                />
            </Grid>
        </TabPanel >
    )
}

export interface BrandProductsTabProps {
    tab: number
    index: number
    brand: Maybe<Brand>
}