import React, { useState, useEffect } from 'react';
import { Grid, TextField, Button, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import DeleteIcon from '@material-ui/icons/Delete';

import {
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell
} from '@material-ui/core';

import useSnackBars from '../../../hooks/useSnackbars';
import * as API from '../../../lib/api';
import { TabPanel } from '../../tabs/TabPanel';
import { ShowMetadataItem } from '../../../types';

const useStyles = makeStyles((theme) => ({
    menuTable: {
        borderTopWidth: 2,
        borderColor: '#f9f9f9',
        borderWidth: 2,
        borderStyle: 'solid'
    }
}));

export function ShowSearchMetadataTab(props: ShowSearchMetadataTabProps) {
    const classes = useStyles();
    const { createErrorSnack } = useSnackBars();
    const [metadata, setMetadata] = useState<ShowMetadataItem[]>([]);
    const [metaTagName, setMetaTagName] = useState('');
    const [metaTagValue, setMetaTagValue] = useState('');

    const reset = () => {
        setMetaTagName('');
        setMetaTagValue('');
    }

    useEffect(() => {
        if (!props.showID) return;

        const fetchShowMetadata = async () => {
            const showMetadataResult = await API.getShowMetadata({ showID: props.showID });
            setMetadata(showMetadataResult);
        }

        fetchShowMetadata();
    }, [props.showID])

    const addShowMetadataItem = async () => {
        let input = {
            showID: props.showID,
            name: metaTagName,
            value: metaTagValue
        }
        const addShowMetadataResult = await API.addShowMetadata(input);
        if (addShowMetadataResult) {
            const newItem = addShowMetadataResult;
            setMetadata(metadata.concat(newItem));
            reset();
        } else {
            createErrorSnack('Error adding show metadata');
        }
    }

    const onItemRemoved = (id) => {
        let updated = metadata.filter((item: any) => {
            return item.id !== id
        })
        setMetadata(updated);
    }

    return (
        <TabPanel value={props.tab} index={props.index} spacing={2} direction="column">
            <Grid item>
                <TableContainer>
                    <Table className={classes.menuTable} size="small" aria-label="show metadata tags">
                        <TableHead>
                            <TableRow style={{ background: "#f9f9f9" }}>
                                <TableCell align="center">Tag Name</TableCell>
                                <TableCell align="center">Value</TableCell>
                                <TableCell align="center">Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                metadata.length === 0
                                    ? <TableRow>No metadata has been added.</TableRow>
                                    : metadata.map((metadata: any, index) => {
                                        return (
                                            <MetadataItemRow key={metadata.name} metadata={metadata} index={index} onItemRemoved={onItemRemoved} />
                                        )
                                    })
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            <Grid item>
                <TextField variant="outlined"
                    label="Meta Tag"
                    placeholder="Meta Tag Name"
                    value={metaTagName}
                    onChange={(e) => setMetaTagName(e.target.value)}
                />
            </Grid>
            <Grid item>
                <TextField variant="outlined"
                    label="Meta Value"
                    placeholder="Meta Tag Value"
                    value={metaTagValue}
                    onChange={(e) => setMetaTagValue(e.target.value)}
                />
            </Grid>
            <Grid item>
                <Button variant="contained" color="secondary" onClick={addShowMetadataItem}>
                    Add Metadata
                </Button>
            </Grid>
        </TabPanel>
    )
}

export interface ShowSearchMetadataTabProps {
    tab: number
    index: number
    showID: number
}

function MetadataItemRow(props: MetadataItemRowProps) {
    const remove = async () => {
        const showMetadata = await API.deleteShowMetadata(props.metadata);
        if (showMetadata) props.onItemRemoved(props.metadata.id);
    }

    return (
        <TableRow style={props.index % 2 ? { background: "#f9f9f9" } : { background: "white" }}>
            <TableCell align="center">{props.metadata.name}</TableCell>
            <TableCell align="center">{props.metadata.value}</TableCell>
            <TableCell align="center">
                <IconButton aria-label="delete" color="primary" onClick={remove}>
                    <DeleteIcon />
                </IconButton>
            </TableCell>
        </TableRow>
    )
}

interface MetadataItemRowProps {
    metadata: ShowMetadataItem
    index: number
    onItemRemoved: Function
}