import React, { useState, useEffect } from 'react';
import { Grid, TextField, IconButton, Tooltip, Button } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';

import DeleteIcon from '@material-ui/icons/Delete';

import {
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell
} from '@material-ui/core';

import { TabPanel } from '../../tabs/TabPanel';

import * as API from '../../../lib/api';

import { Maybe, Product, ProductEpisodePhotoGroup } from '../../../types/api';

const useStyles = makeStyles((theme) => ({
    menuTable: {
        borderTopWidth: 2,
        borderColor: '#f9f9f9',
        borderWidth: 2,
        borderStyle: 'solid'
    },
    comboBox: {
        width: '300px'
    },
}));

const defaultOption = { name: '', value: -1 }

export function ProductPhotoGroupsTab(props: ProductPhotoGroupsTabProps) {
    const classes = useStyles();
    const [episodeOptions, setEpisodeOptions] = useState([defaultOption]);
    const [episodePhotoGroupOptions, setEpisodePhotoGroupOptions] = useState([defaultOption]);
    const [productPhotoGroups, setProductPhotoGroups] = useState<Array<ProductEpisodePhotoGroup>>([]);
    const [newProductEpisode, setNewProductEpisode] = useState(defaultOption);
    const [newEpisodePhotoGroup, setNewEpisodePhotoGroup] = useState(defaultOption);

    const fetchProductEpisodePhotoGroups = async () => {
        const productEpisodePhotoGroupsResult = await API.getProductEpisodePhotoGroups({ productID: props.product!.id });
        setProductPhotoGroups(productEpisodePhotoGroupsResult);
    }

    useEffect(() => {
        if (!props.product) return;

        // TODO: I want this to be fetchEpisodesWithPhotoGroups but it's not working correctly and I don't really care atm
        const fetchCustomerEpisodes = async () => {
            const episodesResult = await API.listCustomerEpisodes({ customerID: props.product!.customerID });

            if (episodesResult) {
                let options = episodesResult.map(episode => {
                    if (episode) {
                        let displayName = `${episode.showName} - ${episode.name}`
                        return { name: displayName, value: episode.id }
                    }

                    return { name: '', value: -1 } //TODO: Fix thissssss types can be annoying
                })

                setEpisodeOptions(options);
                setNewProductEpisode(options[0] || defaultOption);
            }
        }

        fetchProductEpisodePhotoGroups();
        fetchCustomerEpisodes();
    }, [props.product])

    useEffect(() => {
        if (!newProductEpisode || newProductEpisode.value < 1) return;

        const fetchEpisodePhotoGroups = async () => {
            const episodePhotoGroups = await API.getEpisodePhotoGroups({ episodeID: newProductEpisode.value });
            let options = episodePhotoGroups.map(photoGroup => {
                return { name: photoGroup.name, value: photoGroup.id }
            })

            setEpisodePhotoGroupOptions(options);
        }

        fetchEpisodePhotoGroups();
    }, [newProductEpisode])

    const onEpisodeChange = (event, newValue) => {
        setNewProductEpisode(newValue);
    }

    const onPhotoGroupChange = (event, newValue) => {
        setNewEpisodePhotoGroup(newValue);
    }

    const deleteProductPhotoGroup = async (id) => {
        await API.deleteEpisodePhotoProduct(id);
        await fetchProductEpisodePhotoGroups();
    }

    const addProductPhotoGroup = async () => {
        if (!props.product) return;

        let input = {
            groupID: newEpisodePhotoGroup.value,
            productID: props.product.id
        }

        // TODO: Add result instead of fetching again?
        await API.createEpisodePhotoProduct(input);
        await fetchProductEpisodePhotoGroups();
    }

    return (
        <TabPanel value={props.tab} index={props.index} direction="column" spacing={2}>
            <Grid item>
                <TableContainer>
                    <Table className={classes.menuTable} size="small" aria-label="seasons">
                        <TableHead>
                            <TableRow style={{ background: "#f9f9f9" }}>
                                <TableCell align="center">Show Name</TableCell>
                                <TableCell align="center">Episode</TableCell>
                                <TableCell align="center">Photo Group Name</TableCell>
                                <TableCell align="center">Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                productPhotoGroups.map((productPhotoGroup: ProductEpisodePhotoGroup, index) => {
                                    return (
                                        <TableRow key={productPhotoGroup.id} style={index % 2 ? { background: "#f9f9f9" } : { background: "white" }}>
                                            <TableCell align="center">{productPhotoGroup.showName}</TableCell>
                                            <TableCell align="center">
                                                {`S${productPhotoGroup.episode.season}E${productPhotoGroup.episode.number} - ${productPhotoGroup.episode.name}`}
                                            </TableCell>
                                            <TableCell align="center">{productPhotoGroup.groupName}</TableCell>
                                            <TableCell align="center">
                                                <Tooltip title="Remove">
                                                    <IconButton color="primary" onClick={() => deleteProductPhotoGroup(productPhotoGroup.id)}>
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </TableCell>
                                        </TableRow>
                                    )
                                })
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            <Grid item>
                <Autocomplete
                    id="episode-combo-box"
                    options={episodeOptions}
                    value={newProductEpisode}
                    getOptionLabel={(option) => option.name}
                    onChange={onEpisodeChange}
                    renderInput={(params) => <TextField {...params} label="Episode" variant="outlined" />}
                />
            </Grid>
            <Grid item>
                <Autocomplete
                    id="episode-photo-groups-combo-box"
                    options={episodePhotoGroupOptions}
                    value={newEpisodePhotoGroup}
                    getOptionLabel={(option) => option.name}
                    onChange={onPhotoGroupChange}
                    renderInput={(params) => <TextField {...params} label="Photo Group" variant="outlined" />}
                />
            </Grid>
            <Grid item>
                <Button variant="contained" color="secondary" onClick={addProductPhotoGroup}>
                    Add Product to Photo Group
                </Button>
            </Grid>
        </TabPanel>
    )
}

export interface ProductPhotoGroupsTabProps {
    tab: number
    index: number
    product: Maybe<Product>
}