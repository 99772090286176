import React, { useState, useEffect, useCallback } from 'react';
import { Grid, FormControlLabel, Checkbox, TextField, Button } from '@material-ui/core';

import * as API from '../../../../lib/api';
import { TabPanel } from '../../../tabs/TabPanel';

import { Episode } from '../../../../types';

export function EpisodeOptionsTab(props: EpisodeOptionsTabProps) {
    const [enableHeaderButton, setEnableHeaderButton] = useState(props.episode.enableHeaderButton);
    const [headerButtonText, setHeaderButtonText] = useState(props.episode.headerButtonText);
    const [headerButtonURL, setHeaderButtonURL] = useState(props.episode.headerButtonURL);
    const [disableProductScroller, setDisableProductScroller] = useState(props.episode.disableProductScroller);

    const reset = useCallback(() => {
        setEnableHeaderButton(props.episode.enableHeaderButton);
        setHeaderButtonText(props.episode.headerButtonText);
        setHeaderButtonURL(props.episode.headerButtonURL);
        setDisableProductScroller(props.episode.disableProductScroller);
    }, [props.episode])

    useEffect(() => {
        reset();
    }, [props.episode, reset])

    const updateEpisodeOptions = async () => {
        let input = {
            id: props.episode.id,
            enableHeaderButton: enableHeaderButton,
            headerButtonText: headerButtonText,
            headerButtonURL: headerButtonURL,
            disableProductScroller: disableProductScroller
        }

        // TODO: Do we need to do anything here? We are subscribed for updates in Episode.tsx
        await API.updateEpisode(input);
    }

    return (
        <TabPanel value={props.tab} index={props.index} spacing={2} direction="column">
            <Grid item>
                <FormControlLabel
                    control={<Checkbox checked={enableHeaderButton} onChange={(e) => setEnableHeaderButton(e.target.checked)} name="enableHeaderButton" />}
                    label="Enable Header Button"
                />
            </Grid>
            <Grid item xs={6}>
                <TextField variant="outlined"
                    label="Header Button Text"
                    value={headerButtonText}
                    fullWidth
                />
            </Grid>
            <Grid item xs={6}>
                <TextField variant="outlined"
                    label="Header Button URL"
                    value={headerButtonURL}
                    fullWidth
                />
            </Grid>
            <Grid item>
                <FormControlLabel
                    control={<Checkbox checked={disableProductScroller} onChange={(e) => setDisableProductScroller(e.target.checked)} name="disableProductScroller" />}
                    label="Disable Product Scroller"
                />
            </Grid>
            <Grid item container spacing={2} direction="row">
                <Grid item>
                    <Button variant="contained" color="secondary" onClick={updateEpisodeOptions}>
                        Update Episode Options
                    </Button>
                </Grid>
                <Grid item>
                    <Button variant="outlined" onClick={reset}>
                        Cancel
                    </Button>
                </Grid>
            </Grid>
        </TabPanel>
    )
}
export interface EpisodeOptionsTabProps {
    tab: number
    index: number
    episode: Episode
}