/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createUser = /* GraphQL */ `
  mutation CreateUser($input: CreateUserInput!) {
    createUser(input: $input) {
      id
      email
      enabled
      status
      created
      email_verified
      given_name
      family_name
      gender
      phone_number
      customerID
      vendorID
    }
  }
`;
export const disableUser = /* GraphQL */ `
  mutation DisableUser($id: ID!) {
    disableUser(id: $id) {
      id
      email
      enabled
      status
      created
      email_verified
      given_name
      family_name
      gender
      phone_number
      customerID
      vendorID
    }
  }
`;
export const createCustomer = /* GraphQL */ `
  mutation CreateCustomer($input: CreateCustomerInput!) {
    createCustomer(input: $input) {
      id
      shortName
      fullName
      active
      added
      adminType
      isVendor
      emailAddressIdentity
      streamingContent
      displayShowReports
      displayEpisodeReports
    }
  }
`;
export const updateCustomer = /* GraphQL */ `
  mutation UpdateCustomer($input: UpdateCustomerInput!) {
    updateCustomer(input: $input) {
      id
      shortName
      fullName
      active
      added
      adminType
      isVendor
      emailAddressIdentity
      streamingContent
      displayShowReports
      displayEpisodeReports
    }
  }
`;
export const createShow = /* GraphQL */ `
  mutation CreateShow($input: CreateShowInput!) {
    createShow(input: $input) {
      id
      customerID
      nameShort
      nameLong
      description
      poster
      posterURL
      added
      active
      hideInAdmin
      appID
      mainMenuType
      faqContent
      faviconsURL
      heroImageBucket
      heroImageKey
      appData {
        appLogo
        appLogoURL
        primaryColor
        textPrimaryColor
        secondaryColor
        tertiaryColor
        accentColor
        infoColor
        headBackgroundColor
        headTextColor
        menuTextColor
        productCaroselBackgroundColor
        productButTextColor
        productButBackColor
        productBut2TextColor
        productBut2BackColor
        mobileTextPrimaryColor
        mobileTextSecondaryColor
        mobileAccentColor
        mobileButtonColor
        autoplayEnabled
        autoplayAfterFingerprint
        copyright
        privacyPolicy
        appPosterDisplayType
        episodeTileType
        productTileType
        witAIAppID
        witAIAccessToken
        witAILastUpdate
        googleTrackingID
        googlePlayAppEnabled
        googlePlayAppURL
        facebookAppID
        facebookPageURL
        instagramUsername
        twitterUsername
        iOSAppStoreID
        videoGroupingName
        videoGroupingNamePlural
        featuredProductsLabel
      }
      channel {
        rokuChannelEnabled
        rokuChannelType
        rokuChannelInstallURL
        fireTVChannelEnabled
        fireTVInstallURL
        fireTVDevURL
      }
      demographicConfiguration {
        introText
        ageEnabled
        genderEnabled
        ethnicityEnabled
        educationEnabled
        employmentEnabled
        maritalStatusEnabled
        incomeEnabled
        zipcodeEnabled
        emailEnabled
      }
      productRequestConfiguration {
        enabled
        titleText
        introText
        episodeListEnabled
        episodeListText
        photoGroupEnabled
        photoGroupText
        categoryEnabled
        categoryText
        emailEnabled
        emailText
        requestText
        submitThanksText
        notificationsList
      }
    }
  }
`;
export const updateShow = /* GraphQL */ `
  mutation UpdateShow($input: UpdateShowInput!) {
    updateShow(input: $input) {
      id
      customerID
      nameShort
      nameLong
      description
      poster
      posterURL
      added
      active
      hideInAdmin
      appID
      mainMenuType
      faqContent
      faviconsURL
      heroImageBucket
      heroImageKey
      appData {
        appLogo
        appLogoURL
        primaryColor
        textPrimaryColor
        secondaryColor
        tertiaryColor
        accentColor
        infoColor
        headBackgroundColor
        headTextColor
        menuTextColor
        productCaroselBackgroundColor
        productButTextColor
        productButBackColor
        productBut2TextColor
        productBut2BackColor
        mobileTextPrimaryColor
        mobileTextSecondaryColor
        mobileAccentColor
        mobileButtonColor
        autoplayEnabled
        autoplayAfterFingerprint
        copyright
        privacyPolicy
        appPosterDisplayType
        episodeTileType
        productTileType
        witAIAppID
        witAIAccessToken
        witAILastUpdate
        googleTrackingID
        googlePlayAppEnabled
        googlePlayAppURL
        facebookAppID
        facebookPageURL
        instagramUsername
        twitterUsername
        iOSAppStoreID
        videoGroupingName
        videoGroupingNamePlural
        featuredProductsLabel
      }
      channel {
        rokuChannelEnabled
        rokuChannelType
        rokuChannelInstallURL
        fireTVChannelEnabled
        fireTVInstallURL
        fireTVDevURL
      }
      demographicConfiguration {
        introText
        ageEnabled
        genderEnabled
        ethnicityEnabled
        educationEnabled
        employmentEnabled
        maritalStatusEnabled
        incomeEnabled
        zipcodeEnabled
        emailEnabled
      }
      productRequestConfiguration {
        enabled
        titleText
        introText
        episodeListEnabled
        episodeListText
        photoGroupEnabled
        photoGroupText
        categoryEnabled
        categoryText
        emailEnabled
        emailText
        requestText
        submitThanksText
        notificationsList
      }
    }
  }
`;
export const updateShowAppData = /* GraphQL */ `
  mutation UpdateShowAppData($input: UpdateShowAppDataInput!) {
    updateShowAppData(input: $input) {
      appLogo
      appLogoURL
      primaryColor
      textPrimaryColor
      secondaryColor
      tertiaryColor
      accentColor
      infoColor
      headBackgroundColor
      headTextColor
      menuTextColor
      productCaroselBackgroundColor
      productButTextColor
      productButBackColor
      productBut2TextColor
      productBut2BackColor
      mobileTextPrimaryColor
      mobileTextSecondaryColor
      mobileAccentColor
      mobileButtonColor
      autoplayEnabled
      autoplayAfterFingerprint
      copyright
      privacyPolicy
      appPosterDisplayType
      episodeTileType
      productTileType
      witAIAppID
      witAIAccessToken
      witAILastUpdate
      googleTrackingID
      googlePlayAppEnabled
      googlePlayAppURL
      facebookAppID
      facebookPageURL
      instagramUsername
      twitterUsername
      iOSAppStoreID
      videoGroupingName
      videoGroupingNamePlural
      featuredProductsLabel
    }
  }
`;
export const updateShowChannel = /* GraphQL */ `
  mutation UpdateShowChannel($input: UpdateShowChannelInput!) {
    updateShowChannel(input: $input) {
      rokuChannelEnabled
      rokuChannelType
      rokuChannelInstallURL
      fireTVChannelEnabled
      fireTVInstallURL
      fireTVDevURL
    }
  }
`;
export const updateShowProductRequestConfiguration = /* GraphQL */ `
  mutation UpdateShowProductRequestConfiguration(
    $input: UpdateShowProductRequestConfigurationInput
  ) {
    updateShowProductRequestConfiguration(input: $input) {
      enabled
      titleText
      introText
      episodeListEnabled
      episodeListText
      photoGroupEnabled
      photoGroupText
      categoryEnabled
      categoryText
      emailEnabled
      emailText
      requestText
      submitThanksText
      notificationsList
    }
  }
`;
export const updateShowPoster = /* GraphQL */ `
  mutation UpdateShowPoster($input: UpdateShowImageInput!) {
    updateShowPoster(input: $input) {
      url
    }
  }
`;
export const updateShowAppLogo = /* GraphQL */ `
  mutation UpdateShowAppLogo($input: UpdateShowImageInput!) {
    updateShowAppLogo(input: $input) {
      url
    }
  }
`;
export const addShowMetadata = /* GraphQL */ `
  mutation AddShowMetadata($input: ShowMetadataItemInput!) {
    addShowMetadata(input: $input) {
      id
      showID
      name
      value
    }
  }
`;
export const deleteShowMetadata = /* GraphQL */ `
  mutation DeleteShowMetadata($input: DeleteShowMetadataItemInput!) {
    deleteShowMetadata(input: $input) {
      id
      showID
      name
      value
    }
  }
`;
export const createShowSeason = /* GraphQL */ `
  mutation CreateShowSeason($input: CreateShowSeasonInput!) {
    createShowSeason(input: $input) {
      showID
      season
      name
    }
  }
`;
export const createShowDesignMenuItem = /* GraphQL */ `
  mutation CreateShowDesignMenuItem($input: CreateShowDesignMenuItemInput!) {
    createShowDesignMenuItem(input: $input) {
      id
      showID
      orderID
      title
      description
      icon
      iconColor
      url
      showInPullOutMenu
      showInHomeMenu
      enabled
    }
  }
`;
export const updateShowDesignMenuItem = /* GraphQL */ `
  mutation UpdateShowDesignMenuItem($input: UpdateShowDesignMenuItemInput!) {
    updateShowDesignMenuItem(input: $input) {
      id
      showID
      orderID
      title
      description
      icon
      iconColor
      url
      showInPullOutMenu
      showInHomeMenu
      enabled
    }
  }
`;
export const deleteShowDesignMenuItem = /* GraphQL */ `
  mutation DeleteShowDesignMenuItem($id: Int!) {
    deleteShowDesignMenuItem(id: $id)
  }
`;
export const createEpisode = /* GraphQL */ `
  mutation CreateEpisode($input: CreateEpisodeInput!) {
    createEpisode(input: $input) {
      id
      showID
      showName
      season
      seasonName
      number
      firstRelease
      added
      name
      description
      disclaimer
      enabled
      posterType
      poster
      posterURL
      posterFileID
      video
      videoBucket
      videoKey
      videoQuality
      videoType
      videoWidth
      videoHeight
      mediaConvertJobID
      mediaConvertJobStatus
      audioFingerprintID
      duration
      rokuAddBreaks
      releaseToOTT
      releaseToApp
      liveChannelID
      enableHeaderButton
      headerButtonText
      headerButtonURL
      disableProductScroller
      liveStreamID
      liveStreamEnabledOnStartMenuID
      videoToken
    }
  }
`;
export const updateEpisode = /* GraphQL */ `
  mutation UpdateEpisode($input: UpdateEpisodeInput!) {
    updateEpisode(input: $input) {
      id
      showID
      showName
      season
      seasonName
      number
      firstRelease
      added
      name
      description
      disclaimer
      enabled
      posterType
      poster
      posterURL
      posterFileID
      video
      videoBucket
      videoKey
      videoQuality
      videoType
      videoWidth
      videoHeight
      mediaConvertJobID
      mediaConvertJobStatus
      audioFingerprintID
      duration
      rokuAddBreaks
      releaseToOTT
      releaseToApp
      liveChannelID
      enableHeaderButton
      headerButtonText
      headerButtonURL
      disableProductScroller
      liveStreamID
      liveStreamEnabledOnStartMenuID
      videoToken
    }
  }
`;
export const updateEpisodeVideoCleanup = /* GraphQL */ `
  mutation UpdateEpisodeVideoCleanup($id: Int!) {
    updateEpisodeVideoCleanup(id: $id)
  }
`;
export const updateEpisodePoster = /* GraphQL */ `
  mutation UpdateEpisodePoster($input: UpdateEpisodeImageInput!) {
    updateEpisodePoster(input: $input) {
      url
    }
  }
`;
export const createEpisodePhoto = /* GraphQL */ `
  mutation CreateEpisodePhoto($input: CreateEpisodePhotoInput!) {
    createEpisodePhoto(input: $input) {
      id
      episodeID
      groupID
      groupName
      order
      title
      description
      fileID
      extension
      url
      thumbnailURL
      enabled
      added
    }
  }
`;
export const deleteEpisodePhoto = /* GraphQL */ `
  mutation DeleteEpisodePhoto($id: Int!) {
    deleteEpisodePhoto(id: $id)
  }
`;
export const createEpisodeCommercial = /* GraphQL */ `
  mutation CreateEpisodeCommercial($input: CreateEpisodeCommercialInput!) {
    createEpisodeCommercial(input: $input) {
      id
      episodeID
      commercialEpisodeID
      type
      spliceTime
      showID
      episodeName
      episodePoster
      episodePosterURL
      enabled
      duration
    }
  }
`;
export const deleteEpisodeCommercial = /* GraphQL */ `
  mutation DeleteEpisodeCommercial($id: Int!, $episodeID: Int!) {
    deleteEpisodeCommercial(id: $id, episodeID: $episodeID)
  }
`;
export const createProduct = /* GraphQL */ `
  mutation CreateProduct($input: CreateProductInput!) {
    createProduct(input: $input) {
      id
      customerID
      name
      description
      upc
      brand
      brandID
      sku
      active
      added
      deleted
      approved
      replacementProduct
      mainPhotoURL
      mainPhotoFileID
      mainPhotoFileExt
      mainPhotoSize
      issueCount
      photoBucket
      photoKey
      warnings
      originalProductName
      originalProductDescription
      originalProductURL
      originalProductPhotoBucket
      originalProductPhotoKey
      featured
      price
      vendorID
      vendorLogo
      vendorLogoBucket
      vendorLogoKey
    }
  }
`;
export const updateProduct = /* GraphQL */ `
  mutation UpdateProduct($input: UpdateProductInput!) {
    updateProduct(input: $input) {
      id
      customerID
      name
      description
      upc
      brand
      brandID
      sku
      active
      added
      deleted
      approved
      replacementProduct
      mainPhotoURL
      mainPhotoFileID
      mainPhotoFileExt
      mainPhotoSize
      issueCount
      photoBucket
      photoKey
      warnings
      originalProductName
      originalProductDescription
      originalProductURL
      originalProductPhotoBucket
      originalProductPhotoKey
      featured
      price
      vendorID
      vendorLogo
      vendorLogoBucket
      vendorLogoKey
    }
  }
`;
export const deleteProduct = /* GraphQL */ `
  mutation DeleteProduct($id: Int!) {
    deleteProduct(id: $id)
  }
`;
export const createProductLink = /* GraphQL */ `
  mutation CreateProductLink($input: CreateProductLinkInput!) {
    createProductLink(input: $input) {
      id
      productID
      storeID
      storeName
      type
      fullURL
      status
      statusLastChecked
    }
  }
`;
export const deleteProductLink = /* GraphQL */ `
  mutation DeleteProductLink($id: Int!, $productID: Int!) {
    deleteProductLink(id: $id, productID: $productID)
  }
`;
export const createProductCategory = /* GraphQL */ `
  mutation CreateProductCategory($input: CreateProductCategoryInput!) {
    createProductCategory(input: $input) {
      items {
        productID
        categoryID
        categoryName
      }
    }
  }
`;
export const deleteProductCategory = /* GraphQL */ `
  mutation DeleteProductCategory($productID: Int!, $categoryID: Int!) {
    deleteProductCategory(productID: $productID, categoryID: $categoryID)
  }
`;
export const createEpisodeProduct = /* GraphQL */ `
  mutation CreateEpisodeProduct($input: CreateEpisodeProductInput!) {
    createEpisodeProduct(input: $input)
  }
`;
export const deleteEpisodeProduct = /* GraphQL */ `
  mutation DeleteEpisodeProduct($id: Int!, $episodeID: Int!) {
    deleteEpisodeProduct(id: $id, episodeID: $episodeID)
  }
`;
export const createEpisodePhotoProduct = /* GraphQL */ `
  mutation CreateEpisodePhotoProduct($input: CreateEpisodePhotoProductInput!) {
    createEpisodePhotoProduct(input: $input)
  }
`;
export const deleteEpisodePhotoProduct = /* GraphQL */ `
  mutation DeleteEpisodePhotoProduct($id: Int!) {
    deleteEpisodePhotoProduct(id: $id)
  }
`;
export const createProductMetadataItem = /* GraphQL */ `
  mutation CreateProductMetadataItem($input: CreateProductMetadataItem) {
    createProductMetadataItem(input: $input) {
      id
      productID
      name
      value
    }
  }
`;
export const deleteProductMetadataItem = /* GraphQL */ `
  mutation DeleteProductMetadataItem($id: Int!) {
    deleteProductMetadataItem(id: $id)
  }
`;
export const updateBrand = /* GraphQL */ `
  mutation UpdateBrand($input: UpdateBrandInput!) {
    updateBrand(input: $input) {
      id
      customerID
      name
      productCount
    }
  }
`;
export const createCategory = /* GraphQL */ `
  mutation CreateCategory($input: CreateCategoryInput!) {
    createCategory(input: $input) {
      id
      customerID
      name
      image
      imageURL
      imageFileSize
      active
      thumbnail
      thumbnailURL
      thumbnailFileSize
      tag
      description
    }
  }
`;
export const updateCategory = /* GraphQL */ `
  mutation UpdateCategory($input: UpdateCategoryInput!) {
    updateCategory(input: $input) {
      id
      customerID
      name
      image
      imageURL
      imageFileSize
      active
      thumbnail
      thumbnailURL
      thumbnailFileSize
      tag
      description
    }
  }
`;
export const deleteCategory = /* GraphQL */ `
  mutation DeleteCategory($id: Int!, $customerID: Int!) {
    deleteCategory(id: $id, customerID: $customerID)
  }
`;
export const createStore = /* GraphQL */ `
  mutation CreateStore($input: CreateStoreInput!) {
    createStore(input: $input) {
      id
      customerID
      name
      active
      added
      domainName
      searchURL
      productCount
    }
  }
`;
export const updateStore = /* GraphQL */ `
  mutation UpdateStore($input: UpdateStoreInput!) {
    updateStore(input: $input) {
      id
      customerID
      name
      active
      added
      domainName
      searchURL
      productCount
    }
  }
`;
export const createVendorProduct = /* GraphQL */ `
  mutation CreateVendorProduct($input: CreateVendorProductInput!) {
    createVendorProduct(input: $input)
  }
`;
export const deleteVendorProduct = /* GraphQL */ `
  mutation DeleteVendorProduct($vendorID: ID!, $productID: Int!) {
    deleteVendorProduct(vendorID: $vendorID, productID: $productID)
  }
`;
export const createEmailReportSetting = /* GraphQL */ `
  mutation CreateEmailReportSetting($input: CreateEmailReportSettingInput!) {
    createEmailReportSetting(input: $input) {
      id
      userID
      reportType
      daily
      weekly
      monthly
      yearly
      customerID
      customerName
      showID
      showName
      lastRun
      lastRunTime
    }
  }
`;
export const updateEmailReportSetting = /* GraphQL */ `
  mutation UpdateEmailReportSetting($input: UpdateEmailReportSettingInput!) {
    updateEmailReportSetting(input: $input) {
      id
      userID
      reportType
      daily
      weekly
      monthly
      yearly
      customerID
      customerName
      showID
      showName
      lastRun
      lastRunTime
    }
  }
`;
export const deleteEmailReportSetting = /* GraphQL */ `
  mutation DeleteEmailReportSetting($id: Int!) {
    deleteEmailReportSetting(id: $id)
  }
`;
export const createProductRequest = /* GraphQL */ `
  mutation CreateProductRequest($input: CreateProductRequestInput) {
    createProductRequest(input: $input)
  }
`;
export const updateProductRequest = /* GraphQL */ `
  mutation UpdateProductRequest($input: UpdateProductRequestInput) {
    updateProductRequest(input: $input) {
      id
      showID
      appInstallID
      episodeID
      episodeSeason
      episodeNumber
      episodeName
      photoGroup
      category
      email
      request
      added
      ip
      status
      lastChangedByAUID
      userSub
      response
    }
  }
`;
export const replyProductRequest = /* GraphQL */ `
  mutation ReplyProductRequest($input: ReplyProductRequestInput) {
    replyProductRequest(input: $input)
  }
`;
export const updateVendorLogo = /* GraphQL */ `
  mutation UpdateVendorLogo($input: UpdateVendorLogoInput) {
    updateVendorLogo(input: $input)
  }
`;
export const generatePresignedUrl = /* GraphQL */ `
  mutation GeneratePresignedUrl($input: S3SignedURLInput!) {
    generatePresignedURL(input: $input) {
      url
    }
  }
`;
export const generatePresignedRawMediaUrl = /* GraphQL */ `
  mutation GeneratePresignedRawMediaUrl($input: S3SignedURLInput!) {
    generatePresignedRawMediaURL(input: $input) {
      url
    }
  }
`;
export const generatePresignedRawVideoUrl = /* GraphQL */ `
  mutation GeneratePresignedRawVideoUrl($input: S3SignedURLInput!) {
    generatePresignedRawVideoURL(input: $input) {
      url
    }
  }
`;
export const resolveProductsReport = /* GraphQL */ `
  mutation ResolveProductsReport($input: ReportProductConnectionInput) {
    resolveProductsReport(input: $input) {
      id
      items {
        id
        name
        brandName
        upc
        sku
        mainPhotoURL
        mainPhotoFileId
        mainPhotoFileExt
        active
        deleted
        added
        imagePreviews
        detailViews
        clicks
      }
      nextToken
    }
  }
`;
export const resolveVendorProductsReport = /* GraphQL */ `
  mutation ResolveVendorProductsReport($input: ReportProductConnectionInput) {
    resolveVendorProductsReport(input: $input) {
      id
      items {
        id
        name
        brandName
        upc
        sku
        mainPhotoURL
        mainPhotoFileId
        mainPhotoFileExt
        active
        deleted
        added
        imagePreviews
        detailViews
        clicks
      }
      nextToken
    }
  }
`;
export const resolveAdminAdReport = /* GraphQL */ `
  mutation ResolveAdminAdReport($input: AdTrackingReportConnectionInput) {
    resolveAdminAdReport(input: $input) {
      id
      items {
        id
        customerID
        showID
        episodeID
        categoryID
        imageBucket
        imageKey
        vendorAd {
          id
          vendorID
          name
          description
          imageBucket
          imageKey
          url
          type
          createdAt
          updatedAt
        }
        url
        linkType
        type
        createdAt
        updatedAt
        impressions
        clicks
      }
      nextToken
    }
  }
`;
export const createAppInstall = /* GraphQL */ `
  mutation CreateAppInstall($input: CreateAppInstallInput) {
    createAppInstall(input: $input)
  }
`;
export const updateAppInstall = /* GraphQL */ `
  mutation UpdateAppInstall($id: Int!) {
    updateAppInstall(id: $id)
  }
`;
export const createAppUsageLog = /* GraphQL */ `
  mutation CreateAppUsageLog($input: CreateAppUsageLogInput!) {
    createAppUsageLog(input: $input) {
      id
      date
      ipAddress
      userAgent
      referer
      userID
      appID
      appVersion
      episodeID
      exclusiveID
      productID
      linkID
      logEntry
      logData
      version
    }
  }
`;
export const createBannerAdLog = /* GraphQL */ `
  mutation CreateBannerAdLog($input: CreateBannerAdLogInput!) {
    createBannerAdLog(input: $input) {
      id
      bannerAdID
      type
      appID
      referer
      date
      ipAddress
      userAgent
      userID
      deviceID
      appVersion
      vendorID
      episodeID
      categoryID
      showID
      exclusiveID
    }
  }
`;
export const addProductToWishlist = /* GraphQL */ `
  mutation AddProductToWishlist($productID: Int!) {
    addProductToWishlist(productID: $productID) {
      userID
      productID
      added
    }
  }
`;
export const deleteProductFromWishlist = /* GraphQL */ `
  mutation DeleteProductFromWishlist($productID: Int!) {
    deleteProductFromWishlist(productID: $productID)
  }
`;
export const cleanupBannerAd = /* GraphQL */ `
  mutation CleanupBannerAd($id: ID!) {
    cleanupBannerAd(id: $id)
  }
`;
export const deleteExclusiveVideoContent = /* GraphQL */ `
  mutation DeleteExclusiveVideoContent(
    $input: DeleteExclusiveVideoContentInput!
  ) {
    deleteExclusiveVideoContent(input: $input) {
      id
      legacyCustomerID
      legacyCustomerIDString
      legacyShowID
      name
      description
      enabled
      firstRelease
      posterBucket
      posterKey
      videoBucket
      videoKey
      videoURL
      videoQuality
      videoType
      videoWidth
      videoHeight
      mediaConvertJobID
      mediaConvertJobStatus
      duration
      releaseToOTT
      releaseToApp
      videoToken
      disableProductScroller
      productDefinitions {
        productID
        startTimeMilliseconds
        endTimeMilliseconds
      }
      videoProducts {
        brandName
        product {
          id
          customerID
          name
          description
          upc
          brand
          brandID
          sku
          active
          added
          deleted
          approved
          replacementProduct
          mainPhotoURL
          mainPhotoFileID
          mainPhotoFileExt
          mainPhotoSize
          issueCount
          photoBucket
          photoKey
          warnings
          originalProductName
          originalProductDescription
          originalProductURL
          originalProductPhotoBucket
          originalProductPhotoKey
          featured
          price
          vendorID
          vendorLogo
          vendorLogoBucket
          vendorLogoKey
        }
        startTimeMilliseconds
        endTimeMilliseconds
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteNotification = /* GraphQL */ `
  mutation DeleteNotification($input: DeleteNotificationInput!) {
    deleteNotification(input: $input) {
      id
      userID
      description
      time
      createdAt
      updatedAt
    }
  }
`;
export const createVendor = /* GraphQL */ `
  mutation CreateVendor($input: CreateVendorInput!) {
    createVendor(input: $input) {
      id
      name
      type
      active
      salesOwnedByUserID
      balance
      balanceUpdated
      logo
      logoFileID
      logoFileExtension
      logoBucket
      logoKey
      pixelTrackingID
      createdAt
      updatedAt
    }
  }
`;
export const deleteVendor = /* GraphQL */ `
  mutation DeleteVendor($input: DeleteVendorInput!) {
    deleteVendor(input: $input) {
      id
      name
      type
      active
      salesOwnedByUserID
      balance
      balanceUpdated
      logo
      logoFileID
      logoFileExtension
      logoBucket
      logoKey
      pixelTrackingID
      createdAt
      updatedAt
    }
  }
`;
export const createShowFontV2 = /* GraphQL */ `
  mutation CreateShowFontV2($input: CreateShowFontV2Input!) {
    createShowFontV2(input: $input) {
      id
      showFontLocationComposite
      showID
      family
      location
      weight
      style
      createdAt
      updatedAt
    }
  }
`;
export const updateShowFontV2 = /* GraphQL */ `
  mutation UpdateShowFontV2($input: UpdateShowFontV2Input!) {
    updateShowFontV2(input: $input) {
      id
      showFontLocationComposite
      showID
      family
      location
      weight
      style
      createdAt
      updatedAt
    }
  }
`;
export const deleteShowFontV2 = /* GraphQL */ `
  mutation DeleteShowFontV2($input: DeleteShowFontV2Input!) {
    deleteShowFontV2(input: $input) {
      id
      showFontLocationComposite
      showID
      family
      location
      weight
      style
      createdAt
      updatedAt
    }
  }
`;
export const createCubejsDashboardItem = /* GraphQL */ `
  mutation CreateCubejsDashboardItem($input: CreateCubejsDashboardItemInput!) {
    createCubejsDashboardItem(input: $input) {
      id
      userID
      layout
      vizState
      name
      createdAt
      updatedAt
    }
  }
`;
export const updateCubejsDashboardItem = /* GraphQL */ `
  mutation UpdateCubejsDashboardItem($input: UpdateCubejsDashboardItemInput!) {
    updateCubejsDashboardItem(input: $input) {
      id
      userID
      layout
      vizState
      name
      createdAt
      updatedAt
    }
  }
`;
export const deleteCubejsDashboardItem = /* GraphQL */ `
  mutation DeleteCubejsDashboardItem($input: DeleteCubejsDashboardItemInput!) {
    deleteCubejsDashboardItem(input: $input) {
      id
      userID
      layout
      vizState
      name
      createdAt
      updatedAt
    }
  }
`;
export const createDigitalAssetLinks = /* GraphQL */ `
  mutation CreateDigitalAssetLinks($input: CreateDigitalAssetLinksInput!) {
    createDigitalAssetLinks(input: $input) {
      appID
      legacyShowID
      legacyCustomerID
      android
      createdAt
      updatedAt
    }
  }
`;
export const updateDigitalAssetLinks = /* GraphQL */ `
  mutation UpdateDigitalAssetLinks($input: UpdateDigitalAssetLinksInput!) {
    updateDigitalAssetLinks(input: $input) {
      appID
      legacyShowID
      legacyCustomerID
      android
      createdAt
      updatedAt
    }
  }
`;
export const deleteDigitalAssetLinks = /* GraphQL */ `
  mutation DeleteDigitalAssetLinks($input: DeleteDigitalAssetLinksInput!) {
    deleteDigitalAssetLinks(input: $input) {
      appID
      legacyShowID
      legacyCustomerID
      android
      createdAt
      updatedAt
    }
  }
`;
export const createHomeScreenSettings = /* GraphQL */ `
  mutation CreateHomeScreenSettings($input: CreateHomeScreenSettingsInput!) {
    createHomeScreenSettings(input: $input) {
      showID
      legacyCustomerID
      legacyCustomerIDString
      featuredProductsLabel
      smallBusinessLabel
      recentEpisodesLabel
      recommendedProductsLabel
      createdAt
      updatedAt
    }
  }
`;
export const updateHomeScreenSettings = /* GraphQL */ `
  mutation UpdateHomeScreenSettings($input: UpdateHomeScreenSettingsInput!) {
    updateHomeScreenSettings(input: $input) {
      showID
      legacyCustomerID
      legacyCustomerIDString
      featuredProductsLabel
      smallBusinessLabel
      recentEpisodesLabel
      recommendedProductsLabel
      createdAt
      updatedAt
    }
  }
`;
export const deleteHomeScreenSettings = /* GraphQL */ `
  mutation DeleteHomeScreenSettings($input: DeleteHomeScreenSettingsInput!) {
    deleteHomeScreenSettings(input: $input) {
      showID
      legacyCustomerID
      legacyCustomerIDString
      featuredProductsLabel
      smallBusinessLabel
      recentEpisodesLabel
      recommendedProductsLabel
      createdAt
      updatedAt
    }
  }
`;
export const createExclusiveVideoContent = /* GraphQL */ `
  mutation CreateExclusiveVideoContent(
    $input: CreateExclusiveVideoContentInput!
  ) {
    createExclusiveVideoContent(input: $input) {
      id
      legacyCustomerID
      legacyCustomerIDString
      legacyShowID
      name
      description
      enabled
      firstRelease
      posterBucket
      posterKey
      videoBucket
      videoKey
      videoURL
      videoQuality
      videoType
      videoWidth
      videoHeight
      mediaConvertJobID
      mediaConvertJobStatus
      duration
      releaseToOTT
      releaseToApp
      videoToken
      disableProductScroller
      productDefinitions {
        productID
        startTimeMilliseconds
        endTimeMilliseconds
      }
      videoProducts {
        brandName
        product {
          id
          customerID
          name
          description
          upc
          brand
          brandID
          sku
          active
          added
          deleted
          approved
          replacementProduct
          mainPhotoURL
          mainPhotoFileID
          mainPhotoFileExt
          mainPhotoSize
          issueCount
          photoBucket
          photoKey
          warnings
          originalProductName
          originalProductDescription
          originalProductURL
          originalProductPhotoBucket
          originalProductPhotoKey
          featured
          price
          vendorID
          vendorLogo
          vendorLogoBucket
          vendorLogoKey
        }
        startTimeMilliseconds
        endTimeMilliseconds
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateExclusiveVideoContent = /* GraphQL */ `
  mutation UpdateExclusiveVideoContent(
    $input: UpdateExclusiveVideoContentInput!
  ) {
    updateExclusiveVideoContent(input: $input) {
      id
      legacyCustomerID
      legacyCustomerIDString
      legacyShowID
      name
      description
      enabled
      firstRelease
      posterBucket
      posterKey
      videoBucket
      videoKey
      videoURL
      videoQuality
      videoType
      videoWidth
      videoHeight
      mediaConvertJobID
      mediaConvertJobStatus
      duration
      releaseToOTT
      releaseToApp
      videoToken
      disableProductScroller
      productDefinitions {
        productID
        startTimeMilliseconds
        endTimeMilliseconds
      }
      videoProducts {
        brandName
        product {
          id
          customerID
          name
          description
          upc
          brand
          brandID
          sku
          active
          added
          deleted
          approved
          replacementProduct
          mainPhotoURL
          mainPhotoFileID
          mainPhotoFileExt
          mainPhotoSize
          issueCount
          photoBucket
          photoKey
          warnings
          originalProductName
          originalProductDescription
          originalProductURL
          originalProductPhotoBucket
          originalProductPhotoKey
          featured
          price
          vendorID
          vendorLogo
          vendorLogoBucket
          vendorLogoKey
        }
        startTimeMilliseconds
        endTimeMilliseconds
      }
      createdAt
      updatedAt
    }
  }
`;
export const createShowMetaTags = /* GraphQL */ `
  mutation CreateShowMetaTags($input: CreateShowMetaTagsInput!) {
    createShowMetaTags(input: $input) {
      appID
      showID
      OG_TITLE
      OG_DESCRIPTION
      OG_IMAGE
      FAVICON
      MANIFEST
      createdAt
      updatedAt
    }
  }
`;
export const updateShowMetaTags = /* GraphQL */ `
  mutation UpdateShowMetaTags($input: UpdateShowMetaTagsInput!) {
    updateShowMetaTags(input: $input) {
      appID
      showID
      OG_TITLE
      OG_DESCRIPTION
      OG_IMAGE
      FAVICON
      MANIFEST
      createdAt
      updatedAt
    }
  }
`;
export const deleteShowMetaTags = /* GraphQL */ `
  mutation DeleteShowMetaTags($input: DeleteShowMetaTagsInput!) {
    deleteShowMetaTags(input: $input) {
      appID
      showID
      OG_TITLE
      OG_DESCRIPTION
      OG_IMAGE
      FAVICON
      MANIFEST
      createdAt
      updatedAt
    }
  }
`;
export const createNotification = /* GraphQL */ `
  mutation CreateNotification($input: CreateNotificationInput!) {
    createNotification(input: $input) {
      id
      userID
      description
      time
      createdAt
      updatedAt
    }
  }
`;
export const updateNotification = /* GraphQL */ `
  mutation UpdateNotification($input: UpdateNotificationInput!) {
    updateNotification(input: $input) {
      id
      userID
      description
      time
      createdAt
      updatedAt
    }
  }
`;
export const updateVendor = /* GraphQL */ `
  mutation UpdateVendor($input: UpdateVendorInput!) {
    updateVendor(input: $input) {
      id
      name
      type
      active
      salesOwnedByUserID
      balance
      balanceUpdated
      logo
      logoFileID
      logoFileExtension
      logoBucket
      logoKey
      pixelTrackingID
      createdAt
      updatedAt
    }
  }
`;
export const createVendorAd = /* GraphQL */ `
  mutation CreateVendorAd($input: CreateVendorAdInput!) {
    createVendorAd(input: $input) {
      id
      vendorID
      name
      description
      imageBucket
      imageKey
      url
      type
      createdAt
      updatedAt
    }
  }
`;
export const updateVendorAd = /* GraphQL */ `
  mutation UpdateVendorAd($input: UpdateVendorAdInput!) {
    updateVendorAd(input: $input) {
      id
      vendorID
      name
      description
      imageBucket
      imageKey
      url
      type
      createdAt
      updatedAt
    }
  }
`;
export const deleteVendorAd = /* GraphQL */ `
  mutation DeleteVendorAd($input: DeleteVendorAdInput!) {
    deleteVendorAd(input: $input) {
      id
      vendorID
      name
      description
      imageBucket
      imageKey
      url
      type
      createdAt
      updatedAt
    }
  }
`;
export const createBannerAd = /* GraphQL */ `
  mutation CreateBannerAd($input: CreateBannerAdInput!) {
    createBannerAd(input: $input) {
      id
      customerID
      showID
      episodeID
      categoryID
      imageBucket
      imageKey
      url
      linkType
      type
      createdAt
      updatedAt
      vendorAd {
        id
        vendorID
        name
        description
        imageBucket
        imageKey
        url
        type
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateBannerAd = /* GraphQL */ `
  mutation UpdateBannerAd($input: UpdateBannerAdInput!) {
    updateBannerAd(input: $input) {
      id
      customerID
      showID
      episodeID
      categoryID
      imageBucket
      imageKey
      url
      linkType
      type
      createdAt
      updatedAt
      vendorAd {
        id
        vendorID
        name
        description
        imageBucket
        imageKey
        url
        type
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteBannerAd = /* GraphQL */ `
  mutation DeleteBannerAd($input: DeleteBannerAdInput!) {
    deleteBannerAd(input: $input) {
      id
      customerID
      showID
      episodeID
      categoryID
      imageBucket
      imageKey
      url
      linkType
      type
      createdAt
      updatedAt
      vendorAd {
        id
        vendorID
        name
        description
        imageBucket
        imageKey
        url
        type
        createdAt
        updatedAt
      }
    }
  }
`;
