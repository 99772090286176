import React, { useState, useEffect, useCallback, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import {
    Grid, TextField, Checkbox, FormControlLabel, Button, LinearProgress, Typography,
    Dialog, DialogActions, DialogTitle, DialogContent, DialogContentText
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { FileUploadButton } from '../../shared/FileUploadButton';
import { TabPanel } from '../../tabs/TabPanel';

import { ProductStore } from '../ProductsStateProvider';

import { Maybe, Product } from '../../../types';
import useSnackbars from '../../../hooks/useSnackbars';
import * as API from '../../../lib/api';
import { uploadPresignedFile } from '../../../lib/util';
import path from 'path';

const defaultOption = { name: '', value: -1 }

export function ProductDetailTab(props: ProductDetailTabProps) {
    const history = useHistory();
    const { dispatch } = useContext(ProductStore);
    const { createSuccessSnack, createErrorSnack } = useSnackbars();
    const [brandsOptions, setBrandsOptions] = useState<Array<any>>([]);
    const [active, setActive] = useState(false);
    const [approved, setApproved] = useState(false);
    const [isReplacementProduct, setIsReplacementProduct] = useState(false);
    const [featured, setFeatured] = useState(false);
    const [brand, setBrand] = useState(defaultOption);
    const [upc, setUPC] = useState('');
    const [sku, setSKU] = useState('');
    const [productName, setProductName] = useState('');
    const [description, setDescription] = useState('');
    const [imageURL, setImageURL] = useState('');
    const [progress, setProgress] = useState(0);
    const [uploading, setUploading] = useState(false);
    const [confirmOpen, setConfirmOpen] = useState(false);

    const reset = useCallback(() => {
        setActive(props.product?.active || false);
        setApproved(props.product?.approved || false);
        setIsReplacementProduct(props.product?.replacementProduct || false);
        setFeatured(props.product?.featured || false);
        setUPC(props.product?.upc || '');
        setSKU(props.product?.sku || '');
        setProductName(props.product?.name || '');
        setDescription(props.product?.description || '');
        setImageURL(props.product?.mainPhotoURL || '');
    }, [props.product])

    console.log(props.product);

    useEffect(() => {
        if (!props.product) return;
        reset();

        const fetchBrands = async () => {
            const brandsResult = await API.listBrands({ customerID: props.product!.customerID });
            let brandsOptions = brandsResult.map(brand => {
                return { name: brand.name, value: brand.id }
            })

            setBrandsOptions(brandsOptions);

            let findBrand = brandsOptions.find(brandOption => brandOption.value === props.product!.brandID)
            if (findBrand) setBrand(findBrand)
            else setBrand(brandsOptions[0])
        }

        fetchBrands();
    }, [props.product, reset])

    const onBrandChange = (event, newValue) => {
        // Handle the case that someone inserted their own value
        if (typeof (newValue) === 'string') {
            newValue = { name: newValue, value: -1 }
        }

        setBrand(newValue);
    }

    const updateProduct = async () => {
        if (!props.product) return;

        const input = {
            id: props.product.id,
            customerID: props.product.customerID,
            brandName: brand.name,
            brandID: brand?.value || -1,
            name: productName,
            description: description,
            upc: upc,
            sku: sku,
            active: active,
            approved: approved,
            replacementProduct: isReplacementProduct,
            featured: featured
        }

        try {
            await API.updateProduct(input);
            createSuccessSnack('Successfully updated product');
        } catch (err) {
            console.error(err);
            let errorMessage = 'Failed to update product';
            if (err.errors && err.errors[0]) {
                const error = err.errors[0];
                errorMessage = `${error.errorType || 'Error'}: ${error.message}`
            }

            createErrorSnack(errorMessage);
        }
    }

    const cancel = () => {
        reset();
    }

    const updateProductPhoto = async ({ target }) => {
        if (!props.product) return;

        const file = target.files[0];
        if (!file) return;

        const fileExtension = path.extname(file.name).toUpperCase();

        const now = new Date().getTime();
        const filename = `PRODUCT-${props.product?.customerID}-${props.product.id}-${now}${fileExtension}`;

        const presignedUrl = await API.generatePresignedRawMediaURL({
            operation: 'putObject',
            key: filename
        })

        const options = {
            headers: {
                'Content-Type': file.type
            },
            onUploadProgress: function (progressEvent) {
                var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                setProgress(percentCompleted);
            }
        };

        setUploading(true);
        const uploadResponse = await uploadPresignedFile(presignedUrl, file, options);
        if (uploadResponse.status === 200) {
            createSuccessSnack('Successfully updated product photo');
        } else {
            createErrorSnack('Error uploading photo');
        }

        setUploading(false);
    }

    const onConfirmDelete = async () => {
        setConfirmOpen(false);
        if (!props.product) return;

        await API.deleteProduct(props.product.id);
        createSuccessSnack('Successfully deleted product');

        dispatch({ type: 'deleteProductSuccess', productID: props.product.id })
        history.push('/products/list');
    }

    const onConfirmClose = () => {
        setConfirmOpen(false);
    }

    return (
        <TabPanel value={props.tab} index={props.index} direction="row" spacing={2}>
            <Dialog open={confirmOpen}
                onClose={onConfirmClose}
                aria-labelledby="confirm-product-delete-dialog"
                aria-describedby="confirm-product-delete"
            >
                <DialogTitle id="reply-dialog">{`Confirm Deletion of "${productName}"`}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="reply-dialog-information">
                        Warning: Deleting this product will remove all photos, links, etc. Are you SURE you want to delete this product?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onConfirmDelete} color="primary">
                        Delete
                    </Button>
                    <Button onClick={onConfirmClose} color="secondary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
            <Grid item container spacing={2} direction="column" xs={6}>
                <Grid item container spacing={2} direction="row">
                    <Grid item xs={2} sm={3}>
                        <FormControlLabel
                            control={<Checkbox checked={active} onChange={(e) => setActive(e.target.checked)} name="active" />}
                            label="Active"
                        />
                    </Grid>
                    <Grid item xs={2} sm={3}>
                        <FormControlLabel
                            control={<Checkbox checked={approved} onChange={(e) => setApproved(e.target.checked)} name="approved" />}
                            label="Approved"
                        />
                    </Grid>
                    <Grid item xs={6} sm={6}>
                        <FormControlLabel
                            control={<Checkbox checked={isReplacementProduct} onChange={(e) => setIsReplacementProduct(e.target.checked)} name="isReplacementProduct" />}
                            label="Is Replacement Product"
                        />
                    </Grid>
                    <Grid item xs={2} sm={3}>
                        <FormControlLabel
                            control={<Checkbox checked={featured} onChange={(e) => setFeatured(e.target.checked)} name="featured" />}
                            label="Featured"
                        />
                    </Grid>
                </Grid>
                <Grid item>
                    <Autocomplete id="brand-combo-box"
                        options={brandsOptions}
                        getOptionLabel={(option) => option.name}
                        value={brand}
                        onChange={onBrandChange}
                        selectOnFocus
                        clearOnBlur
                        freeSolo
                        renderInput={(params) => <TextField {...params} label="Brand" variant="outlined" />}
                    />
                </Grid>
                <Grid item>
                    <TextField variant="outlined"
                        value={upc}
                        label="UPC"
                        onChange={(e) => setUPC(e.target.value)}
                        placeholder="UPC"
                        fullWidth
                    />
                </Grid>
                <Grid item>
                    <TextField variant="outlined"
                        value={sku}
                        label="SKU / Model #"
                        onChange={(e) => setSKU(e.target.value)}
                        placeholder="SKU / Model #"
                        fullWidth
                    />
                </Grid>
                <Grid item>
                    <TextField variant="outlined"
                        error={productName === ''}
                        required
                        value={productName}
                        label="Product Name"
                        onChange={(e) => setProductName(e.target.value)}
                        placeholder="Product Name"
                        fullWidth
                        helperText={productName === '' ? "Product Name can not be empty" : ''}
                    />
                </Grid>
                <Grid item>
                    <TextField variant="outlined"
                        value={description}
                        label="Description"
                        onChange={(e) => setDescription(e.target.value)}
                        placeholder="Description"
                        fullWidth
                        multiline
                    />
                </Grid>
                <Grid item container spacing={2} direction="row">
                    <Grid item>
                        <Button variant="contained" color="secondary" onClick={updateProduct}>
                            Update product
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button variant="contained" color="primary" onClick={cancel}>
                            Cancel
                        </Button>
                    </Grid>
                </Grid>
                <Grid item container spacing={2} direction="row" alignItems="center">
                    <Grid item>
                        <Button variant="contained" color="primary" onClick={() => setConfirmOpen(true)}>
                            Delete Product
                        </Button>
                    </Grid>
                    <Grid item>
                        <Typography>Warning: Deleting this product will remove all photos, links, etc</Typography>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item container spacing={2} direction="column" xs={6} alignContent="center" alignItems="center">
                {
                    uploading &&
                    <Grid item>
                        <LinearProgress variant="determinate" value={progress} color="secondary" />
                    </Grid>
                }
                <Grid item>
                    <img src={imageURL} alt={productName} width="400" />
                </Grid>
                <Grid item>
                    <FileUploadButton id="product-photo-upload"
                        accept="image/*"
                        text="Update Product photo"
                        onChange={updateProductPhoto}
                    />
                </Grid>
            </Grid>
        </TabPanel>
    )
}

export interface ProductDetailTabProps {
    tab: number
    index: number
    product: Maybe<Product>
}