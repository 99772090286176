/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateCustomer = /* GraphQL */ `
  subscription OnCreateCustomer {
    onCreateCustomer {
      id
      shortName
      fullName
      active
      added
      adminType
      isVendor
      emailAddressIdentity
      streamingContent
      displayShowReports
      displayEpisodeReports
    }
  }
`;
export const onCreateShow = /* GraphQL */ `
  subscription OnCreateShow($customerID: Int!) {
    onCreateShow(customerID: $customerID) {
      id
      customerID
      nameShort
      nameLong
      description
      poster
      posterURL
      added
      active
      hideInAdmin
      appID
      mainMenuType
      faqContent
      faviconsURL
      heroImageBucket
      heroImageKey
      appData {
        appLogo
        appLogoURL
        primaryColor
        textPrimaryColor
        secondaryColor
        tertiaryColor
        accentColor
        infoColor
        headBackgroundColor
        headTextColor
        menuTextColor
        productCaroselBackgroundColor
        productButTextColor
        productButBackColor
        productBut2TextColor
        productBut2BackColor
        mobileTextPrimaryColor
        mobileTextSecondaryColor
        mobileAccentColor
        mobileButtonColor
        autoplayEnabled
        autoplayAfterFingerprint
        copyright
        privacyPolicy
        appPosterDisplayType
        episodeTileType
        productTileType
        witAIAppID
        witAIAccessToken
        witAILastUpdate
        googleTrackingID
        googlePlayAppEnabled
        googlePlayAppURL
        facebookAppID
        facebookPageURL
        instagramUsername
        twitterUsername
        iOSAppStoreID
        videoGroupingName
        videoGroupingNamePlural
        featuredProductsLabel
      }
      channel {
        rokuChannelEnabled
        rokuChannelType
        rokuChannelInstallURL
        fireTVChannelEnabled
        fireTVInstallURL
        fireTVDevURL
      }
      demographicConfiguration {
        introText
        ageEnabled
        genderEnabled
        ethnicityEnabled
        educationEnabled
        employmentEnabled
        maritalStatusEnabled
        incomeEnabled
        zipcodeEnabled
        emailEnabled
      }
      productRequestConfiguration {
        enabled
        titleText
        introText
        episodeListEnabled
        episodeListText
        photoGroupEnabled
        photoGroupText
        categoryEnabled
        categoryText
        emailEnabled
        emailText
        requestText
        submitThanksText
        notificationsList
      }
    }
  }
`;
export const onUpdateShow = /* GraphQL */ `
  subscription OnUpdateShow($id: Int!) {
    onUpdateShow(id: $id) {
      id
      customerID
      nameShort
      nameLong
      description
      poster
      posterURL
      added
      active
      hideInAdmin
      appID
      mainMenuType
      faqContent
      faviconsURL
      heroImageBucket
      heroImageKey
      appData {
        appLogo
        appLogoURL
        primaryColor
        textPrimaryColor
        secondaryColor
        tertiaryColor
        accentColor
        infoColor
        headBackgroundColor
        headTextColor
        menuTextColor
        productCaroselBackgroundColor
        productButTextColor
        productButBackColor
        productBut2TextColor
        productBut2BackColor
        mobileTextPrimaryColor
        mobileTextSecondaryColor
        mobileAccentColor
        mobileButtonColor
        autoplayEnabled
        autoplayAfterFingerprint
        copyright
        privacyPolicy
        appPosterDisplayType
        episodeTileType
        productTileType
        witAIAppID
        witAIAccessToken
        witAILastUpdate
        googleTrackingID
        googlePlayAppEnabled
        googlePlayAppURL
        facebookAppID
        facebookPageURL
        instagramUsername
        twitterUsername
        iOSAppStoreID
        videoGroupingName
        videoGroupingNamePlural
        featuredProductsLabel
      }
      channel {
        rokuChannelEnabled
        rokuChannelType
        rokuChannelInstallURL
        fireTVChannelEnabled
        fireTVInstallURL
        fireTVDevURL
      }
      demographicConfiguration {
        introText
        ageEnabled
        genderEnabled
        ethnicityEnabled
        educationEnabled
        employmentEnabled
        maritalStatusEnabled
        incomeEnabled
        zipcodeEnabled
        emailEnabled
      }
      productRequestConfiguration {
        enabled
        titleText
        introText
        episodeListEnabled
        episodeListText
        photoGroupEnabled
        photoGroupText
        categoryEnabled
        categoryText
        emailEnabled
        emailText
        requestText
        submitThanksText
        notificationsList
      }
    }
  }
`;
export const onCreateEpisode = /* GraphQL */ `
  subscription OnCreateEpisode($showID: Int!) {
    onCreateEpisode(showID: $showID) {
      id
      showID
      showName
      season
      seasonName
      number
      firstRelease
      added
      name
      description
      disclaimer
      enabled
      posterType
      poster
      posterURL
      posterFileID
      video
      videoBucket
      videoKey
      videoQuality
      videoType
      videoWidth
      videoHeight
      mediaConvertJobID
      mediaConvertJobStatus
      audioFingerprintID
      duration
      rokuAddBreaks
      releaseToOTT
      releaseToApp
      liveChannelID
      enableHeaderButton
      headerButtonText
      headerButtonURL
      disableProductScroller
      liveStreamID
      liveStreamEnabledOnStartMenuID
      videoToken
    }
  }
`;
export const onUpdateShowEpisode = /* GraphQL */ `
  subscription OnUpdateShowEpisode($showID: Int!) {
    onUpdateShowEpisode(showID: $showID) {
      id
      showID
      showName
      season
      seasonName
      number
      firstRelease
      added
      name
      description
      disclaimer
      enabled
      posterType
      poster
      posterURL
      posterFileID
      video
      videoBucket
      videoKey
      videoQuality
      videoType
      videoWidth
      videoHeight
      mediaConvertJobID
      mediaConvertJobStatus
      audioFingerprintID
      duration
      rokuAddBreaks
      releaseToOTT
      releaseToApp
      liveChannelID
      enableHeaderButton
      headerButtonText
      headerButtonURL
      disableProductScroller
      liveStreamID
      liveStreamEnabledOnStartMenuID
      videoToken
    }
  }
`;
export const onUpdateEpisode = /* GraphQL */ `
  subscription OnUpdateEpisode($id: Int!) {
    onUpdateEpisode(id: $id) {
      id
      showID
      showName
      season
      seasonName
      number
      firstRelease
      added
      name
      description
      disclaimer
      enabled
      posterType
      poster
      posterURL
      posterFileID
      video
      videoBucket
      videoKey
      videoQuality
      videoType
      videoWidth
      videoHeight
      mediaConvertJobID
      mediaConvertJobStatus
      audioFingerprintID
      duration
      rokuAddBreaks
      releaseToOTT
      releaseToApp
      liveChannelID
      enableHeaderButton
      headerButtonText
      headerButtonURL
      disableProductScroller
      liveStreamID
      liveStreamEnabledOnStartMenuID
      videoToken
    }
  }
`;
export const onCreateEpisodePhoto = /* GraphQL */ `
  subscription OnCreateEpisodePhoto($episodeID: Int!) {
    onCreateEpisodePhoto(episodeID: $episodeID) {
      id
      episodeID
      groupID
      groupName
      order
      title
      description
      fileID
      extension
      url
      thumbnailURL
      enabled
      added
    }
  }
`;
export const onUpdateProduct = /* GraphQL */ `
  subscription OnUpdateProduct($id: Int!) {
    onUpdateProduct(id: $id) {
      id
      customerID
      name
      description
      upc
      brand
      brandID
      sku
      active
      added
      deleted
      approved
      replacementProduct
      mainPhotoURL
      mainPhotoFileID
      mainPhotoFileExt
      mainPhotoSize
      issueCount
      photoBucket
      photoKey
      warnings
      originalProductName
      originalProductDescription
      originalProductURL
      originalProductPhotoBucket
      originalProductPhotoKey
      featured
      price
      vendorID
      vendorLogo
      vendorLogoBucket
      vendorLogoKey
    }
  }
`;
export const onUpdateCategory = /* GraphQL */ `
  subscription OnUpdateCategory($id: Int!, $customerID: Int!) {
    onUpdateCategory(id: $id, customerID: $customerID) {
      id
      customerID
      name
      image
      imageURL
      imageFileSize
      active
      thumbnail
      thumbnailURL
      thumbnailFileSize
      tag
      description
    }
  }
`;
export const onResolveProductsReport = /* GraphQL */ `
  subscription OnResolveProductsReport($id: ID!) {
    onResolveProductsReport(id: $id) {
      id
      items {
        id
        name
        brandName
        upc
        sku
        mainPhotoURL
        mainPhotoFileId
        mainPhotoFileExt
        active
        deleted
        added
        imagePreviews
        detailViews
        clicks
      }
      nextToken
    }
  }
`;
export const onResolveVendorProductsReport = /* GraphQL */ `
  subscription OnResolveVendorProductsReport($id: ID!) {
    onResolveVendorProductsReport(id: $id) {
      id
      items {
        id
        name
        brandName
        upc
        sku
        mainPhotoURL
        mainPhotoFileId
        mainPhotoFileExt
        active
        deleted
        added
        imagePreviews
        detailViews
        clicks
      }
      nextToken
    }
  }
`;
export const onResolveAdminAdReport = /* GraphQL */ `
  subscription OnResolveAdminAdReport($id: ID!) {
    onResolveAdminAdReport(id: $id) {
      id
      items {
        id
        customerID
        showID
        episodeID
        categoryID
        imageBucket
        imageKey
        vendorAd {
          id
          vendorID
          name
          description
          imageBucket
          imageKey
          url
          type
          createdAt
          updatedAt
        }
        url
        linkType
        type
        createdAt
        updatedAt
        impressions
        clicks
      }
      nextToken
    }
  }
`;
export const onCreateNotification = /* GraphQL */ `
  subscription OnCreateNotification($userID: String!) {
    onCreateNotification(userID: $userID) {
      id
      userID
      description
      time
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateNotification = /* GraphQL */ `
  subscription OnUpdateNotification($userID: String!) {
    onUpdateNotification(userID: $userID) {
      id
      userID
      description
      time
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteNotification = /* GraphQL */ `
  subscription OnDeleteNotification($userID: String!) {
    onDeleteNotification(userID: $userID) {
      id
      userID
      description
      time
      createdAt
      updatedAt
    }
  }
`;
export const onCreateExclusiveVideoContent = /* GraphQL */ `
  subscription OnCreateExclusiveVideoContent($legacyCustomerIDString: String) {
    onCreateExclusiveVideoContent(
      legacyCustomerIDString: $legacyCustomerIDString
    ) {
      id
      legacyCustomerID
      legacyCustomerIDString
      legacyShowID
      name
      description
      enabled
      firstRelease
      posterBucket
      posterKey
      videoBucket
      videoKey
      videoURL
      videoQuality
      videoType
      videoWidth
      videoHeight
      mediaConvertJobID
      mediaConvertJobStatus
      duration
      releaseToOTT
      releaseToApp
      videoToken
      disableProductScroller
      productDefinitions {
        productID
        startTimeMilliseconds
        endTimeMilliseconds
      }
      videoProducts {
        brandName
        product {
          id
          customerID
          name
          description
          upc
          brand
          brandID
          sku
          active
          added
          deleted
          approved
          replacementProduct
          mainPhotoURL
          mainPhotoFileID
          mainPhotoFileExt
          mainPhotoSize
          issueCount
          photoBucket
          photoKey
          warnings
          originalProductName
          originalProductDescription
          originalProductURL
          originalProductPhotoBucket
          originalProductPhotoKey
          featured
          price
          vendorID
          vendorLogo
          vendorLogoBucket
          vendorLogoKey
        }
        startTimeMilliseconds
        endTimeMilliseconds
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateExclusiveVideoContent = /* GraphQL */ `
  subscription OnUpdateExclusiveVideoContent($legacyCustomerIDString: String) {
    onUpdateExclusiveVideoContent(
      legacyCustomerIDString: $legacyCustomerIDString
    ) {
      id
      legacyCustomerID
      legacyCustomerIDString
      legacyShowID
      name
      description
      enabled
      firstRelease
      posterBucket
      posterKey
      videoBucket
      videoKey
      videoURL
      videoQuality
      videoType
      videoWidth
      videoHeight
      mediaConvertJobID
      mediaConvertJobStatus
      duration
      releaseToOTT
      releaseToApp
      videoToken
      disableProductScroller
      productDefinitions {
        productID
        startTimeMilliseconds
        endTimeMilliseconds
      }
      videoProducts {
        brandName
        product {
          id
          customerID
          name
          description
          upc
          brand
          brandID
          sku
          active
          added
          deleted
          approved
          replacementProduct
          mainPhotoURL
          mainPhotoFileID
          mainPhotoFileExt
          mainPhotoSize
          issueCount
          photoBucket
          photoKey
          warnings
          originalProductName
          originalProductDescription
          originalProductURL
          originalProductPhotoBucket
          originalProductPhotoKey
          featured
          price
          vendorID
          vendorLogo
          vendorLogoBucket
          vendorLogoKey
        }
        startTimeMilliseconds
        endTimeMilliseconds
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteExclusiveVideoContent = /* GraphQL */ `
  subscription OnDeleteExclusiveVideoContent($legacyCustomerIDString: String) {
    onDeleteExclusiveVideoContent(
      legacyCustomerIDString: $legacyCustomerIDString
    ) {
      id
      legacyCustomerID
      legacyCustomerIDString
      legacyShowID
      name
      description
      enabled
      firstRelease
      posterBucket
      posterKey
      videoBucket
      videoKey
      videoURL
      videoQuality
      videoType
      videoWidth
      videoHeight
      mediaConvertJobID
      mediaConvertJobStatus
      duration
      releaseToOTT
      releaseToApp
      videoToken
      disableProductScroller
      productDefinitions {
        productID
        startTimeMilliseconds
        endTimeMilliseconds
      }
      videoProducts {
        brandName
        product {
          id
          customerID
          name
          description
          upc
          brand
          brandID
          sku
          active
          added
          deleted
          approved
          replacementProduct
          mainPhotoURL
          mainPhotoFileID
          mainPhotoFileExt
          mainPhotoSize
          issueCount
          photoBucket
          photoKey
          warnings
          originalProductName
          originalProductDescription
          originalProductURL
          originalProductPhotoBucket
          originalProductPhotoKey
          featured
          price
          vendorID
          vendorLogo
          vendorLogoBucket
          vendorLogoKey
        }
        startTimeMilliseconds
        endTimeMilliseconds
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateShowMetaTags = /* GraphQL */ `
  subscription OnCreateShowMetaTags {
    onCreateShowMetaTags {
      appID
      showID
      OG_TITLE
      OG_DESCRIPTION
      OG_IMAGE
      FAVICON
      MANIFEST
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateShowMetaTags = /* GraphQL */ `
  subscription OnUpdateShowMetaTags {
    onUpdateShowMetaTags {
      appID
      showID
      OG_TITLE
      OG_DESCRIPTION
      OG_IMAGE
      FAVICON
      MANIFEST
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteShowMetaTags = /* GraphQL */ `
  subscription OnDeleteShowMetaTags {
    onDeleteShowMetaTags {
      appID
      showID
      OG_TITLE
      OG_DESCRIPTION
      OG_IMAGE
      FAVICON
      MANIFEST
      createdAt
      updatedAt
    }
  }
`;
export const onCreateVendor = /* GraphQL */ `
  subscription OnCreateVendor($id: String) {
    onCreateVendor(id: $id) {
      id
      name
      type
      active
      salesOwnedByUserID
      balance
      balanceUpdated
      logo
      logoFileID
      logoFileExtension
      logoBucket
      logoKey
      pixelTrackingID
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateVendor = /* GraphQL */ `
  subscription OnUpdateVendor($id: String) {
    onUpdateVendor(id: $id) {
      id
      name
      type
      active
      salesOwnedByUserID
      balance
      balanceUpdated
      logo
      logoFileID
      logoFileExtension
      logoBucket
      logoKey
      pixelTrackingID
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteVendor = /* GraphQL */ `
  subscription OnDeleteVendor($id: String) {
    onDeleteVendor(id: $id) {
      id
      name
      type
      active
      salesOwnedByUserID
      balance
      balanceUpdated
      logo
      logoFileID
      logoFileExtension
      logoBucket
      logoKey
      pixelTrackingID
      createdAt
      updatedAt
    }
  }
`;
export const onCreateVendorAd = /* GraphQL */ `
  subscription OnCreateVendorAd {
    onCreateVendorAd {
      id
      vendorID
      name
      description
      imageBucket
      imageKey
      url
      type
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateVendorAd = /* GraphQL */ `
  subscription OnUpdateVendorAd {
    onUpdateVendorAd {
      id
      vendorID
      name
      description
      imageBucket
      imageKey
      url
      type
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteVendorAd = /* GraphQL */ `
  subscription OnDeleteVendorAd {
    onDeleteVendorAd {
      id
      vendorID
      name
      description
      imageBucket
      imageKey
      url
      type
      createdAt
      updatedAt
    }
  }
`;
export const onCreateBannerAd = /* GraphQL */ `
  subscription OnCreateBannerAd {
    onCreateBannerAd {
      id
      customerID
      showID
      episodeID
      categoryID
      imageBucket
      imageKey
      url
      linkType
      type
      createdAt
      updatedAt
      vendorAd {
        id
        vendorID
        name
        description
        imageBucket
        imageKey
        url
        type
        createdAt
        updatedAt
      }
    }
  }
`;
export const onUpdateBannerAd = /* GraphQL */ `
  subscription OnUpdateBannerAd {
    onUpdateBannerAd {
      id
      customerID
      showID
      episodeID
      categoryID
      imageBucket
      imageKey
      url
      linkType
      type
      createdAt
      updatedAt
      vendorAd {
        id
        vendorID
        name
        description
        imageBucket
        imageKey
        url
        type
        createdAt
        updatedAt
      }
    }
  }
`;
export const onDeleteBannerAd = /* GraphQL */ `
  subscription OnDeleteBannerAd {
    onDeleteBannerAd {
      id
      customerID
      showID
      episodeID
      categoryID
      imageBucket
      imageKey
      url
      linkType
      type
      createdAt
      updatedAt
      vendorAd {
        id
        vendorID
        name
        description
        imageBucket
        imageKey
        url
        type
        createdAt
        updatedAt
      }
    }
  }
`;
export const onCreateDigitalAssetLinks = /* GraphQL */ `
  subscription OnCreateDigitalAssetLinks {
    onCreateDigitalAssetLinks {
      appID
      legacyShowID
      legacyCustomerID
      android
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateDigitalAssetLinks = /* GraphQL */ `
  subscription OnUpdateDigitalAssetLinks {
    onUpdateDigitalAssetLinks {
      appID
      legacyShowID
      legacyCustomerID
      android
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteDigitalAssetLinks = /* GraphQL */ `
  subscription OnDeleteDigitalAssetLinks {
    onDeleteDigitalAssetLinks {
      appID
      legacyShowID
      legacyCustomerID
      android
      createdAt
      updatedAt
    }
  }
`;
export const onCreateHomeScreenSettings = /* GraphQL */ `
  subscription OnCreateHomeScreenSettings {
    onCreateHomeScreenSettings {
      showID
      legacyCustomerID
      legacyCustomerIDString
      featuredProductsLabel
      smallBusinessLabel
      recentEpisodesLabel
      recommendedProductsLabel
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateHomeScreenSettings = /* GraphQL */ `
  subscription OnUpdateHomeScreenSettings {
    onUpdateHomeScreenSettings {
      showID
      legacyCustomerID
      legacyCustomerIDString
      featuredProductsLabel
      smallBusinessLabel
      recentEpisodesLabel
      recommendedProductsLabel
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteHomeScreenSettings = /* GraphQL */ `
  subscription OnDeleteHomeScreenSettings {
    onDeleteHomeScreenSettings {
      showID
      legacyCustomerID
      legacyCustomerIDString
      featuredProductsLabel
      smallBusinessLabel
      recentEpisodesLabel
      recommendedProductsLabel
      createdAt
      updatedAt
    }
  }
`;
