import React, { useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import { Grid, Typography, TextField, Button, LinearProgress } from '@material-ui/core';

import * as path from 'path';

import { TabPanel } from '../../tabs/TabPanel';
import { FileUploadButton } from '../../shared/FileUploadButton';
import { SelectWithLabel } from '../../shared/SelectWithLabel';
import { uploadPresignedFile } from '../../../lib/util';

import * as API from '../../../lib/api';
import { Maybe, CreateExclusiveVideoContentInput, ExclusiveVideoContent, VideoQuality, VideoType } from '../../../types';

const videoTypeOptions = Object.entries(VideoType).map(type => {
    return { name: type[1].toString(), value: type[1] }
})

export function AddExclusiveContentTab(props: AddExclusiveContentTabProps) {
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [videoType, setVideoType] = useState(videoTypeOptions[0]);
    const [videoURL, setVideoURL] = useState('');
    const [createdContent, setCreatedContent] = useState<Maybe<ExclusiveVideoContent>>();
    const [displayVideoUpload, setDisplayVideoUpload] = useState(false);
    const [uploading, setUploading] = useState(false);
    const [progress, setProgress] = useState(0);

    const clear = () => {
        setName('');
        setDescription('');
        setVideoType(videoTypeOptions[0]);
        setVideoURL('');
        setCreatedContent(undefined);
        setUploading(false);
        setProgress(0);
    }

    useEffect(() => {
        clear();

        if (!props.showID) return;
    }, [props.showID])

    const selectVideoFile = async ({ target }) => {
        if (!target) return;
        if (!createdContent) return;

        const file = target.files[0];
        if (!file) return;

        const fileExtension = path.extname(file.name).toUpperCase();

        const now = new Date().getTime();
        const filename = `EXCLUSIVE-${props.customerID}-${props.showID}-${now}${fileExtension}`;

        const user = await Auth.currentAuthenticatedUser();

        const presignedUrl = await API.generatePresignedRawVideoURL({
            operation: 'putObject',
            key: filename,
            metadata: [
                { name: 'userid', value: user.attributes.sub },
                { name: 'id', value: createdContent.id },
                { name: 'name', value: createdContent.name }
            ]
        })

        const options = {
            headers: {
                'Content-Type': file.type
            },
            onUploadProgress: function (progressEvent) {
                const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                setProgress(percentCompleted)
            }
        };

        setUploading(true);
        let uploadResponse = await uploadPresignedFile(presignedUrl, file, options);
        if (uploadResponse.status === 200) {
            clear();
            setDisplayVideoUpload(false);
        }
    }

    const addContent = async () => {
        const now = new Date().toISOString();

        let input: CreateExclusiveVideoContentInput = {
            legacyCustomerID: props.customerID,
            legacyCustomerIDString: props.customerID.toString(), // For auth reasons ugh
            legacyShowID: props.showID,
            name: name,
            description: description,
            firstRelease: now,
            enabled: false,
            releaseToApp: false,
            releaseToOTT: false,
            videoQuality: VideoQuality.Hd,
            videoType: videoType.value
        }

        if (videoType.value === VideoType.YouTube || videoType.value === VideoType.Vimeo) input.videoURL = videoURL;

        const createContentResult = await API.createExclusiveVideoContent(input);
        setCreatedContent(createContentResult);

        if (videoType.value === VideoType.Hls) {
            // Have them upload the video! Should we pop up a dialog? IDKKKK
            setDisplayVideoUpload(true);
        } else {
            clear();
        }
    }

    return (
        <TabPanel value={props.tab} index={props.index} spacing={2}>
            <Grid item xs={12}>
                <TextField variant="outlined"
                    label="Content Title"
                    placeholder="Title"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    fullWidth
                />
            </Grid>
            <Grid item xs={12}>
                <TextField variant="outlined"
                    label="Content Description"
                    placeholder="Content Description"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    fullWidth
                    multiline
                    rows={5}
                />
            </Grid>
            <Grid item container direction="row" xs={12} spacing={2} alignItems="flex-end" alignContent="flex-end">
                <Grid item>
                    <SelectWithLabel id="media-type"
                        title="Media Type"
                        value={videoType}
                        selectOptions={videoTypeOptions}
                        onChange={(e) => setVideoType(e.target.value)}
                    />
                </Grid>
                {
                    (videoType.value === VideoType.YouTube || videoType.value === VideoType.Vimeo) &&
                    <Grid item xs={6}>
                        <TextField variant="outlined"
                            label="Video URL"
                            placeholder="Video URL"
                            value={videoURL}
                            onChange={(e) => setVideoURL(e.target.value)}
                            fullWidth
                        />
                    </Grid>
                }
                {
                    (videoType.value === VideoType.Hls) &&
                    <Grid item>
                        <Typography>A file upload will display after you create the episode</Typography>
                    </Grid>
                }
            </Grid>
            <Grid item container direction="row" xs={12} spacing={2}>
                <Grid item>
                    <Button variant="contained" color="secondary" onClick={addContent}>
                        Add Content
                    </Button>
                </Grid>
                <Grid item>
                    <Button variant="outlined" onClick={clear}>
                        Cancel
                    </Button>
                </Grid>
            </Grid>
            {
                (videoType.value === VideoType.Hls) && displayVideoUpload &&
                <Grid item>
                    <FileUploadButton
                        id="video-select-button"
                        accept="video/mp4,video/x-m4v,video/*"
                        onChange={selectVideoFile}
                        text="Select Video"
                    />
                </Grid>
            }
            {
                displayVideoUpload && uploading &&
                <>
                    <Grid item xs={12}>
                        <Typography>Upload Progress: Please do not leave this page or refresh while uploading.</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <LinearProgress variant="determinate" value={progress} color="secondary" />
                    </Grid>
                </>
            }
        </TabPanel>
    )
}

export interface AddExclusiveContentTabProps {
    tab: number
    index: number
    customerID: number
    showID: number
}