export type Maybe<T> = T | null | undefined;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string,
  String: string,
  Boolean: boolean,
  Int: number,
  Float: number,
  AWSDateTime: string,
  AWSURL: string,
  AWSJSON: string,
  AWSDate: string,
  AWSTime: string,
  AWSTimestamp: number,
  AWSEmail: string,
  AWSPhone: string,
  AWSIPAddress: string,
};




export type AdTrackingReportConnection = {
   __typename?: 'AdTrackingReportConnection',
  id: Scalars['ID'],
  items?: Maybe<Array<ReportAdTracking>>,
  nextToken?: Maybe<Scalars['Boolean']>,
};

export type AdTrackingReportConnectionInput = {
  id: Scalars['ID'],
  items?: Maybe<Array<AdTrackingReportInput>>,
  nextToken?: Maybe<Scalars['Boolean']>,
};

export type AdTrackingReportInput = {
  id: Scalars['ID'],
  customerID: Scalars['Int'],
  showID?: Maybe<Scalars['Int']>,
  episodeID?: Maybe<Scalars['Int']>,
  categoryID?: Maybe<Scalars['Int']>,
  imageBucket?: Maybe<Scalars['String']>,
  imageKey?: Maybe<Scalars['String']>,
  vendorAd?: Maybe<VendorAdInput>,
  url?: Maybe<Scalars['String']>,
  linkType?: Maybe<BannerAdLinkType>,
  type?: Maybe<BannerAdType>,
  createdAt?: Maybe<Scalars['String']>,
  updatedAt?: Maybe<Scalars['String']>,
  impressions?: Maybe<Scalars['Int']>,
  clicks?: Maybe<Scalars['Int']>,
};

export type AppUsageLog = {
   __typename?: 'AppUsageLog',
  id: Scalars['Int'],
  date?: Maybe<Scalars['AWSDateTime']>,
  ipAddress?: Maybe<Scalars['String']>,
  userAgent?: Maybe<Scalars['String']>,
  referer?: Maybe<Scalars['String']>,
  userID?: Maybe<Scalars['Int']>,
  appID?: Maybe<Scalars['String']>,
  appVersion?: Maybe<Scalars['String']>,
  episodeID?: Maybe<Scalars['Int']>,
  exclusiveID?: Maybe<Scalars['ID']>,
  productID?: Maybe<Scalars['Int']>,
  linkID?: Maybe<Scalars['Int']>,
  logEntry?: Maybe<Scalars['String']>,
  logData?: Maybe<Scalars['String']>,
  version?: Maybe<Scalars['String']>,
};

export type AvailableProduct = {
   __typename?: 'AvailableProduct',
  product: Product,
  customerName: Scalars['String'],
};

export type AvailableProductConnection = {
   __typename?: 'AvailableProductConnection',
  items?: Maybe<Array<AvailableProduct>>,
};










export type BannerAd = {
   __typename?: 'BannerAd',
  id: Scalars['ID'],
  customerID: Scalars['Int'],
  showID?: Maybe<Scalars['Int']>,
  episodeID?: Maybe<Scalars['Int']>,
  categoryID?: Maybe<Scalars['Int']>,
  imageBucket?: Maybe<Scalars['String']>,
  imageKey?: Maybe<Scalars['String']>,
  url?: Maybe<Scalars['String']>,
  linkType?: Maybe<BannerAdLinkType>,
  type?: Maybe<BannerAdType>,
  createdAt: Scalars['AWSDateTime'],
  updatedAt: Scalars['AWSDateTime'],
  vendorAd?: Maybe<VendorAd>,
};

export enum BannerAdLinkType {
  Url = 'URL',
  Vendor = 'VENDOR'
}

export type BannerAdLog = {
   __typename?: 'BannerAdLog',
  id: Scalars['Int'],
  bannerAdID: Scalars['ID'],
  type: BannerAdLogType,
  appID: Scalars['String'],
  referer: Scalars['String'],
  date?: Maybe<Scalars['AWSDateTime']>,
  ipAddress?: Maybe<Scalars['String']>,
  userAgent?: Maybe<Scalars['String']>,
  userID?: Maybe<Scalars['ID']>,
  deviceID?: Maybe<Scalars['ID']>,
  appVersion?: Maybe<Scalars['String']>,
  vendorID?: Maybe<Scalars['ID']>,
  episodeID?: Maybe<Scalars['Int']>,
  categoryID?: Maybe<Scalars['Int']>,
  showID?: Maybe<Scalars['Int']>,
  exclusiveID?: Maybe<Scalars['ID']>,
};

export enum BannerAdLogType {
  Impression = 'IMPRESSION',
  Click = 'CLICK'
}

export enum BannerAdType {
  Hero = 'HERO',
  Poster = 'POSTER'
}

export type Brand = {
   __typename?: 'Brand',
  id: Scalars['Int'],
  customerID: Scalars['Int'],
  name: Scalars['String'],
  productCount?: Maybe<Scalars['Int']>,
};

export type BrandConnection = {
   __typename?: 'BrandConnection',
  items?: Maybe<Array<Brand>>,
};

export type Category = {
   __typename?: 'Category',
  id: Scalars['Int'],
  customerID: Scalars['Int'],
  name: Scalars['String'],
  image: Scalars['String'],
  imageURL: Scalars['AWSURL'],
  imageFileSize: Scalars['String'],
  active?: Maybe<Scalars['Boolean']>,
  thumbnail: Scalars['String'],
  thumbnailURL: Scalars['AWSURL'],
  thumbnailFileSize: Scalars['String'],
  tag: Scalars['String'],
  description: Scalars['String'],
};

export type CategoryConnection = {
   __typename?: 'CategoryConnection',
  items?: Maybe<Array<Category>>,
};

export type CreateAppInstallInput = {
  appID: Scalars['String'],
  userAgent?: Maybe<Scalars['String']>,
  appVersion?: Maybe<Scalars['String']>,
  lastIP?: Maybe<Scalars['String']>,
};

export type CreateAppUsageLogInput = {
  appID: Scalars['String'],
  referer: Scalars['String'],
  date?: Maybe<Scalars['AWSDateTime']>,
  ipAddress?: Maybe<Scalars['String']>,
  userAgent?: Maybe<Scalars['String']>,
  userID?: Maybe<Scalars['Int']>,
  userSub?: Maybe<Scalars['ID']>,
  appInstallID?: Maybe<Scalars['Int']>,
  appVersion?: Maybe<Scalars['String']>,
  episodeID?: Maybe<Scalars['Int']>,
  productID?: Maybe<Scalars['Int']>,
  linkID?: Maybe<Scalars['Int']>,
  logEntry?: Maybe<Scalars['String']>,
  logData?: Maybe<Scalars['String']>,
};

export type CreateBannerAdInput = {
  id?: Maybe<Scalars['ID']>,
  customerID: Scalars['Int'],
  showID?: Maybe<Scalars['Int']>,
  episodeID?: Maybe<Scalars['Int']>,
  categoryID?: Maybe<Scalars['Int']>,
  imageBucket?: Maybe<Scalars['String']>,
  imageKey?: Maybe<Scalars['String']>,
  url?: Maybe<Scalars['String']>,
  linkType?: Maybe<BannerAdLinkType>,
  type?: Maybe<BannerAdType>,
  bannerAdVendorAdId?: Maybe<Scalars['ID']>,
};

export type CreateBannerAdLogInput = {
  bannerAdID: Scalars['ID'],
  type: BannerAdLogType,
  appID: Scalars['String'],
  referer: Scalars['String'],
  date?: Maybe<Scalars['AWSDateTime']>,
  ipAddress?: Maybe<Scalars['String']>,
  userAgent?: Maybe<Scalars['String']>,
  userID?: Maybe<Scalars['ID']>,
  deviceID?: Maybe<Scalars['ID']>,
  appVersion?: Maybe<Scalars['String']>,
  vendorID?: Maybe<Scalars['ID']>,
  episodeID?: Maybe<Scalars['Int']>,
  categoryID?: Maybe<Scalars['Int']>,
  showID?: Maybe<Scalars['Int']>,
  exclusiveID?: Maybe<Scalars['ID']>,
};

export type CreateCategoryInput = {
  customerID: Scalars['Int'],
  name: Scalars['String'],
  active?: Maybe<Scalars['Boolean']>,
  tag?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
};

export type CreateCubejsDashboardItemInput = {
  id?: Maybe<Scalars['ID']>,
  userID: Scalars['ID'],
  layout?: Maybe<Scalars['String']>,
  vizState?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
};

export type CreateCustomerInput = {
  shortName: Scalars['String'],
  fullName: Scalars['String'],
  active: Scalars['Boolean'],
  added?: Maybe<Scalars['AWSDateTime']>,
  adminType?: Maybe<Scalars['Int']>,
  isVendor?: Maybe<Scalars['Boolean']>,
  emailAddressIdentity?: Maybe<Scalars['String']>,
  streamingContent?: Maybe<Scalars['Boolean']>,
  displayShowReports?: Maybe<Scalars['Boolean']>,
  displayEpisodeReports?: Maybe<Scalars['Boolean']>,
};

export type CreateDigitalAssetLinksInput = {
  appID: Scalars['String'],
  legacyShowID: Scalars['Int'],
  legacyCustomerID: Scalars['Int'],
  android: Scalars['AWSJSON'],
};

export type CreateEmailReportSettingInput = {
  userID: Scalars['ID'],
  reportType: EmailReportType,
  daily: Scalars['Boolean'],
  weekly: Scalars['Boolean'],
  monthly: Scalars['Boolean'],
  yearly: Scalars['Boolean'],
  customerID: Scalars['Int'],
  showID: Scalars['Int'],
};

export type CreateEpisodeCommercialInput = {
  episodeID: Scalars['Int'],
  commercialEpisodeID: Scalars['Int'],
  type: Scalars['Int'],
  spliceTime?: Maybe<Scalars['Int']>,
};

export type CreateEpisodeInput = {
  showID: Scalars['Int'],
  season: Scalars['Int'],
  number: Scalars['Int'],
  name: Scalars['String'],
  description: Scalars['String'],
  added: Scalars['AWSDateTime'],
  firstRelease: Scalars['AWSDateTime'],
  video?: Maybe<Scalars['String']>,
  videoURL?: Maybe<Scalars['String']>,
  audioFingerprintID?: Maybe<Scalars['String']>,
};

export type CreateEpisodePhotoInput = {
  episodeID: Scalars['Int'],
  groupID: Scalars['Int'],
  groupName: Scalars['String'],
  fileID: Scalars['String'],
  extension: Scalars['String'],
  title: Scalars['String'],
  description: Scalars['String'],
};

export type CreateEpisodePhotoProductInput = {
  groupID: Scalars['Int'],
  productID: Scalars['Int'],
};

export type CreateEpisodeProductInput = {
  episodeID: Scalars['Int'],
  productID: Scalars['Int'],
  startTimecode: Scalars['String'],
  startTimeSeconds?: Maybe<Scalars['Int']>,
  startTimeMilliseconds: Scalars['Int'],
  endTimecode: Scalars['String'],
  endTimeSeconds?: Maybe<Scalars['Int']>,
  endTimeMilliseconds: Scalars['Int'],
};

export type CreateExclusiveVideoContentInput = {
  id?: Maybe<Scalars['ID']>,
  legacyCustomerID: Scalars['Int'],
  legacyCustomerIDString: Scalars['String'],
  legacyShowID: Scalars['Int'],
  name: Scalars['String'],
  description: Scalars['String'],
  enabled: Scalars['Boolean'],
  firstRelease: Scalars['AWSDateTime'],
  posterBucket?: Maybe<Scalars['String']>,
  posterKey?: Maybe<Scalars['String']>,
  videoBucket?: Maybe<Scalars['String']>,
  videoKey?: Maybe<Scalars['String']>,
  videoURL?: Maybe<Scalars['String']>,
  videoQuality?: Maybe<VideoQuality>,
  videoType?: Maybe<VideoType>,
  videoWidth?: Maybe<Scalars['Int']>,
  videoHeight?: Maybe<Scalars['Int']>,
  mediaConvertJobID?: Maybe<Scalars['String']>,
  mediaConvertJobStatus?: Maybe<Scalars['String']>,
  duration?: Maybe<Scalars['Float']>,
  releaseToOTT?: Maybe<Scalars['Boolean']>,
  releaseToApp?: Maybe<Scalars['Boolean']>,
  videoToken?: Maybe<Scalars['String']>,
  disableProductScroller?: Maybe<Scalars['Boolean']>,
  productDefinitions?: Maybe<Array<ExclusiveVideoContentProductDefinitionInput>>,
  videoProducts?: Maybe<Array<ExclusiveVideoProductInput>>,
};

export type CreateHomeScreenSettingsInput = {
  showID: Scalars['Int'],
  legacyCustomerID: Scalars['Int'],
  legacyCustomerIDString: Scalars['String'],
  featuredProductsLabel?: Maybe<Scalars['String']>,
  smallBusinessLabel?: Maybe<Scalars['String']>,
  recentEpisodesLabel?: Maybe<Scalars['String']>,
  recommendedProductsLabel?: Maybe<Scalars['String']>,
};

export type CreateNotificationInput = {
  id?: Maybe<Scalars['ID']>,
  userID: Scalars['ID'],
  description: Scalars['String'],
  time: Scalars['AWSDateTime'],
};

export type CreateProductCategoryInput = {
  productID: Scalars['Int'],
  categories: Array<Scalars['Int']>,
};

export type CreateProductInput = {
  customerID: Scalars['Int'],
  name: Scalars['String'],
  description?: Maybe<Scalars['String']>,
  upc?: Maybe<Scalars['String']>,
  brand?: Maybe<Scalars['String']>,
  brandID?: Maybe<Scalars['Int']>,
  sku?: Maybe<Scalars['String']>,
  active: Scalars['Boolean'],
  deleted: Scalars['Boolean'],
  approved: Scalars['Boolean'],
  replacementProduct?: Maybe<Scalars['Boolean']>,
  mainPhotoURL?: Maybe<Scalars['String']>,
  price?: Maybe<Scalars['Float']>,
  searchKeywords?: Maybe<Scalars['String']>,
  colors?: Maybe<Array<Scalars['String']>>,
  categories?: Maybe<Array<Scalars['Int']>>,
};

export type CreateProductLinkInput = {
  customerID?: Maybe<Scalars['Int']>,
  productID: Scalars['Int'],
  storeName: Scalars['String'],
  storeID?: Maybe<Scalars['Int']>,
  type: Scalars['Int'],
  fullURL: Scalars['String'],
  status?: Maybe<Scalars['Int']>,
  statusLastChecked?: Maybe<Scalars['AWSDateTime']>,
};

export type CreateProductMetadataItem = {
  productID: Scalars['Int'],
  name: Scalars['String'],
  value: Scalars['String'],
};

export type CreateProductRequestInput = {
  showID: Scalars['Int'],
  email: Scalars['String'],
  request: Scalars['String'],
  episodeID: Scalars['Int'],
  episodeName?: Maybe<Scalars['String']>,
  appInstallID?: Maybe<Scalars['Int']>,
  photoGroup?: Maybe<Scalars['String']>,
  category?: Maybe<Scalars['String']>,
  added?: Maybe<Scalars['AWSDateTime']>,
  ipAddress?: Maybe<Scalars['String']>,
};

export type CreateShowDesignMenuItemInput = {
  showID: Scalars['Int'],
  orderID: Scalars['Int'],
  title: Scalars['String'],
  description: Scalars['String'],
  icon: Scalars['String'],
  iconColor: Scalars['String'],
  url: Scalars['String'],
  showInPullOutMenu: Scalars['Boolean'],
  showInHomeMenu: Scalars['Boolean'],
  enabled: Scalars['Boolean'],
};

export type CreateShowFontV2Input = {
  id?: Maybe<Scalars['ID']>,
  showFontLocationComposite: Scalars['String'],
  showID: Scalars['Int'],
  family: FontFamily,
  location: FontLocation,
  weight: FontWeight,
  style: FontStyle,
};

export type CreateShowInput = {
  customerID: Scalars['Int'],
  nameShort: Scalars['String'],
  nameLong: Scalars['String'],
  description?: Maybe<Scalars['String']>,
  active?: Maybe<Scalars['Boolean']>,
  hideInAdmin?: Maybe<Scalars['Boolean']>,
  mainMenuType?: Maybe<Scalars['Int']>,
  faqContent?: Maybe<Scalars['String']>,
  appID: Scalars['String'],
};

export type CreateShowMetaTagsInput = {
  appID: Scalars['ID'],
  showID: Scalars['Int'],
  OG_TITLE: Scalars['String'],
  OG_DESCRIPTION: Scalars['String'],
  OG_IMAGE: Scalars['String'],
  FAVICON: Scalars['String'],
  MANIFEST: Scalars['String'],
};

export type CreateShowSeasonInput = {
  showID: Scalars['Int'],
  name: Scalars['String'],
  season: Scalars['Int'],
};

export type CreateStoreInput = {
  customerID: Scalars['Int'],
  name: Scalars['String'],
  domainName: Scalars['String'],
  active: Scalars['Boolean'],
};

export type CreateUserInput = {
  email: Scalars['String'],
  userType: UserType,
  vendorID?: Maybe<Scalars['Int']>,
  customerID?: Maybe<Scalars['Int']>,
};

export type CreateVendorAdInput = {
  id?: Maybe<Scalars['ID']>,
  vendorID: Scalars['ID'],
  name?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
  imageBucket?: Maybe<Scalars['String']>,
  imageKey?: Maybe<Scalars['String']>,
  url?: Maybe<Scalars['String']>,
  type?: Maybe<BannerAdType>,
};

export type CreateVendorInput = {
  id?: Maybe<Scalars['ID']>,
  name: Scalars['String'],
  type?: Maybe<VendorBusinessType>,
  active: Scalars['Boolean'],
  salesOwnedByUserID: Scalars['ID'],
  balance: Scalars['Float'],
  balanceUpdated?: Maybe<Scalars['AWSDateTime']>,
  logo?: Maybe<Scalars['String']>,
  logoFileID?: Maybe<Scalars['String']>,
  logoFileExtension?: Maybe<Scalars['String']>,
  logoBucket?: Maybe<Scalars['String']>,
  logoKey?: Maybe<Scalars['String']>,
  pixelTrackingID?: Maybe<Scalars['ID']>,
};

export type CreateVendorProductInput = {
  vendorID: Scalars['ID'],
  productID: Scalars['Int'],
  previousVendorID?: Maybe<Scalars['ID']>,
};

export type CubejsDashboardItem = {
   __typename?: 'CubejsDashboardItem',
  id: Scalars['ID'],
  userID: Scalars['ID'],
  layout?: Maybe<Scalars['String']>,
  vizState?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  createdAt: Scalars['AWSDateTime'],
  updatedAt: Scalars['AWSDateTime'],
};

export type Customer = {
   __typename?: 'Customer',
  id: Scalars['Int'],
  shortName: Scalars['String'],
  fullName: Scalars['String'],
  active: Scalars['Boolean'],
  added: Scalars['AWSDateTime'],
  adminType: Scalars['Int'],
  isVendor: Scalars['Boolean'],
  emailAddressIdentity: Scalars['String'],
  streamingContent: Scalars['Boolean'],
  displayShowReports: Scalars['Boolean'],
  displayEpisodeReports: Scalars['Boolean'],
};

export type CustomerConnection = {
   __typename?: 'CustomerConnection',
  items?: Maybe<Array<Customer>>,
};

export type DashboardProductImpression = {
   __typename?: 'DashboardProductImpression',
  productId: Scalars['Int'],
  BrandName?: Maybe<Scalars['String']>,
  Name: Scalars['String'],
  MainPhotoURL?: Maybe<Scalars['String']>,
  MainPhotoFileId?: Maybe<Scalars['String']>,
  MainPhotoFileExt?: Maybe<Scalars['String']>,
  totalViews?: Maybe<Scalars['Int']>,
};

export type DashboardProductImpressionConnection = {
   __typename?: 'DashboardProductImpressionConnection',
  items?: Maybe<Array<DashboardProductImpression>>,
};

export type DashboardReport = {
   __typename?: 'DashboardReport',
  totalSeconds?: Maybe<Scalars['Float']>,
  totalBytes?: Maybe<Scalars['Float']>,
  previousTotalSeconds?: Maybe<Scalars['Float']>,
  previousTotalBytes?: Maybe<Scalars['Float']>,
  ipAddressCount?: Maybe<Scalars['Int']>,
  previousIPAddressCount?: Maybe<Scalars['Int']>,
  totalViews?: Maybe<Scalars['Int']>,
  totalClicks?: Maybe<Scalars['Int']>,
};

export type DashboardTopEpisode = {
   __typename?: 'DashboardTopEpisode',
  ShowId: Scalars['Int'],
  EpisodeId: Scalars['Int'],
  ShowNameShort: Scalars['String'],
  Season?: Maybe<Scalars['Int']>,
  EpisodeNo?: Maybe<Scalars['Int']>,
  EpisodeName?: Maybe<Scalars['String']>,
  EpisodePoster?: Maybe<Scalars['String']>,
  PosterFileId?: Maybe<Scalars['String']>,
  totalViews?: Maybe<Scalars['Int']>,
};

export type DashboardTopEpisodeConnection = {
   __typename?: 'DashboardTopEpisodeConnection',
  items?: Maybe<Array<DashboardTopEpisode>>,
};

export type DateStream = {
   __typename?: 'DateStream',
  logDate?: Maybe<Scalars['AWSDateTime']>,
  logday?: Maybe<Scalars['AWSDateTime']>,
  totalSecs?: Maybe<Scalars['Float']>,
  totalbytes?: Maybe<Scalars['Float']>,
};

export type DeleteBannerAdInput = {
  id?: Maybe<Scalars['ID']>,
};

export type DeleteCubejsDashboardItemInput = {
  id?: Maybe<Scalars['ID']>,
};

export type DeleteDigitalAssetLinksInput = {
  appID: Scalars['String'],
};

export type DeleteExclusiveVideoContentInput = {
  id?: Maybe<Scalars['ID']>,
};

export type DeleteHomeScreenSettingsInput = {
  showID: Scalars['Int'],
};

export type DeleteNotificationInput = {
  id?: Maybe<Scalars['ID']>,
};

export type DeleteShowFontV2Input = {
  id?: Maybe<Scalars['ID']>,
};

export type DeleteShowMetadataItemInput = {
  id: Scalars['Int'],
  showID: Scalars['Int'],
  name: Scalars['String'],
  value: Scalars['String'],
};

export type DeleteShowMetaTagsInput = {
  appID: Scalars['ID'],
};

export type DeleteVendorAdInput = {
  id?: Maybe<Scalars['ID']>,
};

export type DeleteVendorInput = {
  id?: Maybe<Scalars['ID']>,
};

export type DeviceInfo = {
   __typename?: 'DeviceInfo',
  deviceType?: Maybe<Scalars['String']>,
  OS?: Maybe<Scalars['String']>,
  usercount?: Maybe<Scalars['Int']>,
  totalbytes?: Maybe<Scalars['Float']>,
};

export type DigitalAssetLinks = {
   __typename?: 'DigitalAssetLinks',
  appID: Scalars['String'],
  legacyShowID: Scalars['Int'],
  legacyCustomerID: Scalars['Int'],
  android: Scalars['AWSJSON'],
  createdAt: Scalars['AWSDateTime'],
  updatedAt: Scalars['AWSDateTime'],
};

export type EmailReportSetting = {
   __typename?: 'EmailReportSetting',
  id: Scalars['Int'],
  userID: Scalars['ID'],
  reportType: EmailReportType,
  daily: Scalars['Boolean'],
  weekly: Scalars['Boolean'],
  monthly: Scalars['Boolean'],
  yearly: Scalars['Boolean'],
  customerID: Scalars['Int'],
  customerName: Scalars['String'],
  showID: Scalars['Int'],
  showName: Scalars['String'],
  lastRun?: Maybe<Scalars['AWSDateTime']>,
  lastRunTime?: Maybe<Scalars['Int']>,
};

export type EmailReportSettingConnection = {
   __typename?: 'EmailReportSettingConnection',
  items?: Maybe<Array<EmailReportSetting>>,
};

export enum EmailReportType {
  Dashboard = 'DASHBOARD',
  Showreport = 'SHOWREPORT'
}

export type EngagementActivityDate = {
   __typename?: 'EngagementActivityDate',
  date?: Maybe<Scalars['String']>,
  totalCount?: Maybe<Scalars['Int']>,
};

export type EngagementActivityDateConnection = {
   __typename?: 'EngagementActivityDateConnection',
  items?: Maybe<Array<EngagementActivityDate>>,
};

export type EngagementActivityWeekday = {
   __typename?: 'EngagementActivityWeekday',
  dayNumber?: Maybe<Scalars['Int']>,
  day?: Maybe<Scalars['String']>,
  totalCount?: Maybe<Scalars['Int']>,
};

export type EngagementActivityWeekdayConnection = {
   __typename?: 'EngagementActivityWeekdayConnection',
  items?: Maybe<Array<EngagementActivityWeekday>>,
};

export type EngagementDeviceInfo = {
   __typename?: 'EngagementDeviceInfo',
  deviceType?: Maybe<Scalars['String']>,
  os?: Maybe<Scalars['String']>,
  userCount?: Maybe<Scalars['Int']>,
};

export type EngagementDeviceInfoConnection = {
   __typename?: 'EngagementDeviceInfoConnection',
  items?: Maybe<Array<EngagementDeviceInfo>>,
};

export type EngagementEpisode = {
   __typename?: 'EngagementEpisode',
  ShowId: Scalars['Int'],
  EpisodeId: Scalars['Int'],
  ShowNameShort: Scalars['String'],
  Season?: Maybe<Scalars['Int']>,
  EpisodeNo?: Maybe<Scalars['Int']>,
  EpisodeName?: Maybe<Scalars['String']>,
  EpisodePoster?: Maybe<Scalars['String']>,
  PosterFileId?: Maybe<Scalars['String']>,
  totalViews?: Maybe<Scalars['Int']>,
};

export type EngagementEpisodeConnection = {
   __typename?: 'EngagementEpisodeConnection',
  items?: Maybe<Array<EngagementEpisode>>,
};

export type EngagementReport = {
   __typename?: 'EngagementReport',
  totalViews?: Maybe<Scalars['Int']>,
  ipCount?: Maybe<Scalars['Int']>,
  appUsers?: Maybe<Scalars['Int']>,
  topEpisodes?: Maybe<Array<Maybe<Episode>>>,
  dateStreams?: Maybe<Array<Maybe<DateStream>>>,
  dataByWeekday?: Maybe<Array<Maybe<StreamDataByWeekday>>>,
  dataByHour?: Maybe<Array<Maybe<StreamDataByHour>>>,
};

export type EngagementTopLocation = {
   __typename?: 'EngagementTopLocation',
  country?: Maybe<Scalars['String']>,
  state?: Maybe<Scalars['String']>,
  totalVisits?: Maybe<Scalars['Int']>,
};

export type EngagementTopLocationConnection = {
   __typename?: 'EngagementTopLocationConnection',
  items?: Maybe<Array<EngagementTopLocation>>,
};

export type Episode = {
   __typename?: 'Episode',
  id: Scalars['Int'],
  showID: Scalars['Int'],
  showName?: Maybe<Scalars['String']>,
  season: Scalars['Int'],
  seasonName?: Maybe<Scalars['String']>,
  number: Scalars['Int'],
  firstRelease: Scalars['AWSDateTime'],
  added: Scalars['AWSDateTime'],
  name: Scalars['String'],
  description?: Maybe<Scalars['String']>,
  disclaimer?: Maybe<Scalars['String']>,
  enabled: Scalars['Boolean'],
  posterType: Scalars['Int'],
  poster?: Maybe<Scalars['String']>,
  posterURL?: Maybe<Scalars['AWSURL']>,
  posterFileID?: Maybe<Scalars['String']>,
  video?: Maybe<Scalars['String']>,
  videoBucket?: Maybe<Scalars['String']>,
  videoKey?: Maybe<Scalars['String']>,
  videoQuality: Scalars['String'],
  videoType: Scalars['String'],
  videoWidth?: Maybe<Scalars['Int']>,
  videoHeight?: Maybe<Scalars['Int']>,
  mediaConvertJobID?: Maybe<Scalars['String']>,
  mediaConvertJobStatus?: Maybe<Scalars['String']>,
  audioFingerprintID?: Maybe<Scalars['String']>,
  duration?: Maybe<Scalars['Float']>,
  rokuAddBreaks?: Maybe<Scalars['String']>,
  releaseToOTT: Scalars['Boolean'],
  releaseToApp: Scalars['Boolean'],
  liveChannelID?: Maybe<Scalars['Int']>,
  enableHeaderButton: Scalars['Boolean'],
  headerButtonText: Scalars['String'],
  headerButtonURL: Scalars['String'],
  disableProductScroller: Scalars['Boolean'],
  liveStreamID: Scalars['String'],
  liveStreamEnabledOnStartMenuID: Scalars['Int'],
  videoToken?: Maybe<Scalars['String']>,
};

export type EpisodeCommercial = {
   __typename?: 'EpisodeCommercial',
  id: Scalars['Int'],
  episodeID: Scalars['Int'],
  commercialEpisodeID: Scalars['Int'],
  type: Scalars['Int'],
  spliceTime?: Maybe<Scalars['Int']>,
  showID?: Maybe<Scalars['Int']>,
  episodeName?: Maybe<Scalars['String']>,
  episodePoster?: Maybe<Scalars['String']>,
  episodePosterURL?: Maybe<Scalars['AWSURL']>,
  enabled?: Maybe<Scalars['Boolean']>,
  duration?: Maybe<Scalars['Int']>,
};

export type EpisodeCommercialConnection = {
   __typename?: 'EpisodeCommercialConnection',
  items?: Maybe<Array<EpisodeCommercial>>,
};

export type EpisodeConnection = {
   __typename?: 'EpisodeConnection',
  items?: Maybe<Array<Episode>>,
};

export type EpisodeMetadataItem = {
   __typename?: 'EpisodeMetadataItem',
  id: Scalars['Int'],
  episodeID: Scalars['Int'],
  name: Scalars['String'],
  value: Scalars['String'],
};

export type EpisodeMetadataItemConnection = {
   __typename?: 'EpisodeMetadataItemConnection',
  items?: Maybe<Array<EpisodeMetadataItem>>,
};

export type EpisodeMetaTags = {
   __typename?: 'EpisodeMetaTags',
  episodeID: Scalars['Int'],
  OG_TITLE: Scalars['String'],
  OG_DESCRIPTION: Scalars['String'],
  OG_IMAGE: Scalars['String'],
  FAVICON: Scalars['String'],
  MANIFEST: Scalars['String'],
};

export type EpisodePhoto = {
   __typename?: 'EpisodePhoto',
  id: Scalars['Int'],
  episodeID: Scalars['Int'],
  groupID: Scalars['Int'],
  groupName: Scalars['String'],
  order: Scalars['Int'],
  title?: Maybe<Scalars['String']>,
  description: Scalars['String'],
  fileID: Scalars['String'],
  extension: Scalars['String'],
  url?: Maybe<Scalars['AWSURL']>,
  thumbnailURL?: Maybe<Scalars['AWSURL']>,
  enabled?: Maybe<Scalars['Boolean']>,
  added?: Maybe<Scalars['AWSDateTime']>,
};

export type EpisodePhotoConnection = {
   __typename?: 'EpisodePhotoConnection',
  items?: Maybe<Array<EpisodePhoto>>,
};

export type EpisodePhotoGroup = {
   __typename?: 'EpisodePhotoGroup',
  id: Scalars['Int'],
  episodeID: Scalars['Int'],
  name: Scalars['String'],
  order: Scalars['Int'],
  enabled: Scalars['Boolean'],
};

export type EpisodePhotoGroupConnection = {
   __typename?: 'EpisodePhotoGroupConnection',
  items?: Maybe<Array<EpisodePhotoGroup>>,
};

export type EpisodePhotoProduct = {
   __typename?: 'EpisodePhotoProduct',
  id: Scalars['Int'],
  groupID: Scalars['Int'],
  productID: Scalars['Int'],
  episodeID?: Maybe<Scalars['Int']>,
  brandName: Scalars['String'],
  product: Product,
  vendorLogo?: Maybe<Scalars['String']>,
  vendorLogoBucket?: Maybe<Scalars['String']>,
  vendorLogoKey?: Maybe<Scalars['String']>,
};

export type EpisodePhotoProductConnection = {
   __typename?: 'EpisodePhotoProductConnection',
  items?: Maybe<Array<EpisodePhotoProduct>>,
};

export type EpisodeProduct = {
   __typename?: 'EpisodeProduct',
  id: Scalars['Int'],
  episodeID: Scalars['Int'],
  productID: Scalars['Int'],
  startTimecode: Scalars['String'],
  startTimeSeconds?: Maybe<Scalars['Int']>,
  startTimeMilliseconds: Scalars['Int'],
  endTimecode: Scalars['String'],
  endTimeSeconds?: Maybe<Scalars['Int']>,
  endTimeMilliseconds: Scalars['Int'],
  brandName?: Maybe<Scalars['String']>,
  product: Product,
  vendorLogo?: Maybe<Scalars['String']>,
  vendorLogoBucket?: Maybe<Scalars['String']>,
  vendorLogoKey?: Maybe<Scalars['String']>,
};

export type EpisodeProductConnection = {
   __typename?: 'EpisodeProductConnection',
  items?: Maybe<Array<EpisodeProduct>>,
};

export type EpisodeReport = {
   __typename?: 'EpisodeReport',
  totalSeconds?: Maybe<Scalars['Float']>,
  totalBytes?: Maybe<Scalars['Float']>,
  ipCount?: Maybe<Scalars['Int']>,
  appUsers?: Maybe<Scalars['Int']>,
  totalParts?: Maybe<Scalars['Float']>,
  averageViews?: Maybe<Scalars['Float']>,
  minPartViews?: Maybe<Scalars['Int']>,
  maxPartViews?: Maybe<Scalars['Int']>,
  dateStreams?: Maybe<Array<Maybe<DateStream>>>,
  streamDataByBandwidth?: Maybe<Array<Maybe<StreamDataByBandwidth>>>,
};

export type EpisodeStream = {
   __typename?: 'EpisodeStream',
  id: Scalars['Int'],
  episodeID: Scalars['Int'],
  enabled: Scalars['Boolean'],
  type: Scalars['Int'],
  bandwidth: Scalars['Int'],
  averageBandwidth: Scalars['Int'],
  codecs: Scalars['String'],
  resolution: Scalars['String'],
  frameRate: Scalars['Float'],
  targetDuration: Scalars['Int'],
  targetBandwidth: Scalars['Int'],
  filename: Scalars['String'],
  partDuration: Scalars['Int'],
  partCount: Scalars['Int'],
  videoRoot?: Maybe<Scalars['String']>,
  videoToken?: Maybe<Scalars['String']>,
};

export type EpisodeStreamConnection = {
   __typename?: 'EpisodeStreamConnection',
  items?: Maybe<Array<EpisodeStream>>,
};

export type ExclusiveVideoContent = {
   __typename?: 'ExclusiveVideoContent',
  id: Scalars['ID'],
  legacyCustomerID: Scalars['Int'],
  legacyCustomerIDString: Scalars['String'],
  legacyShowID: Scalars['Int'],
  name: Scalars['String'],
  description: Scalars['String'],
  enabled: Scalars['Boolean'],
  firstRelease: Scalars['AWSDateTime'],
  posterBucket?: Maybe<Scalars['String']>,
  posterKey?: Maybe<Scalars['String']>,
  videoBucket?: Maybe<Scalars['String']>,
  videoKey?: Maybe<Scalars['String']>,
  videoURL?: Maybe<Scalars['String']>,
  videoQuality?: Maybe<VideoQuality>,
  videoType?: Maybe<VideoType>,
  videoWidth?: Maybe<Scalars['Int']>,
  videoHeight?: Maybe<Scalars['Int']>,
  mediaConvertJobID?: Maybe<Scalars['String']>,
  mediaConvertJobStatus?: Maybe<Scalars['String']>,
  duration?: Maybe<Scalars['Float']>,
  releaseToOTT?: Maybe<Scalars['Boolean']>,
  releaseToApp?: Maybe<Scalars['Boolean']>,
  videoToken?: Maybe<Scalars['String']>,
  disableProductScroller?: Maybe<Scalars['Boolean']>,
  productDefinitions?: Maybe<Array<ExclusiveVideoContentProductDefinition>>,
  videoProducts?: Maybe<Array<ExclusiveVideoProduct>>,
  createdAt: Scalars['AWSDateTime'],
  updatedAt: Scalars['AWSDateTime'],
};

export type ExclusiveVideoContentProductDefinition = {
   __typename?: 'ExclusiveVideoContentProductDefinition',
  productID: Scalars['Int'],
  startTimeMilliseconds: Scalars['Int'],
  endTimeMilliseconds: Scalars['Int'],
};

export type ExclusiveVideoContentProductDefinitionInput = {
  productID: Scalars['Int'],
  startTimeMilliseconds: Scalars['Int'],
  endTimeMilliseconds: Scalars['Int'],
};

export type ExclusiveVideoProduct = {
   __typename?: 'ExclusiveVideoProduct',
  brandName?: Maybe<Scalars['String']>,
  product: Product,
  startTimeMilliseconds: Scalars['Int'],
  endTimeMilliseconds: Scalars['Int'],
};

export type ExclusiveVideoProductInput = {
  brandName?: Maybe<Scalars['String']>,
  product: ProductInput,
  startTimeMilliseconds: Scalars['Int'],
  endTimeMilliseconds: Scalars['Int'],
};

export enum FontFamily {
  Montserrat = 'MONTSERRAT',
  Oswald = 'OSWALD',
  PtSans = 'PT_SANS',
  Quicksand = 'QUICKSAND',
  RobotoCondensed = 'ROBOTO_CONDENSED',
  TitilliumWeb = 'TITILLIUM_WEB',
  CrimsonText = 'CRIMSON_TEXT',
  DmSerifDisplay = 'DM_SERIF_DISPLAY',
  Lora = 'LORA',
  Merriweather = 'MERRIWEATHER',
  RobotoSlab = 'ROBOTO_SLAB',
  Arial = 'ARIAL',
  Roboto = 'ROBOTO',
  SegoeUi = 'SEGOE_UI',
  TimesNewRoman = 'TIMES_NEW_ROMAN'
}

export enum FontLocation {
  AppHeader = 'APP_HEADER',
  MenuButton = 'MENU_BUTTON',
  PageTitleText = 'PAGE_TITLE_TEXT',
  CardInfo = 'CARD_INFO',
  ButtonText = 'BUTTON_TEXT',
  Default = 'DEFAULT'
}

export enum FontStyle {
  Normal = 'NORMAL',
  Italic = 'ITALIC'
}

export type FontTarget = {
   __typename?: 'FontTarget',
  id: Scalars['Int'],
  styleClassName: Scalars['String'],
  displayName: Scalars['String'],
  description?: Maybe<Scalars['String']>,
  active: Scalars['Boolean'],
  added?: Maybe<Scalars['AWSDateTime']>,
};

export type FontTargetConnection = {
   __typename?: 'FontTargetConnection',
  items?: Maybe<Array<FontTarget>>,
};

export enum FontWeight {
  Normal = 'NORMAL',
  Bold = 'BOLD'
}

export type GetExclusiveVideoContentFilterInput = {
  onlyActiveProducts?: Maybe<Scalars['Boolean']>,
};

export type HomeScreenSettings = {
   __typename?: 'HomeScreenSettings',
  showID: Scalars['Int'],
  legacyCustomerID: Scalars['Int'],
  legacyCustomerIDString: Scalars['String'],
  featuredProductsLabel?: Maybe<Scalars['String']>,
  smallBusinessLabel?: Maybe<Scalars['String']>,
  recentEpisodesLabel?: Maybe<Scalars['String']>,
  recommendedProductsLabel?: Maybe<Scalars['String']>,
  createdAt: Scalars['AWSDateTime'],
  updatedAt: Scalars['AWSDateTime'],
};

export type ImageUrl = {
   __typename?: 'ImageUrl',
  url?: Maybe<Scalars['AWSURL']>,
};

export type ManifestIcon = {
   __typename?: 'ManifestIcon',
  sizes: Scalars['String'],
  src: Scalars['String'],
};

export type MobileAnalyticsConnection = {
   __typename?: 'MobileAnalyticsConnection',
  items?: Maybe<Array<Maybe<MobileAnalyticsEvent>>>,
};

export type MobileAnalyticsEvent = {
   __typename?: 'MobileAnalyticsEvent',
  eventType: Scalars['String'],
  totalCount: Scalars['Int'],
};

export type MobilePageView = {
   __typename?: 'MobilePageView',
  screen?: Maybe<Scalars['String']>,
  appid?: Maybe<Scalars['String']>,
  totalCount?: Maybe<Scalars['Int']>,
};

export type MobilePageViewConnection = {
   __typename?: 'MobilePageViewConnection',
  items?: Maybe<Array<Maybe<MobilePageView>>>,
};

export type ModelBannerAdConnection = {
   __typename?: 'ModelBannerAdConnection',
  items?: Maybe<Array<Maybe<BannerAd>>>,
  nextToken?: Maybe<Scalars['String']>,
};

export type ModelBannerAdFilterInput = {
  id?: Maybe<ModelIdFilterInput>,
  customerID?: Maybe<ModelIntFilterInput>,
  showID?: Maybe<ModelIntFilterInput>,
  episodeID?: Maybe<ModelIntFilterInput>,
  categoryID?: Maybe<ModelIntFilterInput>,
  imageBucket?: Maybe<ModelStringFilterInput>,
  imageKey?: Maybe<ModelStringFilterInput>,
  url?: Maybe<ModelStringFilterInput>,
  linkType?: Maybe<ModelBannerAdLinkTypeFilterInput>,
  type?: Maybe<ModelBannerAdTypeFilterInput>,
  and?: Maybe<Array<Maybe<ModelBannerAdFilterInput>>>,
  or?: Maybe<Array<Maybe<ModelBannerAdFilterInput>>>,
  not?: Maybe<ModelBannerAdFilterInput>,
};

export type ModelBannerAdLinkTypeFilterInput = {
  eq?: Maybe<BannerAdLinkType>,
  ne?: Maybe<BannerAdLinkType>,
};

export type ModelBannerAdTypeFilterInput = {
  eq?: Maybe<BannerAdType>,
  ne?: Maybe<BannerAdType>,
};

export type ModelBooleanFilterInput = {
  ne?: Maybe<Scalars['Boolean']>,
  eq?: Maybe<Scalars['Boolean']>,
};

export type ModelCubejsDashboardItemConnection = {
   __typename?: 'ModelCubejsDashboardItemConnection',
  items?: Maybe<Array<Maybe<CubejsDashboardItem>>>,
  nextToken?: Maybe<Scalars['String']>,
};

export type ModelCubejsDashboardItemFilterInput = {
  id?: Maybe<ModelIdFilterInput>,
  userID?: Maybe<ModelIdFilterInput>,
  layout?: Maybe<ModelStringFilterInput>,
  vizState?: Maybe<ModelStringFilterInput>,
  name?: Maybe<ModelStringFilterInput>,
  and?: Maybe<Array<Maybe<ModelCubejsDashboardItemFilterInput>>>,
  or?: Maybe<Array<Maybe<ModelCubejsDashboardItemFilterInput>>>,
  not?: Maybe<ModelCubejsDashboardItemFilterInput>,
};

export type ModelDigitalAssetLinksConnection = {
   __typename?: 'ModelDigitalAssetLinksConnection',
  items?: Maybe<Array<Maybe<DigitalAssetLinks>>>,
  nextToken?: Maybe<Scalars['String']>,
};

export type ModelDigitalAssetLinksFilterInput = {
  appID?: Maybe<ModelStringFilterInput>,
  legacyShowID?: Maybe<ModelIntFilterInput>,
  legacyCustomerID?: Maybe<ModelIntFilterInput>,
  android?: Maybe<ModelStringFilterInput>,
  and?: Maybe<Array<Maybe<ModelDigitalAssetLinksFilterInput>>>,
  or?: Maybe<Array<Maybe<ModelDigitalAssetLinksFilterInput>>>,
  not?: Maybe<ModelDigitalAssetLinksFilterInput>,
};

export type ModelExclusiveVideoContentConnection = {
   __typename?: 'ModelExclusiveVideoContentConnection',
  items?: Maybe<Array<Maybe<ExclusiveVideoContent>>>,
  nextToken?: Maybe<Scalars['String']>,
};

export type ModelExclusiveVideoContentFilterInput = {
  id?: Maybe<ModelIdFilterInput>,
  legacyCustomerID?: Maybe<ModelIntFilterInput>,
  legacyCustomerIDString?: Maybe<ModelStringFilterInput>,
  legacyShowID?: Maybe<ModelIntFilterInput>,
  name?: Maybe<ModelStringFilterInput>,
  description?: Maybe<ModelStringFilterInput>,
  enabled?: Maybe<ModelBooleanFilterInput>,
  firstRelease?: Maybe<ModelStringFilterInput>,
  posterBucket?: Maybe<ModelStringFilterInput>,
  posterKey?: Maybe<ModelStringFilterInput>,
  videoBucket?: Maybe<ModelStringFilterInput>,
  videoKey?: Maybe<ModelStringFilterInput>,
  videoURL?: Maybe<ModelStringFilterInput>,
  videoQuality?: Maybe<ModelVideoQualityFilterInput>,
  videoType?: Maybe<ModelVideoTypeFilterInput>,
  videoWidth?: Maybe<ModelIntFilterInput>,
  videoHeight?: Maybe<ModelIntFilterInput>,
  mediaConvertJobID?: Maybe<ModelStringFilterInput>,
  mediaConvertJobStatus?: Maybe<ModelStringFilterInput>,
  duration?: Maybe<ModelFloatFilterInput>,
  releaseToOTT?: Maybe<ModelBooleanFilterInput>,
  releaseToApp?: Maybe<ModelBooleanFilterInput>,
  videoToken?: Maybe<ModelStringFilterInput>,
  disableProductScroller?: Maybe<ModelBooleanFilterInput>,
  and?: Maybe<Array<Maybe<ModelExclusiveVideoContentFilterInput>>>,
  or?: Maybe<Array<Maybe<ModelExclusiveVideoContentFilterInput>>>,
  not?: Maybe<ModelExclusiveVideoContentFilterInput>,
};

export type ModelFloatFilterInput = {
  ne?: Maybe<Scalars['Float']>,
  eq?: Maybe<Scalars['Float']>,
  le?: Maybe<Scalars['Float']>,
  lt?: Maybe<Scalars['Float']>,
  ge?: Maybe<Scalars['Float']>,
  gt?: Maybe<Scalars['Float']>,
  between?: Maybe<Array<Maybe<Scalars['Float']>>>,
};

export type ModelFontFamilyFilterInput = {
  eq?: Maybe<FontFamily>,
  ne?: Maybe<FontFamily>,
};

export type ModelFontLocationFilterInput = {
  eq?: Maybe<FontLocation>,
  ne?: Maybe<FontLocation>,
};

export type ModelFontStyleFilterInput = {
  eq?: Maybe<FontStyle>,
  ne?: Maybe<FontStyle>,
};

export type ModelFontWeightFilterInput = {
  eq?: Maybe<FontWeight>,
  ne?: Maybe<FontWeight>,
};

export type ModelHomeScreenSettingsConnection = {
   __typename?: 'ModelHomeScreenSettingsConnection',
  items?: Maybe<Array<Maybe<HomeScreenSettings>>>,
  nextToken?: Maybe<Scalars['String']>,
};

export type ModelHomeScreenSettingsFilterInput = {
  showID?: Maybe<ModelIntFilterInput>,
  legacyCustomerID?: Maybe<ModelIntFilterInput>,
  legacyCustomerIDString?: Maybe<ModelStringFilterInput>,
  featuredProductsLabel?: Maybe<ModelStringFilterInput>,
  smallBusinessLabel?: Maybe<ModelStringFilterInput>,
  recentEpisodesLabel?: Maybe<ModelStringFilterInput>,
  recommendedProductsLabel?: Maybe<ModelStringFilterInput>,
  and?: Maybe<Array<Maybe<ModelHomeScreenSettingsFilterInput>>>,
  or?: Maybe<Array<Maybe<ModelHomeScreenSettingsFilterInput>>>,
  not?: Maybe<ModelHomeScreenSettingsFilterInput>,
};

export type ModelIdFilterInput = {
  ne?: Maybe<Scalars['ID']>,
  eq?: Maybe<Scalars['ID']>,
  le?: Maybe<Scalars['ID']>,
  lt?: Maybe<Scalars['ID']>,
  ge?: Maybe<Scalars['ID']>,
  gt?: Maybe<Scalars['ID']>,
  contains?: Maybe<Scalars['ID']>,
  notContains?: Maybe<Scalars['ID']>,
  between?: Maybe<Array<Maybe<Scalars['ID']>>>,
  beginsWith?: Maybe<Scalars['ID']>,
};

export type ModelIntFilterInput = {
  ne?: Maybe<Scalars['Int']>,
  eq?: Maybe<Scalars['Int']>,
  le?: Maybe<Scalars['Int']>,
  lt?: Maybe<Scalars['Int']>,
  ge?: Maybe<Scalars['Int']>,
  gt?: Maybe<Scalars['Int']>,
  between?: Maybe<Array<Maybe<Scalars['Int']>>>,
};

export type ModelNotificationConnection = {
   __typename?: 'ModelNotificationConnection',
  items?: Maybe<Array<Maybe<Notification>>>,
  nextToken?: Maybe<Scalars['String']>,
};

export type ModelNotificationFilterInput = {
  id?: Maybe<ModelIdFilterInput>,
  userID?: Maybe<ModelIdFilterInput>,
  description?: Maybe<ModelStringFilterInput>,
  time?: Maybe<ModelStringFilterInput>,
  and?: Maybe<Array<Maybe<ModelNotificationFilterInput>>>,
  or?: Maybe<Array<Maybe<ModelNotificationFilterInput>>>,
  not?: Maybe<ModelNotificationFilterInput>,
};

export type ModelShowFontV2Connection = {
   __typename?: 'ModelShowFontV2Connection',
  items?: Maybe<Array<Maybe<ShowFontV2>>>,
  nextToken?: Maybe<Scalars['String']>,
};

export type ModelShowFontV2FilterInput = {
  id?: Maybe<ModelIdFilterInput>,
  showFontLocationComposite?: Maybe<ModelStringFilterInput>,
  showID?: Maybe<ModelIntFilterInput>,
  family?: Maybe<ModelFontFamilyFilterInput>,
  location?: Maybe<ModelFontLocationFilterInput>,
  weight?: Maybe<ModelFontWeightFilterInput>,
  style?: Maybe<ModelFontStyleFilterInput>,
  and?: Maybe<Array<Maybe<ModelShowFontV2FilterInput>>>,
  or?: Maybe<Array<Maybe<ModelShowFontV2FilterInput>>>,
  not?: Maybe<ModelShowFontV2FilterInput>,
};

export type ModelShowMetaTagsConnection = {
   __typename?: 'ModelShowMetaTagsConnection',
  items?: Maybe<Array<Maybe<ShowMetaTags>>>,
  nextToken?: Maybe<Scalars['String']>,
};

export type ModelShowMetaTagsFilterInput = {
  appID?: Maybe<ModelIdFilterInput>,
  showID?: Maybe<ModelIntFilterInput>,
  OG_TITLE?: Maybe<ModelStringFilterInput>,
  OG_DESCRIPTION?: Maybe<ModelStringFilterInput>,
  OG_IMAGE?: Maybe<ModelStringFilterInput>,
  FAVICON?: Maybe<ModelStringFilterInput>,
  MANIFEST?: Maybe<ModelStringFilterInput>,
  and?: Maybe<Array<Maybe<ModelShowMetaTagsFilterInput>>>,
  or?: Maybe<Array<Maybe<ModelShowMetaTagsFilterInput>>>,
  not?: Maybe<ModelShowMetaTagsFilterInput>,
};

export enum ModelSortDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type ModelStringFilterInput = {
  ne?: Maybe<Scalars['String']>,
  eq?: Maybe<Scalars['String']>,
  le?: Maybe<Scalars['String']>,
  lt?: Maybe<Scalars['String']>,
  ge?: Maybe<Scalars['String']>,
  gt?: Maybe<Scalars['String']>,
  contains?: Maybe<Scalars['String']>,
  notContains?: Maybe<Scalars['String']>,
  between?: Maybe<Array<Maybe<Scalars['String']>>>,
  beginsWith?: Maybe<Scalars['String']>,
};

export type ModelStringKeyConditionInput = {
  eq?: Maybe<Scalars['String']>,
  le?: Maybe<Scalars['String']>,
  lt?: Maybe<Scalars['String']>,
  ge?: Maybe<Scalars['String']>,
  gt?: Maybe<Scalars['String']>,
  between?: Maybe<Array<Maybe<Scalars['String']>>>,
  beginsWith?: Maybe<Scalars['String']>,
};

export type ModelVendorAdConnection = {
   __typename?: 'ModelVendorAdConnection',
  items?: Maybe<Array<Maybe<VendorAd>>>,
  nextToken?: Maybe<Scalars['String']>,
};

export type ModelVendorAdFilterInput = {
  id?: Maybe<ModelIdFilterInput>,
  vendorID?: Maybe<ModelIdFilterInput>,
  name?: Maybe<ModelStringFilterInput>,
  description?: Maybe<ModelStringFilterInput>,
  imageBucket?: Maybe<ModelStringFilterInput>,
  imageKey?: Maybe<ModelStringFilterInput>,
  url?: Maybe<ModelStringFilterInput>,
  type?: Maybe<ModelBannerAdTypeFilterInput>,
  and?: Maybe<Array<Maybe<ModelVendorAdFilterInput>>>,
  or?: Maybe<Array<Maybe<ModelVendorAdFilterInput>>>,
  not?: Maybe<ModelVendorAdFilterInput>,
};

export type ModelVendorBusinessTypeFilterInput = {
  eq?: Maybe<VendorBusinessType>,
  ne?: Maybe<VendorBusinessType>,
};

export type ModelVendorConnection = {
   __typename?: 'ModelVendorConnection',
  items?: Maybe<Array<Maybe<Vendor>>>,
  nextToken?: Maybe<Scalars['String']>,
};

export type ModelVendorFilterInput = {
  id?: Maybe<ModelIdFilterInput>,
  name?: Maybe<ModelStringFilterInput>,
  type?: Maybe<ModelVendorBusinessTypeFilterInput>,
  active?: Maybe<ModelBooleanFilterInput>,
  salesOwnedByUserID?: Maybe<ModelIdFilterInput>,
  balance?: Maybe<ModelFloatFilterInput>,
  balanceUpdated?: Maybe<ModelStringFilterInput>,
  logo?: Maybe<ModelStringFilterInput>,
  logoFileID?: Maybe<ModelStringFilterInput>,
  logoFileExtension?: Maybe<ModelStringFilterInput>,
  logoBucket?: Maybe<ModelStringFilterInput>,
  logoKey?: Maybe<ModelStringFilterInput>,
  pixelTrackingID?: Maybe<ModelIdFilterInput>,
  and?: Maybe<Array<Maybe<ModelVendorFilterInput>>>,
  or?: Maybe<Array<Maybe<ModelVendorFilterInput>>>,
  not?: Maybe<ModelVendorFilterInput>,
};

export type ModelVideoQualityFilterInput = {
  eq?: Maybe<VideoQuality>,
  ne?: Maybe<VideoQuality>,
};

export type ModelVideoTypeFilterInput = {
  eq?: Maybe<VideoType>,
  ne?: Maybe<VideoType>,
};

export type Mutation = {
   __typename?: 'Mutation',
  createUser?: Maybe<User>,
  disableUser?: Maybe<User>,
  createCustomer?: Maybe<Customer>,
  updateCustomer?: Maybe<Customer>,
  createShow?: Maybe<Show>,
  updateShow?: Maybe<Show>,
  updateShowAppData?: Maybe<ShowAppData>,
  updateShowChannel?: Maybe<ShowChannel>,
  updateShowProductRequestConfiguration?: Maybe<ShowProductRequestConfiguration>,
  updateShowPoster?: Maybe<ImageUrl>,
  updateShowAppLogo?: Maybe<ImageUrl>,
  addShowMetadata?: Maybe<ShowMetadataItem>,
  deleteShowMetadata?: Maybe<ShowMetadataItem>,
  createShowSeason?: Maybe<ShowSeason>,
  createShowDesignMenuItem?: Maybe<ShowDesignMenuItem>,
  updateShowDesignMenuItem?: Maybe<ShowDesignMenuItem>,
  deleteShowDesignMenuItem?: Maybe<Scalars['Int']>,
  createEpisode?: Maybe<Episode>,
  updateEpisode?: Maybe<Episode>,
  updateEpisodeVideoCleanup: Scalars['Boolean'],
  updateEpisodePoster?: Maybe<ImageUrl>,
  createEpisodePhoto?: Maybe<EpisodePhoto>,
  deleteEpisodePhoto: Scalars['Int'],
  createEpisodeCommercial?: Maybe<EpisodeCommercial>,
  deleteEpisodeCommercial?: Maybe<Scalars['Int']>,
  createProduct?: Maybe<Product>,
  updateProduct?: Maybe<Product>,
  deleteProduct?: Maybe<Scalars['Int']>,
  createProductLink?: Maybe<ProductLink>,
  deleteProductLink?: Maybe<Scalars['Int']>,
  createProductCategory?: Maybe<ProductCategoryConnection>,
  deleteProductCategory?: Maybe<Scalars['Int']>,
  createEpisodeProduct?: Maybe<Scalars['Int']>,
  deleteEpisodeProduct?: Maybe<Scalars['Int']>,
  createEpisodePhotoProduct?: Maybe<Scalars['Int']>,
  deleteEpisodePhotoProduct?: Maybe<Scalars['Int']>,
  createProductMetadataItem?: Maybe<ProductMetadataItem>,
  deleteProductMetadataItem?: Maybe<Scalars['Int']>,
  updateBrand?: Maybe<Brand>,
  createCategory?: Maybe<Category>,
  updateCategory?: Maybe<Category>,
  deleteCategory?: Maybe<Scalars['Boolean']>,
  createStore?: Maybe<Store>,
  updateStore?: Maybe<Store>,
  createVendorProduct?: Maybe<Scalars['ID']>,
  deleteVendorProduct?: Maybe<Scalars['Boolean']>,
  createEmailReportSetting?: Maybe<EmailReportSetting>,
  updateEmailReportSetting?: Maybe<EmailReportSetting>,
  deleteEmailReportSetting?: Maybe<Scalars['Int']>,
  createProductRequest?: Maybe<Scalars['Int']>,
  updateProductRequest?: Maybe<ProductRequest>,
  replyProductRequest?: Maybe<Scalars['Int']>,
  updateVendorLogo?: Maybe<Scalars['String']>,
  generatePresignedURL: S3SignedUrl,
  generatePresignedRawMediaURL: S3SignedUrl,
  generatePresignedRawVideoURL: S3SignedUrl,
  resolveProductsReport?: Maybe<ReportProductConnection>,
  resolveVendorProductsReport?: Maybe<ReportProductConnection>,
  resolveAdminAdReport?: Maybe<AdTrackingReportConnection>,
  createAppInstall?: Maybe<Scalars['Int']>,
  updateAppInstall?: Maybe<Scalars['Int']>,
  createAppUsageLog?: Maybe<AppUsageLog>,
  createBannerAdLog?: Maybe<BannerAdLog>,
  addProductToWishlist?: Maybe<UserWishlist>,
  deleteProductFromWishlist?: Maybe<Scalars['Int']>,
  cleanupBannerAd?: Maybe<Scalars['Boolean']>,
  deleteExclusiveVideoContent?: Maybe<ExclusiveVideoContent>,
  deleteNotification?: Maybe<Notification>,
  createVendor?: Maybe<Vendor>,
  deleteVendor?: Maybe<Vendor>,
  createShowFontV2?: Maybe<ShowFontV2>,
  updateShowFontV2?: Maybe<ShowFontV2>,
  deleteShowFontV2?: Maybe<ShowFontV2>,
  createCubejsDashboardItem?: Maybe<CubejsDashboardItem>,
  updateCubejsDashboardItem?: Maybe<CubejsDashboardItem>,
  deleteCubejsDashboardItem?: Maybe<CubejsDashboardItem>,
  createDigitalAssetLinks?: Maybe<DigitalAssetLinks>,
  updateDigitalAssetLinks?: Maybe<DigitalAssetLinks>,
  deleteDigitalAssetLinks?: Maybe<DigitalAssetLinks>,
  createHomeScreenSettings?: Maybe<HomeScreenSettings>,
  updateHomeScreenSettings?: Maybe<HomeScreenSettings>,
  deleteHomeScreenSettings?: Maybe<HomeScreenSettings>,
  createExclusiveVideoContent?: Maybe<ExclusiveVideoContent>,
  updateExclusiveVideoContent?: Maybe<ExclusiveVideoContent>,
  createShowMetaTags?: Maybe<ShowMetaTags>,
  updateShowMetaTags?: Maybe<ShowMetaTags>,
  deleteShowMetaTags?: Maybe<ShowMetaTags>,
  createNotification?: Maybe<Notification>,
  updateNotification?: Maybe<Notification>,
  updateVendor?: Maybe<Vendor>,
  createVendorAd?: Maybe<VendorAd>,
  updateVendorAd?: Maybe<VendorAd>,
  deleteVendorAd?: Maybe<VendorAd>,
  createBannerAd?: Maybe<BannerAd>,
  updateBannerAd?: Maybe<BannerAd>,
  deleteBannerAd?: Maybe<BannerAd>,
};


export type MutationCreateUserArgs = {
  input: CreateUserInput
};


export type MutationDisableUserArgs = {
  id: Scalars['ID']
};


export type MutationCreateCustomerArgs = {
  input: CreateCustomerInput
};


export type MutationUpdateCustomerArgs = {
  input: UpdateCustomerInput
};


export type MutationCreateShowArgs = {
  input: CreateShowInput
};


export type MutationUpdateShowArgs = {
  input: UpdateShowInput
};


export type MutationUpdateShowAppDataArgs = {
  input: UpdateShowAppDataInput
};


export type MutationUpdateShowChannelArgs = {
  input: UpdateShowChannelInput
};


export type MutationUpdateShowProductRequestConfigurationArgs = {
  input?: Maybe<UpdateShowProductRequestConfigurationInput>
};


export type MutationUpdateShowPosterArgs = {
  input: UpdateShowImageInput
};


export type MutationUpdateShowAppLogoArgs = {
  input: UpdateShowImageInput
};


export type MutationAddShowMetadataArgs = {
  input: ShowMetadataItemInput
};


export type MutationDeleteShowMetadataArgs = {
  input: DeleteShowMetadataItemInput
};


export type MutationCreateShowSeasonArgs = {
  input: CreateShowSeasonInput
};


export type MutationCreateShowDesignMenuItemArgs = {
  input: CreateShowDesignMenuItemInput
};


export type MutationUpdateShowDesignMenuItemArgs = {
  input: UpdateShowDesignMenuItemInput
};


export type MutationDeleteShowDesignMenuItemArgs = {
  id: Scalars['Int']
};


export type MutationCreateEpisodeArgs = {
  input: CreateEpisodeInput
};


export type MutationUpdateEpisodeArgs = {
  input: UpdateEpisodeInput
};


export type MutationUpdateEpisodeVideoCleanupArgs = {
  id: Scalars['Int']
};


export type MutationUpdateEpisodePosterArgs = {
  input: UpdateEpisodeImageInput
};


export type MutationCreateEpisodePhotoArgs = {
  input: CreateEpisodePhotoInput
};


export type MutationDeleteEpisodePhotoArgs = {
  id: Scalars['Int']
};


export type MutationCreateEpisodeCommercialArgs = {
  input: CreateEpisodeCommercialInput
};


export type MutationDeleteEpisodeCommercialArgs = {
  id: Scalars['Int'],
  episodeID: Scalars['Int']
};


export type MutationCreateProductArgs = {
  input: CreateProductInput
};


export type MutationUpdateProductArgs = {
  input: UpdateProductInput
};


export type MutationDeleteProductArgs = {
  id: Scalars['Int']
};


export type MutationCreateProductLinkArgs = {
  input: CreateProductLinkInput
};


export type MutationDeleteProductLinkArgs = {
  id: Scalars['Int'],
  productID: Scalars['Int']
};


export type MutationCreateProductCategoryArgs = {
  input: CreateProductCategoryInput
};


export type MutationDeleteProductCategoryArgs = {
  productID: Scalars['Int'],
  categoryID: Scalars['Int']
};


export type MutationCreateEpisodeProductArgs = {
  input: CreateEpisodeProductInput
};


export type MutationDeleteEpisodeProductArgs = {
  id: Scalars['Int'],
  episodeID: Scalars['Int']
};


export type MutationCreateEpisodePhotoProductArgs = {
  input: CreateEpisodePhotoProductInput
};


export type MutationDeleteEpisodePhotoProductArgs = {
  id: Scalars['Int']
};


export type MutationCreateProductMetadataItemArgs = {
  input?: Maybe<CreateProductMetadataItem>
};


export type MutationDeleteProductMetadataItemArgs = {
  id: Scalars['Int']
};


export type MutationUpdateBrandArgs = {
  input: UpdateBrandInput
};


export type MutationCreateCategoryArgs = {
  input: CreateCategoryInput
};


export type MutationUpdateCategoryArgs = {
  input: UpdateCategoryInput
};


export type MutationDeleteCategoryArgs = {
  id: Scalars['Int'],
  customerID: Scalars['Int']
};


export type MutationCreateStoreArgs = {
  input: CreateStoreInput
};


export type MutationUpdateStoreArgs = {
  input: UpdateStoreInput
};


export type MutationCreateVendorProductArgs = {
  input: CreateVendorProductInput
};


export type MutationDeleteVendorProductArgs = {
  vendorID: Scalars['ID'],
  productID: Scalars['Int']
};


export type MutationCreateEmailReportSettingArgs = {
  input: CreateEmailReportSettingInput
};


export type MutationUpdateEmailReportSettingArgs = {
  input: UpdateEmailReportSettingInput
};


export type MutationDeleteEmailReportSettingArgs = {
  id: Scalars['Int']
};


export type MutationCreateProductRequestArgs = {
  input?: Maybe<CreateProductRequestInput>
};


export type MutationUpdateProductRequestArgs = {
  input?: Maybe<UpdateProductRequestInput>
};


export type MutationReplyProductRequestArgs = {
  input?: Maybe<ReplyProductRequestInput>
};


export type MutationUpdateVendorLogoArgs = {
  input?: Maybe<UpdateVendorLogoInput>
};


export type MutationGeneratePresignedUrlArgs = {
  input: S3SignedUrlInput
};


export type MutationGeneratePresignedRawMediaUrlArgs = {
  input: S3SignedUrlInput
};


export type MutationGeneratePresignedRawVideoUrlArgs = {
  input: S3SignedUrlInput
};


export type MutationResolveProductsReportArgs = {
  input?: Maybe<ReportProductConnectionInput>
};


export type MutationResolveVendorProductsReportArgs = {
  input?: Maybe<ReportProductConnectionInput>
};


export type MutationResolveAdminAdReportArgs = {
  input?: Maybe<AdTrackingReportConnectionInput>
};


export type MutationCreateAppInstallArgs = {
  input?: Maybe<CreateAppInstallInput>
};


export type MutationUpdateAppInstallArgs = {
  id: Scalars['Int']
};


export type MutationCreateAppUsageLogArgs = {
  input: CreateAppUsageLogInput
};


export type MutationCreateBannerAdLogArgs = {
  input: CreateBannerAdLogInput
};


export type MutationAddProductToWishlistArgs = {
  productID: Scalars['Int']
};


export type MutationDeleteProductFromWishlistArgs = {
  productID: Scalars['Int']
};


export type MutationCleanupBannerAdArgs = {
  id: Scalars['ID']
};


export type MutationDeleteExclusiveVideoContentArgs = {
  input: DeleteExclusiveVideoContentInput
};


export type MutationDeleteNotificationArgs = {
  input: DeleteNotificationInput
};


export type MutationCreateVendorArgs = {
  input: CreateVendorInput
};


export type MutationDeleteVendorArgs = {
  input: DeleteVendorInput
};


export type MutationCreateShowFontV2Args = {
  input: CreateShowFontV2Input
};


export type MutationUpdateShowFontV2Args = {
  input: UpdateShowFontV2Input
};


export type MutationDeleteShowFontV2Args = {
  input: DeleteShowFontV2Input
};


export type MutationCreateCubejsDashboardItemArgs = {
  input: CreateCubejsDashboardItemInput
};


export type MutationUpdateCubejsDashboardItemArgs = {
  input: UpdateCubejsDashboardItemInput
};


export type MutationDeleteCubejsDashboardItemArgs = {
  input: DeleteCubejsDashboardItemInput
};


export type MutationCreateDigitalAssetLinksArgs = {
  input: CreateDigitalAssetLinksInput
};


export type MutationUpdateDigitalAssetLinksArgs = {
  input: UpdateDigitalAssetLinksInput
};


export type MutationDeleteDigitalAssetLinksArgs = {
  input: DeleteDigitalAssetLinksInput
};


export type MutationCreateHomeScreenSettingsArgs = {
  input: CreateHomeScreenSettingsInput
};


export type MutationUpdateHomeScreenSettingsArgs = {
  input: UpdateHomeScreenSettingsInput
};


export type MutationDeleteHomeScreenSettingsArgs = {
  input: DeleteHomeScreenSettingsInput
};


export type MutationCreateExclusiveVideoContentArgs = {
  input: CreateExclusiveVideoContentInput
};


export type MutationUpdateExclusiveVideoContentArgs = {
  input: UpdateExclusiveVideoContentInput
};


export type MutationCreateShowMetaTagsArgs = {
  input: CreateShowMetaTagsInput
};


export type MutationUpdateShowMetaTagsArgs = {
  input: UpdateShowMetaTagsInput
};


export type MutationDeleteShowMetaTagsArgs = {
  input: DeleteShowMetaTagsInput
};


export type MutationCreateNotificationArgs = {
  input: CreateNotificationInput
};


export type MutationUpdateNotificationArgs = {
  input: UpdateNotificationInput
};


export type MutationUpdateVendorArgs = {
  input: UpdateVendorInput
};


export type MutationCreateVendorAdArgs = {
  input: CreateVendorAdInput
};


export type MutationUpdateVendorAdArgs = {
  input: UpdateVendorAdInput
};


export type MutationDeleteVendorAdArgs = {
  input: DeleteVendorAdInput
};


export type MutationCreateBannerAdArgs = {
  input: CreateBannerAdInput
};


export type MutationUpdateBannerAdArgs = {
  input: UpdateBannerAdInput
};


export type MutationDeleteBannerAdArgs = {
  input: DeleteBannerAdInput
};

export type Notification = {
   __typename?: 'Notification',
  id: Scalars['ID'],
  userID: Scalars['ID'],
  description: Scalars['String'],
  time: Scalars['AWSDateTime'],
  createdAt: Scalars['AWSDateTime'],
  updatedAt: Scalars['AWSDateTime'],
};

export type Product = {
   __typename?: 'Product',
  id: Scalars['Int'],
  customerID: Scalars['Int'],
  name: Scalars['String'],
  description?: Maybe<Scalars['String']>,
  upc?: Maybe<Scalars['String']>,
  brand?: Maybe<Scalars['String']>,
  brandID?: Maybe<Scalars['Int']>,
  sku?: Maybe<Scalars['String']>,
  active: Scalars['Boolean'],
  added: Scalars['AWSDateTime'],
  deleted: Scalars['Boolean'],
  approved: Scalars['Boolean'],
  replacementProduct?: Maybe<Scalars['Boolean']>,
  mainPhotoURL?: Maybe<Scalars['String']>,
  mainPhotoFileID?: Maybe<Scalars['String']>,
  mainPhotoFileExt?: Maybe<Scalars['String']>,
  mainPhotoSize?: Maybe<Scalars['Float']>,
  issueCount?: Maybe<Scalars['Int']>,
  photoBucket?: Maybe<Scalars['String']>,
  photoKey?: Maybe<Scalars['String']>,
  warnings?: Maybe<Array<Scalars['String']>>,
  originalProductName?: Maybe<Scalars['String']>,
  originalProductDescription?: Maybe<Scalars['String']>,
  originalProductURL?: Maybe<Scalars['String']>,
  originalProductPhotoBucket?: Maybe<Scalars['String']>,
  originalProductPhotoKey?: Maybe<Scalars['String']>,
  featured?: Maybe<Scalars['Boolean']>,
  price?: Maybe<Scalars['Float']>,
  vendorID?: Maybe<Scalars['ID']>,
  vendorLogo?: Maybe<Scalars['String']>,
  vendorLogoBucket?: Maybe<Scalars['String']>,
  vendorLogoKey?: Maybe<Scalars['String']>,
};

export type ProductCategory = {
   __typename?: 'ProductCategory',
  productID: Scalars['Int'],
  categoryID: Scalars['Int'],
  categoryName: Scalars['String'],
};

export type ProductCategoryConnection = {
   __typename?: 'ProductCategoryConnection',
  items?: Maybe<Array<ProductCategory>>,
};

export type ProductConnection = {
   __typename?: 'ProductConnection',
  items?: Maybe<Array<Product>>,
};

export type ProductEpisode = {
   __typename?: 'ProductEpisode',
  id: Scalars['Int'],
  episodeID: Scalars['Int'],
  productID: Scalars['Int'],
  startTimecode: Scalars['String'],
  startTimeMilliseconds: Scalars['Int'],
  startTimeSeconds?: Maybe<Scalars['Int']>,
  endTimecode: Scalars['String'],
  endTimeMilliseconds: Scalars['Int'],
  endTimeSeconds?: Maybe<Scalars['Int']>,
  showName: Scalars['String'],
  episodeName: Scalars['String'],
  episodeSeasonName?: Maybe<Scalars['String']>,
  episodeSeason?: Maybe<Scalars['Int']>,
  episodeNumber?: Maybe<Scalars['Int']>,
};

export type ProductEpisodeConnection = {
   __typename?: 'ProductEpisodeConnection',
  items?: Maybe<Array<ProductEpisode>>,
};

export type ProductEpisodePhotoGroup = {
   __typename?: 'ProductEpisodePhotoGroup',
  id: Scalars['Int'],
  groupID: Scalars['Int'],
  groupName: Scalars['String'],
  showName: Scalars['String'],
  episode: Episode,
};

export type ProductEpisodePhotoGroupConnection = {
   __typename?: 'ProductEpisodePhotoGroupConnection',
  items?: Maybe<Array<ProductEpisodePhotoGroup>>,
};

export type ProductExistsResult = {
   __typename?: 'ProductExistsResult',
  product: Product,
  storeID: Scalars['Int'],
};

export type ProductInput = {
  id: Scalars['Int'],
  customerID: Scalars['Int'],
  name: Scalars['String'],
  description?: Maybe<Scalars['String']>,
  upc?: Maybe<Scalars['String']>,
  brand?: Maybe<Scalars['String']>,
  brandID?: Maybe<Scalars['Int']>,
  sku?: Maybe<Scalars['String']>,
  active: Scalars['Boolean'],
  added: Scalars['AWSDateTime'],
  deleted: Scalars['Boolean'],
  approved: Scalars['Boolean'],
  replacementProduct?: Maybe<Scalars['Boolean']>,
  mainPhotoURL?: Maybe<Scalars['String']>,
  mainPhotoFileID?: Maybe<Scalars['String']>,
  mainPhotoFileExt?: Maybe<Scalars['String']>,
  mainPhotoSize?: Maybe<Scalars['Float']>,
  issueCount?: Maybe<Scalars['Int']>,
  photoBucket?: Maybe<Scalars['String']>,
  photoKey?: Maybe<Scalars['String']>,
  warnings?: Maybe<Array<Scalars['String']>>,
  originalProductName?: Maybe<Scalars['String']>,
  originalProductDescription?: Maybe<Scalars['String']>,
  originalProductURL?: Maybe<Scalars['String']>,
  originalProductPhotoBucket?: Maybe<Scalars['String']>,
  originalProductPhotoKey?: Maybe<Scalars['String']>,
  featured?: Maybe<Scalars['Boolean']>,
  price?: Maybe<Scalars['Float']>,
  vendorID?: Maybe<Scalars['ID']>,
  vendorLogo?: Maybe<Scalars['String']>,
  vendorLogoBucket?: Maybe<Scalars['String']>,
  vendorLogoKey?: Maybe<Scalars['String']>,
};

export type ProductLink = {
   __typename?: 'ProductLink',
  id: Scalars['Int'],
  productID: Scalars['Int'],
  storeID?: Maybe<Scalars['Int']>,
  storeName?: Maybe<Scalars['String']>,
  type?: Maybe<Scalars['Int']>,
  fullURL?: Maybe<Scalars['String']>,
  status?: Maybe<Scalars['Int']>,
  statusLastChecked?: Maybe<Scalars['AWSDateTime']>,
};

export type ProductLinkConnection = {
   __typename?: 'ProductLinkConnection',
  items?: Maybe<Array<ProductLink>>,
};

export type ProductMetadataConnection = {
   __typename?: 'ProductMetadataConnection',
  items?: Maybe<Array<ProductMetadataItem>>,
};

export type ProductMetadataItem = {
   __typename?: 'ProductMetadataItem',
  id: Scalars['Int'],
  productID: Scalars['Int'],
  name: Scalars['String'],
  value: Scalars['String'],
};

export type ProductMetaTags = {
   __typename?: 'ProductMetaTags',
  productID: Scalars['Int'],
  OG_TITLE: Scalars['String'],
  OG_DESCRIPTION: Scalars['String'],
  OG_IMAGE: Scalars['String'],
  FAVICON: Scalars['String'],
  MANIFEST: Scalars['String'],
};

export type ProductRequest = {
   __typename?: 'ProductRequest',
  id: Scalars['Int'],
  showID: Scalars['Int'],
  appInstallID: Scalars['Int'],
  episodeID: Scalars['Int'],
  episodeSeason?: Maybe<Scalars['String']>,
  episodeNumber?: Maybe<Scalars['String']>,
  episodeName?: Maybe<Scalars['String']>,
  photoGroup?: Maybe<Scalars['String']>,
  category: Scalars['String'],
  email: Scalars['String'],
  request: Scalars['String'],
  added: Scalars['AWSDateTime'],
  ip: Scalars['String'],
  status: Scalars['Int'],
  lastChangedByAUID?: Maybe<Scalars['String']>,
  userSub?: Maybe<Scalars['ID']>,
  response?: Maybe<Scalars['String']>,
};

export type ProductRequestConnection = {
   __typename?: 'ProductRequestConnection',
  items?: Maybe<Array<ProductRequest>>,
};

export type ProductSearchMetadata = {
   __typename?: 'ProductSearchMetadata',
  productID: Scalars['Int'],
  searchData?: Maybe<Scalars['String']>,
  witAISearchData?: Maybe<Scalars['String']>,
  searchColor?: Maybe<Scalars['String']>,
  searchPeople?: Maybe<Scalars['String']>,
  lastUpdate?: Maybe<Scalars['AWSDateTime']>,
};

export type Query = {
   __typename?: 'Query',
  getDashboardReport?: Maybe<DashboardReport>,
  getDashboardTopTenEpisodes?: Maybe<DashboardTopEpisodeConnection>,
  getDashboardTopTenProductsImpressions?: Maybe<DashboardProductImpressionConnection>,
  getDashboardTopTenProductsViews?: Maybe<DashboardProductImpressionConnection>,
  getDashboardTopTenProductsClicks?: Maybe<DashboardProductImpressionConnection>,
  listCustomers?: Maybe<CustomerConnection>,
  getCustomer?: Maybe<Customer>,
  listShows?: Maybe<ShowConnection>,
  getShow?: Maybe<Show>,
  getShowByHostname?: Maybe<Show>,
  getShowReport?: Maybe<ShowReport>,
  getShowSeasons?: Maybe<ShowSeasonConnection>,
  getShowDesignMenu?: Maybe<ShowDesignMenuConnection>,
  getShowMetadata?: Maybe<ShowMetadataItemConnection>,
  listEpisodes?: Maybe<EpisodeConnection>,
  listAppEpisodes?: Maybe<EpisodeConnection>,
  listOttEpisodes?: Maybe<EpisodeConnection>,
  listCustomerEpisodes?: Maybe<EpisodeConnection>,
  listCustomerEpisodesWithPhotoGroups?: Maybe<EpisodeConnection>,
  getEpisode?: Maybe<Episode>,
  listEpisodeCommercials?: Maybe<EpisodeCommercialConnection>,
  listCommercials?: Maybe<EpisodeConnection>,
  getEpisodeMetadata?: Maybe<EpisodeMetadataItemConnection>,
  getEpisodeReport?: Maybe<EpisodeReport>,
  getEpisodeProducts?: Maybe<EpisodeProductConnection>,
  getEpisodePhotoGroups?: Maybe<EpisodePhotoGroupConnection>,
  getEpisodePhotos?: Maybe<EpisodePhotoConnection>,
  getEpisodePhotosByGroup?: Maybe<EpisodePhotoConnection>,
  getEpisodePhotoProducts?: Maybe<EpisodePhotoProductConnection>,
  getEpisodePhotoProductsByGroup?: Maybe<EpisodePhotoProductConnection>,
  getEpisodeStreams?: Maybe<EpisodeStreamConnection>,
  getEpisodeStream?: Maybe<EpisodeStream>,
  listProducts?: Maybe<ProductConnection>,
  getProduct?: Maybe<Product>,
  listRelatedProducts?: Maybe<ProductConnection>,
  listShowFeaturedProducts?: Maybe<ProductConnection>,
  listAvailableProducts?: Maybe<AvailableProductConnection>,
  getProductLinks?: Maybe<ProductLinkConnection>,
  getProductCategories?: Maybe<ProductCategoryConnection>,
  getProductSearchMetadata?: Maybe<ProductSearchMetadata>,
  getProductMetadata?: Maybe<ProductMetadataConnection>,
  getProductEpisodes?: Maybe<ProductEpisodeConnection>,
  getProductFeaturedEpisodes?: Maybe<ProductEpisodeConnection>,
  getProductEpisodePhotoGroups?: Maybe<ProductEpisodePhotoGroupConnection>,
  getProductVendor?: Maybe<Vendor>,
  getProductRequestsByShow?: Maybe<ProductRequestConnection>,
  getProductRequestsByUser?: Maybe<ProductRequestConnection>,
  getProductsReport?: Maybe<Scalars['ID']>,
  getVendorProductsReport?: Maybe<Scalars['ID']>,
  getAdminAdReport?: Maybe<Scalars['ID']>,
  getEngagementDeviceInfo?: Maybe<EngagementDeviceInfoConnection>,
  getEngagementTopTwentyLocations?: Maybe<EngagementTopLocationConnection>,
  getEngagementEpisodeViews?: Maybe<EngagementEpisodeConnection>,
  getEngagementActivityByWeekday?: Maybe<EngagementActivityWeekdayConnection>,
  getEngagementActivityByDate?: Maybe<EngagementActivityDateConnection>,
  getMobileActivityReport?: Maybe<MobileAnalyticsConnection>,
  getMobilePageViewReport?: Maybe<MobilePageViewConnection>,
  checkIfProductExists?: Maybe<ProductExistsResult>,
  scrapeProductURL?: Maybe<ScrapedProduct>,
  walmartSearch?: Maybe<WalmartProductConnection>,
  listStores?: Maybe<StoreConnection>,
  listActiveStores?: Maybe<StoreConnection>,
  getStore?: Maybe<Store>,
  listCategories?: Maybe<CategoryConnection>,
  listActiveCategories?: Maybe<CategoryConnection>,
  listActiveCategoriesByShow?: Maybe<CategoryConnection>,
  getCategory?: Maybe<Category>,
  listBrands?: Maybe<BrandConnection>,
  getBrand?: Maybe<Brand>,
  listBrandProducts?: Maybe<ProductConnection>,
  listActiveFontTargets?: Maybe<FontTargetConnection>,
  listUsers?: Maybe<UserConnection>,
  listVoxiAdmins?: Maybe<UserConnection>,
  getUser?: Maybe<User>,
  listEmailReportSettings?: Maybe<EmailReportSettingConnection>,
  textSearch?: Maybe<ProductConnection>,
  getShowManifest?: Maybe<ShowManifest>,
  getEpisodeMetaTags?: Maybe<EpisodeMetaTags>,
  getProductMetaTags?: Maybe<ProductMetaTags>,
  getShowFeed?: Maybe<ShowFeed>,
  getUserWishlist?: Maybe<ProductConnection>,
  productInWishlist?: Maybe<Scalars['Boolean']>,
  getExclusiveVideoContent?: Maybe<ExclusiveVideoContent>,
  listShowSmallBusinessVendors?: Maybe<VendorConnection>,
  listVendorProducts?: Maybe<VendorProductConnection>,
  listVendorShowProducts?: Maybe<VendorProductConnection>,
  getNotification?: Maybe<Notification>,
  listNotifications?: Maybe<ModelNotificationConnection>,
  getCubejsDashboardItem?: Maybe<CubejsDashboardItem>,
  listCubejsDashboardItems?: Maybe<ModelCubejsDashboardItemConnection>,
  listUserDashboardItems?: Maybe<ModelCubejsDashboardItemConnection>,
  listExclusiveVideoContents?: Maybe<ModelExclusiveVideoContentConnection>,
  listExclusiveContentsByCustomer?: Maybe<ModelExclusiveVideoContentConnection>,
  listExclusiveContentsByShow?: Maybe<ModelExclusiveVideoContentConnection>,
  listExclusiveContentsByShowSorted?: Maybe<ModelExclusiveVideoContentConnection>,
  getShowMetaTags?: Maybe<ShowMetaTags>,
  listShowMetaTagss?: Maybe<ModelShowMetaTagsConnection>,
  getVendor?: Maybe<Vendor>,
  listVendors?: Maybe<ModelVendorConnection>,
  listVendorsByType?: Maybe<ModelVendorConnection>,
  listVendorAds?: Maybe<ModelVendorAdConnection>,
  getVendorAd?: Maybe<VendorAd>,
  listAdsByVendor?: Maybe<ModelVendorAdConnection>,
  getBannerAd?: Maybe<BannerAd>,
  listBannerAds?: Maybe<ModelBannerAdConnection>,
  listBannerAdsByShow?: Maybe<ModelBannerAdConnection>,
  listBannerAdsByEpisode?: Maybe<ModelBannerAdConnection>,
  listBannerAdsByCategory?: Maybe<ModelBannerAdConnection>,
  getShowFontV2?: Maybe<ShowFontV2>,
  listShowFontV2s?: Maybe<ModelShowFontV2Connection>,
  listFontsByShow?: Maybe<ModelShowFontV2Connection>,
  getDigitalAssetLinks?: Maybe<DigitalAssetLinks>,
  listDigitalAssetLinkss?: Maybe<ModelDigitalAssetLinksConnection>,
  getHomeScreenSettings?: Maybe<HomeScreenSettings>,
  listHomeScreenSettingss?: Maybe<ModelHomeScreenSettingsConnection>,
};


export type QueryGetDashboardReportArgs = {
  customerID: Scalars['Int'],
  fromDate: Scalars['AWSDateTime'],
  toDate: Scalars['AWSDateTime']
};


export type QueryGetDashboardTopTenEpisodesArgs = {
  customerID: Scalars['Int'],
  fromDate: Scalars['AWSDateTime'],
  toDate: Scalars['AWSDateTime']
};


export type QueryGetDashboardTopTenProductsImpressionsArgs = {
  customerID: Scalars['Int'],
  fromDate: Scalars['AWSDateTime'],
  toDate: Scalars['AWSDateTime']
};


export type QueryGetDashboardTopTenProductsViewsArgs = {
  customerID: Scalars['Int'],
  fromDate: Scalars['AWSDateTime'],
  toDate: Scalars['AWSDateTime']
};


export type QueryGetDashboardTopTenProductsClicksArgs = {
  customerID: Scalars['Int'],
  fromDate: Scalars['AWSDateTime'],
  toDate: Scalars['AWSDateTime']
};


export type QueryListCustomersArgs = {
  limit?: Maybe<Scalars['Int']>
};


export type QueryGetCustomerArgs = {
  id: Scalars['Int']
};


export type QueryListShowsArgs = {
  customerID: Scalars['Int']
};


export type QueryGetShowArgs = {
  id: Scalars['Int']
};


export type QueryGetShowByHostnameArgs = {
  hostname: Scalars['String']
};


export type QueryGetShowReportArgs = {
  showID: Scalars['Int'],
  fromDate: Scalars['AWSDateTime'],
  toDate: Scalars['AWSDateTime']
};


export type QueryGetShowSeasonsArgs = {
  showID: Scalars['Int']
};


export type QueryGetShowDesignMenuArgs = {
  showID: Scalars['Int']
};


export type QueryGetShowMetadataArgs = {
  showID: Scalars['Int']
};


export type QueryListEpisodesArgs = {
  showID: Scalars['Int']
};


export type QueryListAppEpisodesArgs = {
  showID: Scalars['Int'],
  limit?: Maybe<Scalars['Int']>
};


export type QueryListOttEpisodesArgs = {
  showID: Scalars['Int'],
  limit?: Maybe<Scalars['Int']>
};


export type QueryListCustomerEpisodesArgs = {
  customerID: Scalars['Int']
};


export type QueryListCustomerEpisodesWithPhotoGroupsArgs = {
  customerID: Scalars['Int']
};


export type QueryGetEpisodeArgs = {
  id: Scalars['Int']
};


export type QueryListEpisodeCommercialsArgs = {
  episodeID: Scalars['Int']
};


export type QueryListCommercialsArgs = {
  showID: Scalars['Int']
};


export type QueryGetEpisodeMetadataArgs = {
  episodeID: Scalars['Int']
};


export type QueryGetEpisodeReportArgs = {
  showID: Scalars['Int'],
  episodeID: Scalars['Int'],
  fromDate: Scalars['AWSDateTime'],
  toDate: Scalars['AWSDateTime']
};


export type QueryGetEpisodeProductsArgs = {
  episodeID: Scalars['Int'],
  active?: Maybe<Scalars['Boolean']>
};


export type QueryGetEpisodePhotoGroupsArgs = {
  episodeID: Scalars['Int']
};


export type QueryGetEpisodePhotosArgs = {
  episodeID: Scalars['Int']
};


export type QueryGetEpisodePhotosByGroupArgs = {
  groupID: Scalars['Int']
};


export type QueryGetEpisodePhotoProductsArgs = {
  episodeID: Scalars['Int']
};


export type QueryGetEpisodePhotoProductsByGroupArgs = {
  groupID: Scalars['Int']
};


export type QueryGetEpisodeStreamsArgs = {
  episodeID: Scalars['Int'],
  showID: Scalars['Int']
};


export type QueryGetEpisodeStreamArgs = {
  episodeID: Scalars['Int'],
  streamID?: Maybe<Scalars['Int']>,
  targetBandwidth?: Maybe<Scalars['Int']>
};


export type QueryListProductsArgs = {
  customerID: Scalars['Int']
};


export type QueryGetProductArgs = {
  id: Scalars['Int']
};


export type QueryListRelatedProductsArgs = {
  categoryID: Scalars['Int'],
  showID: Scalars['Int']
};


export type QueryListShowFeaturedProductsArgs = {
  showID: Scalars['Int'],
  limit?: Maybe<Scalars['Int']>
};


export type QueryGetProductLinksArgs = {
  productID: Scalars['Int']
};


export type QueryGetProductCategoriesArgs = {
  productID: Scalars['Int']
};


export type QueryGetProductSearchMetadataArgs = {
  productID: Scalars['Int']
};


export type QueryGetProductMetadataArgs = {
  productID: Scalars['Int']
};


export type QueryGetProductEpisodesArgs = {
  productID: Scalars['Int']
};


export type QueryGetProductFeaturedEpisodesArgs = {
  productID: Scalars['Int']
};


export type QueryGetProductEpisodePhotoGroupsArgs = {
  productID: Scalars['Int']
};


export type QueryGetProductVendorArgs = {
  productID: Scalars['Int']
};


export type QueryGetProductRequestsByShowArgs = {
  showID: Scalars['Int'],
  status?: Maybe<Scalars['Int']>
};


export type QueryGetProductRequestsByUserArgs = {
  userID?: Maybe<Scalars['ID']>
};


export type QueryGetProductsReportArgs = {
  customerID: Scalars['Int'],
  fromDate: Scalars['AWSDateTime'],
  toDate: Scalars['AWSDateTime']
};


export type QueryGetVendorProductsReportArgs = {
  vendorID: Scalars['ID'],
  fromDate: Scalars['AWSDateTime'],
  toDate: Scalars['AWSDateTime']
};


export type QueryGetAdminAdReportArgs = {
  fromDate: Scalars['AWSDateTime'],
  toDate: Scalars['AWSDateTime']
};


export type QueryGetEngagementDeviceInfoArgs = {
  customerID: Scalars['Int'],
  fromDate: Scalars['AWSDateTime'],
  toDate: Scalars['AWSDateTime']
};


export type QueryGetEngagementTopTwentyLocationsArgs = {
  customerID: Scalars['Int'],
  fromDate: Scalars['AWSDateTime'],
  toDate: Scalars['AWSDateTime']
};


export type QueryGetEngagementEpisodeViewsArgs = {
  customerID: Scalars['Int'],
  fromDate: Scalars['AWSDateTime'],
  toDate: Scalars['AWSDateTime']
};


export type QueryGetEngagementActivityByWeekdayArgs = {
  customerID: Scalars['Int'],
  fromDate: Scalars['AWSDateTime'],
  toDate: Scalars['AWSDateTime']
};


export type QueryGetEngagementActivityByDateArgs = {
  customerID: Scalars['Int'],
  fromDate: Scalars['AWSDateTime'],
  toDate: Scalars['AWSDateTime']
};


export type QueryGetMobileActivityReportArgs = {
  fromDate: Scalars['AWSDateTime'],
  toDate: Scalars['AWSDateTime']
};


export type QueryGetMobilePageViewReportArgs = {
  fromDate: Scalars['AWSDateTime'],
  toDate: Scalars['AWSDateTime']
};


export type QueryCheckIfProductExistsArgs = {
  customerID: Scalars['Int'],
  productURL: Scalars['String']
};


export type QueryScrapeProductUrlArgs = {
  productURL: Scalars['String']
};


export type QueryWalmartSearchArgs = {
  query: Scalars['String']
};


export type QueryListStoresArgs = {
  customerID: Scalars['Int']
};


export type QueryListActiveStoresArgs = {
  customerID: Scalars['Int']
};


export type QueryGetStoreArgs = {
  id: Scalars['Int']
};


export type QueryListCategoriesArgs = {
  customerID: Scalars['Int']
};


export type QueryListActiveCategoriesArgs = {
  customerID: Scalars['Int']
};


export type QueryListActiveCategoriesByShowArgs = {
  showID: Scalars['Int']
};


export type QueryGetCategoryArgs = {
  id: Scalars['Int']
};


export type QueryListBrandsArgs = {
  customerID: Scalars['Int']
};


export type QueryGetBrandArgs = {
  id: Scalars['Int']
};


export type QueryListBrandProductsArgs = {
  id: Scalars['Int'],
  customerID: Scalars['Int']
};


export type QueryGetUserArgs = {
  id: Scalars['ID']
};


export type QueryTextSearchArgs = {
  appID: Scalars['String'],
  query: Scalars['String']
};


export type QueryGetShowManifestArgs = {
  appID: Scalars['String']
};


export type QueryGetEpisodeMetaTagsArgs = {
  episodeID: Scalars['Int']
};


export type QueryGetProductMetaTagsArgs = {
  productID: Scalars['Int'],
  appID: Scalars['String']
};


export type QueryGetShowFeedArgs = {
  appID: Scalars['String']
};


export type QueryGetUserWishlistArgs = {
  userID: Scalars['ID'],
  showID?: Maybe<Scalars['Int']>
};


export type QueryProductInWishlistArgs = {
  productID: Scalars['Int']
};


export type QueryGetExclusiveVideoContentArgs = {
  id: Scalars['ID'],
  filter?: Maybe<GetExclusiveVideoContentFilterInput>
};


export type QueryListShowSmallBusinessVendorsArgs = {
  showID: Scalars['Int']
};


export type QueryListVendorProductsArgs = {
  vendorID: Scalars['ID']
};


export type QueryListVendorShowProductsArgs = {
  vendorID: Scalars['ID'],
  showID: Scalars['Int']
};


export type QueryGetNotificationArgs = {
  id: Scalars['ID']
};


export type QueryListNotificationsArgs = {
  filter?: Maybe<ModelNotificationFilterInput>,
  limit?: Maybe<Scalars['Int']>,
  nextToken?: Maybe<Scalars['String']>
};


export type QueryGetCubejsDashboardItemArgs = {
  id: Scalars['ID']
};


export type QueryListCubejsDashboardItemsArgs = {
  filter?: Maybe<ModelCubejsDashboardItemFilterInput>,
  limit?: Maybe<Scalars['Int']>,
  nextToken?: Maybe<Scalars['String']>
};


export type QueryListUserDashboardItemsArgs = {
  userID?: Maybe<Scalars['ID']>,
  sortDirection?: Maybe<ModelSortDirection>,
  filter?: Maybe<ModelCubejsDashboardItemFilterInput>,
  limit?: Maybe<Scalars['Int']>,
  nextToken?: Maybe<Scalars['String']>
};


export type QueryListExclusiveVideoContentsArgs = {
  filter?: Maybe<ModelExclusiveVideoContentFilterInput>,
  limit?: Maybe<Scalars['Int']>,
  nextToken?: Maybe<Scalars['String']>
};


export type QueryListExclusiveContentsByCustomerArgs = {
  legacyCustomerID?: Maybe<Scalars['Int']>,
  sortDirection?: Maybe<ModelSortDirection>,
  filter?: Maybe<ModelExclusiveVideoContentFilterInput>,
  limit?: Maybe<Scalars['Int']>,
  nextToken?: Maybe<Scalars['String']>
};


export type QueryListExclusiveContentsByShowArgs = {
  legacyShowID?: Maybe<Scalars['Int']>,
  sortDirection?: Maybe<ModelSortDirection>,
  filter?: Maybe<ModelExclusiveVideoContentFilterInput>,
  limit?: Maybe<Scalars['Int']>,
  nextToken?: Maybe<Scalars['String']>
};


export type QueryListExclusiveContentsByShowSortedArgs = {
  legacyShowID?: Maybe<Scalars['Int']>,
  firstRelease?: Maybe<ModelStringKeyConditionInput>,
  sortDirection?: Maybe<ModelSortDirection>,
  filter?: Maybe<ModelExclusiveVideoContentFilterInput>,
  limit?: Maybe<Scalars['Int']>,
  nextToken?: Maybe<Scalars['String']>
};


export type QueryGetShowMetaTagsArgs = {
  appID: Scalars['ID']
};


export type QueryListShowMetaTagssArgs = {
  appID?: Maybe<Scalars['ID']>,
  filter?: Maybe<ModelShowMetaTagsFilterInput>,
  limit?: Maybe<Scalars['Int']>,
  nextToken?: Maybe<Scalars['String']>,
  sortDirection?: Maybe<ModelSortDirection>
};


export type QueryGetVendorArgs = {
  id: Scalars['ID']
};


export type QueryListVendorsArgs = {
  filter?: Maybe<ModelVendorFilterInput>,
  limit?: Maybe<Scalars['Int']>,
  nextToken?: Maybe<Scalars['String']>
};


export type QueryListVendorsByTypeArgs = {
  type?: Maybe<VendorBusinessType>,
  sortDirection?: Maybe<ModelSortDirection>,
  filter?: Maybe<ModelVendorFilterInput>,
  limit?: Maybe<Scalars['Int']>,
  nextToken?: Maybe<Scalars['String']>
};


export type QueryListVendorAdsArgs = {
  filter?: Maybe<ModelVendorAdFilterInput>,
  limit?: Maybe<Scalars['Int']>,
  nextToken?: Maybe<Scalars['String']>
};


export type QueryGetVendorAdArgs = {
  id: Scalars['ID']
};


export type QueryListAdsByVendorArgs = {
  vendorID?: Maybe<Scalars['ID']>,
  sortDirection?: Maybe<ModelSortDirection>,
  filter?: Maybe<ModelVendorAdFilterInput>,
  limit?: Maybe<Scalars['Int']>,
  nextToken?: Maybe<Scalars['String']>
};


export type QueryGetBannerAdArgs = {
  id: Scalars['ID']
};


export type QueryListBannerAdsArgs = {
  filter?: Maybe<ModelBannerAdFilterInput>,
  limit?: Maybe<Scalars['Int']>,
  nextToken?: Maybe<Scalars['String']>
};


export type QueryListBannerAdsByShowArgs = {
  showID?: Maybe<Scalars['Int']>,
  sortDirection?: Maybe<ModelSortDirection>,
  filter?: Maybe<ModelBannerAdFilterInput>,
  limit?: Maybe<Scalars['Int']>,
  nextToken?: Maybe<Scalars['String']>
};


export type QueryListBannerAdsByEpisodeArgs = {
  episodeID?: Maybe<Scalars['Int']>,
  sortDirection?: Maybe<ModelSortDirection>,
  filter?: Maybe<ModelBannerAdFilterInput>,
  limit?: Maybe<Scalars['Int']>,
  nextToken?: Maybe<Scalars['String']>
};


export type QueryListBannerAdsByCategoryArgs = {
  categoryID?: Maybe<Scalars['Int']>,
  sortDirection?: Maybe<ModelSortDirection>,
  filter?: Maybe<ModelBannerAdFilterInput>,
  limit?: Maybe<Scalars['Int']>,
  nextToken?: Maybe<Scalars['String']>
};


export type QueryGetShowFontV2Args = {
  id: Scalars['ID']
};


export type QueryListShowFontV2sArgs = {
  filter?: Maybe<ModelShowFontV2FilterInput>,
  limit?: Maybe<Scalars['Int']>,
  nextToken?: Maybe<Scalars['String']>
};


export type QueryListFontsByShowArgs = {
  showID?: Maybe<Scalars['Int']>,
  sortDirection?: Maybe<ModelSortDirection>,
  filter?: Maybe<ModelShowFontV2FilterInput>,
  limit?: Maybe<Scalars['Int']>,
  nextToken?: Maybe<Scalars['String']>
};


export type QueryGetDigitalAssetLinksArgs = {
  appID: Scalars['String']
};


export type QueryListDigitalAssetLinkssArgs = {
  appID?: Maybe<Scalars['String']>,
  filter?: Maybe<ModelDigitalAssetLinksFilterInput>,
  limit?: Maybe<Scalars['Int']>,
  nextToken?: Maybe<Scalars['String']>,
  sortDirection?: Maybe<ModelSortDirection>
};


export type QueryGetHomeScreenSettingsArgs = {
  showID: Scalars['Int']
};


export type QueryListHomeScreenSettingssArgs = {
  showID?: Maybe<Scalars['Int']>,
  filter?: Maybe<ModelHomeScreenSettingsFilterInput>,
  limit?: Maybe<Scalars['Int']>,
  nextToken?: Maybe<Scalars['String']>,
  sortDirection?: Maybe<ModelSortDirection>
};

export type ReplyProductRequestInput = {
  productRequestID: Scalars['Int'],
  customerID: Scalars['Int'],
  showID: Scalars['Int'],
  email: Scalars['String'],
  message: Scalars['String'],
};

export type ReportAdTracking = {
   __typename?: 'ReportAdTracking',
  id: Scalars['ID'],
  customerID: Scalars['Int'],
  showID?: Maybe<Scalars['Int']>,
  episodeID?: Maybe<Scalars['Int']>,
  categoryID?: Maybe<Scalars['Int']>,
  imageBucket?: Maybe<Scalars['String']>,
  imageKey?: Maybe<Scalars['String']>,
  vendorAd?: Maybe<VendorAd>,
  url?: Maybe<Scalars['String']>,
  linkType?: Maybe<BannerAdLinkType>,
  type?: Maybe<BannerAdType>,
  createdAt?: Maybe<Scalars['String']>,
  updatedAt?: Maybe<Scalars['String']>,
  impressions?: Maybe<Scalars['Int']>,
  clicks?: Maybe<Scalars['Int']>,
};

export type ReportProduct = {
   __typename?: 'ReportProduct',
  id: Scalars['Int'],
  name?: Maybe<Scalars['String']>,
  brandName?: Maybe<Scalars['String']>,
  upc?: Maybe<Scalars['String']>,
  sku?: Maybe<Scalars['String']>,
  mainPhotoURL?: Maybe<Scalars['String']>,
  mainPhotoFileId?: Maybe<Scalars['String']>,
  mainPhotoFileExt?: Maybe<Scalars['String']>,
  active?: Maybe<Scalars['Int']>,
  deleted?: Maybe<Scalars['Boolean']>,
  added?: Maybe<Scalars['AWSDateTime']>,
  imagePreviews?: Maybe<Scalars['Int']>,
  detailViews?: Maybe<Scalars['Int']>,
  clicks?: Maybe<Scalars['Int']>,
};

export type ReportProductConnection = {
   __typename?: 'ReportProductConnection',
  id: Scalars['ID'],
  items?: Maybe<Array<ReportProduct>>,
  nextToken?: Maybe<Scalars['Boolean']>,
};

export type ReportProductConnectionInput = {
  id: Scalars['ID'],
  items?: Maybe<Array<ReportProductInput>>,
  nextToken?: Maybe<Scalars['Boolean']>,
};

export type ReportProductInput = {
  id: Scalars['Int'],
  name?: Maybe<Scalars['String']>,
  brandName?: Maybe<Scalars['String']>,
  upc?: Maybe<Scalars['String']>,
  sku?: Maybe<Scalars['String']>,
  mainPhotoURL?: Maybe<Scalars['String']>,
  mainPhotoFileId?: Maybe<Scalars['String']>,
  mainPhotoFileExt?: Maybe<Scalars['String']>,
  active?: Maybe<Scalars['Int']>,
  deleted?: Maybe<Scalars['Boolean']>,
  added?: Maybe<Scalars['AWSDateTime']>,
  imagePreviews?: Maybe<Scalars['Int']>,
  detailViews?: Maybe<Scalars['Int']>,
  clicks?: Maybe<Scalars['Int']>,
};

export type S3SignedUrl = {
   __typename?: 'S3SignedURL',
  url: Scalars['String'],
};

export type S3SignedUrlInput = {
  operation: Scalars['String'],
  key: Scalars['String'],
  metadata?: Maybe<Array<Maybe<S3SignedUrlMetadata>>>,
};

export type S3SignedUrlMetadata = {
  name: Scalars['String'],
  value: Scalars['String'],
};

export type ScrapedProduct = {
   __typename?: 'ScrapedProduct',
  title?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
  keywords?: Maybe<Scalars['String']>,
  brand?: Maybe<Scalars['String']>,
  sku?: Maybe<Scalars['String']>,
  imageURL?: Maybe<Scalars['String']>,
  domainName?: Maybe<Scalars['String']>,
  site_name?: Maybe<Scalars['String']>,
  url?: Maybe<Scalars['String']>,
  imageURLs?: Maybe<Array<Scalars['String']>>,
};

export type Show = {
   __typename?: 'Show',
  id: Scalars['Int'],
  customerID: Scalars['Int'],
  nameShort: Scalars['String'],
  nameLong: Scalars['String'],
  description: Scalars['String'],
  poster?: Maybe<Scalars['String']>,
  posterURL?: Maybe<Scalars['AWSURL']>,
  added: Scalars['AWSDateTime'],
  active?: Maybe<Scalars['Boolean']>,
  hideInAdmin?: Maybe<Scalars['Boolean']>,
  appID: Scalars['String'],
  mainMenuType?: Maybe<Scalars['Int']>,
  faqContent?: Maybe<Scalars['String']>,
  faviconsURL?: Maybe<Scalars['String']>,
  heroImageBucket?: Maybe<Scalars['String']>,
  heroImageKey?: Maybe<Scalars['String']>,
  appData?: Maybe<ShowAppData>,
  channel?: Maybe<ShowChannel>,
  demographicConfiguration?: Maybe<ShowDemographicConfiguration>,
  productRequestConfiguration?: Maybe<ShowProductRequestConfiguration>,
};

export type ShowAppData = {
   __typename?: 'ShowAppData',
  appLogo?: Maybe<Scalars['String']>,
  appLogoURL?: Maybe<Scalars['AWSURL']>,
  primaryColor: Scalars['String'],
  textPrimaryColor: Scalars['String'],
  secondaryColor: Scalars['String'],
  tertiaryColor: Scalars['String'],
  accentColor: Scalars['String'],
  infoColor: Scalars['String'],
  headBackgroundColor: Scalars['String'],
  headTextColor: Scalars['String'],
  menuTextColor: Scalars['String'],
  productCaroselBackgroundColor: Scalars['String'],
  productButTextColor: Scalars['String'],
  productButBackColor: Scalars['String'],
  productBut2TextColor: Scalars['String'],
  productBut2BackColor: Scalars['String'],
  mobileTextPrimaryColor: Scalars['String'],
  mobileTextSecondaryColor: Scalars['String'],
  mobileAccentColor: Scalars['String'],
  mobileButtonColor: Scalars['String'],
  autoplayEnabled?: Maybe<Scalars['Boolean']>,
  autoplayAfterFingerprint?: Maybe<Scalars['Boolean']>,
  copyright?: Maybe<Scalars['String']>,
  privacyPolicy?: Maybe<Scalars['String']>,
  appPosterDisplayType?: Maybe<Scalars['Int']>,
  episodeTileType?: Maybe<Scalars['Int']>,
  productTileType?: Maybe<Scalars['Int']>,
  witAIAppID?: Maybe<Scalars['String']>,
  witAIAccessToken?: Maybe<Scalars['String']>,
  witAILastUpdate?: Maybe<Scalars['AWSDateTime']>,
  googleTrackingID?: Maybe<Scalars['String']>,
  googlePlayAppEnabled?: Maybe<Scalars['Boolean']>,
  googlePlayAppURL?: Maybe<Scalars['String']>,
  facebookAppID?: Maybe<Scalars['String']>,
  facebookPageURL?: Maybe<Scalars['String']>,
  instagramUsername?: Maybe<Scalars['String']>,
  twitterUsername?: Maybe<Scalars['String']>,
  iOSAppStoreID?: Maybe<Scalars['String']>,
  videoGroupingName?: Maybe<Scalars['String']>,
  videoGroupingNamePlural?: Maybe<Scalars['String']>,
  featuredProductsLabel?: Maybe<Scalars['String']>,
};

export type ShowChannel = {
   __typename?: 'ShowChannel',
  rokuChannelEnabled: Scalars['Boolean'],
  rokuChannelType?: Maybe<Scalars['Int']>,
  rokuChannelInstallURL?: Maybe<Scalars['String']>,
  fireTVChannelEnabled: Scalars['Boolean'],
  fireTVInstallURL?: Maybe<Scalars['String']>,
  fireTVDevURL?: Maybe<Scalars['String']>,
};

export type ShowConnection = {
   __typename?: 'ShowConnection',
  items?: Maybe<Array<Show>>,
};

export type ShowDemographicConfiguration = {
   __typename?: 'ShowDemographicConfiguration',
  introText?: Maybe<Scalars['String']>,
  ageEnabled: Scalars['Boolean'],
  genderEnabled: Scalars['Boolean'],
  ethnicityEnabled: Scalars['Boolean'],
  educationEnabled: Scalars['Boolean'],
  employmentEnabled: Scalars['Boolean'],
  maritalStatusEnabled: Scalars['Boolean'],
  incomeEnabled: Scalars['Boolean'],
  zipcodeEnabled: Scalars['Boolean'],
  emailEnabled: Scalars['Boolean'],
};

export type ShowDesignMenuConnection = {
   __typename?: 'ShowDesignMenuConnection',
  items?: Maybe<Array<ShowDesignMenuItem>>,
};

export type ShowDesignMenuItem = {
   __typename?: 'ShowDesignMenuItem',
  id: Scalars['Int'],
  showID: Scalars['Int'],
  orderID: Scalars['Int'],
  title: Scalars['String'],
  description: Scalars['String'],
  icon: Scalars['String'],
  iconColor: Scalars['String'],
  url: Scalars['String'],
  showInPullOutMenu: Scalars['Boolean'],
  showInHomeMenu: Scalars['Boolean'],
  enabled: Scalars['Boolean'],
};

export type ShowFeed = {
   __typename?: 'ShowFeed',
  showName?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
  imageURL?: Maybe<Scalars['String']>,
  lastUpdated?: Maybe<Scalars['AWSDateTime']>,
  providerName?: Maybe<Scalars['String']>,
  language?: Maybe<Scalars['String']>,
  categories?: Maybe<Array<Maybe<ShowFeedCategory>>>,
  playlists?: Maybe<Array<Maybe<ShowFeedPlaylist>>>,
  movies?: Maybe<Array<Maybe<ShowFeedMovie>>>,
  series?: Maybe<Array<Maybe<ShowFeedSeries>>>,
  shortFormVideos?: Maybe<Array<Maybe<ShowFeedShortFormVideo>>>,
  tvSpecials?: Maybe<Array<Maybe<ShowFeedTvSpecial>>>,
};

export type ShowFeedCategory = {
   __typename?: 'ShowFeedCategory',
  playlistName: Scalars['String'],
  name: Scalars['String'],
  order: Scalars['String'],
};

export type ShowFeedMovie = {
   __typename?: 'ShowFeedMovie',
  id: Scalars['String'],
};

export type ShowFeedPlaylist = {
   __typename?: 'ShowFeedPlaylist',
  name: Scalars['String'],
  itemIds: Array<Maybe<Scalars['String']>>,
};

export type ShowFeedSeries = {
   __typename?: 'ShowFeedSeries',
  id: Scalars['String'],
};

export type ShowFeedShortFormVideo = {
   __typename?: 'ShowFeedShortFormVideo',
  id: Scalars['String'],
  name?: Maybe<Scalars['String']>,
  season?: Maybe<Scalars['String']>,
  title?: Maybe<Scalars['String']>,
  content: ShowFeedShortFormVideoContent,
  thumbnail: Scalars['String'],
  shortDescription: Scalars['String'],
  releaseDate: Scalars['String'],
  tags?: Maybe<Array<Maybe<Scalars['String']>>>,
};

export type ShowFeedShortFormVideoContent = {
   __typename?: 'ShowFeedShortFormVideoContent',
  dateAdded: Scalars['String'],
  videos?: Maybe<Array<Maybe<ShowFeedShortFormVideoContentVideo>>>,
  duration: Scalars['Int'],
  adBreaks?: Maybe<Array<Maybe<Scalars['String']>>>,
};

export type ShowFeedShortFormVideoContentVideo = {
   __typename?: 'ShowFeedShortFormVideoContentVideo',
  url: Scalars['String'],
  quality: Scalars['String'],
  videoType: Scalars['String'],
};

export type ShowFeedTvSpecial = {
   __typename?: 'ShowFeedTVSpecial',
  id: Scalars['String'],
};

export type ShowFontV2 = {
   __typename?: 'ShowFontV2',
  id: Scalars['ID'],
  showFontLocationComposite: Scalars['String'],
  showID: Scalars['Int'],
  family: FontFamily,
  location: FontLocation,
  weight: FontWeight,
  style: FontStyle,
  createdAt: Scalars['AWSDateTime'],
  updatedAt: Scalars['AWSDateTime'],
};

export type ShowManifest = {
   __typename?: 'ShowManifest',
  name: Scalars['String'],
  short_name: Scalars['String'],
  start_url: Scalars['String'],
  display: Scalars['String'],
  background_color: Scalars['String'],
  theme_color: Scalars['String'],
  permissions?: Maybe<Array<Scalars['String']>>,
  icons: Array<ManifestIcon>,
};

export type ShowMetadataItem = {
   __typename?: 'ShowMetadataItem',
  id: Scalars['Int'],
  showID: Scalars['Int'],
  name: Scalars['String'],
  value: Scalars['String'],
};

export type ShowMetadataItemConnection = {
   __typename?: 'ShowMetadataItemConnection',
  items?: Maybe<Array<ShowMetadataItem>>,
};

export type ShowMetadataItemInput = {
  showID: Scalars['Int'],
  name: Scalars['String'],
  value: Scalars['String'],
};

export type ShowMetaTags = {
   __typename?: 'ShowMetaTags',
  appID: Scalars['ID'],
  showID: Scalars['Int'],
  OG_TITLE: Scalars['String'],
  OG_DESCRIPTION: Scalars['String'],
  OG_IMAGE: Scalars['String'],
  FAVICON: Scalars['String'],
  MANIFEST: Scalars['String'],
  createdAt: Scalars['AWSDateTime'],
  updatedAt: Scalars['AWSDateTime'],
};

export type ShowProductRequestConfiguration = {
   __typename?: 'ShowProductRequestConfiguration',
  enabled?: Maybe<Scalars['Boolean']>,
  titleText?: Maybe<Scalars['String']>,
  introText?: Maybe<Scalars['String']>,
  episodeListEnabled?: Maybe<Scalars['Boolean']>,
  episodeListText?: Maybe<Scalars['String']>,
  photoGroupEnabled?: Maybe<Scalars['Boolean']>,
  photoGroupText?: Maybe<Scalars['String']>,
  categoryEnabled?: Maybe<Scalars['Boolean']>,
  categoryText?: Maybe<Scalars['String']>,
  emailEnabled?: Maybe<Scalars['Boolean']>,
  emailText?: Maybe<Scalars['String']>,
  requestText?: Maybe<Scalars['String']>,
  submitThanksText?: Maybe<Scalars['String']>,
  notificationsList?: Maybe<Scalars['String']>,
};

export type ShowReport = {
   __typename?: 'ShowReport',
  totalSeconds?: Maybe<Scalars['Float']>,
  totalBytes?: Maybe<Scalars['Float']>,
  ipCount?: Maybe<Scalars['Int']>,
  appUsers?: Maybe<Scalars['Int']>,
  totalParts?: Maybe<Scalars['Float']>,
  averageViews?: Maybe<Scalars['Float']>,
  minPartViews?: Maybe<Scalars['Int']>,
  maxPartViews?: Maybe<Scalars['Int']>,
  deviceInfo?: Maybe<Array<Maybe<DeviceInfo>>>,
  topEpisodes?: Maybe<Array<Maybe<Episode>>>,
  dateStreams?: Maybe<Array<Maybe<DateStream>>>,
  streamDataByBandwidth?: Maybe<Array<Maybe<StreamDataByBandwidth>>>,
  streamDataByWeekday?: Maybe<Array<Maybe<StreamDataByWeekday>>>,
  streamDataByHour?: Maybe<Array<Maybe<StreamDataByHour>>>,
};

export type ShowSeason = {
   __typename?: 'ShowSeason',
  showID?: Maybe<Scalars['Int']>,
  season: Scalars['Int'],
  name?: Maybe<Scalars['String']>,
};

export type ShowSeasonConnection = {
   __typename?: 'ShowSeasonConnection',
  items?: Maybe<Array<ShowSeason>>,
};

export type Store = {
   __typename?: 'Store',
  id: Scalars['Int'],
  customerID: Scalars['Int'],
  name: Scalars['String'],
  active: Scalars['Boolean'],
  added: Scalars['AWSDateTime'],
  domainName?: Maybe<Scalars['String']>,
  searchURL?: Maybe<Scalars['String']>,
  productCount?: Maybe<Scalars['Int']>,
};

export type StoreConnection = {
   __typename?: 'StoreConnection',
  items?: Maybe<Array<Store>>,
};

export type StreamDataByBandwidth = {
   __typename?: 'StreamDataByBandwidth',
  streamKbps?: Maybe<Scalars['Float']>,
  streamPart?: Maybe<Scalars['Int']>,
  totalhits?: Maybe<Scalars['Int']>,
};

export type StreamDataByHour = {
   __typename?: 'StreamDataByHour',
  hourNumber?: Maybe<Scalars['String']>,
  totalSecs?: Maybe<Scalars['Float']>,
  totalbytes?: Maybe<Scalars['Float']>,
};

export type StreamDataByWeekday = {
   __typename?: 'StreamDataByWeekday',
  dayNumber?: Maybe<Scalars['Int']>,
  weekName?: Maybe<Scalars['String']>,
  totalSecs?: Maybe<Scalars['Float']>,
  totalbytes?: Maybe<Scalars['Float']>,
};

export type Subscription = {
   __typename?: 'Subscription',
  onCreateCustomer?: Maybe<Customer>,
  onCreateShow?: Maybe<Show>,
  onUpdateShow?: Maybe<Show>,
  onCreateEpisode?: Maybe<Episode>,
  onUpdateShowEpisode?: Maybe<Episode>,
  onUpdateEpisode?: Maybe<Episode>,
  onCreateEpisodePhoto?: Maybe<EpisodePhoto>,
  onUpdateProduct?: Maybe<Product>,
  onUpdateCategory?: Maybe<Category>,
  onResolveProductsReport?: Maybe<ReportProductConnection>,
  onResolveVendorProductsReport?: Maybe<ReportProductConnection>,
  onResolveAdminAdReport?: Maybe<AdTrackingReportConnection>,
  onCreateNotification?: Maybe<Notification>,
  onUpdateNotification?: Maybe<Notification>,
  onDeleteNotification?: Maybe<Notification>,
  onCreateExclusiveVideoContent?: Maybe<ExclusiveVideoContent>,
  onUpdateExclusiveVideoContent?: Maybe<ExclusiveVideoContent>,
  onDeleteExclusiveVideoContent?: Maybe<ExclusiveVideoContent>,
  onCreateShowMetaTags?: Maybe<ShowMetaTags>,
  onUpdateShowMetaTags?: Maybe<ShowMetaTags>,
  onDeleteShowMetaTags?: Maybe<ShowMetaTags>,
  onCreateVendor?: Maybe<Vendor>,
  onUpdateVendor?: Maybe<Vendor>,
  onDeleteVendor?: Maybe<Vendor>,
  onCreateVendorAd?: Maybe<VendorAd>,
  onUpdateVendorAd?: Maybe<VendorAd>,
  onDeleteVendorAd?: Maybe<VendorAd>,
  onCreateBannerAd?: Maybe<BannerAd>,
  onUpdateBannerAd?: Maybe<BannerAd>,
  onDeleteBannerAd?: Maybe<BannerAd>,
  onCreateDigitalAssetLinks?: Maybe<DigitalAssetLinks>,
  onUpdateDigitalAssetLinks?: Maybe<DigitalAssetLinks>,
  onDeleteDigitalAssetLinks?: Maybe<DigitalAssetLinks>,
  onCreateHomeScreenSettings?: Maybe<HomeScreenSettings>,
  onUpdateHomeScreenSettings?: Maybe<HomeScreenSettings>,
  onDeleteHomeScreenSettings?: Maybe<HomeScreenSettings>,
};


export type SubscriptionOnCreateShowArgs = {
  customerID: Scalars['Int']
};


export type SubscriptionOnUpdateShowArgs = {
  id: Scalars['Int']
};


export type SubscriptionOnCreateEpisodeArgs = {
  showID: Scalars['Int']
};


export type SubscriptionOnUpdateShowEpisodeArgs = {
  showID: Scalars['Int']
};


export type SubscriptionOnUpdateEpisodeArgs = {
  id: Scalars['Int']
};


export type SubscriptionOnCreateEpisodePhotoArgs = {
  episodeID: Scalars['Int']
};


export type SubscriptionOnUpdateProductArgs = {
  id: Scalars['Int']
};


export type SubscriptionOnUpdateCategoryArgs = {
  id: Scalars['Int'],
  customerID: Scalars['Int']
};


export type SubscriptionOnResolveProductsReportArgs = {
  id: Scalars['ID']
};


export type SubscriptionOnResolveVendorProductsReportArgs = {
  id: Scalars['ID']
};


export type SubscriptionOnResolveAdminAdReportArgs = {
  id: Scalars['ID']
};


export type SubscriptionOnCreateNotificationArgs = {
  userID: Scalars['String']
};


export type SubscriptionOnUpdateNotificationArgs = {
  userID: Scalars['String']
};


export type SubscriptionOnDeleteNotificationArgs = {
  userID: Scalars['String']
};


export type SubscriptionOnCreateExclusiveVideoContentArgs = {
  legacyCustomerIDString?: Maybe<Scalars['String']>
};


export type SubscriptionOnUpdateExclusiveVideoContentArgs = {
  legacyCustomerIDString?: Maybe<Scalars['String']>
};


export type SubscriptionOnDeleteExclusiveVideoContentArgs = {
  legacyCustomerIDString?: Maybe<Scalars['String']>
};


export type SubscriptionOnCreateVendorArgs = {
  id?: Maybe<Scalars['String']>
};


export type SubscriptionOnUpdateVendorArgs = {
  id?: Maybe<Scalars['String']>
};


export type SubscriptionOnDeleteVendorArgs = {
  id?: Maybe<Scalars['String']>
};

export type UpdateBannerAdInput = {
  id: Scalars['ID'],
  customerID?: Maybe<Scalars['Int']>,
  showID?: Maybe<Scalars['Int']>,
  episodeID?: Maybe<Scalars['Int']>,
  categoryID?: Maybe<Scalars['Int']>,
  imageBucket?: Maybe<Scalars['String']>,
  imageKey?: Maybe<Scalars['String']>,
  url?: Maybe<Scalars['String']>,
  linkType?: Maybe<BannerAdLinkType>,
  type?: Maybe<BannerAdType>,
  bannerAdVendorAdId?: Maybe<Scalars['ID']>,
};

export type UpdateBrandInput = {
  id: Scalars['Int'],
  customerID: Scalars['Int'],
  name?: Maybe<Scalars['String']>,
};

export type UpdateCategoryInput = {
  id: Scalars['Int'],
  customerID: Scalars['Int'],
  name?: Maybe<Scalars['String']>,
  image?: Maybe<Scalars['String']>,
  imageURL?: Maybe<Scalars['AWSURL']>,
  imageFileSize?: Maybe<Scalars['String']>,
  active?: Maybe<Scalars['Boolean']>,
  thumbnail?: Maybe<Scalars['String']>,
  thumbnailURL?: Maybe<Scalars['AWSURL']>,
  thumbnailFileSize?: Maybe<Scalars['String']>,
  tag?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
};

export type UpdateCubejsDashboardItemInput = {
  id: Scalars['ID'],
  userID?: Maybe<Scalars['ID']>,
  layout?: Maybe<Scalars['String']>,
  vizState?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
};

export type UpdateCustomerInput = {
  id: Scalars['Int'],
  shortName?: Maybe<Scalars['String']>,
  fullName?: Maybe<Scalars['String']>,
  active?: Maybe<Scalars['Boolean']>,
  added?: Maybe<Scalars['AWSDateTime']>,
  adminType?: Maybe<Scalars['Int']>,
  isVendor?: Maybe<Scalars['Boolean']>,
  emailAddressIdentity?: Maybe<Scalars['String']>,
  streamingContent?: Maybe<Scalars['Boolean']>,
  displayShowReports?: Maybe<Scalars['Boolean']>,
  displayEpisodeReports?: Maybe<Scalars['Boolean']>,
};

export type UpdateDigitalAssetLinksInput = {
  appID: Scalars['String'],
  legacyShowID?: Maybe<Scalars['Int']>,
  legacyCustomerID?: Maybe<Scalars['Int']>,
  android?: Maybe<Scalars['AWSJSON']>,
};

export type UpdateEmailReportSettingInput = {
  id: Scalars['Int'],
  daily: Scalars['Boolean'],
  weekly: Scalars['Boolean'],
  monthly: Scalars['Boolean'],
  yearly: Scalars['Boolean'],
};

export type UpdateEpisodeImageInput = {
  episodeID: Scalars['Int'],
  showID: Scalars['Int'],
  customerID: Scalars['Int'],
  newFilename: Scalars['String'],
};

export type UpdateEpisodeInput = {
  id: Scalars['Int'],
  season?: Maybe<Scalars['Int']>,
  number?: Maybe<Scalars['Int']>,
  name?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
  disclaimer?: Maybe<Scalars['String']>,
  enabled?: Maybe<Scalars['Boolean']>,
  firstRelease?: Maybe<Scalars['AWSDateTime']>,
  posterType?: Maybe<Scalars['Int']>,
  poster?: Maybe<Scalars['String']>,
  posterURL?: Maybe<Scalars['AWSURL']>,
  posterFileID?: Maybe<Scalars['String']>,
  video?: Maybe<Scalars['String']>,
  videoURL?: Maybe<Scalars['String']>,
  videoBucket?: Maybe<Scalars['String']>,
  videoKey?: Maybe<Scalars['String']>,
  videoQuality?: Maybe<Scalars['String']>,
  videoType?: Maybe<Scalars['String']>,
  videoWidth?: Maybe<Scalars['Int']>,
  videoHeight?: Maybe<Scalars['Int']>,
  mediaConvertJobID?: Maybe<Scalars['String']>,
  mediaConvertJobStatus?: Maybe<Scalars['String']>,
  audioFingerprintID?: Maybe<Scalars['String']>,
  duration?: Maybe<Scalars['Float']>,
  rokuAddBreaks?: Maybe<Scalars['String']>,
  releaseToOTT?: Maybe<Scalars['Boolean']>,
  releaseToApp?: Maybe<Scalars['Boolean']>,
  liveChannelID?: Maybe<Scalars['Int']>,
  enableHeaderButton?: Maybe<Scalars['Boolean']>,
  headerButtonText?: Maybe<Scalars['String']>,
  headerButtonURL?: Maybe<Scalars['String']>,
  disableProductScroller?: Maybe<Scalars['Boolean']>,
  liveStreamID?: Maybe<Scalars['String']>,
  liveStreamEnabledOnStartMenuID?: Maybe<Scalars['Int']>,
};

export type UpdateExclusiveVideoContentInput = {
  id: Scalars['ID'],
  legacyCustomerID?: Maybe<Scalars['Int']>,
  legacyCustomerIDString?: Maybe<Scalars['String']>,
  legacyShowID?: Maybe<Scalars['Int']>,
  name?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
  enabled?: Maybe<Scalars['Boolean']>,
  firstRelease?: Maybe<Scalars['AWSDateTime']>,
  posterBucket?: Maybe<Scalars['String']>,
  posterKey?: Maybe<Scalars['String']>,
  videoBucket?: Maybe<Scalars['String']>,
  videoKey?: Maybe<Scalars['String']>,
  videoURL?: Maybe<Scalars['String']>,
  videoQuality?: Maybe<VideoQuality>,
  videoType?: Maybe<VideoType>,
  videoWidth?: Maybe<Scalars['Int']>,
  videoHeight?: Maybe<Scalars['Int']>,
  mediaConvertJobID?: Maybe<Scalars['String']>,
  mediaConvertJobStatus?: Maybe<Scalars['String']>,
  duration?: Maybe<Scalars['Float']>,
  releaseToOTT?: Maybe<Scalars['Boolean']>,
  releaseToApp?: Maybe<Scalars['Boolean']>,
  videoToken?: Maybe<Scalars['String']>,
  disableProductScroller?: Maybe<Scalars['Boolean']>,
  productDefinitions?: Maybe<Array<ExclusiveVideoContentProductDefinitionInput>>,
  videoProducts?: Maybe<Array<ExclusiveVideoProductInput>>,
};

export type UpdateHomeScreenSettingsInput = {
  showID: Scalars['Int'],
  legacyCustomerID?: Maybe<Scalars['Int']>,
  legacyCustomerIDString?: Maybe<Scalars['String']>,
  featuredProductsLabel?: Maybe<Scalars['String']>,
  smallBusinessLabel?: Maybe<Scalars['String']>,
  recentEpisodesLabel?: Maybe<Scalars['String']>,
  recommendedProductsLabel?: Maybe<Scalars['String']>,
};

export type UpdateNotificationInput = {
  id: Scalars['ID'],
  userID?: Maybe<Scalars['ID']>,
  description?: Maybe<Scalars['String']>,
  time?: Maybe<Scalars['AWSDateTime']>,
};

export type UpdateProductInput = {
  id: Scalars['Int'],
  customerID?: Maybe<Scalars['Int']>,
  name?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
  upc?: Maybe<Scalars['String']>,
  brandName?: Maybe<Scalars['String']>,
  brandID?: Maybe<Scalars['Int']>,
  sku?: Maybe<Scalars['String']>,
  active?: Maybe<Scalars['Boolean']>,
  added?: Maybe<Scalars['AWSDateTime']>,
  deleted?: Maybe<Scalars['Boolean']>,
  approved?: Maybe<Scalars['Boolean']>,
  replacementProduct?: Maybe<Scalars['Boolean']>,
  mainPhotoURL?: Maybe<Scalars['String']>,
  mainPhotoFileID?: Maybe<Scalars['String']>,
  mainPhotoFileExt?: Maybe<Scalars['String']>,
  mainPhotoSize?: Maybe<Scalars['Float']>,
  photoBucket?: Maybe<Scalars['String']>,
  photoKey?: Maybe<Scalars['String']>,
  originalProductName?: Maybe<Scalars['String']>,
  originalProductDescription?: Maybe<Scalars['String']>,
  originalProductURL?: Maybe<Scalars['String']>,
  originalProductPhotoBucket?: Maybe<Scalars['String']>,
  originalProductPhotoKey?: Maybe<Scalars['String']>,
  featured?: Maybe<Scalars['Boolean']>,
  price?: Maybe<Scalars['Float']>,
};

export type UpdateProductRequestInput = {
  id: Scalars['Int'],
  showID: Scalars['Int'],
  episodeName?: Maybe<Scalars['String']>,
  appInstallID?: Maybe<Scalars['Int']>,
  episodeID?: Maybe<Scalars['Int']>,
  photoGroup?: Maybe<Scalars['String']>,
  category?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
  request?: Maybe<Scalars['String']>,
  added?: Maybe<Scalars['AWSDateTime']>,
  ip?: Maybe<Scalars['String']>,
  status?: Maybe<Scalars['Int']>,
};

export type UpdateShowAppDataInput = {
  id: Scalars['Int'],
  primaryColor?: Maybe<Scalars['String']>,
  textPrimaryColor?: Maybe<Scalars['String']>,
  secondaryColor?: Maybe<Scalars['String']>,
  tertiaryColor?: Maybe<Scalars['String']>,
  accentColor?: Maybe<Scalars['String']>,
  infoColor?: Maybe<Scalars['String']>,
  headBackgroundColor?: Maybe<Scalars['String']>,
  headTextColor?: Maybe<Scalars['String']>,
  menuTextColor?: Maybe<Scalars['String']>,
  productCaroselBackgroundColor?: Maybe<Scalars['String']>,
  productButTextColor?: Maybe<Scalars['String']>,
  productButBackColor?: Maybe<Scalars['String']>,
  productBut2TextColor?: Maybe<Scalars['String']>,
  productBut2BackColor?: Maybe<Scalars['String']>,
  mobileTextPrimaryColor?: Maybe<Scalars['String']>,
  mobileTextSecondaryColor?: Maybe<Scalars['String']>,
  mobileAccentColor?: Maybe<Scalars['String']>,
  mobileButtonColor?: Maybe<Scalars['String']>,
  autoplayEnabled?: Maybe<Scalars['Boolean']>,
  autoplayAfterFingerprint?: Maybe<Scalars['Boolean']>,
  copyright?: Maybe<Scalars['String']>,
  privacyPolicy?: Maybe<Scalars['String']>,
  appPosterDisplayType?: Maybe<Scalars['Int']>,
  episodeTileType?: Maybe<Scalars['Int']>,
  productTileType?: Maybe<Scalars['Int']>,
  witAIAppID?: Maybe<Scalars['String']>,
  witAIAccessToken?: Maybe<Scalars['String']>,
  witAILastUpdate?: Maybe<Scalars['AWSDateTime']>,
  googleTrackingID?: Maybe<Scalars['String']>,
  googlePlayAppEnabled?: Maybe<Scalars['Boolean']>,
  googlePlayAppURL?: Maybe<Scalars['String']>,
  facebookAppID?: Maybe<Scalars['String']>,
  facebookPageURL?: Maybe<Scalars['String']>,
  instagramUsername?: Maybe<Scalars['String']>,
  twitterUsername?: Maybe<Scalars['String']>,
  iOSAppStoreID?: Maybe<Scalars['String']>,
  videoGroupingName?: Maybe<Scalars['String']>,
  videoGroupingNamePlural?: Maybe<Scalars['String']>,
  featuredProductsLabel?: Maybe<Scalars['String']>,
};

export type UpdateShowChannelInput = {
  id: Scalars['Int'],
  rokuChannelEnabled?: Maybe<Scalars['Boolean']>,
  rokuChannelType?: Maybe<Scalars['Int']>,
  rokuChannelInstallURL?: Maybe<Scalars['String']>,
  fireTVChannelEnabled?: Maybe<Scalars['Boolean']>,
  fireTVInstallURL?: Maybe<Scalars['String']>,
  fireTVDevURL?: Maybe<Scalars['String']>,
};

export type UpdateShowDesignMenuItemInput = {
  id: Scalars['Int'],
  showID?: Maybe<Scalars['Int']>,
  orderID?: Maybe<Scalars['Int']>,
  title?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
  icon?: Maybe<Scalars['String']>,
  iconColor?: Maybe<Scalars['String']>,
  url?: Maybe<Scalars['String']>,
  showInPullOutMenu?: Maybe<Scalars['Boolean']>,
  showInHomeMenu?: Maybe<Scalars['Boolean']>,
  enabled?: Maybe<Scalars['Boolean']>,
};

export type UpdateShowFontV2Input = {
  id: Scalars['ID'],
  showFontLocationComposite?: Maybe<Scalars['String']>,
  showID?: Maybe<Scalars['Int']>,
  family?: Maybe<FontFamily>,
  location?: Maybe<FontLocation>,
  weight?: Maybe<FontWeight>,
  style?: Maybe<FontStyle>,
};

export type UpdateShowImageInput = {
  showID: Scalars['Int'],
  customerID: Scalars['Int'],
  newFilename: Scalars['String'],
};

export type UpdateShowInput = {
  id: Scalars['Int'],
  customerID?: Maybe<Scalars['Int']>,
  nameShort?: Maybe<Scalars['String']>,
  nameLong?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
  active?: Maybe<Scalars['Boolean']>,
  hideInAdmin?: Maybe<Scalars['Boolean']>,
  mainMenuType?: Maybe<Scalars['Int']>,
  faqContent?: Maybe<Scalars['String']>,
  heroImageBucket?: Maybe<Scalars['String']>,
  heroImageKey?: Maybe<Scalars['String']>,
};

export type UpdateShowMetaTagsInput = {
  appID: Scalars['ID'],
  showID?: Maybe<Scalars['Int']>,
  OG_TITLE?: Maybe<Scalars['String']>,
  OG_DESCRIPTION?: Maybe<Scalars['String']>,
  OG_IMAGE?: Maybe<Scalars['String']>,
  FAVICON?: Maybe<Scalars['String']>,
  MANIFEST?: Maybe<Scalars['String']>,
};

export type UpdateShowProductRequestConfigurationInput = {
  id: Scalars['Int'],
  titleText?: Maybe<Scalars['String']>,
  introText?: Maybe<Scalars['String']>,
  episodeListEnabled?: Maybe<Scalars['Boolean']>,
  episodeListText?: Maybe<Scalars['String']>,
  photoGroupEnabled?: Maybe<Scalars['Boolean']>,
  photoGroupText?: Maybe<Scalars['String']>,
  categoryEnabled?: Maybe<Scalars['Boolean']>,
  categoryText?: Maybe<Scalars['String']>,
  emailEnabled?: Maybe<Scalars['Boolean']>,
  emailText?: Maybe<Scalars['String']>,
  requestText?: Maybe<Scalars['String']>,
  submitThanksText?: Maybe<Scalars['String']>,
  notificationsList?: Maybe<Scalars['String']>,
};

export type UpdateStoreInput = {
  id: Scalars['Int'],
  customerID: Scalars['Int'],
  name?: Maybe<Scalars['String']>,
  domainName?: Maybe<Scalars['String']>,
  active?: Maybe<Scalars['Boolean']>,
};

export type UpdateVendorAdInput = {
  id: Scalars['ID'],
  vendorID?: Maybe<Scalars['ID']>,
  name?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
  imageBucket?: Maybe<Scalars['String']>,
  imageKey?: Maybe<Scalars['String']>,
  url?: Maybe<Scalars['String']>,
  type?: Maybe<BannerAdType>,
};

export type UpdateVendorInput = {
  id: Scalars['ID'],
  name?: Maybe<Scalars['String']>,
  type?: Maybe<VendorBusinessType>,
  active?: Maybe<Scalars['Boolean']>,
  salesOwnedByUserID?: Maybe<Scalars['ID']>,
  balance?: Maybe<Scalars['Float']>,
  balanceUpdated?: Maybe<Scalars['AWSDateTime']>,
  logo?: Maybe<Scalars['String']>,
  logoFileID?: Maybe<Scalars['String']>,
  logoFileExtension?: Maybe<Scalars['String']>,
  logoBucket?: Maybe<Scalars['String']>,
  logoKey?: Maybe<Scalars['String']>,
  pixelTrackingID?: Maybe<Scalars['ID']>,
};

export type UpdateVendorLogoInput = {
  id: Scalars['ID'],
  logo: Scalars['String'],
  logoFileID: Scalars['String'],
  logoFileExtension: Scalars['String'],
  logoBucket: Scalars['String'],
  logoKey: Scalars['String'],
};

export type User = {
   __typename?: 'User',
  id: Scalars['ID'],
  email: Scalars['String'],
  enabled: Scalars['Boolean'],
  status: Scalars['String'],
  created: Scalars['AWSDateTime'],
  email_verified?: Maybe<Scalars['String']>,
  given_name?: Maybe<Scalars['String']>,
  family_name?: Maybe<Scalars['String']>,
  gender?: Maybe<Scalars['String']>,
  phone_number?: Maybe<Scalars['String']>,
  customerID?: Maybe<Scalars['Int']>,
  vendorID?: Maybe<Scalars['Int']>,
};

export type UserConnection = {
   __typename?: 'UserConnection',
  items?: Maybe<Array<User>>,
};

export enum UserType {
  Brand = 'BRAND',
  ContentAdmin = 'CONTENT_ADMIN',
  VoxiAdmin = 'VOXI_ADMIN'
}

export type UserWishlist = {
   __typename?: 'UserWishlist',
  userID: Scalars['ID'],
  productID: Scalars['Int'],
  added: Scalars['AWSDateTime'],
};

export type Vendor = {
   __typename?: 'Vendor',
  id: Scalars['ID'],
  name: Scalars['String'],
  type?: Maybe<VendorBusinessType>,
  active: Scalars['Boolean'],
  salesOwnedByUserID: Scalars['ID'],
  balance: Scalars['Float'],
  balanceUpdated?: Maybe<Scalars['AWSDateTime']>,
  logo?: Maybe<Scalars['String']>,
  logoFileID?: Maybe<Scalars['String']>,
  logoFileExtension?: Maybe<Scalars['String']>,
  logoBucket?: Maybe<Scalars['String']>,
  logoKey?: Maybe<Scalars['String']>,
  pixelTrackingID?: Maybe<Scalars['ID']>,
  createdAt: Scalars['AWSDateTime'],
  updatedAt: Scalars['AWSDateTime'],
};

export type VendorAd = {
   __typename?: 'VendorAd',
  id: Scalars['ID'],
  vendorID: Scalars['ID'],
  name?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
  imageBucket?: Maybe<Scalars['String']>,
  imageKey?: Maybe<Scalars['String']>,
  url?: Maybe<Scalars['String']>,
  type?: Maybe<BannerAdType>,
  createdAt: Scalars['AWSDateTime'],
  updatedAt: Scalars['AWSDateTime'],
};

export type VendorAdInput = {
  id: Scalars['ID'],
  vendorID: Scalars['ID'],
  name?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
  imageBucket?: Maybe<Scalars['String']>,
  imageKey?: Maybe<Scalars['String']>,
  url?: Maybe<Scalars['String']>,
  type?: Maybe<BannerAdType>,
  createdAt: Scalars['AWSDateTime'],
  updatedAt: Scalars['AWSDateTime'],
};

export enum VendorBusinessType {
  None = 'NONE',
  Enterprise = 'ENTERPRISE',
  NonProfit = 'NON_PROFIT',
  SmallBusiness = 'SMALL_BUSINESS'
}

export type VendorConnection = {
   __typename?: 'VendorConnection',
  items?: Maybe<Array<Vendor>>,
};

export type VendorProduct = {
   __typename?: 'VendorProduct',
  vendorID: Scalars['ID'],
  productID: Scalars['Int'],
  owned: Scalars['Boolean'],
  assigned: Scalars['AWSDateTime'],
  unassigned?: Maybe<Scalars['AWSDateTime']>,
  product: Product,
};

export type VendorProductConnection = {
   __typename?: 'VendorProductConnection',
  items?: Maybe<Array<VendorProduct>>,
};

export enum VideoQuality {
  Hd = 'HD',
  Fhd = 'FHD',
  Uhd = 'UHD'
}

export enum VideoType {
  Hls = 'HLS',
  YouTube = 'YouTube',
  Vimeo = 'Vimeo'
}

export type WalmartProduct = {
   __typename?: 'WalmartProduct',
  id: Scalars['Int'],
  parentID: Scalars['Int'],
  name: Scalars['String'],
  msrp?: Maybe<Scalars['Float']>,
  salePrice?: Maybe<Scalars['Float']>,
  upc?: Maybe<Scalars['String']>,
  modelNumber?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
  category?: Maybe<Scalars['String']>,
  productURL?: Maybe<Scalars['String']>,
  affiliateURL?: Maybe<Scalars['String']>,
  thumbnailImageURL?: Maybe<Scalars['String']>,
  imageURL?: Maybe<Scalars['String']>,
};

export type WalmartProductConnection = {
   __typename?: 'WalmartProductConnection',
  items?: Maybe<Array<WalmartProduct>>,
};
