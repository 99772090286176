import React, { useState, useEffect } from 'react';
import { Analytics } from 'aws-amplify';
import { Grid, Typography, TextField, Button, Checkbox, FormControlLabel, CircularProgress } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useHistory } from 'react-router-dom';

import { TabPanel } from '../../tabs/TabPanel';

import useSnackbars from '../../../hooks/useSnackbars';
import * as API from '../../../lib/api';

import { linkTypeOptions, linkStatusTypes, colorOptions } from '../../../lib/util';
import { CreateProductInput, Maybe, Product, ScrapedProduct } from '../../../types';

const defaultOption = { name: '', value: -1 };

interface ColorOption {
    name: string
    value: string
}

export function AddProductTab(props: AddProductTabProps) {
    const history = useHistory();
    const { createErrorSnack } = useSnackbars();
    const [brandsOptions, setBrandsOptions] = useState<Array<any>>([]);
    const [searchProductURL, setSearchProductURL] = useState('');
    const [message, setMessage] = useState('');
    const [isReplacementProduct, setIsReplacementProduct] = useState(false);
    const [brand, setBrand] = useState<any>(null);
    const [upc, setUPC] = useState('');
    const [sku, setSKU] = useState('');
    const [productName, setProductName] = useState('');
    const [searchKeywords, setSearchKeywords] = useState('');
    const [colors, setColors] = useState<Array<ColorOption>>([]);
    const [categoriesOptions, setCategoriesOptions] = useState<Array<any>>([]);
    const [categories, setCategories] = useState<Array<any>>([]);
    const [description, setDescription] = useState('');

    const [addProductLink, setAddProductLink] = useState(false);
    const [newLinkType, setNewLinkType] = useState(linkTypeOptions[0]);
    const [storeOptions, setStoreOptions] = useState<Array<any>>([defaultOption]);
    const [store, setStore] = useState(defaultOption);
    const [productURL, setProductURL] = useState('');
    const [imageURL, setImageURL] = useState('');
    const [searching, setSearching] = useState(false);

    useEffect(() => {
        if (!props.customerID) return;

        const fetchActiveStores = async () => {
            const activeStoresResult = await API.listActiveStores({ customerID: props.customerID });
            let options = activeStoresResult.map(store => {
                return { name: store.name, domainName: store.domainName, value: store.id }
            })
            setStoreOptions(options);
            setStore(options[0] || defaultOption);
        }

        const fetchBrands = async () => {
            const brandsResult = await API.listBrands({ customerID: props.customerID });
            let brandsOptions = brandsResult.map(brand => {
                return { name: brand.name, value: brand.id }
            })

            setBrandsOptions(brandsOptions);
            setBrand(brandsOptions[0])
        }

        const fetchActiveCategories = async () => {
            const categoriesResult = await API.listActiveCategories({ customerID: props.customerID });
            let categoriesOptions = categoriesResult.map(category => {
                return { name: category.name, value: category.id }
            })

            setCategoriesOptions(categoriesOptions);
        }

        fetchActiveStores();
        fetchBrands();
        fetchActiveCategories();
    }, [props.customerID])

    useEffect(() => {
        if (!props.product) return;

        setProductName(props.product.name);
        setUPC(props.product.upc)
        setSKU(props.product.modelNumber);
        setDescription(props.product.description);
        setProductURL(props.product.affiliateURL);
        setImageURL(props.product.imageURL);
        setAddProductLink(true);
    }, [props.product])

    const getProductInfo = async () => {
        try {
            const url = new URL(searchProductURL);
            Analytics.record({
                name: 'ScrapeProductInfo',
                attributes: { domain: url.host }
            });
        } catch (err) {
            Analytics.record({
                name: 'ScrapeProductInfo',
                attributes: { url: searchProductURL }
            });
        }
        
        setSearching(true);
        const productExistsResult = await API.checkIfProductExists({
            customerID: props.customerID,
            productURL: searchProductURL
        });

        if (productExistsResult) {
            const product = productExistsResult.product;
            setMessage('A product with this URL already exists! To create another with the same URL change the product info');
            setIsReplacementProduct(product.replacementProduct || false);
            setProductName(product.name || '');
            setUPC(product.upc || '');
            setSKU(product.sku || '');
            setDescription(product.description || '');
            setAddProductLink(true);
            setProductURL(searchProductURL);
            setImageURL(product.mainPhotoURL || '');

            let brandFound = brandsOptions.find(brand => brand.value === product.brandID);
            if (brandFound) setBrand(brandFound);

            let storeFound = storeOptions.find(store => store.value === productExistsResult.storeID);
            if (storeFound) setStore(storeFound);

            setSearching(false);
            return;
        }

        let scrapedProduct: Maybe<ScrapedProduct> = null;
        try {
            scrapedProduct = await API.scrapeProductURL({ productURL: searchProductURL });
        } catch (err) {
            setMessage('** Error trying to get product info! **');
            setSearching(false);
        }

        if (!scrapedProduct || !scrapedProduct.domainName) {
            setMessage('** Failed to get product info!');
            setSearching(false);
            return;
        }

        setProductName(scrapedProduct.title?.trim() || '');
        setSearchKeywords(scrapedProduct.keywords?.trim() || '');

        const description = Buffer.from(scrapedProduct.description?.trim() || '').toString('latin1');
        setDescription(description);
        setSKU(scrapedProduct.sku?.trim() || '')

        let brandFound = brandsOptions.find(brand => brand.name === scrapedProduct!.brand);
        if (brandFound) setBrand(brandFound);
        else setBrand({ name: scrapedProduct.brand || '', value: null })

        let storeFound = storeOptions.find(store => store.name === scrapedProduct!.domainName || store.name === scrapedProduct!.site_name || store.domainName === scrapedProduct!.domainName)
        if (storeFound) setStore(storeFound);

        setProductURL(scrapedProduct.url || '');
        if (storeFound && scrapedProduct.url) setAddProductLink(true);

        setImageURL(scrapedProduct.imageURL || '');

        setSearching(false);
    }

    const storeChange = (e, v) => {
        setStore(v);
    }

    const addProduct = async () => {
        const productInput: CreateProductInput = {
            customerID: props.customerID,
            name: productName,
            description: description || '',
            upc: upc || '',
            brand: brand?.name || '',
            brandID: brand?.value || -1, // Set this to -1 so we know to update it in the api... Probz not the best but idk
            sku: sku || '',
            active: true,
            deleted: false,
            approved: false,
            replacementProduct: isReplacementProduct,
            mainPhotoURL: imageURL || '',
            searchKeywords: searchKeywords,
            colors: colors ? colors.map(color => color.value) : null,
            categories: categories ? categories.map(category => category.value) : null,
        }

        let createProductResponse: Maybe<Product> = undefined;

        try {
            createProductResponse = await API.createProduct(productInput);
        } catch (err) {
            console.error(err);
            let errorMessage = 'Failed to add product. Check the Brand, Product Name, and Description.';
            if (err.errors && err.errors[0]) {
                const error = err.errors[0];
                errorMessage = `${error.errorType || 'Error'}: ${error.message}`
            }

            createErrorSnack(errorMessage);
            return;
        }
        
        if (createProductResponse && addProductLink) {
            const productLinkInput = {
                customerID: props.customerID,
                productID: createProductResponse.id,
                storeID: store.value,
                storeName: store.name,
                type: newLinkType.value,
                fullURL: productURL,
                status: linkStatusTypes[0].value // "Not Checked"
            }

            try {
                await API.createProductLink(productLinkInput);
            } catch (err) {
                console.error(err);
                let errorMessage = 'Product created but product link failed';
                if (err.errors && err.errors[0]) {
                    const error = err.errors[0];
                    errorMessage = `${error.errorType || 'Error'}: ${error.message}`
                }

                createErrorSnack(errorMessage);
            }
        }

        if (createProductResponse) {
            Analytics.record({
                name: 'CreateProduct',
                attributes: { id: createProductResponse.id, name: productName, customerID: createProductResponse.customerID }
            });

            history.push(`/products/list/${createProductResponse.id}`)
        }
    }

    const onBrandChange = (event, newValue) => {
        // Handle the case that someone inserted their own value
        if (typeof (newValue) === 'string') {
            newValue = { name: newValue, value: -1 }
        }

        setBrand(newValue);
    }

    const onLinkTypeChange = (event, newValue) => {
        setNewLinkType(newValue);
    }

    return (
        <TabPanel value={props.tab} index={props.index} direction="column" spacing={2}>
            <Grid item container direction="row" spacing={2} alignItems="center" justify="center">
                <Grid item xs={12}>
                    <Typography>Prefill product info from a webpage (optional):</Typography>
                </Grid>
                <Grid item xs={8}>
                    <TextField variant="outlined"
                        placeholder="Full URL to the product detail page"
                        value={searchProductURL}
                        onChange={(e) => setSearchProductURL(e.target.value)}
                        fullWidth
                    />
                </Grid>
                <Grid item xs>
                    <Button variant="contained" color="secondary" onClick={getProductInfo}>
                        Get Product Info
                    </Button>
                </Grid>
                {
                    searching &&
                    <Grid item>
                        <CircularProgress />
                    </Grid>
                }
            </Grid>
            {
                message && message !== '' &&
                <Grid item>
                    <Typography variant="h6" color="primary">{message}</Typography>
                </Grid>
            }
            <Grid item container spacing={2} direction="row">
                <Grid item container spacing={2} direction="column" xs>
                    <Grid item>
                        <FormControlLabel
                            control={<Checkbox checked={isReplacementProduct} onChange={(e) => setIsReplacementProduct(e.target.checked)} name="isReplacementProduct" />}
                            label="Is Replacement Product"
                        />
                    </Grid>
                    <Grid item>
                        <Autocomplete
                            multiple={false}
                            id="brand-combo-box"
                            options={brandsOptions}
                            getOptionLabel={(option) => option.name}
                            value={brand}
                            onChange={onBrandChange}
                            selectOnFocus
                            clearOnBlur
                            freeSolo
                            renderInput={(params) => <TextField {...params} label="Brand" variant="outlined" />}
                        />
                    </Grid>
                    <Grid item>
                        <TextField variant="outlined"
                            value={upc}
                            label="UPC"
                            onChange={(e) => setUPC(e.target.value)}
                            placeholder="UPC"
                            fullWidth
                        />
                    </Grid>
                    <Grid item>
                        <TextField variant="outlined"
                            value={sku}
                            label="SKU / Model #"
                            onChange={(e) => setSKU(e.target.value)}
                            placeholder="SKU / Model #"
                            fullWidth
                        />
                    </Grid>
                    <Grid item>
                        <TextField variant="outlined"
                            error={productName === ''}
                            required
                            value={productName}
                            label="Product Name"
                            onChange={(e) => setProductName(e.target.value)}
                            placeholder="Product Name"
                            fullWidth
                            helperText={productName === '' ? "Product Name can not be empty" : ''}
                        />
                    </Grid>
                    <Grid item>
                        <TextField variant="outlined"
                            value={searchKeywords}
                            label="Search Keywords"
                            onChange={(e) => setSearchKeywords(e.target.value)}
                            placeholder="Search Keywords"
                            fullWidth
                        />
                    </Grid>
                    <Grid item>
                        <Autocomplete
                            multiple={true}
                            id="colors-combo-box"
                            options={colorOptions}
                            getOptionLabel={(option) => option.name}
                            onChange={(e, v) => setColors(v)}
                            filterSelectedOptions
                            disableCloseOnSelect={true}
                            renderInput={(params) => <TextField {...params} label="Color(s)" variant="outlined" />}
                        />
                    </Grid>
                    <Grid item>
                        <Autocomplete
                            multiple={true}
                            id="categories-combo-box"
                            options={categoriesOptions}
                            getOptionLabel={(option) => option.name}
                            onChange={(e, v) => setCategories(v)}
                            filterSelectedOptions
                            disableCloseOnSelect={true}
                            renderInput={(params) => <TextField {...params} label="Categories" variant="outlined" />}
                        />
                    </Grid>
                    <Grid item>
                        <TextField variant="outlined"
                            value={description}
                            label="Description"
                            onChange={(e) => setDescription(e.target.value)}
                            placeholder="Description"
                            fullWidth
                            multiline
                        />
                    </Grid>
                    <Grid item>
                        <Button variant="contained" color="secondary" onClick={addProduct}>
                            Add Product
                        </Button>
                    </Grid>
                </Grid>
                <Grid item container spacing={2} direction="column" xs>
                    <Grid item>
                        <FormControlLabel
                            control={<Checkbox checked={addProductLink} onChange={(e) => setAddProductLink(e.target.checked)} name="addProductLink" />}
                            label="Add Product Link"
                        />
                    </Grid>
                    <Grid item container direction="row" spacing={1}>
                        <Grid item xs={4}>
                            <Autocomplete
                                // multiple={false} // https://material-ui.com/components/autocomplete/#typescript
                                id="link-type-combo-box"
                                options={linkTypeOptions}
                                getOptionLabel={(option) => option.name}
                                value={newLinkType}
                                onChange={onLinkTypeChange}
                                disabled={!addProductLink}
                                renderInput={(params) => <TextField {...params} label="Link Type" variant="outlined" />}
                            />
                        </Grid>
                        <Grid item xs>
                            <Autocomplete
                                multiple={false} // https://material-ui.com/components/autocomplete/#typescript
                                id="store-combo-box"
                                options={storeOptions}
                                getOptionLabel={(option) => option.name}
                                value={store}
                                onChange={storeChange}
                                disabled={!addProductLink}
                                renderInput={(params) => <TextField {...params} label="Store" variant="outlined" />}
                            />
                        </Grid>
                    </Grid>
                    <Grid item>
                        <TextField variant="outlined"
                            value={productURL}
                            onChange={(e) => setProductURL(e.target.value)}
                            label="Product URL"
                            placeholder="Product URL"
                            fullWidth
                            disabled={!addProductLink}
                        />
                    </Grid>
                    <Grid item>
                        <TextField variant="outlined"
                            value={imageURL}
                            onChange={(e) => setImageURL(e.target.value)}
                            label="Image URL"
                            placeholder="Image URL"
                            fullWidth
                        />
                    </Grid>
                    <Grid item>
                        <img src={imageURL} alt={productName} width="400" height="400" />
                    </Grid>
                </Grid>
            </Grid>

        </TabPanel>
    )
}

export interface AddProductTabProps {
    tab: number
    index: number
    customerID: number
    product: any
}