import React, { useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';

import { Typography, Grid, Button, Snackbar, FormControl, InputLabel, FilledInput, InputAdornment, IconButton } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import MuiAlert from '@material-ui/lab/Alert';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

const useStyles = makeStyles((theme) => ({
    textField: {
        width: '25ch',
    },
}));

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export function Profile(props: ProfileProps) {
    const classes = useStyles();
    const [user, setUser] = useState(props.authData);
    const [oldPassword, setOldPassword] = useState('');
    const [showOldPassword, setShowOldPassword] = useState(false);
    const [newPassword, setNewPassword] = useState('');
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [successOpen, setSuccessOpen] = useState(false);
    const [errorOpen, setErrorOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState('Error changing password!');

    useEffect(() => {
        async function fetchUser() {
            const user = await Auth.currentAuthenticatedUser()
            setUser(user)
        };

        fetchUser();
    }, [])

    const handleSuccessClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setSuccessOpen(false);
    };

    const handleErrorClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setErrorOpen(false);
    };

    const changePassword = async () => {
        try {
            await Auth.changePassword(user, oldPassword, newPassword);
            setSuccessOpen(true);

            setOldPassword('');
            setNewPassword('');
        } catch (err) {
            switch (err.code) {
                case 'NotAuthorizedException':
                    // Incorrect old password
                    setErrorMessage('Incorrect old password!')
                    break;
                case 'InvalidParameterException':
                    // Password is too short
                    setErrorMessage('Password is too short!')
                    break;
                case 'InvalidPasswordException':
                    // Password does not meet the policy
                    setErrorMessage('Password does not meet the password policy!')
                    break;
                case 'LimitExceededException':
                    // Too many changes
                    setErrorMessage('Attempt limit exceeded, please try after some time!')
                    break;
                default:
                    break;
            }


            setErrorOpen(true);
        }
    }

    const handleClickShowOldPassword = () => {
        setShowOldPassword(!showOldPassword);
    };

    const handleClickShowNewPassword = () => {
        setShowNewPassword(!showNewPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    return (
        <Grid container spacing={3} direction="column">
            <Grid item>
                <Typography variant="h2">My Profile</Typography>
            </Grid>

            <Grid item>
                <Grid item>
                    <Typography variant="subtitle1">Name: {user.attributes.given_name} {user.attributes.family_name}</Typography>
                </Grid>
                <Grid item>
                    <Typography variant="subtitle1">Email: {user.username}</Typography>
                </Grid>
                <Grid item>
                    <Typography variant="subtitle1">Phone: {user.attributes.phone_number}</Typography>
                </Grid>
                {/* <Grid item>
                    <Typography variant="subtitle1">Gender: {user.attributes.gender}</Typography>
                </Grid> */}
            </Grid>
            <Grid item>
                <Typography variant="h4">Change Password</Typography>
            </Grid>
            <Grid item>
                <Typography variant="body1">Password must have at least 7 characters</Typography>
                <Typography variant="body1">Password must have at least one uppercase</Typography>
                <Typography variant="body1">Password must have at least one lowercase</Typography>
            </Grid>
            <Grid item>
                <FormControl className={classes.textField} variant="filled">
                    <InputLabel htmlFor="old-password">Old Password *</InputLabel>
                    <FilledInput
                        id="old-password"
                        type={showOldPassword ? 'text' : 'password'}
                        value={oldPassword}
                        onChange={(e) => setOldPassword(e.target.value)}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowOldPassword}
                                    onMouseDown={handleMouseDownPassword}
                                >
                                    {showOldPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                </FormControl>
            </Grid>
            <Grid item>
                <FormControl className={classes.textField} variant="filled">
                    <InputLabel htmlFor="new-password">New Password *</InputLabel>
                    <FilledInput
                        id="new-password"
                        type={showNewPassword ? 'text' : 'password'}
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowNewPassword}
                                    onMouseDown={handleMouseDownPassword}
                                >
                                    {showNewPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                </FormControl>
            </Grid>
            <Grid item>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={changePassword}
                >Change Password</Button>
            </Grid>
            <Snackbar open={successOpen} autoHideDuration={5000} onClose={handleSuccessClose}>
                <Alert onClose={handleSuccessClose} severity="success">
                    Sucessfully changed password!
                </Alert>
            </Snackbar>
            <Snackbar open={errorOpen} autoHideDuration={5000} onClose={handleErrorClose}>
                <Alert onClose={handleErrorClose} severity="error">
                    {errorMessage}
                </Alert>
            </Snackbar>
        </Grid>
    )
}

export interface ProfileProps {
    authData: any
}