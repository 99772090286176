import React, { useState } from 'react';
import { Grid, AppBar, Tabs, Tab } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { TabPanel, a11yProps } from '../../tabs/TabPanel';

import { Maybe, Show } from '../../../types/api';

import {
    ColorsTab,
    ContentTab,
    DigitalAssetLinksTab,
    FAQTab,
    FontsTabV2,
    GeneralSettingsTab,
    HomeScreenSettingsTab,
    ImagesTab,
    MenusTab,
    ProductRequestTab,
    SearchTab,
    SocialMediaTab
} from './applicationSettingsTabs';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
    settingsBar: {
        backgroundColor: 'black'
    },
    tab: {
        minWidth: 0,
        border: 'gray',
        borderWidth: '1px',
        fontSize: 12
    }
}));

export function ApplicationSettingsTab(props: ApplicationSettingsTabProps) {
    const classes = useStyles();
    const [tab, setTab] = useState(0);

    const handleChange = (_, newValue) => {
        setTab(newValue);
    };

    const tabs = [
        { key: 'generalSettings', label: 'General Settings' },
        { key: 'homeScreenSettings', label: 'Home Screen Settings' },
        { key: 'menus', label: 'Menus' },
        { key: 'images', label: 'Images' },
        { key: 'colors', label: 'Colors' },
        { key: 'fonts', label: 'Fonts' },
        { key: 'content', label: 'Content' },
        { key: 'productRequest', label: 'Product Request' },
        { key: 'faq', label: 'FAQ\'s' },
        { key: 'socialMedia', label: 'Social Media' },
        { key: 'search', label: 'Search' },
        { key: 'digitalAssetLinks', label: 'Digital Asset Links' },
    ]

    return props.show ? (
        <TabPanel value={props.tab} index={props.index} spacing={2}>
            <Grid item xs={12}>
                <AppBar position="static" color="secondary" className={classes.settingsBar} >
                    <Tabs value={tab} onChange={handleChange} aria-label="simple tabs example">
                        {
                            tabs.map((tab, index) => {
                                return (
                                    <Tab key={tab.key} label={tab.label} {...a11yProps(index)} className={classes.tab} />
                                )
                            })
                        }
                    </Tabs>
                </AppBar>
            </Grid>
            <GeneralSettingsTab tab={tab} index={0} show={props.show} />
            <HomeScreenSettingsTab tab={tab} index={1} show={props.show} />
            <MenusTab tab={tab} index={2} show={props.show} />
            <ImagesTab tab={tab} index={3} show={props.show} />
            <ColorsTab tab={tab} index={4} show={props.show} />
            <FontsTabV2 tab={tab} index={5} show={props.show} />
            <ContentTab tab={tab} index={6} show={props.show} />
            <ProductRequestTab tab={tab} index={7} show={props.show} />
            <FAQTab tab={tab} index={8} show={props.show} />
            <SocialMediaTab tab={tab} index={9} show={props.show} />
            <SearchTab tab={tab} index={10} show={props.show} />
            <DigitalAssetLinksTab tab={tab} index={11} show={props.show} />
        </TabPanel>
    ) : (<></>)
}

export interface ApplicationSettingsTabProps {
    tab: number
    index: number
    show: Maybe<Show>
}