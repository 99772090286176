import React, { useState, useEffect } from 'react';
import { Grid, IconButton, Typography, TextField, Button, FormControlLabel, Checkbox } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import ArrowBack from '@material-ui/icons/ArrowBack';

import useSnackbars from '../../hooks/useSnackbars';
import * as API from '../../lib/api';

import { Maybe, Customer as CustomerType } from '../../types';

export function Customer(props: UserProps) {
    const history = useHistory();
    const { createSuccessSnack, createErrorSnack } = useSnackbars();
    const [shortName, setShortName] = useState('');
    const [fullName, setFullName] = useState('');
    const [active, setActive] = useState(false);
    const [emailAddressIdentity, setEmailAddressIdentity] = useState('');
    const [streamingContent, setStreamingContent] = useState(false);
    const [displayShowReports, setDisplayShowReports] = useState(false);
    const [displayEpisodeReports, setDisplayEpisodeReports] = useState(false);

    const reset = (customer: Maybe<CustomerType>) => {
        setShortName(customer?.shortName || '');
        setFullName(customer?.fullName || '');
        setActive(customer?.active || false);
        setEmailAddressIdentity(customer?.emailAddressIdentity || '');
        setStreamingContent(customer?.streamingContent || false);
        setDisplayShowReports(customer?.displayShowReports || false);
        setDisplayEpisodeReports(customer?.displayEpisodeReports || false);
    }

    useEffect(() => {
        const fetchCustomer = async () => {
            const customerResult = await API.getCustomer({ id: props.match.params.customerID });
            reset(customerResult);
        }

        fetchCustomer();
    }, [props.match.params.customerID])

    const onBackClick = () => {
        history.goBack();
    }

    const updateCustomer = async () => {
        if (!props.match.params.customerID) return;

        const input = {
            id: props.match.params.customerID,
            shortName: shortName,
            fullName: fullName,
            active: active,
            emailAddressIdentity: emailAddressIdentity,
            streamingContent: streamingContent,
            displayShowReports: displayShowReports,
            displayEpisodeReports: displayEpisodeReports
        }

        const updateResult = await API.updateCustomer(input);
        if (updateResult) {
            createSuccessSnack('Successfully updated customer');
            reset(updateResult);
        } else {
            createErrorSnack('Error updating customer');
        }
    }

    return (
        <Grid container spacing={2} direction="column">
            <Grid item container spacing={1} direction="row" alignItems="center">
                <Grid item>
                    <IconButton onClick={onBackClick}>
                        <ArrowBack />
                    </IconButton>
                </Grid>
                <Grid item>
                    <Typography variant="h5">Customer: {fullName}</Typography>
                </Grid>
            </Grid>
            <Grid item>
                <FormControlLabel
                    control={<Checkbox checked={active} onChange={(e) => setActive(e.target.checked)} />}
                    label="Active"
                />
            </Grid>
            <Grid item>
                <TextField variant="outlined"
                    label="Short Name"
                    value={shortName}
                    onChange={(e) => setShortName(e.target.value)}
                    style={{ width: 500 }}
                />
            </Grid>
            <Grid item>
                <TextField variant="outlined"
                    label="Full Name"
                    value={fullName}
                    onChange={(e) => setFullName(e.target.value)}
                    style={{ width: 500 }}
                />
            </Grid>
            <Grid item>
                <TextField variant="outlined"
                    label="Email Address Identity"
                    value={emailAddressIdentity}
                    onChange={(e) => setEmailAddressIdentity(e.target.value)}
                    style={{ width: 500 }}
                />
            </Grid>
            <Grid item container spacing={2} direction="row">
                <Grid item>
                    <FormControlLabel
                        control={<Checkbox checked={streamingContent} onChange={(e) => setStreamingContent(e.target.checked)} />}
                        label="Can Stream/Broadcast Content"
                    />
                </Grid>
                <Grid item>
                    <FormControlLabel
                        control={<Checkbox checked={displayShowReports} onChange={(e) => setDisplayShowReports(e.target.checked)} />}
                        label="Display Show Reports"
                    />
                </Grid>
                <Grid item>
                    <FormControlLabel
                        control={<Checkbox checked={displayEpisodeReports} onChange={(e) => setDisplayEpisodeReports(e.target.checked)} />}
                        label="Display Episode Reports"
                    />
                </Grid>
            </Grid>
            <Grid item>
                <Button variant="contained" color="secondary" onClick={updateCustomer}>
                    Update Customer
                </Button>
            </Grid>
        </Grid>
    )
}

export interface UserProps {
    match: { params: { customerID } }
}