import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Carousel from 'react-material-ui-carousel'
import { Grid, Button, IconButton, TextField, Tooltip } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';

import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';

import {
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell
} from '@material-ui/core';

import * as API from '../../../../lib/api';
import { TabPanel } from '../../../tabs/TabPanel';

import { Episode, EpisodePhoto, EpisodePhotoProduct } from '../../../../types';

const useStyles = makeStyles((theme) => ({
    menuTable: {
        borderTopWidth: 2,
        borderColor: '#f9f9f9',
        borderWidth: 2,
        borderStyle: 'solid'
    }
}));

const defaultOption = { name: '', value: -1 };

export function PhotoProductsTab(props: PhotoProductsTabProps) {
    const classes = useStyles();
    const [episodePhotoProducts, setEpisodePhotoProducts] = useState<Array<EpisodePhotoProduct>>([]);
    const [photoGroupOptions, setPhotoGroupOptions] = useState([defaultOption]);
    const [selectedPhotoGroup, setSelectedPhotoGroup] = useState(defaultOption);
    const [photos, setPhotos] = useState<Array<EpisodePhoto>>([]);
    const [productOptions, setProductOptions] = useState<Array<any>>([]);
    const [newEpisodePhotoProduct, setNewEpisodePhotoProduct] = useState(defaultOption);

    const fetchEpisodePhotoGroupProducts = async () => {
        if (!selectedPhotoGroup || selectedPhotoGroup.value < 1) return;
        const episodePhotoProductsResult = await API.getEpisodePhotoProductsByGroup({ groupID: selectedPhotoGroup.value });
        setEpisodePhotoProducts(episodePhotoProductsResult);
    }

    useEffect(() => {
        if (!props.episode) return;

        const fetchEpisodePhotoGroups = async () => {
            const photoGroupsResult = await API.getEpisodePhotoGroups({ episodeID: props.episode.id });
            let options = photoGroupsResult.map(photoGroup => {
                return { name: photoGroup.name, value: photoGroup.id }
            })
            setPhotoGroupOptions(options);
            if (options && options.length > 0) setSelectedPhotoGroup(options[0]);
        }

        fetchEpisodePhotoGroups();
    }, [props.episode])

    useEffect(() => {
        let options = props.products.map(product => {
            return { name: product.name, value: product.id }
        })
        if (options) setProductOptions(options)
    }, [props.products])

    useEffect(() => {
        if (!selectedPhotoGroup || selectedPhotoGroup.value < 1) return;

        const fetchPhotoGroupPhotos = async () => {
            const photosResult = await API.getEpisodePhotosByGroup({ groupID: selectedPhotoGroup.value });
            setPhotos(photosResult);
        }

        fetchEpisodePhotoGroupProducts();
        fetchPhotoGroupPhotos();
    }, [selectedPhotoGroup])

    const onItemRemoved = (id) => {
        let updated = episodePhotoProducts.filter((item: any) => {
            return item.id !== id
        })
        setEpisodePhotoProducts(updated);
    }

    const addPhotoProduct = async () => {
        let input = {
            groupID: selectedPhotoGroup.value,
            productID: newEpisodePhotoProduct?.value
        }

        // TODO: Add instead of fetching?
        await API.createEpisodePhotoProduct(input);
        fetchEpisodePhotoGroupProducts();
    }

    const onPhotoGroupChange = (event, newValue) => {
        setSelectedPhotoGroup(newValue);
    }

    return (
        <TabPanel value={props.tab} index={props.index} spacing={2} direction="row">
            <Grid item container spacing={2} direction="column" xs={4}>
                <Grid item>
                    <Autocomplete id="photo-group-combo-box"
                        options={photoGroupOptions}
                        value={selectedPhotoGroup}
                        getOptionLabel={(option) => option.name}
                        onChange={onPhotoGroupChange}
                        style={{ width: '250px' }}
                        selectOnFocus
                        clearOnBlur
                        freeSolo
                        renderInput={(params) => <TextField {...params} label="Photo Group" variant="outlined" />}
                    />
                </Grid>
                <Grid item>
                    <Carousel>
                        { photos.map(photo => <PhotoItem key={photo.id} photo={photo} />) }
                    </Carousel>
                </Grid>
                <Grid item>
                    <Autocomplete id="product-combo-box"
                        options={productOptions}
                        value={newEpisodePhotoProduct}
                        getOptionLabel={(option) => option.name}
                        onChange={(e, v) => setNewEpisodePhotoProduct(v)}
                        renderInput={(params) => <TextField {...params} label="Product" variant="outlined" />}
                    />
                </Grid>
                <Grid item>
                    <Button variant="contained" color="secondary" onClick={addPhotoProduct}>
                        Add Product
                    </Button>
                </Grid>
            </Grid>
            <Grid item container spacing={2} direction="column" xs>
                <Grid item>
                    <TableContainer>
                        <Table className={classes.menuTable} size="small" aria-label="episode products">
                            <TableHead>
                                <TableRow style={{ background: "#f9f9f9" }}>
                                    <TableCell align="center">Image</TableCell>
                                    <TableCell align="center">Product Brand / Product Name</TableCell>
                                    <TableCell align="center">Status</TableCell>
                                    <TableCell align="center">Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    episodePhotoProducts.length === 0
                                        ? <>
                                            <TableRow>
                                                No products have been added.
                                            </TableRow>
                                        </>
                                        : episodePhotoProducts.map((episodeProduct: EpisodePhotoProduct, index: number) => {
                                            return (
                                                <EpisodeProductRow key={episodeProduct.id} 
                                                    episodeProduct={episodeProduct} 
                                                    index={index} 
                                                    onItemRemoved={onItemRemoved} 
                                                    location={window.location}
                                                    tabIndex={props.index}
                                                />
                                            )
                                        })
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        </TabPanel>
    )
}
export interface PhotoProductsTabProps {
    tab: number
    index: number
    episode: Episode
    products: any[]
}

function EpisodeProductRow(props: MetadataItemRowProps) {
    const remove = async () => {
        await API.deleteEpisodePhotoProduct(props.episodeProduct.id);
        props.onItemRemoved(props.episodeProduct.id);
    }

    return (
        <TableRow style={props.index % 2 ? { background: "#f9f9f9" } : { background: "white" }}>
            <TableCell align="center">
                <img src={props.episodeProduct.product.mainPhotoURL || ''}
                    alt={props.episodeProduct.product.name}
                    width="50"
                    height="50"
                />
            </TableCell>
            <TableCell align="center">
                {`${props.episodeProduct.brandName} - ${props.episodeProduct.product.name}`}
            </TableCell>
            <TableCell align="center">
                {props.episodeProduct.product.active ? 'Active' : 'Inactive'}
            </TableCell>
            <TableCell align="center">
                <Tooltip title="Edit Product">
                    <Link to={{pathname: `/products/list/${props.episodeProduct.product.id}`, 
                                state: { prevPath: props.location.pathname, 
                                        showTab: 2,
                                        episodeID: props.episodeProduct.episodeID, 
                                        episodeTab: props.tabIndex }}}
                    >
                        <IconButton aria-label="edit">
                            <EditIcon />
                        </IconButton>
                    </Link>
                </Tooltip>
                <Tooltip title="Remove Product">
                    <IconButton aria-label="delete" color="primary" onClick={remove}>
                        <DeleteIcon />
                    </IconButton>
                </Tooltip>
            </TableCell>
        </TableRow>
    )
}

interface MetadataItemRowProps {
    episodeProduct: EpisodePhotoProduct
    index: number
    onItemRemoved: Function
    location: any
    tabIndex: number
}

function PhotoItem(props)
{
    return (
        <img src={props.photo.thumbnailURL} alt={props.photo.title} />
    )
}