import React, { useState, useEffect } from 'react';
import { Grid, FormControlLabel, Button, Checkbox, TextField, LinearProgress, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { DatePicker } from '@material-ui/pickers';

import { format } from 'date-fns'
import path from 'path';

import useSnackbars from '../../../../hooks/useSnackbars';
import * as API from '../../../../lib/api';
import { TabPanel } from '../../../tabs/TabPanel';
import { SelectWithLabel } from '../../../shared/SelectWithLabel';

import { uploadPresignedFile, seasonsOptions, episodeNumberOptions } from '../../../../lib/util';
import { FileUploadButton } from '../../../shared/FileUploadButton';

import { Episode, Show } from '../../../../types';

const useStyles = makeStyles((theme) => ({
    divider: {
        color: 'white',
        backgroundColor: 'white'
    },
    poster: {
        maxHeight: '250px'
    },
}));

// This is based off of poster type........ Should make this an enum probably and fix it in the DB
const viewTypeSelectOptions = [
    { name: 'Poster Only', value: 0 },
    { name: 'Poster w/Custom Video', value: 2 },
    { name: 'Poster w/Photo Carousel', value: 4 }
];

export function GeneralInfoTab(props: GeneralInfoTabProps) {
    const classes = useStyles();
    const { createSuccessSnack, createErrorSnack } = useSnackbars();
    const [showSeasonsOptions, setShowSeasonsOptions] = useState(seasonsOptions);
    const [name, setName] = useState(props.episode.name || '');
    const [season, setSeason] = useState({ name: '', season: 1 });
    const [episodeNumber, setEpisodeNumber] = useState(props.episode.number);
    const [enabled, setEnabled] = useState(props.episode?.enabled || false);
    const [enabledForApp, setEnabledForApp] = useState(props.episode?.releaseToApp || false);
    const [enabledForOTT, setEnabledForOTT] = useState(props.episode?.releaseToOTT || false);
    const [firstRelease, setFirstRelease] = useState(props.episode.firstRelease && format(new Date(props.episode.firstRelease), 'MM/dd/yyyy'));
    const [duration, setDuration] = useState(props.episode.duration || 0);
    const [description, setDescription] = useState(props.episode.description || '');
    const [disclaimer, setDisclaimer] = useState(props.episode.disclaimer || '');
    const [posterURL, setPosterURL] = useState(props.episode.posterURL || '');
    const [viewType, setViewType] = useState(viewTypeSelectOptions.find((option) => option.value === props.episode.posterType) || viewTypeSelectOptions[0])
    const [uploading, setUploading] = useState(false);
    const [progress, setProgress] = useState(0);

    const reset = () => {
        setName(props.episode.name || '');

        const season = showSeasonsOptions.find(season => season.season === props.episode.season);
        setSeason(season || { name: '', season: 1 });
        setEpisodeNumber(props.episode.number);
        setEnabled(props.episode.enabled || false);
        setEnabledForApp(props.episode.releaseToApp || false);
        setEnabledForOTT(props.episode.releaseToOTT || false);
        setFirstRelease(props.episode.firstRelease && format(new Date(props.episode.firstRelease), 'MM/dd/yyyy'));
        setDuration(props.episode.duration || 0);
        setDescription(props.episode.description || '');
        setDisclaimer(props.episode.disclaimer || '');
        setPosterURL(props.episode.posterURL || '');

        let view = viewTypeSelectOptions.find((option) => option.value === props.episode.posterType)
        if (view) setViewType(view);
    }

    useEffect(() => {
        reset();

        if (!props.episode) return;

        const fetchShowSeasons = async (showID: number) => {
            if (!showID) return;

            const showSeasonsResult = await API.getShowSeasons({ showID: showID });
            let newOptions = showSeasonsResult.filter(showSeason => showSeason.name).map(showSeason => {
                return { name: showSeason.name!, season: showSeason.season }
            })
            newOptions = newOptions.concat(seasonsOptions);

            setShowSeasonsOptions(newOptions);

            const season = newOptions.find(season => season.season === props.episode.season);
            setSeason(season || newOptions[0]);
        }

        fetchShowSeasons(props.episode.showID);
    }, [props.episode])

    const updateEpisodePoster = async ({ target }) => {
        const file = target.files[0];
        if (!file) return;

        const fileExtension = path.extname(file.name).toUpperCase();

        const now = new Date().getTime()
        const filename = `POSTER-${props.show.customerID}-${props.episode.showID}-${props.episode.id}-${now}${fileExtension}`

        const presignedUrl = await API.generatePresignedRawMediaURL({
            operation: 'putObject',
            key: filename
        })

        var options = {
            headers: {
                'Content-Type': file.type
            },
            onUploadProgress: function (progressEvent) {
                var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                setProgress(percentCompleted);
            }
        };

        setUploading(true);
        try {
            await uploadPresignedFile(presignedUrl, file, options);
            createSuccessSnack('Successfully uploaded photo. Photo is being converted now.');
        } catch (err) {
            createErrorSnack('Failed to upload photo');
        }

        setUploading(false);
        setProgress(0);
    }

    const onSeasonChange = (event, newValue) => {
        setSeason(newValue);
    }

    const onEpisodeNumberChange = (event, newValue) => {
        setEpisodeNumber(newValue);
    }

    const onChangeReleaseDate = (date) => {
        setFirstRelease(date);
    }

    const updateEpisode = async () => {
        let input = {
            id: props.episode.id,
            season: season.season,
            number: episodeNumber,
            firstRelease: new Date(firstRelease).toISOString(),
            name: name,
            description: description,
            disclaimer: disclaimer,
            enabled: enabled,
            posterType: viewType?.value,
            releaseToApp: enabledForApp,
            releaseToOTT: enabledForOTT,
            duration: duration
        }

        try {
            await API.updateEpisode(input);
            createSuccessSnack('Successfully updated episode');
        } catch (err) {
            console.error(err);
            createErrorSnack('Failed to update episode');
        }
    }

    return (
        <TabPanel value={props.tab} index={props.index} direction="row" spacing={1}>
            {
                uploading &&
                <Grid item xs={12}>
                    <LinearProgress variant="determinate" value={progress} color="secondary" />
                </Grid>
            }
            <Grid item container spacing={2} direction="column" xs={6} alignItems="center">
                <Grid item>
                    <img src={posterURL} className={classes.poster} alt={`${name} poster`} />
                </Grid>
                <Grid item>
                    <FileUploadButton id="episode-poster-upload-button"
                        accept="image/*"
                        onChange={updateEpisodePoster}
                        text="Add/Change Episode Poster"
                    />
                </Grid>
            </Grid>
            <Grid item container spacing={2} direction="column" xs={6}>
                <Grid item container direction="row">
                    <Grid item>
                        <FormControlLabel
                            control={<Checkbox checked={enabled} onChange={(e) => setEnabled(e.target.checked)} name="enabled" />}
                            label="Enabled"
                        />
                    </Grid>
                    <Grid item>
                        <FormControlLabel
                            control={<Checkbox checked={enabledForApp} onChange={(e) => setEnabledForApp(e.target.checked)} name="enabledForApp" />}
                            label="Enable for App"
                        />
                    </Grid>
                    <Grid item>
                        <FormControlLabel
                            control={<Checkbox checked={enabledForOTT} onChange={(e) => setEnabledForOTT(e.target.checked)} name="enabledForOTT" />}
                            label="Enable for OTT"
                        />
                    </Grid>
                </Grid>
                <Grid item container spacing={2} direction="row">
                    <Grid item>
                        <Autocomplete id="season-combo-box"
                            options={showSeasonsOptions}
                            getOptionLabel={(option) => option.name}
                            value={season}
                            onChange={onSeasonChange}
                            style={{ width: 150 }}
                            selectOnFocus
                            clearOnBlur
                            freeSolo
                            renderInput={(params) => <TextField {...params} label={props.show.appData?.videoGroupingName || 'Season'} variant="outlined" />}
                        />
                    </Grid>
                    <Grid item>
                        <Autocomplete id="episode-combo-box"
                            options={episodeNumberOptions}
                            getOptionLabel={(option) => option.toString()}
                            value={episodeNumber.toString()}
                            onChange={onEpisodeNumberChange}
                            style={{ width: 150 }}
                            selectOnFocus
                            clearOnBlur
                            freeSolo
                            renderInput={(params) => <TextField {...params} label="Episode" variant="outlined" />}
                        />
                    </Grid>
                    <Grid item>
                        <Typography>Episode ID: {props.episode.id}</Typography>
                    </Grid>
                </Grid>
                <Grid item container spacing={2} direction="row">
                    <Grid item>
                        <DatePicker label="Realease Date"
                            format="MM/dd/yyyy"
                            value={firstRelease}
                            onChange={onChangeReleaseDate}
                            variant="inline"
                            inputVariant="outlined"
                        />
                    </Grid>
                    <Grid item>
                        <TextField variant="outlined"
                            label="Runtime"
                            value={duration}
                            onChange={(e) => setDuration(parseInt(e.target.value))}
                        />
                    </Grid>
                </Grid>
                <Grid item>
                    <SelectWithLabel id="view-type-selector"
                        title="View Type"
                        value={viewType}
                        onChange={(e) => setViewType(e.target.value)}
                        selectOptions={viewTypeSelectOptions}
                    />
                </Grid>
                <Grid item>
                    <TextField variant="outlined"
                        label="Episode Name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        fullWidth
                    />
                </Grid>
                <Grid item>
                    <TextField variant="outlined"
                        label="Episode Description"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        fullWidth
                        multiline
                        rows={10}
                    />
                </Grid>
                <Grid item>
                    <TextField variant="outlined"
                        label="Episode Disclaimer"
                        value={disclaimer}
                        placeholder="Any information you may need to disclose from the Episode view"
                        onChange={(e) => setDisclaimer(e.target.value)}
                        fullWidth
                        multiline
                        rows={10}
                    />
                </Grid>
                <Grid item>
                    <Button variant="contained" color="secondary" onClick={updateEpisode}>
                        Update Episode
                    </Button>
                </Grid>
            </Grid>
        </TabPanel>
    )
}
export interface GeneralInfoTabProps {
    tab: number
    index: number
    episode: Episode
    show: Show
}