import React, { useState, useEffect } from 'react';
import { Grid, Card, Popover, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { Bar } from 'react-chartjs-2';

import * as API from '../../../lib/api';

const useStyles = makeStyles((theme) => ({
    paper: {
        backgroundColor: "transparent",
        boxShadow: "none",
        overflow: "hidden"
    }
}));

const title = 'Users by Location';

interface data {
    labels: any[]
    datasets: any[]
}

const emptyData = {
    labels: [],
    datasets: [{
        label: title,
        backgroundColor: 'rgb(0, 0, 90)',
        borderColor: 'rgb(0, 0, 90)',
        data: []
    }]
}

export function TopLocationsChart(props: TopLocationsChartProps) {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const [loading, setLoading] = useState(true);

    const [metricsData, setMetricsData] = useState<data>(emptyData);

    const [options] = useState({
        scales: {
            yAxes: [{
                scaleLabel: {
                    labelString: 'Users',
                    display: true,
                    fontSize: 16
                }
            }]
        }
    });

    useEffect(() => {
        setMetricsData(emptyData);
        if (!props.customerID) return;

        const fetchDeviceInfo = async () => {
            setLoading(true);

            const queryArgs = {
                customerID: props.customerID,
                fromDate: props.dates.fromDate.toISOString(),
                toDate: props.dates.toDate.toISOString(),
            }
            const locationInfoResults = await API.getEngagementTopTwentyLocations(queryArgs);

            let locationData: any[] = [];

            let labels = locationInfoResults?.map(entry => {
                locationData.push(entry?.totalVisits || 0)
                return entry?.state || ''
            }) || []

            const data = {
                labels: labels,
                datasets: [{
                    label: title,
                    backgroundColor: 'rgb(0, 0, 90)',
                    borderColor: 'rgb(0, 0, 90)',
                    data: locationData
                }]
            }

            setMetricsData(data)
            setLoading(false);
        }

        fetchDeviceInfo();
    }, [props.customerID, props.dates])

    return (
        <>
            <Card ref={setAnchorEl}>
                <Grid container spacing={2} direction="column" xs>
                    <Grid item>
                        <Bar data={metricsData} options={options} height={75} />
                    </Grid>
                </Grid>
            </Card>
            <Popover id="loading-card-metrics"
                open={loading}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'center',
                    horizontal: 'center',
                }}
                PaperProps={{
                    classes: {
                        root: classes.paper
                    }
                }}
            >
                <CircularProgress size={100} />
            </Popover>
        </>
    )
}

export interface TopLocationsChartProps {
    customerID: number
    dates: { fromDate: Date, toDate: Date }
}