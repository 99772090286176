import React, { useState, useEffect, useCallback } from 'react';
import { Grid, Typography, FormControlLabel, Checkbox, Button, TextField } from '@material-ui/core';

import useSnackbars from '../../../../hooks/useSnackbars';

import { TabPanel } from '../../../tabs/TabPanel';
import { FileUploadButton } from '../../../shared/FileUploadButton';
import { SelectWithLabel } from '../../../shared/SelectWithLabel';

import { Show, UpdateShowAppDataInput } from '../../../../types';
import * as API from '../../../../lib/api';

const posterDisplayTypeOptions = [
    { name: '0 - Standard (Below Header)', value: 0 },
    { name: '1 - In Place of App Tile', value: 1},
];

export function GeneralSettingsTab(props: GeneralSettingsTabProps) {
    const { createSuccessSnack, createErrorSnack } = useSnackbars();
    const [enabled, setEnabled] = useState(props.show?.active || false);
    const [posterDisplayType, setPosterDisplayType] = useState(posterDisplayTypeOptions[props.show.appData?.appPosterDisplayType || 0]);
    const [videoGroupingName, setVideoGroupingName] = useState(props.show?.appData?.videoGroupingName || 'Season');
    const [videoGroupingNamePlural, setVideoGroupingNamePlural] = useState(props.show?.appData?.videoGroupingNamePlural || 'Seasons');

    const appSiteID = `https://${props.show.appID}.getvoxi.app`;

    const reset = useCallback(() => {
        if (!props.show) return;

        setEnabled(props.show.active || false);
        setPosterDisplayType(posterDisplayTypeOptions[props.show.appData?.appPosterDisplayType || 0]);
        setVideoGroupingName(props.show?.appData?.videoGroupingName || 'Season');
        setVideoGroupingNamePlural(props.show?.appData?.videoGroupingNamePlural || 'Seasons');
    }, [props.show])

    useEffect(() => {
        reset();
    }, [props.show, reset])

    const handlePosterDisplayChange = (event) => {
        setPosterDisplayType(event.target.value);
    };

    const updateGeneralSettings = async () => {
        const input: UpdateShowAppDataInput = {
            id: props.show.id,
            appPosterDisplayType: posterDisplayType.value,
            videoGroupingName,
            videoGroupingNamePlural,
        }

        try {
            await API.updateShowAppData(input);
            createSuccessSnack('Successfully updated show settings');
        } catch (err) {
            console.error(err);
            createErrorSnack('Failed to update show settings');
        }
    }

    const updateShowFavIcons = async ({ target }) => {
        if (!props.show) return;

        const file = target.files[0];
        if (!file) return;

        // const fileExtension = path.extname(file.name).toUpperCase();
    }

    return (
        <TabPanel value={props.tab} index={props.index} spacing={2} direction="row">
            <Grid item container spacing={2} direction="column" xs={6}>
                <Grid item>
                    <FormControlLabel
                        control={<Checkbox checked={enabled} onChange={(e) => setEnabled(e.target.checked)} name="enabled" />}
                        label="Enabled"
                    />
                </Grid>
                <Grid item>
                    <Typography variant="subtitle1">App Site ID</Typography>
                    <a href={appSiteID}>{appSiteID}</a>
                </Grid>
                <Grid item>
                    <SelectWithLabel id="poster-display-type"
                        title="Show Poster Display Type"
                        value={posterDisplayType}
                        onChange={handlePosterDisplayChange}
                        selectOptions={posterDisplayTypeOptions}
                    />
                </Grid>
                <Grid item>
                    <TextField id="video-grouping-name-field"
                        variant="outlined"
                        label="Video Grouping Name"
                        value={videoGroupingName}
                        onChange={(e) => setVideoGroupingName(e.target.value)}
                        style={{ width: '350px' }}
                    />
                </Grid>
                <Grid item>
                    <TextField id="video-grouping-name-plural-field"
                        variant="outlined"
                        label="Video Grouping NamePlural"
                        value={videoGroupingNamePlural}
                        onChange={(e) => setVideoGroupingNamePlural(e.target.value)}
                        style={{ width: '350px' }}
                    />
                </Grid>
                <Grid item>
                    <Button variant="contained" color="secondary" onClick={updateGeneralSettings}>
                        Update
                    </Button>
                </Grid>
                <Grid item>
                    <Button variant="contained" color="primary" onClick={reset}>
                        Cancel
                    </Button>
                </Grid>
            </Grid>
            <Grid item container spacing={2} xs={6} direction="column">
                <Grid item>
                    <Typography variant="h6">Update App favicon's</Typography>
                </Grid>
                <Grid item>
                    <Typography>Current Favicon (32x32): <img src='' alt='show favicon' height="32" /></Typography>
                    <br />
                    <Typography variant="subtitle2">
                        Upload the zip file genterated from <a href="https://realfavicongenerator.net">https://realfavicongenerator.net</a>
                    </Typography>
                    <Typography variant="subtitle2">
                        At the bottom of the page where is says "Favicon Generator Options", for "Path", select "I cannot or I do not want to place favicon files at the root..." and use this value
                    </Typography>
                </Grid>
                <Grid item>
                    <FileUploadButton id="show-favicon-upload-button"
                        accept="image/*"
                        onChange={updateShowFavIcons}
                        text="Update Favicons"
                        disabled
                    />
                </Grid>
            </Grid>
        </TabPanel>
    )
}

export interface GeneralSettingsTabProps {
    tab: number
    index: number
    show: Show
}