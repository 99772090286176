import React, { useState, useEffect, useReducer } from 'react';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import MenuIcon from '@material-ui/icons/Menu';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import {
    AppBar, Avatar, Box, Button, Drawer, Hidden, IconButton, List, ListItem, ListItemText,
    Menu, MenuItem, Select, Toolbar, Typography, Badge, Tooltip, Paper, Grid, Divider,
    Dialog, DialogActions, DialogTitle, DialogContent, DialogContentText
} from '@material-ui/core';

import IconDashboard from '@material-ui/icons/Dashboard';
import IconShoppingCart from '@material-ui/icons/ShoppingCart';
import IconBarChart from '@material-ui/icons/BarChart';
import IconAdmin from '@material-ui/icons/SupervisedUserCircle';
import IconTV from '@material-ui/icons/TvRounded';

import HelpIcon from '@material-ui/icons/HelpOutline';
import NotificationIcon from '@material-ui/icons/Notifications';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

import * as API from '../../lib/api';
import { isVoxiAdmin } from '../../lib/util';

import { SideBarItem } from './SideBarItem';
import { Dashboard } from '../dashboard/Dashboard';
import { Show } from '../show/Show';
import { Profile } from '../profile/Profile';

import { 
    AdminAdReport,
    AdminMobileAnalyticsReport,
    AdminMobilePageHeatmapReport,
    AddShow,
    Customer,
    Customers,
    User,
    Users
} from '../admin';

import {
    Brand, Brands, Product, ProductAdd,
    ProductCategory, ProductCategories, ProductList,
    ProductsStateProvider, CompleteProductRequests, IncompleteProductRequests,
    Store, StoreList, StoresStateProvider
} from '../products';

import { SnackbarProvider } from '../shared/SnackbarProvider';

import { EngagementReport, EpisodeReport, ProductsReport, ShowReport } from '../reports';
import { Vendor, VendorList } from '../vendorAdmin';

import { API as AmplifyAPI, graphqlOperation } from 'aws-amplify';
import { onCreateShow, onCreateNotification } from '../../graphql/subscriptions';

import { Maybe, Customer as CustomerType, Notification, Show as ShowType } from '../../types';

import { format } from 'date-fns';

const drawerWidth = 250;

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    drawer: {
        [theme.breakpoints.up('sm')]: {
            width: drawerWidth,
            flexShrink: 0
        },
    },
    appBar: {
        [theme.breakpoints.up('sm')]: {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: drawerWidth,
        }
    },
    customerSelect: {
        color: 'black',
        backgroundColor: 'white',
        textAlign: 'center',
        maxHeight: '40px'
    },
    menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },
    toolbar: {
        textAlign: 'center',
        marginTop: theme.spacing(1),
        ...theme.mixins.toolbar,
    },
    topBar: {
        // @ts-ignore
        backgroundColor: theme.palette.toolbar.dark
    },
    drawerPaper: {
        width: drawerWidth,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(2),
    },
    notificationsIcon: {
        color: 'white'
    },
    helpIcon: {
        color: 'white'
    },
    card: {
        maxWidth: 200,
    },
    notificationPaper: {
        padding: theme.spacing(2),
        margin: 'auto',
        maxWidth: 500,
    }
}));

const initialState = {
    shows: [],
    notifications: [],
    loading: true,
    error: false
}

function reducer(state, action) {
    switch (action.type) {
        case 'fetchShowsSuccess':
            return {
                ...state,
                shows: action.shows,
                loading: false
            }
        case 'addShowFromSubscription':
            return {
                ...state,
                shows: [
                    action.show,
                    ...state.shows
                ]
            }
        case 'fetchNotificationsSuccess':
            return {
                ...state,
                notifications: action.notifications.sort(notificationCompare)
            }
        case 'addNotificationFromSubscription':
            return {
                ...state,
                notifications: [
                    action.notification,
                    ...state.notifications
                ]
            }
        case 'deleteNotification':
            let updatedNotifications = state.notifications.filter(notification => notification.id !== action.id);
            return {
                ...state,
                notifications: updatedNotifications
            }
        default:
            throw new Error();
    }
}

function ResponsiveDrawer(props: any) {
    const { container } = props;
    const classes = useStyles();
    const theme = useTheme();
    const [mobileOpen, setMobileOpen] = useState(false);
    const [drawerState, dispatch] = useReducer(reducer, initialState)
    const [customers, setCustomers] = useState<CustomerType[]>([]);
    const [selectedCustomer, setSelectedCustomer] = useState<Maybe<CustomerType>>(null);
    const [authData, setAuthData] = useState(props.authData);
    const [profilePic] = useState(undefined);
    const [userInitials, setUserInitials] = useState('');
    const [isSuperAdmin, setIsSuperAdmin] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [notificationsAnchorEl, setNotificationsAnchorEl] = useState(null);
    const [helpOpen, setHelpOpen] = useState(false);

    const listShows = async (customerID: number) => {
        if (!customerID) return;

        const showsResult = await API.listShowsSideBar({ customerID: customerID });
        dispatch({
            type: 'fetchShowsSuccess',
            shows: showsResult
        })
    }

    const fetchNotifications = async () => {
        let filterInput = {
            userID: {
                eq: authData.attributes.sub
            }
        }

        const notificationsResult = await API.listNotifications(filterInput)
        dispatch({
            type: 'fetchNotificationsSuccess',
            notifications: notificationsResult
        })
    }

    useEffect(() => {
        if (!props.authData) return;

        let initials = '';
        if (props.authData.attributes?.given_name) {
            initials += props.authData.attributes.given_name[0];
        }
        if (props.authData.attributes?.family_name) {
            initials += props.authData.attributes.family_name[0];
        }
        setUserInitials(initials);

        // TODO: Add customer subscriptions and clear them
        const fetchCustomers = async () => {
            const customerList = await API.listCustomers();
            setCustomers(customerList);

            if (customerList && customerList.length > 0) {
                setSelectedCustomer(customerList[0]);
                listShows(customerList[0].id)
            }
        }

        const fetchCustomer = async (customerID: number) => {
            const customer = await API.getCustomer({ id: customerID });
            if (customer) {
                setCustomers([customer]);
                setSelectedCustomer(customer);
                listShows(customer.id)
            }
        }

        setAuthData(props.authData);
        fetchNotifications();

        let idToken = props.authData.signInUserSession?.idToken;
        let groups = idToken?.payload['cognito:groups'];
        let isSuperAdmin = isVoxiAdmin(groups);
        setIsSuperAdmin(isSuperAdmin);

        const customerID = idToken?.payload['customerID'];
        if (isSuperAdmin) fetchCustomers();
        else if (customerID) fetchCustomer(customerID);

        // @ts-ignore - for some reason it doesn't like the .subscribe.... 
        const onCreateNotificationSubscription = AmplifyAPI.graphql(graphqlOperation(onCreateNotification, { userID: authData.attributes.sub })).subscribe({
            next: (data) => {
                let newNotification = data.value.data.onCreateNotification;
                dispatch({
                    type: 'addNotificationFromSubscription',
                    notification: newNotification
                })
            },
            error: (error) => {
                console.error(error);
            }
        });

        return function cleanup() {
            // Stop receiving data updates from the subscription
            onCreateNotificationSubscription.unsubscribe();
        };
    }, [props.authData])

    useEffect(() => {
        if (!selectedCustomer) return;

        // @ts-ignore - for some reason it doesn't like the .subscribe.... 
        const onCreateShowSubscription = AmplifyAPI.graphql(graphqlOperation(onCreateShow, { customerID: selectedCustomer.id })).subscribe({
            next: (data) => {
                let newShow = data.value.data.onCreateShow;
                dispatch({
                    type: 'addShowFromSubscription',
                    show: newShow
                })
            },
            error: (error) => {
                console.error(error);
            }
        });

        return function cleanup() {
            // Stop receiving data updates from the subscription
            onCreateShowSubscription.unsubscribe();
        };
    }, [selectedCustomer])

    const onAvatarClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    }

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        setAnchorEl(null);
        props.onSignOut();
    };

    const onNotificationsClick = (event: any) => {
        setNotificationsAnchorEl(event.currentTarget);
    }

    const closeNotifications = () => {
        setNotificationsAnchorEl(null);
    }

    const onHelpClick = () => {
        setHelpOpen(true);
    }

    const onHelpClose = () => {
        setHelpOpen(false);
    }

    const onNotificationRemoved = (id) => {
        dispatch({
            type: 'deleteNotification',
            id: id
        })
    }

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const handleCustomerSelectChange = async (event) => {
        let selected = event.target.value;
        setSelectedCustomer(selected)
        listShows(selected.id);
    }

    const menuItems = [
        {
            key: 'dashboard',
            name: 'Dashboard',
            link: '/',
            Icon: IconDashboard
        },
        {
            key: 'shows',
            name: 'Shows / Network',
            Icon: IconTV,
            subMenu: drawerState.shows
                .filter((show: ShowType) => { return !show.hideInAdmin })
                .map((show: ShowType) => {
                    return {
                        name: show.nameShort,
                        link: `/shows/${show.id}`,
                        padding: '35px',
                    }
                })
        },
        {
            key: 'products',
            name: 'Products',
            Icon: IconShoppingCart,
            subMenu: [
                { name: 'Product List', link: '/products/list', padding: '35px' },
                { name: 'Categories', link: '/products/categories', padding: '35px' },
                { name: 'Stores', link: '/products/stores', padding: '35px' },
                { name: 'Brands', link: '/products/brands', padding: '35px' },
                {
                    name: 'Product Admin',
                    padding: '35px',
                    subMenu: [
                        { name: 'Add Product', link: '/products/add', padding: '60px' },
                        { name: 'Check Product Links', link: '/products/checklinks', padding: '60px' }
                    ]
                },
                {
                    name: 'Product Requests',
                    padding: '35px',
                    subMenu: [
                        { name: 'Incomplete / New Requests', link: '/products/requests/incomplete', padding: '60px' },
                        { name: 'Completed Requests', link: '/products/requests/complete', padding: '60px' },
                    ]
                },
            ]
        },
        {
            key: 'reports',
            name: 'Reports',
            Icon: IconBarChart,
            subMenu: [
                { name: 'Products Report', link: '/reports/products', padding: '35px' },
                selectedCustomer?.displayEpisodeReports ? { name: 'Episode Reports', link: '/reports/episode', padding: '35px' } : { name: 'empty' },
                selectedCustomer?.displayShowReports ? { name: 'Show Reports', link: '/reports/show', padding: '35px' } : { name: 'empty' },
                !selectedCustomer?.displayShowReports ? { name: 'Engagement Report', link: '/reports/engagement', padding: '35px' } : { name: 'empty' },
            ]
        },
        ... isSuperAdmin ? [{
                key: 'admin',
                auth: ['superadmin'],
                name: 'Administration',
                Icon: IconAdmin,
                subMenu: [
                    {
                        name: 'Voxi Admin',
                        padding: '35px',
                        subMenu: [
                            { name: 'Users', link: '/superadmin/users', padding: '60px' },
                            { name: 'Add Show', link: '/superadmin/shows/add', padding: '60px' },
                        ]
                    },
                    {
                        name: 'Vendor Admin',
                        link: '/vendoradmin',
                        padding: '35px'
                    },
                    {
                        name: 'Customer Admin',
                        link: '/superadmin/customers',
                        padding: '35px'
                    },
                    {
                        name: 'Reports',
                        padding: '35px',
                        subMenu: [
                            { name: 'Ads', link: '/superadmin/reports/adTracking', padding: '60px' },
                            { name: 'Mobile Analytics', link: '/superadmin/reports/mobileAnalytics', padding: '60px' },
                            { name: 'Page Views', link: '/superadmin/reports/pageViews', padding: '60px' },
                        ]
                    },
                ]
            }] : []
    ]

    const drawer = (
        <div>
            <div className={classes.toolbar}>
                <img src={process.env.PUBLIC_URL + '/voxi-logo-white-text-transparent-bg.svg'} alt="voxi logo" style={{ paddingLeft: "10", height: "50px" }} />
            </div>
            <div>
                <Select id="customer-select"
                    className={classes.customerSelect}
                    value={selectedCustomer}
                    onChange={handleCustomerSelectChange}
                    variant="outlined"
                    fullWidth
                >
                    {
                        customers &&
                        customers.map((customer: any) => {
                            return (
                                <MenuItem key={customer.id} value={customer}>{customer.shortName}</MenuItem>
                            )
                        })
                    }
                </Select>
            </div>
            <List>
                {
                    menuItems.map((item, index) => (
                        <SideBarItem {...item} key={index} />
                    ))
                }
                <ListItem style={{ textAlign: 'center' }}>
                    <ListItemText primary={`Version: ${process.env.REACT_APP_VERSION || '0.0.1'}`} />
                </ListItem>
            </List>
        </div>
    )

    return (
        <div className={classes.root}>
            <Dialog open={helpOpen}
                onClose={onHelpClose}
                aria-labelledby="help-dialog"
                aria-describedby="help-dialog-for-support-help-information"
            >
                <DialogTitle id="help-dialog">{'Help / Support'}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="help-dialog-information">
                        For assistance with any issues please contact Voxi support at
                        <a href={`mailto:support@getvoxi.com?subject=${encodeURIComponent('Voxi Content Admin Support')}`}> support@getvoxi.com</a>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onHelpClose} color="secondary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
            <Router>
                <AppBar position="fixed" className={classes.appBar}>
                    <Toolbar className={classes.topBar}>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            edge="start"
                            onClick={handleDrawerToggle}
                            className={classes.menuButton}
                        >
                            <MenuIcon />
                        </IconButton>
                        {
                            process.env.REACT_APP_STAGE !== 'prod' &&
                            <Typography>DEVELOPMENT</Typography>
                        }
                        <Box
                            display="flex"
                            justifyContent="flex-end"
                            alignItems="center"
                            width="100%"
                        >
                            <Typography>{authData.username}</Typography>
                            <Button onClick={onAvatarClick}>
                                {
                                    profilePic
                                        ? <Avatar src={profilePic} alt='profile pic' />
                                        : <Avatar>{userInitials}</Avatar>
                                }
                            </Button>
                            <Menu
                                id="profile-menu"
                                anchorEl={anchorEl}
                                anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                                getContentAnchorEl={null}
                                keepMounted
                                open={Boolean(anchorEl)}
                                onClose={handleClose}
                            >
                                <MenuItem onClick={handleClose} component={Link} to={'/profile'}>Profile</MenuItem>
                                <MenuItem onClick={handleLogout}>Logout</MenuItem>
                            </Menu>
                        </Box>
                        <Box>
                            <Tooltip title="Notifications">
                                <IconButton className={classes.notificationsIcon} onClick={onNotificationsClick}>
                                    <Badge badgeContent={drawerState.notifications.length} color="primary">
                                        <NotificationIcon />
                                    </Badge>
                                </IconButton>
                            </Tooltip>
                            <Menu id="notifications-menu"
                                anchorEl={notificationsAnchorEl}
                                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                                getContentAnchorEl={null}
                                keepMounted
                                open={Boolean(notificationsAnchorEl)}
                                onClose={closeNotifications}
                            >
                                {
                                    drawerState.notifications.length > 0
                                        ? drawerState.notifications.map((notification: Notification, index: number) => {
                                            return (
                                                <NotificationItem key={index} notification={notification} onNotificationRemoved={onNotificationRemoved} />
                                            )
                                        })
                                        : <Paper className={classes.notificationPaper}><Typography variant="body2">No notifications found</Typography></Paper>
                                }
                            </Menu>
                        </Box>
                        <Box>
                            <Tooltip title="Help" onClick={onHelpClick}>
                                <IconButton className={classes.helpIcon}>
                                    <HelpIcon />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    </Toolbar>
                </AppBar>
                <nav className={classes.drawer} aria-label="drawer items">
                    {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                    <Hidden smUp implementation="css">
                        <Drawer
                            container={container}
                            variant="temporary"
                            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                            open={mobileOpen}
                            onClose={handleDrawerToggle}
                            classes={{
                                paper: classes.drawerPaper,
                            }}
                            ModalProps={{
                                keepMounted: true, // Better open performance on mobile.
                            }}
                        >
                            {drawer}
                        </Drawer>
                    </Hidden>
                    <Hidden xsDown implementation="css">
                        <Drawer
                            classes={{
                                paper: classes.drawerPaper,
                            }}
                            variant="permanent"
                            open
                        >
                            {drawer}
                        </Drawer>
                    </Hidden>
                </nav>
                <main className={classes.content}>
                    <div className={classes.toolbar} />
                    <main className={classes.content}>
                        <SnackbarProvider>
                            <Route exact path="/profile" render={(props: any) => <Profile {...props} authData={authData} />} />
                            <Route exact path="/shows/:showID" render={(props: any) => <Show {...props} authData={authData} />} />

                            <Route exact path="/products/add" render={(props: any) => <ProductAdd {...props} authData={authData} customerID={selectedCustomer?.id || -1} />} />

                            <ProductsStateProvider>
                                <Route exact path="/products/list" render={(props: any) => <ProductList {...props} authData={authData} customerID={selectedCustomer?.id || -1} />} />
                                <Route exact path="/products/list/:productID" render={(props: any) => <Product {...props} authData={authData} customerID={selectedCustomer?.id || -1} />} />
                            </ProductsStateProvider>

                            <StoresStateProvider>
                                <Route exact path="/products/stores" render={(props: any) => <StoreList {...props} authData={authData} customerID={selectedCustomer?.id || -1} />} />
                                <Route exact path="/products/stores/:storeID" render={(props: any) => <Store {...props} authData={authData} customerID={selectedCustomer?.id || -1} />} />
                            </StoresStateProvider>

                            <Route exact path="/products/requests/complete" render={(props: any) => <CompleteProductRequests {...props} authData={authData} shows={drawerState.shows} customerID={selectedCustomer?.id || -1} />} />
                            <Route exact path="/products/requests/incomplete" render={(props: any) => <IncompleteProductRequests {...props} authData={authData} shows={drawerState.shows} customerID={selectedCustomer?.id || -1} />} />
                            <Route exact path="/products/categories/:categoryID" render={(props: any) => <ProductCategory {...props} authData={authData} customerID={selectedCustomer?.id || -1} />} />
                            <Route exact path="/products/categories" render={(props: any) => <ProductCategories {...props} authData={authData} customerID={selectedCustomer?.id || -1} />} />
                            <Route exact path="/products/brands" render={(props: any) => <Brands {...props} authData={authData} customerID={selectedCustomer?.id || -1} />} />
                            <Route exact path="/products/brands/:brandID" render={(props: any) => <Brand {...props} authData={authData} customerID={selectedCustomer?.id || -1} />} />

                            <Route exact path="/reports/episode" render={(props: any) => <EpisodeReport {...props} authData={authData} shows={drawerState.shows} />} />
                            <Route exact path="/reports/show" render={(props: any) => <ShowReport {...props} authData={authData} shows={drawerState.shows} />} />
                            <Route exact path="/reports/products" render={(props: any) => <ProductsReport {...props} authData={authData} customerID={selectedCustomer?.id || -1} />} />
                            <Route exact path="/reports/engagement" render={(props: any) => <EngagementReport {...props} authData={authData} customerID={selectedCustomer?.id || -1} />} />

                            <Route exact path="/superadmin/customers/:customerID" render={(props: any) => <Customer {...props} authData={authData} />} />
                            <Route exact path="/superadmin/customers" render={(props: any) => <Customers {...props} authData={authData} />} />
                            <Route exact path="/superadmin/shows/add" render={(props: any) => <AddShow {...props} authData={authData} />} />
                            <Route exact path="/superadmin/users/:userID" render={(props: any) => <User {...props} authData={authData} />} />
                            <Route exact path="/superadmin/users" render={(props: any) => <Users {...props} authData={authData} />} />
                            {/* <Route exact path="/superadmin/emailreports" render={(props: any) => <EmailReportSettings {...props} authData={authData} />} /> */}

                            <Route exact path="/superadmin/reports/adTracking" render={(props: any) => <AdminAdReport {...props} authData={authData} />} />
                            <Route exact path="/superadmin/reports/mobileAnalytics" render={(props: any) => <AdminMobileAnalyticsReport {...props} authData={authData} />} />
                            <Route exact path="/superadmin/reports/pageViews" render={(props: any) => <AdminMobilePageHeatmapReport {...props} authData={authData} />} />

                            <Route exact path="/vendoradmin/:vendorID" render={(props: any) => <Vendor {...props} authData={authData} />} />
                            <Route exact path="/vendoradmin" render={(props: any) => <VendorList {...props} authData={authData} />} />

                            <Route exact path="/" render={(props: any) => <Dashboard {...props} authData={authData} customer={selectedCustomer} />} />
                        </SnackbarProvider>
                    </main>
                </main>
            </Router>
        </div>
    );
}

export default ResponsiveDrawer;

const notificationFormatString = 'MMM dd yyyy HH:mm:ss'

function NotificationItem(props: NotificationItemProps) {
    const classes = useStyles();

    const remove = async () => {
        try {
            await API.deleteNotification({ id: props.notification.id });
            props.onNotificationRemoved(props.notification.id);
        } catch (err) {
            console.error(err);
        }
    }

    return (
        <>
            <Paper className={classes.notificationPaper}>
                <Grid item xs={12} sm container>
                    <Grid item xs container direction="column" spacing={2} justify="flex-start">
                        <Grid item xs>
                            <Typography variant="body2" gutterBottom>
                                {props.notification.description}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="caption">
                                {format(new Date(props.notification.time), notificationFormatString)}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <IconButton onClick={remove}>
                            <DeleteForeverIcon />
                        </IconButton>
                    </Grid>
                </Grid>
            </Paper>
            <Divider />
        </>
    )
}

interface NotificationItemProps {
    notification: Notification
    onNotificationRemoved: Function
}

function notificationCompare(a, b) {
    if (a.time < b.time) return 1;
    if (a.time > b.time) return -1;
    return 0;
}