import { API, graphqlOperation } from 'aws-amplify';
import { GraphQLResult } from '@aws-amplify/api/lib/types';
import * as types from '../types';

import * as customQueries from '../graphql/customQueries';
import * as queries from '../graphql/queries';
import * as mutations from '../graphql/mutations';

// USERS 
export async function listUsers(): Promise<Array<types.User>> {
    const response = await API.graphql(graphqlOperation(queries.listUsers)) as GraphQLResult<types.Query>;
    return response.data?.listUsers?.items || [];
}

export async function listVoxiAdmins(): Promise<Array<types.User>> {
    const response = await API.graphql(graphqlOperation(queries.listVoxiAdmins)) as GraphQLResult<types.Query>;
    return response.data?.listVoxiAdmins?.items || [];
}
export async function getUser(queryArgs: types.QueryGetUserArgs): Promise<types.Maybe<types.User>> {
    const response = await genericQuery(queries.getUser, queryArgs);
    return response.data?.getUser;
}
export async function createUser(input: types.CreateUserInput): Promise<types.Maybe<types.User>> {
    const response = await genericMutation(mutations.createUser, input);
    return response.data?.createUser;
}

// CUSTOMERS
export async function listCustomers(): Promise<Array<types.Customer>> {
    const response = await API.graphql(graphqlOperation(queries.listCustomers)) as GraphQLResult<types.Query>;
    return response.data?.listCustomers?.items || [];
}
export async function getCustomer(queryArgs: types.QueryGetCustomerArgs): Promise<types.Maybe<types.Customer>> {
    const response = await genericQuery(queries.getCustomer, queryArgs);
    return response.data?.getCustomer;
}
export async function createCustomer(input: types.CreateCustomerInput): Promise<types.Maybe<types.Customer>> {
    const response = await genericMutation(mutations.createCustomer, input);
    return response.data?.createCustomer;
}
export async function updateCustomer(input: types.UpdateCustomerInput): Promise<types.Maybe<types.Customer>> {
    const response = await genericMutation(mutations.updateCustomer, input);
    return response.data?.updateCustomer;
}

// SHOWS
export async function createShow(input: types.CreateShowInput): Promise<types.Maybe<types.Show>> {
    const response = await genericMutation(mutations.createShow, input);
    return response.data?.createShow
}
export async function listShowsSideBar(queryArgs: types.QueryListShowsArgs): Promise<Array<types.Show>> {
    const response = await genericQuery(customQueries.listShowsSideBar, queryArgs);
    return response.data?.listShows?.items || [];
}
export async function getShow(queryArgs: types.QueryGetShowArgs): Promise<types.Maybe<types.Show>> {
    const response = await genericQuery(queries.getShow, queryArgs);
    return response.data?.getShow;
}
export async function getShowMetadata(queryArgs: types.QueryGetShowMetadataArgs): Promise<Array<types.ShowMetadataItem>> {
    const response = await genericQuery(queries.getShowMetadata, queryArgs);
    return response.data?.getShowMetadata?.items || [];
}

export async function addShowMetadata(input: types.ShowMetadataItemInput): Promise<types.Maybe<types.ShowMetadataItem>> {
    const response = await genericMutation(mutations.addShowMetadata, input);
    return response.data?.addShowMetadata;
}

export async function deleteShowMetadata(input: types.DeleteShowMetadataItemInput): Promise<types.Maybe<types.ShowMetadataItem>> {
    const response = await genericMutation(mutations.deleteShowMetadata, input);
    return response.data?.deleteShowMetadata;
}

export async function updateShow(input: types.UpdateShowInput): Promise<types.Maybe<types.Show>> {
    const response = await genericMutation(mutations.updateShow, input);
    return response.data?.updateShow;
}

export async function updateShowAppData(input: types.UpdateShowAppDataInput): Promise<types.Maybe<types.ShowAppData>> {
    const response = await genericMutation(mutations.updateShowAppData, input);
    return response.data?.updateShowAppData;
}

export async function createHomeScreenSettings(input: types.CreateHomeScreenSettingsInput): Promise<types.Maybe<types.HomeScreenSettings>> {
    const response = await genericMutation(mutations.createHomeScreenSettings, input);
    return response.data?.createHomeScreenSettings;
}

export async function getHomeScreenSettings(queryArgs: types.QueryGetHomeScreenSettingsArgs): Promise<types.Maybe<types.HomeScreenSettings>> {
    const response = await genericQuery(queries.getHomeScreenSettings, queryArgs);
    return response.data?.getHomeScreenSettings;
}

export async function updateHomeScreenSettings(input: types.UpdateHomeScreenSettingsInput): Promise<types.Maybe<types.HomeScreenSettings>> {
    const response = await genericMutation(mutations.updateHomeScreenSettings, input);
    return response.data?.updateHomeScreenSettings;
}

export async function updateShowProductRequestConfiguration(input: types.UpdateShowProductRequestConfigurationInput): Promise<types.Maybe<types.ShowProductRequestConfiguration>> {
    const response = await genericMutation(mutations.updateShowProductRequestConfiguration, input);
    return response.data?.updateShowProductRequestConfiguration;
}

export async function updateShowPoster(input: types.UpdateShowImageInput): Promise<types.Maybe<string>> {
    const response = await genericMutation(mutations.updateShowPoster, input);
    return response.data?.updateShowPoster?.url;
}

export async function updateShowAppLogo(input: types.UpdateShowImageInput): Promise<types.Maybe<string>> {
    const response = await genericMutation(mutations.updateShowAppLogo, input);
    return response.data?.updateShowAppLogo?.url;
}

export async function getShowReport(queryArgs: types.QueryGetShowReportArgs): Promise<types.Maybe<types.ShowReport>> {
    const response = await genericQuery(queries.getShowReport, queryArgs);
    return response.data?.getShowReport;
}

export async function getShowSeasons(queryArgs: types.QueryGetShowSeasonsArgs): Promise<Array<types.ShowSeason>> {
    const response = await genericQuery(queries.getShowSeasons, queryArgs);
    return response.data?.getShowSeasons?.items || [];
}
export async function createShowSeason(input: types.CreateShowSeasonInput): Promise<types.Maybe<types.ShowSeason>> {
    const response = await genericMutation(mutations.createShowSeason, input);
    return response.data?.createShowSeason;
}
export async function getShowDesignMenu(queryArgs: types.QueryGetShowDesignMenuArgs): Promise<Array<types.ShowDesignMenuItem>> {
    const response = await genericQuery(queries.getShowDesignMenu, queryArgs);
    return response.data?.getShowDesignMenu?.items || []
}
export async function createShowDesignMenuItem(input: types.CreateShowDesignMenuItemInput): Promise<types.Maybe<types.ShowDesignMenuItem>> {
    const response = await genericMutation(mutations.createShowDesignMenuItem, input);
    return response.data?.createShowDesignMenuItem;
}
export async function updateShowDesignMenuItem(input: types.UpdateShowDesignMenuItemInput): Promise<types.Maybe<types.ShowDesignMenuItem>> {
    const response = await genericMutation(mutations.updateShowDesignMenuItem, input);
    return response.data?.updateShowDesignMenuItem;
}
export async function deleteShowDesignMenuItem(id: number): Promise<types.Maybe<number>> {
    const response = await API.graphql(graphqlOperation(mutations.deleteShowDesignMenuItem, { id: id })) as GraphQLResult<types.Mutation>;
    return response.data?.deleteShowDesignMenuItem;
}

// EPISODES
export async function listEpisodes(queryArgs: types.QueryListEpisodesArgs): Promise<Array<types.Episode>> {
    const response = await genericQuery(queries.listEpisodes, queryArgs);
    return response.data?.listEpisodes?.items || [];
}
export async function listCommercials(queryArgs: types.QueryListCommercialsArgs): Promise<Array<types.Episode>> {
    const response = await genericQuery(queries.listCommercials, queryArgs);
    return response.data?.listCommercials?.items || [];
}
export async function listCustomerEpisodes(queryArgs: types.QueryListCustomerEpisodesArgs): Promise<Array<types.Episode>> {
    const response = await genericQuery(queries.listCustomerEpisodes, queryArgs);
    return response.data?.listCustomerEpisodes?.items || [];
}
export async function listCustomerEpisodesWithPhotoGroups(queryArgs: types.QueryListCustomerEpisodesWithPhotoGroupsArgs): Promise<Array<types.Episode>> {
    const response = await genericQuery(queries.listCustomerEpisodesWithPhotoGroups, queryArgs);
    return response.data?.listCustomerEpisodes?.items || [];
}
export async function listEpisodeCommercials(queryArgs: types.QueryListEpisodeCommercialsArgs): Promise<Array<types.EpisodeCommercial>> {
    const response = await genericQuery(queries.listEpisodeCommercials, queryArgs);
    return response.data?.listEpisodeCommercials?.items || [];
}
export async function getEpisode(queryArgs: types.QueryGetEpisodeArgs): Promise<types.Maybe<types.Episode>> {
    const response = await genericQuery(queries.getEpisode, queryArgs);
    return response.data?.getEpisode;
}
export async function getEpisodeMetadata(queryArgs: types.QueryGetEpisodeMetadataArgs): Promise<Array<types.EpisodeMetadataItem>> {
    const response = await genericQuery(queries.getEpisodeMetadata, queryArgs);
    return response.data?.getEpisodeMetadata?.items || [];
}
export async function getEpisodePhotoGroups(queryArgs: types.QueryGetEpisodePhotoGroupsArgs): Promise<Array<types.EpisodePhotoGroup>> {
    const response = await genericQuery(queries.getEpisodePhotoGroups, queryArgs)
    return response.data?.getEpisodePhotoGroups?.items || [];
}
export async function getEpisodePhotos(queryArgs: types.QueryGetEpisodePhotosArgs): Promise<Array<types.EpisodePhoto>> {
    const response = await genericQuery(queries.getEpisodePhotos, queryArgs)
    return response.data?.getEpisodePhotos?.items || [];
}
export async function getEpisodePhotosByGroup(queryArgs: types.QueryGetEpisodePhotosByGroupArgs): Promise<Array<types.EpisodePhoto>> {
    const response = await genericQuery(queries.getEpisodePhotosByGroup, queryArgs)
    return response.data?.getEpisodePhotosByGroup?.items || [];
}
export async function getEpisodePhotoProducts(queryArgs: types.QueryGetEpisodePhotoProductsArgs): Promise<Array<types.EpisodePhotoProduct>> {
    const response = await genericQuery(queries.getEpisodePhotoProducts, queryArgs)
    return response.data?.getEpisodePhotoProducts?.items || [];
}
export async function getEpisodePhotoProductsByGroup(queryArgs: types.QueryGetEpisodePhotoProductsByGroupArgs): Promise<Array<types.EpisodePhotoProduct>> {
    const response = await genericQuery(queries.getEpisodePhotoProductsByGroup, queryArgs)
    return response.data?.getEpisodePhotoProductsByGroup?.items || [];
}
export async function getEpisodeReport(queryArgs: types.QueryGetEpisodeReportArgs): Promise<types.Maybe<types.EpisodeReport>> {
    const response = await genericQuery(queries.getEpisodeReport, queryArgs);
    return response.data?.getEpisodeReport;
}
export async function updateEpisodePoster(input: types.UpdateEpisodeImageInput): Promise<string> {
    const response = await genericMutation(mutations.updateEpisodePoster, input);
    return response.data?.updateEpisodePoster?.url || '';
}
export async function createEpisode(input: types.CreateEpisodeInput): Promise<types.Maybe<types.Episode>> {
    const response = await genericMutation(mutations.createEpisode, input);
    return response.data?.createEpisode;
}
export async function updateEpisode(input: types.UpdateEpisodeInput): Promise<types.Maybe<types.Episode>> {
    const response = await genericMutation(mutations.updateEpisode, input);
    return response.data?.updateEpisode;
}
export async function updateEpisodeVideoCleanup(id: number): Promise<boolean> {
    const response = await API.graphql(graphqlOperation(mutations.updateEpisodeVideoCleanup, { id: id })) as GraphQLResult<types.Mutation>;
    return response.data?.updateEpisodeVideoCleanup || false;
}
export async function deleteEpisodePhoto(id: number): Promise<types.Maybe<number>> {
    const response = await API.graphql(graphqlOperation(mutations.deleteEpisodePhoto, { id: id })) as GraphQLResult<types.Mutation>;
    return response.data?.deleteEpisodePhoto
}
export async function createEpisodeCommercial(input: types.CreateEpisodeCommercialInput): Promise<types.Maybe<types.EpisodeCommercial>> {
    const response = await genericMutation(mutations.createEpisodeCommercial, input);
    return response.data?.createEpisodeCommercial;
}
export async function deleteEpisodeCommercial(id: number, episodeID: number): Promise<types.Maybe<number>> {
    const response = await API.graphql(graphqlOperation(mutations.deleteEpisodeCommercial, { id: id, episodeID: episodeID })) as GraphQLResult<types.Mutation>;
    return response.data?.deleteEpisodeCommercial
}

// Exclusive Video Content
export async function listExclusiveVideoContents(filterInput: types.ModelExclusiveVideoContentFilterInput): Promise<Array<types.Maybe<types.ExclusiveVideoContent>>> {
    const response = await genericQueryFilter(queries.listExclusiveVideoContents, filterInput);
    return response.data?.listExclusiveVideoContents?.items || [];
}
export async function listExclusiveVideoContentsByCustomer(queryArgs: types.QueryListExclusiveContentsByCustomerArgs): Promise<Array<types.Maybe<types.ExclusiveVideoContent>>> {
    const response = await genericQuery(queries.listExclusiveContentsByCustomer, queryArgs);
    return response.data?.listExclusiveContentsByCustomer?.items || [];
}
export async function listExclusiveVideoContentsByShow(queryArgs: types.QueryListExclusiveContentsByShowArgs): Promise<Array<types.Maybe<types.ExclusiveVideoContent>>> {
    const response = await genericQuery(queries.listExclusiveContentsByShow, queryArgs);
    return response.data?.listExclusiveContentsByShow?.items || [];
}
export async function getExclusiveVideoContent(queryArgs: types.QueryGetExclusiveVideoContentArgs): Promise<types.Maybe<types.ExclusiveVideoContent>> {
    const response = await genericQuery(queries.getExclusiveVideoContent, queryArgs);
    return response.data?.getExclusiveVideoContent;
}
export async function createExclusiveVideoContent(input: types.CreateExclusiveVideoContentInput): Promise<types.Maybe<types.ExclusiveVideoContent>> {
    const response = await genericMutation(mutations.createExclusiveVideoContent, input);
    return response.data?.createExclusiveVideoContent;
}
export async function updateExclusiveVideoContent(input: types.UpdateExclusiveVideoContentInput): Promise<types.Maybe<types.ExclusiveVideoContent>> {
    const response = await genericMutation(mutations.updateExclusiveVideoContent, input);
    return response.data?.updateExclusiveVideoContent;
}
export async function deleteExclusiveVideoContent(input: types.DeleteExclusiveVideoContentInput): Promise<types.Maybe<types.ExclusiveVideoContent>> {
    const response = await genericMutation(mutations.deleteExclusiveVideoContent, input);
    return response.data?.deleteExclusiveVideoContent;
}

// PRODUCTS
export async function listProducts(queryArgs: types.QueryListProductsArgs): Promise<Array<types.Product>> {
    const response = await genericQuery(queries.listProducts, queryArgs);
    return response.data?.listProducts?.items || [];
}
export async function getProduct(queryArgs: types.QueryGetProductArgs): Promise<types.Maybe<types.Product>> {
    const response = await genericQuery(queries.getProduct, queryArgs);
    return response.data?.getProduct;
}
export async function getProductLinks(queryArgs: types.QueryGetProductLinksArgs): Promise<Array<types.ProductLink>> {
    const response = await genericQuery(queries.getProductLinks, queryArgs);
    return response.data?.getProductLinks?.items || [];
}
export async function getProductCategories(queryArgs: types.QueryGetProductCategoriesArgs): Promise<Array<types.ProductCategory>> {
    const response = await genericQuery(queries.getProductCategories, queryArgs);
    return response.data?.getProductCategories?.items || [];
}
export async function getProductSearchMetadata(queryArgs: types.QueryGetProductSearchMetadataArgs): Promise<types.Maybe<types.ProductSearchMetadata>> {
    const response = await genericQuery(queries.getProductSearchMetadata, queryArgs);
    return response.data?.getProductSearchMetadata;
}
export async function getProductMetadata(queryArgs: types.QueryGetProductMetadataArgs): Promise<Array<types.ProductMetadataItem>> {
    const response = await genericQuery(queries.getProductMetadata, queryArgs);
    return response.data?.getProductMetadata?.items || [];
}
export async function createProductMetadataItem(input: types.CreateProductMetadataItem): Promise<types.Maybe<types.ProductMetadataItem>> {
    const response = await genericMutation(mutations.createProductMetadataItem, input);
    return response.data?.createProductMetadataItem;
}
export async function deleteProductMetadataItem(id: number): Promise<types.Maybe<number>> {
    const response = await API.graphql(graphqlOperation(mutations.deleteProductMetadataItem, { id: id })) as GraphQLResult<types.Mutation>;
    return response.data?.deleteProductMetadataItem;
}
export async function getProductEpisodes(queryArgs: types.QueryGetProductEpisodesArgs): Promise<Array<types.ProductEpisode>> {
    const response = await genericQuery(queries.getProductEpisodes, queryArgs);
    return response.data?.getProductEpisodes?.items || [];
}

export async function getProductRequestsByShow(queryArgs: types.QueryGetProductRequestsByShowArgs): Promise<Array<types.ProductRequest>> {
    const response = await genericQuery(queries.getProductRequestsByShow, queryArgs);
    return response.data?.getProductRequestsByShow?.items || [];
}

export async function getEpisodeProducts(queryArgs: types.QueryGetEpisodeProductsArgs): Promise<Array<types.EpisodeProduct>> {
    const response = await genericQuery(queries.getEpisodeProducts, queryArgs);
    return response.data?.getEpisodeProducts?.items || [];
}
export async function getProductEpisodePhotoGroups(queryArgs: types.QueryGetProductEpisodePhotoGroupsArgs): Promise<Array<types.ProductEpisodePhotoGroup>> {
    const response = await genericQuery(queries.getProductEpisodePhotoGroups, queryArgs);
    return response.data?.getProductEpisodePhotoGroups?.items || [];
}

export async function getProductsReport(queryArgs: types.QueryGetProductsReportArgs): Promise<types.Maybe<string>> {
    const response = await genericQuery(queries.getProductsReport, queryArgs);
    return response.data?.getProductsReport;
}

export async function getProductVendor(queryArgs: types.QueryGetProductVendorArgs): Promise<types.Maybe<types.Vendor>> {
    const response = await genericQuery(queries.getProductVendor, queryArgs);
    return response.data?.getProductVendor;
}

export async function checkIfProductExists(queryArgs: types.QueryCheckIfProductExistsArgs): Promise<types.Maybe<types.ProductExistsResult>> {
    const response = await genericQuery(queries.checkIfProductExists, queryArgs);
    return response.data?.checkIfProductExists;
}

export async function scrapeProductURL(queryArgs: types.QueryScrapeProductUrlArgs): Promise<types.Maybe<types.ScrapedProduct>> {
    const response = await genericQuery(queries.scrapeProductUrl, queryArgs);
    return response.data?.scrapeProductURL;
}

export async function walmartSearch(queryArgs: types.QueryWalmartSearchArgs): Promise<Array<types.WalmartProduct>> {
    const response = await genericQuery(queries.walmartSearch, queryArgs);
    return response.data?.walmartSearch?.items || [];
}
export async function createProduct(input: types.CreateProductInput): Promise<types.Maybe<types.Product>> {
    const response = await genericMutation(mutations.createProduct, input);
    return response.data?.createProduct;
}
export async function updateProduct(input: types.UpdateProductInput): Promise<types.Maybe<types.Product>> {
    const response = await genericMutation(mutations.updateProduct, input);
    return response.data?.updateProduct;
}
export async function deleteProduct(id: number): Promise<types.Maybe<number>> {
    const response = await API.graphql(graphqlOperation(mutations.deleteProduct, { id: id })) as GraphQLResult<types.Mutation>;
    return response.data?.deleteProduct;
}
export async function createProductLink(input: types.CreateProductLinkInput): Promise<types.Maybe<types.ProductLink>> {
    const response = await genericMutation(mutations.createProductLink, input);
    return response.data?.createProductLink;
}
export async function deleteProductLink(queryArgs: types.MutationDeleteProductLinkArgs): Promise<types.Maybe<number>> {
    const response = await API.graphql(graphqlOperation(mutations.deleteProductLink, queryArgs)) as GraphQLResult<types.Mutation>;
    return response.data?.deleteProductLink;
}
export async function createProductCategory(input: types.CreateProductCategoryInput): Promise<Array<types.ProductCategory>> {
    const response = await genericMutation(mutations.createProductCategory, input);
    return response.data?.createProductCategory?.items || [];
}
export async function deleteProductCategory(queryArgs: types.MutationDeleteProductCategoryArgs): Promise<types.Maybe<number>> {
    const response = await API.graphql(graphqlOperation(mutations.deleteProductCategory, queryArgs)) as GraphQLResult<types.Mutation>;
    return response.data?.deleteProductCategory;
}
export async function createEpisodeProduct(input: types.CreateEpisodeProductInput): Promise<types.Maybe<number>> {
    const response = await genericMutation(mutations.createEpisodeProduct, input);
    return response.data?.createEpisodeProduct;
}
export async function deleteEpisodeProduct(queryArgs: types.MutationDeleteEpisodeProductArgs): Promise<types.Maybe<number>> {
    const response = await API.graphql(graphqlOperation(mutations.deleteEpisodeProduct, queryArgs)) as GraphQLResult<types.Mutation>;
    return response.data?.deleteEpisodeProduct;
}
export async function createEpisodePhotoProduct(input: types.CreateEpisodePhotoProductInput): Promise<types.Maybe<number>> {
    const response = await genericMutation(mutations.createEpisodePhotoProduct, input);
    return response.data?.createEpisodePhotoProduct;
}
export async function deleteEpisodePhotoProduct(id: number): Promise<types.Maybe<number>> {
    const response = await API.graphql(graphqlOperation(mutations.deleteEpisodePhotoProduct, { id: id })) as GraphQLResult<types.Mutation>;
    return response.data?.deleteEpisodePhotoProduct;
}
export async function createVendorProduct(input: types.CreateVendorProductInput): Promise<types.Maybe<string>> {
    const response = await genericMutation(mutations.createVendorProduct, input);
    return response.data?.createVendorProduct;
}
export async function deleteVendorProduct(queryArgs: types.MutationDeleteVendorProductArgs): Promise<boolean> {
    const response = await API.graphql(graphqlOperation(mutations.deleteVendorProduct, queryArgs)) as GraphQLResult<types.Mutation>;
    return response.data?.deleteVendorProduct || false;
}
export async function updateProductRequest(input: types.UpdateProductRequestInput): Promise<types.Maybe<types.ProductRequest>> {
    const response = await genericMutation(mutations.updateProductRequest, input);
    return response.data?.updateProductRequest;
}
export async function replyProductRequest(input: types.ReplyProductRequestInput): Promise<types.Maybe<number>> {
    const response = await genericMutation(mutations.replyProductRequest, input);
    return response.data?.replyProductRequest;
}

// CATEGORIES
export async function listCategories(queryArgs: types.QueryListCategoriesArgs): Promise<Array<types.Category>> {
    const response = await genericQuery(queries.listCategories, queryArgs);
    return response.data?.listCategories?.items || [];
}
export async function listActiveCategories(queryArgs: types.QueryListActiveCategoriesArgs): Promise<Array<types.Category>> {
    const response = await genericQuery(queries.listActiveCategories, queryArgs);
    return response.data?.listActiveCategories?.items || [];
}
export async function getCategory(queryArgs: types.QueryGetCategoryArgs): Promise<types.Maybe<types.Category>> {
    const response = await genericQuery(queries.getCategory, queryArgs);
    return response.data?.getCategory;
}
export async function createCategory(input: types.CreateCategoryInput): Promise<types.Maybe<types.Category>> {
    const response = await genericMutation(mutations.createCategory, input);
    return response.data?.createCategory;
}
export async function updateCategory(input: types.UpdateCategoryInput): Promise<types.Maybe<types.Category>> {
    const response = await genericMutation(mutations.updateCategory, input);
    return response.data?.updateCategory;
}
export async function deleteCategory(queryArgs: types.MutationDeleteCategoryArgs): Promise<boolean> {
    const response = await genericMutationWithQueryArgs(mutations.deleteCategory, queryArgs);
    return response.data?.deleteCategory ?? false;
}

// STORES
export async function listStores(queryArgs: types.QueryListStoresArgs): Promise<Array<types.Store>> {
    const response = await genericQuery(queries.listStores, queryArgs);
    return response.data?.listStores?.items || [];
}
export async function listActiveStores(queryArgs: types.QueryListActiveStoresArgs): Promise<Array<types.Store>> {
    const response = await genericQuery(queries.listActiveStores, queryArgs);
    return response.data?.listActiveStores?.items || [];
}
export async function getStore(queryArgs: types.QueryGetStoreArgs): Promise<types.Maybe<types.Store>> {
    const response = await genericQuery(queries.getStore, queryArgs);
    return response.data?.getStore;
}
export async function createStore(input: types.CreateStoreInput): Promise<types.Maybe<types.Store>> {
    const response = await genericMutation(mutations.createStore, input);
    return response.data?.createStore;
}
export async function updateStore(input: types.UpdateStoreInput): Promise<types.Maybe<types.Store>> {
    const response = await genericMutation(mutations.updateStore, input);
    return response.data?.updateStore;
}

// BRANDS
export async function listBrands(queryArgs: types.QueryListBrandsArgs): Promise<Array<types.Brand>> {
    const response = await genericQuery(queries.listBrands, queryArgs);
    return response.data?.listBrands?.items || [];
}
export async function getBrand(queryArgs: types.QueryGetBrandArgs): Promise<types.Maybe<types.Brand>> {
    const response = await genericQuery(queries.getBrand, queryArgs);
    return response.data?.getBrand;
}
export async function listBrandProducts(queryArgs: types.QueryListBrandProductsArgs) {
    const response = await genericQuery(queries.listBrandProducts, queryArgs);
    return response.data?.listBrandProducts?.items || [];
}
export async function updateBrand(input) {
    const response = await genericMutation(mutations.updateBrand, input);
    return response.data?.updateBrand;
}

// FONTS
export async function listShowFontV2s(): Promise<Array<types.Maybe<types.ShowFontV2>>> {
    const response = await API.graphql(graphqlOperation(queries.listShowFontV2s)) as GraphQLResult<types.Query>;
    return response.data?.listShowFontV2s?.items || [];
}
export async function listFontsByShow(queryArgs: types.QueryListFontsByShowArgs): Promise<Array<types.Maybe<types.ShowFontV2>>> {
    const response = await genericQuery(queries.listFontsByShow, queryArgs);
    return response.data?.listFontsByShow?.items || [];
}
export async function getShowFontV2(queryArgs: types.QueryGetShowFontV2Args): Promise<types.Maybe<types.ShowFontV2>> {
    const response = await genericQuery(queries.getShowFontV2, queryArgs);
    return response.data?.getShowFontV2;
}
export async function createShowFontV2(input: types.CreateShowFontV2Input): Promise<types.Maybe<types.ShowFontV2>> {
    const response = await genericMutation(mutations.createShowFontV2, input);
    return response.data?.createShowFontV2;
}
export async function deleteShowFontV2(input: types.DeleteShowFontV2Input): Promise<types.Maybe<types.ShowFontV2>> {
    const response = await genericMutation(mutations.deleteShowFontV2, input);
    return response.data?.deleteShowFontV2;
}

// VENDORS
export async function listVendors(): Promise<Array<types.Maybe<types.Vendor>>> {
    const response = await API.graphql(graphqlOperation(queries.listVendors)) as GraphQLResult<types.Query>;
    return response.data?.listVendors?.items || [];
}
export async function getVendor(queryArgs: types.QueryGetVendorArgs): Promise<types.Maybe<types.Vendor>> {
    const response = await genericQuery(queries.getVendor, queryArgs);
    return response.data?.getVendor;
}
export async function createVendor(input: types.CreateVendorInput): Promise<types.Maybe<types.Vendor>> {
    const response = await genericMutation(mutations.createVendor, input);
    return response.data?.createVendor;
}
export async function updateVendor(input: types.UpdateVendorInput): Promise<types.Maybe<types.Vendor>> {
    const response = await genericMutation(mutations.updateVendor, input);
    return response.data?.updateVendor;
}

// VENDOR ADS
export async function listVendorAds(queryArgs: types.QueryListVendorAdsArgs): Promise<Array<types.Maybe<types.VendorAd>>> {
    const response = await genericQuery(queries.listVendorAds, queryArgs);
    return response.data?.listVendorAds?.items ?? [];
}
export async function listAdsByVendor(queryArgs: types.QueryListAdsByVendorArgs): Promise<Array<types.Maybe<types.VendorAd>>> {
    const response = await genericQuery(queries.listAdsByVendor, queryArgs);
    return response.data?.listAdsByVendor?.items ?? [];
}
export async function createVendorAd(input: types.CreateVendorAdInput): Promise<types.Maybe<types.VendorAd>> {
    const response = await genericMutation(mutations.createVendorAd, input);
    return response.data?.createVendorAd;
}

// NOTIFICATIONS
export async function listNotifications(filterInput: types.ModelNotificationFilterInput): Promise<Array<types.Maybe<types.Notification>>> {
    const response = await genericQueryFilter(queries.listNotifications, filterInput);
    return response.data?.listNotifications?.items || [];
}
export async function deleteNotification(input: types.DeleteNotificationInput): Promise<types.Maybe<types.Notification>> {
    const response = await genericMutation(mutations.deleteNotification, input);
    return response.data?.deleteNotification;
}

// REPORTS
export async function listEmailReportSettings(): Promise<Array<types.EmailReportSetting>> {
    const response = await API.graphql(graphqlOperation(queries.listEmailReportSettings)) as GraphQLResult<types.Query>;
    return response.data?.listEmailReportSettings?.items || [];
}
export async function createEmailReportSetting(input: types.CreateEmailReportSettingInput): Promise<types.Maybe<types.EmailReportSetting>> {
    const response = await genericMutation(mutations.createEmailReportSetting, input);
    return response.data?.createEmailReportSetting;
}
export async function updateEmailReportSetting(input: types.UpdateEmailReportSettingInput): Promise<types.Maybe<types.EmailReportSetting>> {
    const response = await genericMutation(mutations.updateEmailReportSetting, input);
    return response.data?.updateEmailReportSetting;
}
export async function deleteEmailReportSetting(id: number): Promise<types.Maybe<number>> {
    const response = await API.graphql(graphqlOperation(mutations.deleteEmailReportSetting, { id: id })) as GraphQLResult<types.Mutation>;
    return response.data?.deleteEmailReportSetting;
}

export async function getMobileActivityReport(queryArgs: types.QueryGetMobileActivityReportArgs): Promise<Array<types.Maybe<types.MobileAnalyticsEvent>>> {
    const response = await genericQuery(queries.getMobileActivityReport, queryArgs);
    return response.data?.getMobileActivityReport?.items ?? [];
}

export async function getMobilePageViewReport(queryArgs: types.QueryGetMobilePageViewReportArgs): Promise<Array<types.Maybe<types.MobilePageView>>> {
    const response = await genericQuery(queries.getMobilePageViewReport, queryArgs);
    return response.data?.getMobilePageViewReport?.items ?? [];
}

// ENGAGEMENT REPORTS
export async function getEngagementDeviceInfo(queryArgs: types.QueryGetEngagementDeviceInfoArgs): Promise<Array<types.EngagementDeviceInfo>> {
    const response = await genericQuery(queries.getEngagementDeviceInfo, queryArgs);
    return response.data?.getEngagementDeviceInfo?.items || [];
}
export async function getEngagementTopTwentyLocations(queryArgs: types.QueryGetEngagementTopTwentyLocationsArgs): Promise<Array<types.EngagementTopLocation>> {
    const response = await genericQuery(queries.getEngagementTopTwentyLocations, queryArgs);
    return response.data?.getEngagementTopTwentyLocations?.items || [];
}
export async function getEngagementEpisodeViews(queryArgs: types.QueryGetEngagementEpisodeViewsArgs): Promise<Array<types.EngagementEpisode>> {
    const response = await genericQuery(queries.getEngagementEpisodeViews, queryArgs);
    return response.data?.getEngagementEpisodeViews?.items || [];
}
export async function getEngagementActivityByWeekday(queryArgs: types.QueryGetEngagementActivityByWeekdayArgs): Promise<Array<types.EngagementActivityWeekday>> {
    const response = await genericQuery(queries.getEngagementActivityByWeekday, queryArgs);
    return response.data?.getEngagementActivityByWeekday?.items || [];
}
export async function getEngagementActivityByDate(queryArgs: types.QueryGetEngagementActivityByDateArgs): Promise<Array<types.EngagementActivityDate>> {
    const response = await genericQuery(queries.getEngagementActivityByDate, queryArgs);
    return response.data?.getEngagementActivityByDate?.items || [];
}

// DASHBOARD
export async function getDashboardTopTenEpisodes(queryArgs: types.QueryGetDashboardTopTenEpisodesArgs): Promise<Array<types.DashboardTopEpisode>> {
    const response = await genericQuery(queries.getDashboardTopTenEpisodes, queryArgs);
    return response.data?.getDashboardTopTenEpisodes?.items || [];
}
export async function getDashboardTopTenProductsImpressions(queryArgs: types.QueryGetDashboardTopTenProductsImpressionsArgs): Promise<Array<types.DashboardProductImpression>> {
    const response = await genericQuery(queries.getDashboardTopTenProductsImpressions, queryArgs);
    return response.data?.getDashboardTopTenProductsImpressions?.items || [];
}
export async function getDashboardTopTenProductsViews(queryArgs: types.QueryGetDashboardTopTenProductsViewsArgs): Promise<Array<types.DashboardProductImpression>> {
    const response = await genericQuery(queries.getDashboardTopTenProductsViews, queryArgs);
    return response.data?.getDashboardTopTenProductsViews?.items || [];
}
export async function getDashboardTopTenProductsClicks(queryArgs: types.QueryGetDashboardTopTenProductsClicksArgs): Promise<Array<types.DashboardProductImpression>> {
    const response = await genericQuery(queries.getDashboardTopTenProductsClicks, queryArgs);
    return response.data?.getDashboardTopTenProductsClicks?.items || [];
}

// BANNER ADS
export async function listBannerAdsByShow(queryArgs: types.QueryListBannerAdsByShowArgs): Promise<Array<types.Maybe<types.BannerAd>>> {
    const response = await genericQuery(queries.listBannerAdsByShow, queryArgs);
    return response.data?.listBannerAdsByShow?.items || [];
}
export async function listBannerAdsByEpisode(queryArgs: types.QueryListBannerAdsByEpisodeArgs): Promise<Array<types.Maybe<types.BannerAd>>> {
    const response = await genericQuery(queries.listBannerAdsByEpisode, queryArgs);
    return response.data?.listBannerAdsByEpisode?.items || [];
}
export async function listBannerAdsByCategory(queryArgs: types.QueryListBannerAdsByCategoryArgs): Promise<Array<types.Maybe<types.BannerAd>>> {
    const response = await genericQuery(queries.listBannerAdsByCategory, queryArgs);
    return response.data?.listBannerAdsByCategory?.items || [];
}
export async function createBannerAd(input: types.CreateBannerAdInput): Promise<types.Maybe<types.BannerAd>> {
    const response = await genericMutation(mutations.createBannerAd, input);
    return response.data?.createBannerAd;
}
export async function updateBannerAd(input: types.UpdateBannerAdInput): Promise<types.Maybe<types.BannerAd>> {
    const response = await genericMutation(mutations.updateBannerAd, input);
    return response.data?.updateBannerAd;
}
export async function deleteBannerAd(input: types.DeleteBannerAdInput): Promise<types.Maybe<types.BannerAd>> {
    const response = await genericMutation(mutations.deleteBannerAd, input);
    return response.data?.deleteBannerAd;
}
export async function cleanupBannerAd(queryArgs: types.MutationCleanupBannerAdArgs): Promise<boolean> {
    const response = await genericMutationWithQueryArgs(mutations.cleanupBannerAd, queryArgs);
    return response.data?.cleanupBannerAd || false;
}

export async function getAdminAdReport(queryArgs: types.QueryGetAdminAdReportArgs): Promise<types.Maybe<string>> {
    const response = await genericQuery(queries.getAdminAdReport, queryArgs);
    return response.data?.getAdminAdReport;
}

// DIGITAL ASSET LINKS
export async function getDigitalAssetLinks(queryArgs: types.QueryGetDigitalAssetLinksArgs): Promise<types.Maybe<types.DigitalAssetLinks>> {
    const response = await genericQuery(queries.getDigitalAssetLinks, queryArgs);
    return response.data?.getDigitalAssetLinks;
}
export async function createDigitalAssetLinks(input: types.CreateDigitalAssetLinksInput): Promise<types.Maybe<types.DigitalAssetLinks>> {
    const response = await genericMutation(mutations.createDigitalAssetLinks, input);
    return response.data?.createDigitalAssetLinks;
}
export async function updateDigitalAssetLinks(input: types.UpdateDigitalAssetLinksInput): Promise<types.Maybe<types.DigitalAssetLinks>> {
    const response = await genericMutation(mutations.updateDigitalAssetLinks, input);
    return response.data?.updateDigitalAssetLinks;
}

// MISC
export async function getDashboardReport(queryArgs: types.QueryGetDashboardReportArgs): Promise<types.Maybe<types.DashboardReport>> {
    const response = await genericQuery(queries.getDashboardReport, queryArgs);
    return response.data?.getDashboardReport;
}
export async function generatePresignedURL(input: types.S3SignedUrlInput): Promise<types.Maybe<string>> {
    const response = await genericMutation(mutations.generatePresignedUrl, input);
    return response.data?.generatePresignedURL?.url;
}
export async function generatePresignedRawMediaURL(input: types.S3SignedUrlInput): Promise<types.Maybe<string>> {
    const response = await genericMutation(mutations.generatePresignedRawMediaUrl, input);
    return response.data?.generatePresignedRawMediaURL?.url;
}
export async function generatePresignedRawVideoURL(input: types.S3SignedUrlInput): Promise<types.Maybe<string>> {
    const response = await genericMutation(mutations.generatePresignedRawVideoUrl, input);
    return response.data?.generatePresignedRawVideoURL?.url;
}

// GENERIC UTILITY

async function genericQuery(query: string, queryArgs: any) {
    return await API.graphql(graphqlOperation(query, queryArgs)) as GraphQLResult<types.Query>;
}

async function genericQueryFilter(query: string, filterInput: any) {
    return await API.graphql(graphqlOperation(query, { input: filterInput } )) as GraphQLResult<types.Query>;
}

async function genericMutation(mutation: string, input: any) {
    return await API.graphql(graphqlOperation(mutation, { input: input })) as GraphQLResult<types.Mutation>;
}

async function genericMutationWithQueryArgs(mutation: string, queryArgs: any) {
    return await API.graphql(graphqlOperation(mutation, queryArgs)) as GraphQLResult<types.Mutation>;
}