import React, { useState, useEffect } from 'react';
import { Grid, Typography, TextField, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { TabPanel } from '../../../tabs/TabPanel';

import useSnackbars from '../../../../hooks/useSnackbars';
import * as API from '../../../../lib/api';
import { Maybe, Show } from '../../../../types/api';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
    multiLineBox: {
        width: '75%'
    }
}));

export function ContentTab(props: ContentTabProps) {
    const classes = useStyles();
    const { createSuccessSnack, createErrorSnack } = useSnackbars();
    const [googleAnalyticsTrackingID, setGoogleAnalyticsTrackingID] = useState('')
    const [copyright, setCopyright] = useState(props.show?.appData?.copyright || '');
    const [isCopyrightHidden, setIsCopyrightHidden] = useState(false);
    const [privacyPolicy, setPrivacyPolicy] = useState(props.show?.appData?.privacyPolicy || '');
    const [isPrivacyPolicyHidden, setIsPrivacyPolicyHidden] = useState(false);

    useEffect(() => {
        if (!props.show) return;

        setGoogleAnalyticsTrackingID('');
        setCopyright(props.show.appData?.copyright || '');
        setPrivacyPolicy(props.show.appData?.privacyPolicy || '')
    }, [props.show])

    const createCopyRightMarkup = () => {
        return { __html: copyright };
    }

    const createPrivacyPolicyMarkup = () => {
        return { __html: privacyPolicy };
    }

    const updateContent = async () => {
        if (!props.show) return;
        let input = {
            id: props.show.id,
            googleTrackingID: googleAnalyticsTrackingID,
            copyright: copyright,
            privacyPolicy: privacyPolicy
        }

        const updateAppDataResult = await API.updateShowAppData(input);
        if (!updateAppDataResult) {
            createErrorSnack('Error updating show app data');
        } else {
            createSuccessSnack('Successfully updated show app data');
        }
    }

    return (
        <TabPanel value={props.tab} index={props.index} spacing={2} direction="column">
            <Grid item>
                <Typography>Google Analytics Tracking ID:</Typography>
            </Grid>
            <Grid item xs>
                <TextField variant="outlined"
                    value={googleAnalyticsTrackingID}
                />
            </Grid>
            <Grid item>
                <Typography>Copyright:</Typography>
            </Grid>
            <Grid item hidden={isCopyrightHidden}>
                <TextField variant="outlined"
                    className={classes.multiLineBox}
                    value={copyright}
                    onChange={(e) => setCopyright(e.target.value)}
                    multiline
                    rows={4}
                    disabled={isCopyrightHidden}
                />
            </Grid>
            <Grid item hidden={!isCopyrightHidden}>
                <div dangerouslySetInnerHTML={createCopyRightMarkup()} 
                    hidden={!isCopyrightHidden} 
                    style={{border: 'rgba(0,0,0,0.23)', borderStyle: 'solid', borderWidth: '2px', width: '75%', borderRadius: '5px'}}
                />
            </Grid>
            <Grid item>
                <Button variant="outlined" onClick={(e) => setIsCopyrightHidden(!isCopyrightHidden)}>
                    Preview
                </Button>
            </Grid>
            <Grid item>
                <Typography>Privacy Policy:</Typography>
            </Grid>
            <Grid item hidden={isPrivacyPolicyHidden}>
                <TextField variant="outlined"
                    className={classes.multiLineBox}
                    value={privacyPolicy}
                    onChange={(e) => setPrivacyPolicy(e.target.value)}
                    multiline
                    rows={6}
                    disabled={isPrivacyPolicyHidden}
                />
            </Grid>
            <Grid item hidden={!isPrivacyPolicyHidden}>
                <div dangerouslySetInnerHTML={createPrivacyPolicyMarkup()} 
                    hidden={!isPrivacyPolicyHidden} 
                    style={{border: 'rgba(0,0,0,0.23)', borderStyle: 'solid', borderWidth: '2px', width: '75%', borderRadius: '5px'}}
                />
            </Grid>
            <Grid item>
                <Button variant="outlined" onClick={(e) => setIsPrivacyPolicyHidden(!isPrivacyPolicyHidden)}>
                    Preview
                </Button>
            </Grid>
            <Grid item>
                <Button variant="contained" color="secondary" onClick={updateContent}>
                    Update App
                    </Button>
            </Grid>
        </TabPanel>
    )
}

export interface ContentTabProps {
    tab: number
    index: number
    show: Maybe<Show>
}