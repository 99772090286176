import React, { useState, useEffect } from 'react';
import { Grid, Card, Popover, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { Bar } from 'react-chartjs-2';

import * as API from '../../../lib/api';

const useStyles = makeStyles((theme) => ({
    paper: {
        backgroundColor: "transparent",
        boxShadow: "none",
        overflow: "hidden"
    }
}));

const title = 'Activity by Weekday';

interface data {
    labels: any[]
    datasets: any[]
}

const emptyData = {
    labels: [] as any[],
    datasets: [{
        label: title,
        backgroundColor: 'rgb(0, 0, 90)',
        borderColor: 'rgb(0, 0, 90)',
        data: [] as any[]
    }]
}

export function WeekdayActivityChart(props: WeekdayActivityChartProps) {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const [loading, setLoading] = useState(true);

    const [metricsData, setMetricsData] = useState<data>(emptyData);

    const [options] = useState({
        scales: {
            yAxes: [{
                scaleLabel: {
                    labelString: 'Activity',
                    display: true,
                    fontSize: 16
                }
            }]
        }
    });

    useEffect(() => {
        setMetricsData(emptyData);
        if (!props.customerID) return;

        const fetchWeekDayActivityData = async () => {
            setLoading(true);

            let data = emptyData;

            try {
                const queryArgs = {
                    customerID: props.customerID,
                    fromDate: props.dates.fromDate.toISOString(),
                    toDate: props.dates.toDate.toISOString(),
                }
                const weekDayActivityResults = await API.getEngagementActivityByWeekday(queryArgs);
    
                let activityData: any[] = [];
    
                let labels = weekDayActivityResults.map(entry => {
                    activityData.push(entry?.totalCount || 0)
                    return entry?.day || ''
                })
    
                data = {
                    labels: labels,
                    datasets: [{
                        label: title,
                        backgroundColor: 'rgb(0, 0, 90)',
                        borderColor: 'rgb(0, 0, 90)',
                        data: activityData
                    }]
                }
            } catch (err) {
                console.error(err);
            }

            setMetricsData(data)
            setLoading(false);
        }

        fetchWeekDayActivityData();
    }, [props.customerID, props.dates])

    return (
        <>
            <Card ref={setAnchorEl}>
                <Grid container spacing={2} direction="column" xs>
                    <Grid item>
                        <Bar data={metricsData} options={options} />
                    </Grid>
                </Grid>
            </Card>
            <Popover id="loading-card-metrics"
                open={loading}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'center',
                    horizontal: 'center',
                }}
                PaperProps={{
                    classes: {
                        root: classes.paper
                    }
                }}
            >
                <CircularProgress size={100} />
            </Popover>
        </>
    )
}

export interface WeekdayActivityChartProps {
    customerID: number
    dates: { fromDate: Date, toDate: Date }
}