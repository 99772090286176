import React, { useState, useEffect, useReducer } from 'react';
import { Grid, Typography } from '@material-ui/core';

import { List, ListItem, ListItemText } from '@material-ui/core';

import { ExclusiveVideoContent, Maybe, Show } from '../../../types';
import * as API from '../../../lib/api';
import { TabPanel } from '../../tabs/TabPanel';
import { Exclusive } from '../exclusive/Exclusive';

import { API as AmplifyAPI, graphqlOperation } from 'aws-amplify';
import { onCreateExclusiveVideoContent, onUpdateExclusiveVideoContent } from '../../../graphql/customSubscriptions';

const initialState = {
    exclusives: [],
    showID: -1,
    loading: true,
    error: false
}

function reducer(state, action) {
    switch (action.type) {
        case 'fetchExclusiveVideoContentSuccess':
            return {
                ...state,
                exclusives: action.exclusives
            }
        case 'addExclusiveVideoContentFromSubscription':
            if (action.exclusive.legacyShowID !== state.showID) return state;

            return {
                ...state,
                exclusives: [
                    action.exclusive,
                    ...state.exclusives
                ]
            }
        case 'updateExclusiveVideoContentFromSubscription':
            if (action.exclusive.legacyShowID !== state.showID) return state;

            let toUpdate = state.exclusives.find(exclusive => exclusive.id === action.exclusive.id);
            toUpdate = Object.assign(toUpdate, action.exclusive);

            return {
                ...state,
                exclusives: state.exclusives
            }
        case 'setShowID': 
            return {
                ...state,
                showID: action.showID
            }
        default:
            throw new Error();
    }
}

export function ExclusiveVideosTab(props: ExclusiveVideosTabProps) {
    const [selectedExclusive, setSelectedExclusive] = useState<any>();
    const [exclusivesState, dispatch] = useReducer(reducer, initialState)

    const clear = () => {
        setSelectedExclusive(undefined);
    }

    useEffect(() => {
        clear();
        if (!props.show) return;

        dispatch({ type: 'setShowID', showID: props.show.id });

        const fetchExclusiveVideoContents = async () => {
            const exclusivesResult = await API.listExclusiveVideoContentsByShow({ legacyShowID: props.show!.id });
            dispatch({ type: 'fetchExclusiveVideoContentSuccess', exclusives: exclusivesResult })
        }

        fetchExclusiveVideoContents();

        // @ts-ignore - for some reason it doesn't like the .subscribe.... 
        const createExclusiveSubscription = AmplifyAPI.graphql(graphqlOperation(onCreateExclusiveVideoContent)).subscribe({
            next: (data) => {
                const newExclusive = data.value.data.onCreateExclusiveVideoContent;
                if (newExclusive) {
                    dispatch({ type: 'addExclusiveVideoContentFromSubscription', exclusive: newExclusive })
                } else {
                    console.error('Something went wrong... but the exclusive got created');
                }
            },
            error: (error) => {
                console.warn(error);
            }
        });

        // @ts-ignore - for some reason it doesn't like the .subscribe.... 
        const updateExclusiveVideoContent = AmplifyAPI.graphql(graphqlOperation(onUpdateExclusiveVideoContent)).subscribe({
            next: (data) => {
                const updatedExclusive = data.value.data.onUpdateExclusiveVideoContent;
                if (updatedExclusive) {
                    dispatch({
                        type: 'updateExclusiveVideoContentFromSubscription',
                        exclusive: updatedExclusive
                    })
                } else {
                    console.error('Something went wrong... but an exclusive was updated');
                }
            },
            error: (error) => {
                console.error(error);
            }
        });

        return function cleanup() {
            // Stop receiving data updates from the subscription
            createExclusiveSubscription.unsubscribe();
            updateExclusiveVideoContent.unsubscribe();
        };
    }, [props.show])

    const onSelectExclusive = (exclusive) => {
        setSelectedExclusive(exclusive);
    }

    return (
        <TabPanel value={props.tab} index={props.index} spacing={2}>
            <Grid container spacing={2} direction="row">
                <Grid item container spacing={2} direction="column" xs={2}>
                    <Grid item>
                        {
                            exclusivesState.exclusives.length === 0
                                ? <Typography>No exclusives have been added. Add an exclusive to get started</Typography>
                                : <List>
                                    {
                                        exclusivesState.exclusives.map((exclusive: ExclusiveVideoContent) => (
                                            <ListItem key={`${exclusive.id}`} button onClick={(e) => onSelectExclusive(exclusive)}>
                                                <ListItemText primary={exclusive.name} />
                                            </ListItem>
                                        ))
                                    }
                                </List>
                        }
                    </Grid>
                </Grid>
                <Grid item container spacing={2} direction="row" alignItems="flex-start" alignContent="flex-start" justify="flex-start" xs>
                    {
                        !selectedExclusive || !props.show
                            ? <Typography>No Exclusive Selected</Typography>
                            : <Exclusive show={props.show} exclusive={selectedExclusive!} location={props.location} />
                    }
                </Grid>
            </Grid>
        </TabPanel>
    )
}
export interface ExclusiveVideosTabProps {
    tab: number
    index: number
    show: Maybe<Show>
    location: any
}