import React from 'react';
import { Grid } from '@material-ui/core';

export function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Grid item container
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <>{children}</>}
        </Grid>
    );
}

export function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}