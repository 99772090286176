import React, { useState, useEffect, forwardRef } from 'react';
import { Grid, Typography, Paper, Divider, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import useSnackbars from '../../hooks/useSnackbars';

import { format, subDays } from 'date-fns';

import MaterialTable, { MTableToolbar } from "material-table";
import { Icons } from 'material-table'; // typescript specific import https://github.com/mbrn/material-table/issues/1150

import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';

import { RangeSelect } from '../shared/RangeSelect';
import { ReportProduct } from '../../types/api';
import * as API from '../../lib/api';

import { API as AmplifyAPI, graphqlOperation } from 'aws-amplify';
import { onResolveProductsReport } from '../../graphql/subscriptions';

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2)
    },
    tableToolBar: {
        backgroundColor: '#f9f9f9!important'
    }
}));

const tableIcons: Icons = {
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

const formatString = 'MMM dd yyyy';

export function ProductsReport(props: ProductsReportProps) {
    const classes = useStyles();
    const { createErrorSnack } = useSnackbars();
    const [customerID, setCustomerID] = useState(props.customerID);
    const [reportDateString, setReportDateString] = useState('');
    const [productsReport, setProductsReport] = useState<Array<ReportProduct>>();
    const [totalImpressions, setTotalImpressions] = useState(0)
    const [totalViews, setTotalViews] = useState(0)
    const [totalClicks, setTotalClicks] = useState(0)
    const [loading, setLoading] = useState(true);

    const fetchProductsReport = async (customerID: number, from: Date, to: Date) => {
        setLoading(true);

        let queryArgs = {
            customerID: customerID,
            fromDate: from.toISOString(),
            toDate: to.toISOString()
        }

        try {
            const productsReportResult = await API.getProductsReport(queryArgs);
            if (productsReportResult) {
                const timer = setTimeout(() => {
                    setLoading(false);
                    createErrorSnack('Failed to get report within 4 minutes. Please contact support if it happens again.');
                    onResolveProductsReportSubscription.unsubscribe();
                }, 240000);

                let impressions = 0
                let views = 0
                let clicks = 0
                let items: any[] = [];

                // @ts-ignore - for some reason it doesn't like the .subscribe.... 
                const onResolveProductsReportSubscription = AmplifyAPI.graphql(graphqlOperation(onResolveProductsReport, { id: productsReportResult })).subscribe({
                    next: (data) => {
                        let reportResult = data.value.data.onResolveProductsReport;
                        items = items.concat(reportResult.items);

                        if (!reportResult.nextToken) {
                            items.forEach(product => {
                                impressions += product?.imagePreviews || 0;
                                views += product?.detailViews || 0;
                                clicks += product?.clicks || 0;
                            })
                            setTotalImpressions(impressions);
                            setTotalViews(views);
                            setTotalClicks(clicks);
                            setProductsReport(items);
                            clearTimeout(timer);
                            setReportDateString(`${format(from, 'M/dd/yyyy')} through ${format(to, 'M/dd/yyyy')}`);
                            onResolveProductsReportSubscription.unsubscribe();
                            setLoading(false);
                        }
                    },
                    error: (error) => {
                        console.error(error);
                        setLoading(false);
                        createErrorSnack('Failed to get report');
                    }
                });

            } else {
                setLoading(false);
                createErrorSnack('Failed to get report');
            }

        } catch (err) {
            console.error(err);
            setLoading(false);
            createErrorSnack('Failed to get report');
        }
    }

    useEffect(() => {
        if (!props.customerID || props.customerID < 1) return;

        setCustomerID(props.customerID);

        let from = subDays(new Date(), 7);
        let to = new Date();

        fetchProductsReport(props.customerID, from, to);
    }, [props.customerID])

    const handleDateChange = async (from, to) => {
        await fetchProductsReport(customerID, from, to);
    };

    const renderTableImage = (rowData) => {
        return (
            <img src={rowData.mainPhotoURL} width="50" height="50" alt={rowData.name} />
        )
    }

    const renderDateAdded = (rowData) => {
        const date = new Date(rowData.added);
        return format(date, formatString);
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs>
                <Paper className={classes.paper}>
                    <Grid container spacing={2} direction="row">
                        <Grid item container direction="column" spacing={2}>
                            <RangeSelect handleSelectChange={handleDateChange} onApplyClicked={handleDateChange} />
                            {
                                loading &&
                                <CircularProgress />
                            }
                        </Grid>
                        {
                            productsReport &&
                            <>
                                <Grid item xs={12}>
                                    <Divider />
                                </Grid>
                                <Grid item container direction="row">
                                    <Grid item xs>
                                        <Typography>Report Date Range</Typography>
                                        <Typography>{reportDateString}</Typography>
                                    </Grid>
                                </Grid>
                                <Grid item container direction="row" spacing={1}>
                                    <Grid item xs={2}>
                                        <Typography variant="subtitle2">Total Impressions</Typography>
                                        <Typography>{totalImpressions || 0}</Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Typography variant="subtitle2">Total Views</Typography>
                                        <Typography>{totalViews || 0}</Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Typography variant="subtitle2">Total Clicks</Typography>
                                        <Typography>{totalClicks || 0}</Typography>
                                    </Grid>
                                </Grid>
                            </>
                        }
                    </Grid>
                </Paper>
            </Grid>
            <Grid item xs={12}>
                {
                    productsReport &&
                    <MaterialTable
                        title="Products Report"
                        icons={tableIcons}
                        columns={[
                            { title: "Image", field: "mainPhotoURL", export: false, filtering: false, render: renderTableImage },
                            { title: "Brand", field: "brandName" },
                            { title: "Name", field: "name" },
                            { title: "Enabled", field: "active", type: "boolean" },
                            { title: "Date Added", field: "added", filtering: false, render: renderDateAdded },
                            { title: "Impressions", field: "imagePreviews", type: "numeric" },
                            { title: "Views", field: "detailViews", type: "numeric" },
                            { title: "Clicks", field: "clicks", type: "numeric" }
                        ]}
                        data={productsReport}
                        components={{
                            Toolbar: props => (
                                <div className={classes.tableToolBar}>
                                    <MTableToolbar {...props} className={classes.tableToolBar} />
                                </div>
                            )
                        }}
                        options={{
                            exportButton: true,
                            exportAllData: true,
                            pageSize: 25,
                            pageSizeOptions: [10, 25, 50, 100, 200, 300],
                            filtering: true
                        }}
                    />
                }


            </Grid>
        </Grid>
    )
}

export interface ProductsReportProps {
    authData: any
    customerID: number
}