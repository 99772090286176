import React, { useState, useEffect } from 'react';
import { Grid, Button, TextField, Typography } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';

import { TabPanel } from '../../../tabs/TabPanel';

import useSnackbars from '../../../../hooks/useSnackbars';

import * as API from '../../../../lib/api';
import { Maybe, Show } from '../../../../types/api';

const useStyles = makeStyles((theme) => ({
  multiLineBox: {
    maxWidth: '100%',
    wordWrap: "break-word"
  },
  boxHeaders: {
    textAlign: "center",
    fontWeight: 600
  },
  faqPreview: {
    border: '1px solid rgba(0,0,0,0.23)',
    borderRadius: '5px',
    padding: theme.spacing(1),
    fontSize: "16px",
    wordWrap: "break-word",
    minHeight: "40px",
    width: 0,
    minWidth: "100%",
    "&:hover": {
      border: "1px solid black",
      borderRadius: '5px'
    }
  },
  updateButtonContainer: {
    display: "flex",
    justifyContent: "center",
  },
}));

export function FAQTab(props: FAQTabProps) {
  const classes = useStyles();
  const { createSuccessSnack, createErrorSnack } = useSnackbars();
  const [faqEditorValue, setFaqEditorValue] = useState(props?.show?.faqContent || '');

  useEffect(() => {
    if (!props.show) return;

    setFaqEditorValue(props.show.faqContent || '');
  }, [props.show])

  const updateShow = async () => {
    if (!props.show) return;

    let input = {
      id: props.show?.id,
      faqContent: faqEditorValue
    }
    const updateShowResult = await API.updateShow(input);

    if (!updateShowResult) {
      createErrorSnack('Error updating show FAQ data');
    } else {
      createSuccessSnack('Successfully updated show FAQ data');
    }
  }

  const createFaqPreview = () => {
    return { __html: faqEditorValue || '' }
  }

  return (
    <TabPanel value={props.tab} index={props.index} spacing={2} direction="row">
      <Grid item container spacing={2}>
        <Grid item xs={6}>
          <Typography variant={"h6"} className={classes.boxHeaders}>Edit FAQ</Typography>
          <TextField variant="outlined"
            className={classes.multiLineBox}
            value={faqEditorValue}
            onChange={(e) => setFaqEditorValue(e.target.value)}
            multiline
            placeholder="Add to your FAQ!"
            fullWidth={true}
          />
        </Grid>
        <Grid item xs={6}>
          <Typography variant={"h6"} className={classes.boxHeaders}>Preview</Typography>
          <div dangerouslySetInnerHTML={createFaqPreview()}
            className={classes.faqPreview}
          />
          <Grid />
        </Grid>
      </Grid>
      <Grid item xs={12} className={classes.updateButtonContainer}>
        <Button variant="contained" color="secondary" onClick={updateShow}>Update FAQ</Button>
      </Grid>
    </TabPanel>
  )
}

export interface FAQTabProps {
  tab: number
  index: number
  show: Maybe<Show>
}