import React, { useState, useEffect } from 'react';
import { Grid, Typography, Tabs, Tab, AppBar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { a11yProps } from '../tabs/TabPanel';

import { AddProductTab, AmazonProductLookupTab, SearchHelperTab, WalmartProductLookupTab } from './productAddTabs';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
    mainBar: {
        backgroundColor: 'black',
        // width: '75%'
    },
    tab: {
        minWidth: 0
    }
}));

export function ProductAdd(props: ProductAddProps) {
    const classes = useStyles();
    const [customerID, setCustomerID] = useState(props.customerID);
    const [tab, setTab] = useState(0);
    const [product, setProduct] = useState(undefined);

    const handleChange = (event, newValue) => {
        setTab(newValue);
    };

    const walmartProductSelected = (product) => {
        setTab(0);
        setProduct(product);
    }

    useEffect(() => {
        setCustomerID(props.customerID);
    }, [props.customerID])

    const tabs = [
        { key: 'addProduct', label: 'Add A Product', disabled: false },
        { key: 'searchHelper', label: 'Search Helper', disabled: false },
        { key: 'walmartSearch', label: 'Walmart Product Lookup', disabled: false },
        { key: 'amazonSearch', label: 'Amazon Product Lookup', disabled: true }
    ]

    return (
        <Grid container spacing={1}>
            <Grid item>
                <Typography variant="h5">Add A Product</Typography>
            </Grid>

            <br />
            <Grid item xs={12}>
                <AppBar position="static" className={classes.mainBar}>
                    <Tabs value={tab} onChange={handleChange} aria-label="simple tabs example">
                        {
                            tabs.map((tab, index) => {
                                return (
                                    <Tab key={tab.key} label={tab.label} {...a11yProps(index)} className={classes.tab} disabled={tab.disabled} />
                                )
                            })
                        }
                    </Tabs>
                </AppBar>
            </Grid>
            <AddProductTab tab={tab} index={0} customerID={customerID} product={product} />
            <SearchHelperTab tab={tab} index={1} />
            <WalmartProductLookupTab tab={tab} index={2} onProductSelected={walmartProductSelected} />
            <AmazonProductLookupTab tab={tab} index={3} />
        </Grid>
    )
}

interface ProductAddProps {
    customerID: number
}