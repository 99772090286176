export const listShowsSideBar = /* GraphQL */ `
  query ListShows($customerID: Int!) {
    listShows(customerID: $customerID) {
      items {
        id
        customerID
        nameShort
        nameLong
        active
        hideInAdmin
      }
    }
  }
`;