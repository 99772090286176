import React, { useState, useEffect } from 'react';
import { Grid, Typography, Paper, Divider, Select, MenuItem, InputLabel, Button, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { format, subDays } from 'date-fns';

import * as API from '../../lib/api';

import {
    StreamMetricsByDateChart,
    StreamUsersByBandwidthChart,
    StreamUsersDeviceTypesChart,
    StreamMetricsByHourChart,
    StreamMetricsByWeekdayChart
} from './charts';

import { RangeSelect } from '../shared/RangeSelect';

import { Maybe, ShowReport as ShowReportType } from '../../types';

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2)
    },
    divider: {
        color: 'gray',
        backgroundColor: 'gray'
    }
}));

export function ShowReport(props: ShowReportProps) {
    const classes = useStyles();
    const [shows, setShows] = useState(props.shows);
    const [selectedShow, setSelectedShow] = useState(1);
    const [showName, setShowName] = useState('');
    const [reportDateString, setReportDateString] = useState('');
    const [showReport, setShowReport] = useState<Maybe<ShowReportType>>();
    const [fromDate, setFromDate] = useState(subDays(new Date(), 7));
    const [toDate, setToDate] = useState(new Date());
    const [loading, setLoading] = useState(false);

    const fetchShowReport = async (showID: number) => {
        setLoading(true);
        let queryArgs = {
            showID: showID,
            fromDate: fromDate.toISOString(),
            toDate: toDate.toISOString()
        }
        const showReportResult = await API.getShowReport(queryArgs);
        setShowReport(showReportResult);
        setLoading(false);
    }

    const onSelectShowChange = async (event, child) => {
        const showID = event.target.value;
        setSelectedShow(showID);
    }

    const generateReport = async () => {
        await fetchShowReport(selectedShow);

        let show = shows.find(show => {
            return show.id === selectedShow
        })
        setShowName(show.nameShort)
        setReportDateString(`${format(fromDate, 'M/dd/yyyy')} through ${format(toDate, 'M/dd/yyyy')}`);
    }

    const handleDateChange = async (from, to) => {
        setFromDate(from);
        setToDate(to);
    };

    useEffect(() => {
        setShows(props.shows);
        if (props.shows && props.shows.length > 0) setSelectedShow(props.shows[0].id);
    }, [props.shows])

    return (
        <Grid container spacing={2}>
            <Grid item xs>
                <Paper className={classes.paper}>
                    <Grid container spacing={2} direction="row">
                        <Grid item container direction="column" spacing={2}>
                            <Grid item>
                                <InputLabel id="report-show-select">Show</InputLabel>
                                <Select labelId="report-show-select"
                                    value={selectedShow}
                                    onChange={onSelectShowChange}
                                >
                                    {
                                        shows.map(show => {
                                            return (
                                                <MenuItem key={show.id} value={show.id}>{show.nameShort}</MenuItem>
                                            )
                                        })
                                    }

                                </Select>
                            </Grid>
                        </Grid>
                        <Grid item container direction="column" spacing={2}>
                            <RangeSelect handleSelectChange={handleDateChange} onApplyClicked={handleDateChange} />
                        </Grid>
                        <Grid item>
                            <Button variant="outlined" onClick={generateReport}>
                                Generate Report
                            </Button>
                        </Grid>
                        {
                            loading &&
                            <Grid item>
                                <CircularProgress />
                            </Grid>
                        }
                        {
                            showReport &&
                            <>
                                <Grid item xs={12}>
                                    <Divider className={classes.divider} />
                                </Grid>
                                <Grid item container direction="row">
                                    <Grid item xs={6}>
                                        <Typography>{showName}</Typography>
                                    </Grid>
                                    <Grid item xs>
                                        <Typography>Report Date Range</Typography>
                                        <Typography>{reportDateString}</Typography>
                                    </Grid>
                                </Grid>
                                <Grid item container direction="row" spacing={1}>
                                    <Grid item xs={2}>
                                        <Typography variant="subtitle2">Minimum Full Episode Views</Typography>
                                        <Typography>{showReport.minPartViews || 0}</Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Typography variant="subtitle2">Average Full Episode Views</Typography>
                                        <Typography>{showReport.averageViews || 0}</Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Typography variant="subtitle2">Total Hours Streamed</Typography>
                                        <Typography>
                                            {(Math.round(((showReport.totalSeconds! / (60.0 * 60.0)) + Number.EPSILON) * 100) / 100) + ' H'}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Typography variant="subtitle2">Total Bandwidth</Typography>
                                        <Typography>
                                            {(Math.round(((showReport.totalBytes! / (1000000000)) + Number.EPSILON) * 100) / 100) + ' GB'}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Typography variant="subtitle2">OTT User Count</Typography>
                                        <Typography variant="subtitle2">
                                            {showReport!.deviceInfo?.filter(info => info?.deviceType === 'Smart TV' ).length || 0}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Typography variant="subtitle2">App User Count</Typography>
                                        <Typography variant="subtitle2">{showReport.appUsers || 0}</Typography>
                                    </Grid>
                                </Grid>
                            </>
                        }
                    </Grid>
                </Paper>
            </Grid>
            <Grid item xs={12}>
                <StreamMetricsByDateChart dateStreams={showReport?.dateStreams} />
            </Grid>
            <Grid item sm={6}>
                <StreamUsersByBandwidthChart streamDataByBandwidth={showReport?.streamDataByBandwidth} />
            </Grid>
            <Grid item sm={6}>
                <StreamUsersDeviceTypesChart streamDataByBandwidth={showReport?.streamDataByBandwidth} />
            </Grid>
            <Grid item sm={8}>
                <StreamMetricsByHourChart streamDataByHour={showReport?.streamDataByHour} />
            </Grid>
            <Grid item sm>
                <StreamMetricsByWeekdayChart streamDataByWeekday={showReport?.streamDataByWeekday} />
            </Grid>
        </Grid>
    )
}

export interface ShowReportProps {
    authData: any
    shows: any[]
}