import React, { useState, useEffect, useCallback } from 'react';
import { Grid, Button, TextField } from '@material-ui/core';

import { TabPanel } from '../../tabs/TabPanel';

import { Maybe, Brand } from '../../../types';
import useSnackbars from '../../../hooks/useSnackbars';
import * as API from '../../../lib/api';

export function BrandDetailTab(props: BrandDetailTabProps) {
    const { createSuccessSnack, createErrorSnack } = useSnackbars();
    const [brandName, setBrandName] = useState('');

    const reset = useCallback(() => {
        setBrandName(props.brand?.name || '');
    }, [props.brand])

    useEffect(() => {
        if (!props.brand) return;
        reset();
    }, [props.brand, reset])

    const updateBrand = async () => {
        if (!props.brand) return;

        let input = {
            id: props.brand.id,
            customerID: props.brand.customerID,
            name: brandName
        }

        try {
            await API.updateBrand(input);
            createSuccessSnack('Successfully updated brand');
        } catch (err) {
            console.error(err);
            createErrorSnack('Failed to update brand');
        }
    }

    return (
        <TabPanel value={props.tab} index={props.index} direction="column" spacing={2}>
            <Grid item>
                <TextField variant="outlined"
                    label="Brand Name"
                    value={brandName}
                    onChange={(e) => setBrandName(e.target.value)}
                />
            </Grid>
            <Grid item>
                <Button variant="contained" color="secondary" onClick={updateBrand}>
                    Update Brand
                </Button>
            </Grid>
        </TabPanel >
    )
}

export interface BrandDetailTabProps {
    tab: number
    index: number
    brand: Maybe<Brand>
}