import React, { createContext, useReducer } from 'react';

const initialState: any = {
    stores: [],
    customerID: -1,
    loading: true,
    error: false
}

const StoreStore = createContext(initialState);
const { Provider } = StoreStore;

const reducer = (state, action) => {
    switch (action.type) {
        case 'fetchStoresSuccess':
            return {
                ...state,
                stores: action.stores,
                loading: false,
                customerID: action.customerID
            }
        case 'addStoreSuccess':
            return {
                ...state,
                stores: state.stores.concat(action.store)
            }
        default:
            throw new Error();
    }
}

const StoresStateProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    return <Provider value={{state, dispatch}}>{children}</Provider>
};

export { StoreStore, StoresStateProvider }