import React, { useState } from 'react';
import { Grid, TextField, Button } from '@material-ui/core';

import { TabPanel } from '../../tabs/TabPanel';

export function AmazonProductLookupTab(props: AmazonProductLookupTabProps) {
    const [searchText, setSearchText] = useState('');

    const searchAmazon = async () => {

    }

    return (
        <TabPanel value={props.tab} index={props.index} spacing={2} direction="column">
            <Grid item container spacing={2} direction="row">
                <Grid item xs={6}>
                    <TextField variant="outlined"
                        label="Search"
                        placeholder="Amazon Product Search"
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                        fullWidth
                    />
                </Grid>
                <Grid item xs>
                    <Button variant="contained" color="secondary" onClick={searchAmazon}>
                        Search
                    </Button>
                </Grid>
            </Grid>
        </TabPanel>
    )
}

export interface AmazonProductLookupTabProps {
    tab: Number
    index: Number
}