import React, { useState, useEffect, forwardRef } from 'react';
import * as Sentry from "@sentry/react";
import { Grid, InputLabel, Select, MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import useSnackbars from '../../hooks/useSnackbars';

import MaterialTable, { MTableToolbar } from "material-table";
import { Icons } from 'material-table'; // typescript specific import https://github.com/mbrn/material-table/issues/1150

import UndoIcon from '@material-ui/icons/Replay'

import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';

import * as API from '../../lib/api';
import { ProductRequest, Show } from '../../types/api';

import { format } from 'date-fns';

const useStyles = makeStyles((theme) => ({
    menuTable: {
        borderTopWidth: 2,
        borderColor: '#f9f9f9',
        borderWidth: 2,
        borderStyle: 'solid'
    },
    tableToolBar: {
        backgroundColor: '#f9f9f9!important'
    }
}));

const tableIcons: Icons = {
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

const formatString = 'MMM dd yyyy';

export function CompleteProductRequests(props: CompleteProductRequestsProps) {
    const classes = useStyles();
    const { createErrorSnack } = useSnackbars();
    const [shows, setShows] = useState<Array<Show>>([]);
    const [selectedShow, setSelectedShow] = useState(1);
    const [productRequests, setProductRequests] = useState<Array<ProductRequest>>([]);

    const fetchProductRequests = async (showID: number) => {
        const productRequestsResult = await API.getProductRequestsByShow({ showID: showID, status: 1 });
        setProductRequests(productRequestsResult)
    }

    const onSelectShowChange = async (event: any) => {
        const showID = event.target.value;
        setSelectedShow(showID);
        await fetchProductRequests(showID);
    }

    useEffect(() => {
        setShows(props.shows);
        if (props.shows && props.shows.length > 0) {
            setSelectedShow(props.shows[0].id)
            fetchProductRequests(props.shows[0].id)
        }
    }, [props.shows])

    const renderDateAdded = (rowData) => {
        const date = new Date(rowData.added);
        return format(date, formatString);
    }

    const renderEpisodeName = (rowData) => {
        if (!rowData || !rowData.episodeName) return;
        return `S${rowData.episodeSeason}:E${rowData.episodeNumber} - ${rowData.episodeName}`;
    }

    const updateRequestStatus = async (event, rowData) => {
        const input = {
            id: rowData.id,
            showID: rowData.showID,
            status: 0
        }

        try {
            await API.updateProductRequest(input);
            const updated = productRequests.filter(request => request.id !== rowData.id);
            setProductRequests(updated);
        } catch (err) {
            Sentry.captureException(err);
            createErrorSnack('Failed to update request status');
        }
    }

    return (
        <Grid container spacing={3} direction="column">
            <Grid item>
                <InputLabel id="report-show-select">Show</InputLabel>
                <Select labelId="report-show-select"
                    value={selectedShow}
                    onChange={onSelectShowChange}
                >
                    {
                        shows.map(show => {
                            return (
                                <MenuItem key={show.id} value={show.id}>{show.nameShort}</MenuItem>
                            )
                        })
                    }

                </Select>
            </Grid>
            <Grid item>
                <MaterialTable
                    title="Completed Product Requests"
                    icons={tableIcons}
                    columns={[
                        { title: "Episode Name", field: "episodeName", emptyValue: 'N/A', render: renderEpisodeName },
                        { title: "Category", field: "category" },
                        { title: "Photo Group", field: "photoGroup" },
                        { title: "Email", field: "email" },
                        { title: "IP Address", field: "ip", filtering: false },
                        { title: "Add Date", field: "added", render: renderDateAdded },
                        { title: "Request", field: "request" },
                        { title: "Status", field: "status", type: "boolean", filtering: false }
                    ]}
                    data={productRequests}
                    components={{
                        Toolbar: props => (
                            <div className={classes.tableToolBar}>
                                <MTableToolbar {...props} className={classes.tableToolBar} />
                            </div>
                        )
                    }}
                    options={{
                        exportButton: true,
                        pageSize: 25,
                        pageSizeOptions: [10, 25, 50],
                        filtering: true,
                        actionsColumnIndex: -1
                    }}
                    actions={[
                        { icon: (() => (<UndoIcon />)), tooltip: 'Mark Incomplete', onClick: updateRequestStatus }
                    ]}
                />
            </Grid>
        </Grid>
    )
}

export interface CompleteProductRequestsProps {
    authData: any
    shows: Array<Show>
    customerID: number
}