import React from "react";
import { Auth } from 'aws-amplify';
import { RequireNewPassword } from 'aws-amplify-react';
import { IAuthPieceProps } from 'aws-amplify-react/lib-esm/Auth/AuthPiece';
import { I18n } from '@aws-amplify/core';
import { auth } from 'aws-amplify-react/lib-esm/Amplify-UI/data-test-attributes';
import {
    FormSection,
    FormField,
    SectionHeader,
    SectionBody,
    SectionFooter,
    Button,
    Link,
    InputLabel,
    Input,
    SectionFooterPrimaryContent,
    SectionFooterSecondaryContent,
    SelectInput
} from 'aws-amplify-react/lib-esm/Amplify-UI/Amplify-UI-Components-React';

import { countryDialCodes } from './common/country-dial-codes';
const defaultDialCode = '+1'

class CustomRequireNewPassword extends RequireNewPassword {
    selectInputs: any

    constructor(props: IAuthPieceProps) {
        super(props);
        this._validAuthStates = ['requireNewPassword'];

        this.convertToPlaceholder = this.convertToPlaceholder.bind(this);
        this.mapRequiredAttribute = this.mapRequiredAttribute.bind(this);
        this.genderSelectField = this.genderSelectField.bind(this);
        this.phoneNumberSelectField = this.phoneNumberSelectField.bind(this);

        this.handleSelectInputChange = this.handleSelectInputChange.bind(this);
        this.composePhoneNumber = this.composePhoneNumber.bind(this);
        this.changePassword = this.changePassword.bind(this);

        this.selectInputs = {
            dial_code: defaultDialCode || '+1',
            phone_number: '',
            gender: 'Male'
        };
    }

    convertToPlaceholder(str) {
        return str
            .split('_')
            .map(function (part) { return part.charAt(0).toUpperCase() + part.substr(1).toLowerCase(); })
            .join(' ');
    }

    composePhoneNumber(dial_code, phone_number) {
        return `${dial_code || '+1'}${phone_number.replace(/[-()]/g, '')}`;
    }

    handleSelectInputChange(event) {
        const { name, value } = event.target;
        this.selectInputs[name] = value;

        this.handleInputChange(event)
    }

    mapRequiredAttribute(attribute) {
        let theme = this.props.theme;

        switch (attribute) {
            case 'gender':
                return this.genderSelectField(attribute);
            case 'phone_number':
                return this.phoneNumberSelectField(attribute);
            case 'family_name':
                return (
                    <Input theme={theme}
                        key={attribute}
                        name={attribute}
                        placeholder={I18n.get('Last Name') + ' *'}
                        type="text"
                        onChange={this.handleInputChange} />
                )

            case 'given_name':
                return (
                    <Input theme={theme}
                        key={attribute}
                        name={attribute}
                        placeholder={I18n.get('First Name') + ' *'}
                        type="text"
                        onChange={this.handleInputChange} />
                )
            default:
                return (
                    <Input theme={theme}
                        key={attribute}
                        name={attribute}
                        placeholder={I18n.get(this.convertToPlaceholder(attribute))}
                        type="text"
                        onChange={this.handleInputChange} />
                )
        }
    }

    phoneNumberSelectField(attribute) {
        let theme = this.props.theme;
        return (
            <SelectInput theme={theme}>
                <select
                    name="dial_code"
                    defaultValue={defaultDialCode}
                    onChange={this.handleSelectInputChange}
                    data-test={auth.genericAttrs.dialCodeSelect}>
                    {countryDialCodes.map(dialCode => (
                        <option key={dialCode} value={dialCode}>
                            {dialCode}
                        </option>
                    ))}
                </select>
                <Input theme={theme}
                    id={attribute}
                    key={attribute}
                    name={attribute}
                    placeholder={I18n.get(this.convertToPlaceholder(attribute)) + ' *'}
                    type="tel"
                    onChange={this.handleSelectInputChange}
                />
            </SelectInput>
        )
    }

    genderSelectField(attribute) {
        let theme = this.props.theme;
        return (
            <>
                <InputLabel theme={theme}>{I18n.get('Gender')} *</InputLabel>
                <SelectInput theme={theme}>
                    <select
                        id={attribute}
                        key={attribute}
                        name={attribute}
                        defaultValue="Male"
                        value={this.inputs.gender}
                        style={{ flexBasis: '100%' }}
                        onChange={this.handleInputChange}>
                        <option>Male</option>
                        <option>Female</option>
                        <option>Other</option>
                        <option>Prefer Not To Answer</option>
                    </select>
                </SelectInput>
            </>
        )
    }

    changePassword() {
        const user = this.props.authData;
        const password = this.inputs.password;
        const requiredAttributes = user.challengeParam.requiredAttributes;
        const attrs = objectWithProperties(this.inputs, requiredAttributes);
        attrs['phone_number'] = this.composePhoneNumber(this.selectInputs.dial_code, this.selectInputs.phone_number)

        if (!attrs['gender'] || attrs['gender'] === '') attrs['gender'] = this.selectInputs.gender;

        if (!Auth || typeof Auth.completeNewPassword !== 'function') {
            throw new Error('No Auth module found, please ensure @aws-amplify/auth is imported');
        }
        Auth.completeNewPassword(user, password, attrs)
            .then((user) => {
                if (user.challengeName === 'SMS_MFA') {
                    this.changeState('confirmSignIn', user);
                }
                else if (user.challengeName === 'MFA_SETUP') {
                    this.changeState('TOTPSetup', user);
                }
                else {
                    this.checkContact(user);
                }
            })
            .catch((err) => { return this.error(err); });
    };

    showComponent(theme) {
        let user = this.props.authData;
        let requiredAttributes = reorderAttributes(user.challengeParam.requiredAttributes)

        return (
            <FormSection theme={theme} data-test={auth.requireNewPassword.section} >
                <SectionHeader theme={theme} data-test={auth.requireNewPassword.headerSection} >
                    {I18n.get('Change Password')}
                </SectionHeader>
                <SectionBody theme={theme}>
                    <FormField theme={theme}>
                        <Input theme={theme}
                            autoFocus={true}
                            key="password"
                            name="password"
                            placeholder={I18n.get('New Password') + ' *'}
                            type="password"
                            onChange={this.handleInputChange}
                            data-test={auth.requireNewPassword.newPasswordInput} />
                    </FormField>
                    {
                        requiredAttributes.map((attribute) => {
                            return this.mapRequiredAttribute(attribute);
                        })
                    }
                </SectionBody>
                <SectionFooter theme={theme} data-test={auth.requireNewPassword.footerSection}>
                    <SectionFooterPrimaryContent theme={theme}>
                        <Button theme={theme} onClick={this.changePassword}>
                            {I18n.get('CHANGE')}
                        </Button>
                    </SectionFooterPrimaryContent>
                    <SectionFooterSecondaryContent theme={theme}>
                        <Link theme={theme}
                            data-test={auth.requireNewPassword.backToSignInLink}
                            onClick={() => { return this.changeState('signIn') }}>
                            {I18n.get('Back to Sign In')}
                        </Link>
                    </SectionFooterSecondaryContent>
                </SectionFooter>
            </FormSection>
        )
    }
}

export default CustomRequireNewPassword;

//@ts-ignore
Array.prototype.move = function (from, to) {
    this.splice(to, 0, this.splice(from, 1)[0]);
};

function reorderAttributes(requiredAttributes: any) {
    let firstNameIndex = requiredAttributes.indexOf('given_name');
    requiredAttributes.move(firstNameIndex, 0)

    let lastNameIndex = requiredAttributes.indexOf('family_name');
    requiredAttributes.move(lastNameIndex, 1)

    let genderIndex = requiredAttributes.indexOf('gender');
    requiredAttributes.move(genderIndex, requiredAttributes.length - 1)

    return requiredAttributes
}

function objectWithProperties(obj: any, keys: any) {
    var target = {};
    for (var key in obj) {
        if (keys.indexOf(key) === -1) {
            continue;
        }
        if (!Object.prototype.hasOwnProperty.call(obj, key)) {
            continue;
        }
        target[key] = obj[key];
    }
    return target;
}