import React, { useState, useEffect } from 'react';
import { Grid, TextField, Button, IconButton, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useHistory } from 'react-router-dom';

import * as API from '../../lib/api';

import { TableContainer, Table, TableHead, TableBody, TableRow, TableCell } from '@material-ui/core';

import DisableIcon from '@material-ui/icons/NoEncryptionRounded';
import EditIcon from '@material-ui/icons/Edit';

import { UserType, User } from '../../types';

const useStyles = makeStyles((theme) => ({
    menuTable: {
        borderTopWidth: 2,
        borderColor: '#f9f9f9',
        borderWidth: 2,
        borderStyle: 'solid'
    },
    emailField: {
        width: '300px'
    }
}));

const defaultOption = { name: '', value: -1 };

export function Users(props: UsersProps) {
    const classes = useStyles();
    const history = useHistory();
    const [users, setUsers] = useState<Array<User>>([]);
    const [customerOptions, setCustomerOptions] = useState([defaultOption]);
    const [newUserEmail, setNewUserEmail] = useState('');
    const [newUserCustomer, setNewUserCustomer] = useState(defaultOption)

    useEffect(() => {
        const fetchUsers = async () => {
            const usersResult = await API.listUsers();
            setUsers(usersResult);
        }

        const fetchCustomers = async () => {
            const customerList = await API.listCustomers();
            let options = customerList.map(customer => {
                return { name: customer.shortName, value: customer.id }
            })

            setCustomerOptions(options);
            setNewUserCustomer(options[0] || defaultOption);
        }

        fetchUsers();
        fetchCustomers();
    }, [])

    const addUser = async () => {
        const customerID = newUserCustomer.value;
        let input = {
            email: newUserEmail,
            userType: customerID === 1 ? UserType.VoxiAdmin : UserType.ContentAdmin,
            customerID: customerID
        }

        let addUserResult = await API.createUser(input);

        if (addUserResult) setUsers(users.concat(addUserResult));
    }

    const editUser = (userID) => {
        history.push(`/superadmin/users/${userID}`)
    }

    const disableUser = (userID) => {
        
    }

    const onCustomerChange = (e, v) => {
        setNewUserCustomer(v);
    }

    return (
        <Grid container spacing={2} direction="column">
            <Grid item container spacing={2} direction="row">
                <Grid item>
                    <TextField className={classes.emailField}
                        label="Email"
                        value={newUserEmail}
                        type="email"
                        onChange={(e) => setNewUserEmail(e.target.value)}
                    />
                </Grid>
                <Grid item>
                    <Autocomplete id="customer-combo-box"
                        options={customerOptions}
                        getOptionLabel={(option) => option.name}
                        value={newUserCustomer}
                        onChange={onCustomerChange}
                        style={{ width: '200px' }}
                        renderInput={(params) => <TextField {...params} label="Customer" />}
                    />
                </Grid>
                <Grid item>
                    <Button variant="contained" color="secondary" onClick={addUser}>
                        Add New User
                    </Button>
                </Grid>
            </Grid>
            <Grid item>
                <TableContainer>
                    <Table className={classes.menuTable} size="small" aria-label="seasons">
                        <TableHead>
                            <TableRow style={{ background: "#f9f9f9" }}>
                                <TableCell align="center">Profile Pic</TableCell>
                                <TableCell align="center">Username / Email</TableCell>
                                <TableCell align="center">First Name</TableCell>
                                <TableCell align="center">Last Name</TableCell>
                                <TableCell align="center">Last Login</TableCell>
                                <TableCell align="center">Status</TableCell>
                                <TableCell align="center">Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                users.map((user: User, index) => {
                                    return (
                                        <TableRow key={user.id} style={index % 2 ? { background: "#f9f9f9" } : { background: "white" }}>
                                            <TableCell align="center">TODO</TableCell>
                                            <TableCell align="center">{user.email}</TableCell>
                                            <TableCell align="center">{user.given_name}</TableCell>
                                            <TableCell align="center">{user.family_name}</TableCell>
                                            <TableCell align="center">TODO</TableCell>
                                            <TableCell align="center">{user.enabled ? 'Enabled' : 'Disabled'}</TableCell>
                                            <TableCell align="center">
                                                <Tooltip title="Edit User">
                                                    <IconButton aria-label="edit user" onClick={() => editUser(user.id)}>
                                                        <EditIcon />
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title="Disable User">
                                                    <IconButton aria-label="disable user" color="primary" onClick={() => disableUser(user.id)}>
                                                        <DisableIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </TableCell>
                                        </TableRow>
                                    )
                                })
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </Grid>
    )
}

export interface UsersProps {
    authData: any
}