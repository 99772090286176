import React, { useState, useEffect, useContext, forwardRef } from 'react';
import { useHistory } from 'react-router-dom';
import { Grid, TextField, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import MaterialTable, { MTableToolbar } from "material-table";
import { Icons } from 'material-table'; // typescript specific import https://github.com/mbrn/material-table/issues/1150

import { StoreStore } from './StoresStateProvider';

import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';

import * as API from '../../lib/api';

const useStyles = makeStyles((theme) => ({
    tableToolBar: {
        backgroundColor: '#f9f9f9!important'
    }
}));

const tableIcons: Icons = {
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

export function StoreList(props: StoreListProps) {
    const classes = useStyles();
    const history = useHistory();
    const { state, dispatch } = useContext(StoreStore);
    const [newStoreName, setNewStoreName] = useState('');
    const [newStoreDomainName, setNewStoreDomainName] = useState('');

    useEffect(() => {
        if (!props.customerID) return;

        // Don't refetch. Eventually, need a subscription...
        if (state.customerID === props.customerID && state.stores && state.stores.length > 0) return;

        const fetchStores = async () => {
            const storesResult = await API.listStores({ customerID: props.customerID });
            dispatch({
                type: 'fetchStoresSuccess',
                stores: storesResult,
                customerID: props.customerID
            })
        }

        fetchStores();
    }, [props.customerID])

    const activeFilter = (term, rowData) => {
        return term.toString() === rowData.active.toString()
    }

    const editStore = (event, rowData) => {
        history.push(`/products/stores/${rowData.id}`)
    }

    const addStore = async () => {
        let input = {
            customerID: props.customerID,
            name: newStoreName,
            domainName: newStoreDomainName,
            active: true
        }
        const storeResult = await API.createStore(input);
        dispatch({
            type: 'addStoreSuccess',
            store: storeResult,
        })
    }

    return (
        <Grid item container spacing={2} direction="column">
            <Grid item container spacing={2} direction="row" alignItems="flex-end">
                <Grid item>
                    <TextField label="Store Name"
                        value={newStoreName}
                        onChange={(e) => setNewStoreName(e.target.value)}
                    />
                </Grid>
                <Grid item>
                    <TextField label="Root Domain Name"
                        value={newStoreDomainName}
                        onChange={(e) => setNewStoreDomainName(e.target.value)}
                    />
                </Grid>
                <Grid item>
                    <Button variant="contained" color="secondary" onClick={addStore}>
                        Add Store
                    </Button>
                </Grid>
            </Grid>
            <Grid item>
                <MaterialTable
                    title="Store List"
                    icons={tableIcons}
                    columns={[
                        { title: "Store", field: "name" },
                        { title: "Root Domain", field: "domainName" },
                        { title: "Enabled", field: "active", customFilterAndSearch: activeFilter, filterPlaceholder: 'true or false' },
                        { title: "Product Count", field: "productCount", filtering: true },
                        { title: "Date Added", field: "added", filtering: false },
                    ]}
                    data={state.stores}
                    components={{
                        Toolbar: props => (
                            <div className={classes.tableToolBar}>
                                <MTableToolbar {...props} className={classes.tableToolBar} />
                            </div>
                        )
                    }}
                    options={{
                        exportButton: true,
                        pageSize: 10,
                        pageSizeOptions: [5, 10, 25],
                        filtering: true,
                        actionsColumnIndex: -1
                    }}
                    actions={[
                        { icon: (() => (<Edit />)), tooltip: 'Edit Store', onClick: editStore }
                    ]}
                />
            </Grid>
        </Grid>
    )
}

export interface StoreListProps {
    authData: any
    customerID: number
}