import React, { useState } from 'react';
import { Grid, Select, InputLabel, MenuItem, Button } from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';

import {
    startOfDay,
    endOfDay,
    startOfYesterday,
    endOfYesterday,
    startOfToday,
    endOfToday,
    startOfMonth,
    endOfMonth,
    subDays,
    format,
    subMonths
} from 'date-fns'

const RangeSelectData = {
    today: `Today: ${format(new Date(), 'MMM dd')}`,
    yesterday: `Yesterday: ${format(startOfYesterday(), 'MMM dd')}`,
    previous7: 'Previous 7 Days',
    previous30: 'Previous 30 Days',
    thisMonth: 'This Month',
    lastMonth: 'Last Month',
    custom: 'Custom'
}

export function RangeSelect({ handleSelectChange, onApplyClicked }) {
    const [rangeSelect, setRangeSelect] = useState('previous7');
    const [from, setFrom] = useState(startOfDay(subDays(new Date(), 7)));
    const [to, setTo] = useState(endOfDay(subDays(new Date(), 1)));

    const handleRangeSelectChange = async (event: any) => {
        setRangeSelect(event.target.value);

        let fromDate = new Date();
        let toDate = new Date();

        switch (event.target.value) {
            case 'today':
                fromDate = startOfToday();
                toDate = endOfToday();
                break;
            case 'yesterday':
                fromDate = startOfYesterday();
                toDate = endOfYesterday();
                break;
            case 'previous7':
                toDate = endOfDay(subDays(new Date(), 1));
                fromDate = startOfDay(subDays(toDate, 7));
                break;
            case 'previous30':
                fromDate = startOfDay(subDays(new Date(), 30));
                break;
            case 'thisMonth':
                fromDate = startOfMonth(fromDate);
                toDate = endOfMonth(fromDate);
                break;
            case 'lastMonth':
                let lastMonth = subMonths(fromDate, 1);
                fromDate = startOfMonth(lastMonth);
                toDate = endOfMonth(lastMonth);
                break;
            default:
                return
        }

        handleSelectChange(fromDate, toDate);
    }

    return (
        <Grid item container spacing={3} alignItems="flex-end">
            <Grid item>
                <InputLabel id="date-select">Range</InputLabel>
                <Select
                    labelId="date-select"
                    id="date-select"
                    value={rangeSelect}
                    onChange={handleRangeSelectChange} >
                    {
                        Object.keys(RangeSelectData).map(range => {
                            let display = RangeSelectData[range];
                            return (
                                <MenuItem key={range} value={range}>{display}</MenuItem>
                            )
                        })
                    }
                </Select>
            </Grid>
            {
                rangeSelect === 'custom' &&
                <>
                    <Grid item>
                        <DatePicker label="From"
                            value={from}
                            //@ts-ignore
                            onChange={setFrom}
                            maxDate={to}
                            maxDateMessage="Date should not be after To" 
                        />
                    </Grid>
                    <Grid item>
                        <DatePicker label="To"
                            value={to}
                            //@ts-ignore
                            onChange={setTo}
                            minDate={from}
                            minDateMessage="Date should not be before From" />
                    </Grid>
                    <Grid item>
                        <Button variant="contained" color="primary" onClick={() => onApplyClicked(from, to)}>
                            Apply
                        </Button>
                    </Grid>
                </>
            }
        </Grid>
    )
}