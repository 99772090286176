import React, { useState, useEffect } from 'react';
import { Grid, Typography, Button, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import useSnackbars from '../../../../hooks/useSnackbars';
import { TabPanel } from '../../../tabs/TabPanel';
import * as API from '../../../../lib/api';

import { ColorBox } from '../../../shared/ColorBox'

import { Maybe, Show } from '../../../../types';

const useStyles = makeStyles((theme) => ({
    colorItemDiv: {
        width: '200px'
    }
}));

export function ColorsTab(props: ColorsTabProps) {
    const { createSuccessSnack, createErrorSnack } = useSnackbars();
    const [primaryColor, setPrimaryColor] = useState(props.show.appData?.primaryColor);
    const [textPrimaryColor, setTextPrimaryColor] = useState(props.show.appData?.textPrimaryColor);
    const [secondaryColor, setSecondaryColor] = useState(props.show.appData?.secondaryColor);
    const [tertiaryColor, setTertiaryColor] = useState(props.show.appData?.tertiaryColor);
    const [accentColor, setAccentColor] = useState(props.show.appData?.accentColor);
    const [infoColor, setInfoColor] = useState(props.show.appData?.infoColor);
    const [headBackgroundColor, setHeadBackgroundColor] = useState(props.show.appData?.headBackgroundColor);
    const [headTextColor, setHeadTextColor] = useState(props.show.appData?.headTextColor);
    const [menuTextColor, setMenuTextColor] = useState(props.show.appData?.menuTextColor);
    const [productCaroselBackgroundColor, setProductCaroselBackgroundColor] = useState(props.show.appData?.productCaroselBackgroundColor);
    const [productButTextColor, setProductButTextColor] = useState(props.show.appData?.productButTextColor);
    const [productButBackColor, setProductButBackColor] = useState(props.show.appData?.productButBackColor);
    const [productBut2TextColor, setProductBut2TextColor] = useState(props.show.appData?.productBut2TextColor);
    const [productBut2BackColor, setProductBut2BackColor] = useState(props.show.appData?.productBut2BackColor);
    const [mobileTextPrimaryColor, setMobileTextPrimaryColor] = useState(props.show.appData?.mobileTextPrimaryColor);
    const [mobileTextSecondaryColor, setMobileTextSecondaryColor] = useState(props.show.appData?.mobileTextSecondaryColor);
    const [mobileAccentColor, setMobileAccentColor] = useState(props.show.appData?.mobileAccentColor);
    const [mobileButtonColor, setMobileButtonColor] = useState(props.show.appData?.mobileButtonColor);

    const resetColors = (appData: any) => {
        setPrimaryColor(appData.primaryColor);
        setTextPrimaryColor(appData.textPrimaryColor);
        setSecondaryColor(appData.secondaryColor)
        setTertiaryColor(appData.tertiaryColor);
        setAccentColor(appData.accentColor);
        setInfoColor(appData.infoColor);
        setHeadBackgroundColor(appData.headBackgroundColor);
        setHeadTextColor(appData.headTextColor);
        setMenuTextColor(appData.menuTextColor);
        setProductCaroselBackgroundColor(appData.productCaroselBackgroundColor);
        setProductButTextColor(appData.productButTextColor);
        setProductButBackColor(appData.productButBackColor);
        setProductBut2TextColor(appData.productBut2TextColor);
        setProductBut2BackColor(appData.productBut2BackColor);
        setMobileTextPrimaryColor(appData.mobileTextPrimaryColor);
        setMobileTextSecondaryColor(appData.mobileTextSecondaryColor);
        setMobileAccentColor(appData.mobileAccentColor);
        setMobileButtonColor(appData.mobileButtonColor);
    }

    useEffect(() => {
        if (!props.show) return;
        resetColors(props.show.appData);
    }, [props.show])

    const updateAppColors = async () => {
        const input = {
            id: props.show.id,
            primaryColor,
            textPrimaryColor,
            secondaryColor,
            tertiaryColor,
            accentColor,
            infoColor,
            headBackgroundColor,
            headTextColor,
            menuTextColor,
            productCaroselBackgroundColor,
            productButTextColor,
            productButBackColor,
            productBut2TextColor,
            productBut2BackColor,
            mobileTextPrimaryColor,
            mobileTextSecondaryColor, 
            mobileAccentColor,
            mobileButtonColor 
        }

        try {
            const appData = await API.updateShowAppData(input);
            console.log(appData)
            resetColors(appData);
            createSuccessSnack('Successfully updated app colors');
        } catch(err) {
            console.error(err);
            createErrorSnack('Failed to update app colors');
        }
        
    }

    const cancelUpdateAppColors = () => {
        resetColors(props.show.appData);
    }

    return (
        <TabPanel value={props.tab} index={props.index} spacing={2} direction="column">
            <Grid item container direction="column" spacing={2}>
                <Grid item>
                    <Typography variant="h5">Background Colors</Typography>
                </Grid>
                <Grid item container direction="row" spacing={3}>
                    <Grid item>
                        <ColorDiv title="Primary Background"
                            locations="Background"
                            color={primaryColor}
                            setColor={setPrimaryColor} />
                    </Grid>
                    <Grid item>
                        <ColorDiv title="Header Background"
                            locations='Primary Background Color on the header'
                            color={headBackgroundColor}
                            setColor={setHeadBackgroundColor}
                        />
                    </Grid>
                    <Grid item>
                        <ColorDiv title="Tile Background"
                            locations='Menu tiles, Product tile background, Episode tile background'
                            color={secondaryColor}
                            setColor={setSecondaryColor}
                        />
                    </Grid>
                    <Grid item>
                        <ColorDiv title="Icon Background"
                            locations='Background for Menu Item Icon Tiles'
                            color={tertiaryColor}
                            setColor={setTertiaryColor}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item container direction="column" spacing={2}>
                <Grid item>
                    <Typography variant="h5">Font/Text Colors</Typography>
                </Grid>
                <Grid item container direction="row" spacing={3}>
                    <Grid item>
                        <ColorDiv title="Primary Text"
                            locations='Text over the Primary Background Color'
                            color={textPrimaryColor}
                            setColor={setTextPrimaryColor}
                        />
                    </Grid>
                    <Grid item>
                        <ColorDiv title="Header Text"
                            locations='Header text color'
                            color={headTextColor}
                            setColor={setHeadTextColor}
                        />
                    </Grid>
                    <Grid item>
                        <ColorDiv title="Menu Text"
                            locations='Menu Text Color'
                            color={menuTextColor}
                            setColor={setMenuTextColor}
                        />
                    </Grid>
                    <Grid item>
                        <ColorDiv title="Title Text (Accent)"
                            locations='Title text color'
                            color={accentColor}
                            setColor={setAccentColor}
                        />
                    </Grid>
                    <Grid item>
                        <ColorDiv title="General Text (Info)"
                            locations='General Text Color'
                            color={infoColor}
                            setColor={setInfoColor}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item container direction="column" spacing={2}>
                <Grid item>
                    <Typography variant="h5">Product Colors</Typography>
                </Grid>
                <Grid item container direction="row" spacing={3}>
                    <Grid item>
                        <ColorDiv title={'Product Buy/Favorite Buttons Background'}
                            locations='Text Color on the Buy Now and Add to Wishlist buttons'
                            color={productButBackColor}
                            setColor={setProductButBackColor}
                        />
                    </Grid>
                    <Grid item>
                        <ColorDiv title="Product Buy/Favorite Button Text"
                            locations='Background Color behind the Buy Now and Add to Wishlist buttons'
                            color={productButTextColor}
                            setColor={setProductButTextColor}
                        />
                    </Grid>
                    <Grid item>
                        <ColorDiv title="Product More Info Button Background"
                            locations='Background Color on the More Info buttons'
                            color={productBut2BackColor}
                            setColor={setProductBut2BackColor}
                        />
                    </Grid>
                    <Grid item>
                        <ColorDiv title="Product More Info Button Text"
                            locations='Text Color on the More Info buttons'
                            color={productBut2TextColor}
                            setColor={setProductBut2TextColor}
                        />
                    </Grid>
                    <Grid item>
                        <ColorDiv title="Product Carousel Background"
                            locations='Background Color behind the products'
                            color={productCaroselBackgroundColor}
                            setColor={setProductCaroselBackgroundColor}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item container direction="column" spacing={2}>
                <Grid item>
                    <Typography variant="h5">Mobile Colors</Typography>
                </Grid>
                <Grid item container direction="row" spacing={3}>
                    <Grid item>
                        <ColorDiv title={'Mobile Primary Text Color'}
                            locations='Text color for most text on the mobile app'
                            color={mobileTextPrimaryColor}
                            setColor={setMobileTextPrimaryColor}
                        />
                    </Grid>
                    <Grid item>
                        <ColorDiv title="Mobile Secondary Text Color"
                            locations='Unsure/Unused/Work in progress'
                            color={mobileTextSecondaryColor}
                            setColor={setMobileTextSecondaryColor}
                        />
                    </Grid>
                    <Grid item>
                        <ColorDiv title="Mobile Accent Color"
                            locations='Unsure/Unused/Work in progress'
                            color={mobileAccentColor}
                            setColor={setMobileAccentColor}
                        />
                    </Grid>
                    <Grid item>
                        <ColorDiv title="Mobile Button Color"
                            locations='Buttons in the mobile app'
                            color={mobileButtonColor}
                            setColor={setMobileButtonColor}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <Button variant="contained" color="secondary" onClick={updateAppColors}>
                    Update App Colors
                </Button>
                <Button variant="contained" onClick={cancelUpdateAppColors}>
                    Cancel
                </Button>
            </Grid>
        </TabPanel>
    )
}

export interface ColorsTabProps {
    tab: number
    index: number
    show: Show
}

function ColorDiv(props: ColorDivProps) {
    const classes = useStyles();

    return (
        <Grid item container direction="column" className={classes.colorItemDiv}>
            <Grid item>
                <Typography component={'span'}>
                    <Box fontWeight={600} fontSize={13}>
                        {props.title}
                    </Box>
                </Typography>
            </Grid>
            <Grid item container direction="row" spacing={1}>
                <Grid item xs={4}>
                    <ColorBox color={props.color || ''} setColor={props.setColor} />
                </Grid>
                <Grid item container direction="column" xs>
                    <Grid item>
                        <Typography variant="subtitle2" component={'span'}>
                            <Box fontStyle="italic" fontSize={10}>
                                Locations Used:
                            </Box>
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography style={{ wordWrap: "normal" }} component={'span'}>
                            <Box fontSize={10}>
                                {props.locations}
                            </Box>
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

interface ColorDivProps {
    title: string
    locations: string
    color: Maybe<string>
    setColor: Function
}