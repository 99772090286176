import React, { useState, useEffect } from 'react';
import { Grid, Typography, Tabs, Tab, AppBar, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';

import * as API from '../../lib/api';
import { a11yProps } from '../tabs/TabPanel';

import { VendorAdsTab, VendorDetailsTab, VendorImagesTab, VendorProductsTab, VendorUsersTab } from './vendorTabs';

import ArrowBack from '@material-ui/icons/ArrowBack';

import { Maybe, Vendor as VendorModel } from '../../types/api';

const useStyles = makeStyles((theme) => ({
    mainBar: {
        backgroundColor: 'black',
    },
    tab: {
        minWidth: 0
    }
}));

export function Vendor(props: VendorProps) {
    const classes = useStyles();
    const history = useHistory();
    const [vendor, setVendor] = useState<Maybe<VendorModel>>();
    const [tab, setTab] = useState(0);

    const handleChange = (event, newValue) => {
        setTab(newValue);
    };

    useEffect(() => {
        const fetchVendor = async () => {
            const vendorResult = await API.getVendor({ id: props.match.params.vendorID });
            setVendor(vendorResult);
        }

        fetchVendor();
    }, [props.match.params.vendorID])

    const onBackClick = () => {
        history.goBack();
    }

    const tabs = [
        { key: 'vendorDetails', label: 'Vendor Details' },
        { key: 'images', label: 'Vendor Images' },
        { key: 'vendorUsers', label: 'Vendor Users' },
        { key: 'assignedProducts', label: 'Assigned Products' },
        { key: 'vendorAds', label: 'Vendor Ads' }
    ]

    return (
        <Grid item container spacing={1}>
            <Grid item container spacing={1} direction="row" alignItems="center">
                <Grid item>
                    <IconButton onClick={onBackClick}>
                        <ArrowBack />
                    </IconButton>
                </Grid>
                <Grid item>
                    <Typography variant="h5">Vendor Detail: {vendor?.name || ''}</Typography>
                </Grid>
            </Grid>
            <br />
            <Grid item xs={12}>
                <AppBar position="static" className={classes.mainBar}>
                    <Tabs value={tab} onChange={handleChange} aria-label="simple tabs example">
                        {
                            tabs.map((tab, index) => {
                                return (
                                    <Tab key={tab.key} label={tab.label} {...a11yProps(index)} className={classes.tab} />
                                )
                            })
                        }
                    </Tabs>
                </AppBar>
            </Grid>
            <VendorDetailsTab tab={tab} index={0} vendor={vendor} />
            <VendorImagesTab tab={tab} index={1} vendor={vendor} />
            <VendorUsersTab tab={tab} index={2} vendor={vendor} />
            <VendorProductsTab tab={tab} index={3} vendor={vendor} />
            <VendorAdsTab tab={tab} index={4} vendor={vendor} />
        </Grid>
    )
}

interface VendorProps {
    match: { params: { vendorID } }
}