import React, { useState, useEffect } from 'react';
import { Grid, TextField, Button } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

import useSnackbars from '../../../hooks/useSnackbars';

import * as API from '../../../lib/api';
import { TabPanel } from '../../tabs/TabPanel';

export function CreateNamedGroupingTab(props: CreateNamedGroupingTabProps) {
    const { createSuccessSnack, createErrorSnack } = useSnackbars();
    const [showSeasonsOptions, setShowSeasonsOptions] = useState([30]);
    const [season, setSeason] = useState(30);
    const [name, setName] = useState('');

    const clear = () => {
        setSeason(showSeasonsOptions[0]);
        setName('');
    }

    useEffect(() => {
        clear();

        if (!props.showID) return;

        const fetchShowSeasons = async () => {
            const showSeasonsResult = await API.getShowSeasons({ showID: props.showID });

            let options = Array(20).fill(0).map((_, i) => i + 30);

            const filterOut = showSeasonsResult.filter(showSeason => showSeason.name).map(showSeason => showSeason.season);
            options = options.filter(option => !filterOut.includes(option));

            setShowSeasonsOptions(options);
            setSeason(options[0]);
        }

        fetchShowSeasons();
    }, [props.showID])

    const onSeasonChange = (_, newValue) => {
        setSeason(newValue);
    }

    const addSeasonName = async () => {
        const input = {
            showID: props.showID,
            name,
            season 
        }

        try {
            await API.createShowSeason(input);
            createSuccessSnack('Successfully created named groupign');
        } catch (err) {
            console.error(err);
            createErrorSnack('Failed to add named grouping');
        }
    }

    return (
        <TabPanel value={props.tab} index={props.index} spacing={2}>
            <Grid item xs={3}>
                <Autocomplete id="season-combo-box"
                    options={showSeasonsOptions}
                    getOptionLabel={(option) => option.toString()}
                    value={season}
                    onChange={onSeasonChange}
                    selectOnFocus
                    clearOnBlur
                    freeSolo
                    renderInput={(params) => <TextField {...params} label="Season Number" variant="outlined" />}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField variant="outlined"
                    label="Group Name"
                    placeholder="Group Name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    fullWidth
                />
            </Grid>
            <Grid item container direction="row" xs={12} spacing={2}>
                <Grid item>
                    <Button variant="contained" color="secondary" onClick={addSeasonName}>
                        Add Named Grouping
                    </Button>
                </Grid>
                <Grid item>
                    <Button variant="outlined" onClick={clear}>
                        Cancel
                    </Button>
                </Grid>
            </Grid>
        </TabPanel>
    )
}

export interface CreateNamedGroupingTabProps {
    tab: number
    index: number
    customerID: number
    showID: number
}