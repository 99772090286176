import React, { useState, useEffect, forwardRef } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Grid, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';

import path from 'path';

import useSnackbars from '../../../hooks/useSnackbars';
import * as API from '../../../lib/api';
import { FileUploadButton } from '../../shared/FileUploadButton';
import { TabPanel } from '../../tabs/TabPanel';
import { BannerAdType, CreateVendorAdInput, Maybe, Vendor, VendorAd } from '../../../types';
import { notEmpty, uploadPresignedFile } from '../../../lib/util';

import MaterialTable, { MTableToolbar } from "material-table";
import { Icons } from 'material-table'; // typescript specific import https://github.com/mbrn/material-table/issues/1150

import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';

const useStyles = makeStyles((theme) => ({
    tableToolBar: {
        backgroundColor: '#f9f9f9!important'
    }
}));

const tableIcons: Icons = {
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

interface BannerAdSelect {
    name: string;
    value: string;
}

const bannerAdTypeSelectOptions = Object.entries(BannerAdType).map(type => {
    return { name: type[1].toString(), value: type[1] }
})

export function VendorAdsTab(props: VendorAdsTabProps) {
    const { createSuccessSnack, createErrorSnack } = useSnackbars();
    const classes = useStyles();
    const history = useHistory();
    const [vendorAds, setVendorAds] = useState<Array<VendorAd>>([]);

    const [newAdName, setNewAdName] = useState('');
    const [newAdDescription, setNewAdDescription] = useState('');
    const [newAdURL, setNewAdURL] = useState('');
    const [newAdBannerType, setNewAdBannerType] = useState(bannerAdTypeSelectOptions[0]);
    const [rawFile, setRawFile] = useState<File | null>(null);
    const [adImage, setAdImage] = useState<any>(null);
    const [displayImage, setDisplayImage] = useState(false);
    const [imageError, setImageError] = useState('');
    const [uploading, setUploading] = useState(false);
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        if (!props.vendor) return;

        const fetchVendorAds = async () => {
            const vendorAdsResult = await API.listAdsByVendor({ vendorID: props.vendor!.id });
            const filtered = vendorAdsResult.filter(notEmpty);
            setVendorAds(filtered);
        }

        fetchVendorAds();
    }, [props.vendor])

    const selectFile = ({ target }) => {
        const file = target.files[0];
        if (!file) return;

        setRawFile(file);

        var img = new Image();

        var reader = new FileReader();
        reader.onloadend = function (ended) {
            setAdImage(ended?.target?.result?.toString() || '');

            img.onload = () => {
                console.log('## LOADED:', img);

                if (newAdBannerType.value === BannerAdType.Hero) {
                    if (img.width === 1920 && img.height === 1080) {
                        setDisplayImage(true);
                        setImageError('');
                    } else {
                        setImageError(`Wrong image size. Expected 1920x1080 for hero type, received ${img.width}x${img.height}`);
                        setRawFile(null);
                    }
                } else if (newAdBannerType.value === BannerAdType.Poster) {
                    if (img.width === 1280 && img.height === 200) {
                        setDisplayImage(true);
                        setImageError('');
                    } else {
                        setImageError(`Wrong image size. Expected 1280x200 for poster type, received ${img.width}x${img.height}`);
                        setRawFile(null);
                    }
                } else {
                    setImageError(`Banner type not supported`);
                    setRawFile(null);
                }
            };

            img.src = ended?.target?.result?.toString() || '';
        }
        reader.readAsDataURL(file);
    }

    const createVendorAd = async () => {
        if (!props.vendor || !rawFile) return;

        const fileExtension = path.extname(rawFile.name).toUpperCase();
        const now = new Date().getTime();
        const filename = `VENDOR-AD-${now}${fileExtension}`;

        const key = `VENDORS/${props.vendor.id}/${filename}`;

        let presignedUrl: Maybe<string> = '';

        try {
            presignedUrl = await API.generatePresignedURL({
                operation: 'putObject',
                key: key
            });

            const options = {
                headers: {
                    'Content-Type': rawFile.type
                },
                onUploadProgress: function (progressEvent) {
                    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    setProgress(percentCompleted);
                }
            };

            setUploading(true);
            const uploadResponse = await uploadPresignedFile(presignedUrl, rawFile, options);
            if (uploadResponse.status === 200) {
                setUploading(false);
                setProgress(0);
                setRawFile(null);
            }
        } catch (err) {
            console.error(err);
            createErrorSnack('Failed to upload vendor ad');
            return;
        }

        const bucket = presignedUrl?.split('.')[0].replace('https://', '');

        const input: CreateVendorAdInput = {
            vendorID: props.vendor.id,
            name: newAdName,
            description: newAdDescription,
            url: newAdURL,
            imageBucket: bucket,
            imageKey: key,
            type: newAdBannerType.value
        }

        try {
            const result = await API.createVendorAd(input);
            if (result) {
                createSuccessSnack('Successfully created vendor ad');
                const updated = vendorAds.concat(result);
                setVendorAds(updated);
            } else {
                createErrorSnack('Failed to create vendor ad');
            }
        } catch (err) {
            createErrorSnack('Failed to create vendor ad');
        }
    }

    const editAd = (_, rowData) => {
        history.push(`/vendors/${props.vendor?.id}/ads/${rowData.id}`);
    }

    const onAdTypeChanged = (event, type) => {
        setNewAdBannerType(type);
    }

    const renderTableImage = (rowData) => {
        const imageUrl = `https://s3.dualstack.us-east-2.amazonaws.com/${rowData.imageBucket}/${rowData.imageKey}`;
        let width = "320";
        let height = "50";

        if (rowData.type === BannerAdType.Hero) {
            width = "320";
            height = "180";
        }

        return (
            <img src={imageUrl} width={width} height={height} alt={rowData.url} />
        )
    }

    return (
        <TabPanel value={props.tab} index={props.index} spacing={2} direction="column">
            <Grid item container direction="row" spacing={2}>
                <Grid item>
                    <TextField variant="outlined"
                        label="Ad Name *"
                        value={newAdName}
                        onChange={(e) => setNewAdName(e.target.value)}
                        style={{ width: 200 }}
                    />
                </Grid>
                <Grid item>
                    <TextField variant="outlined"
                        label="Ad Description *"
                        value={newAdDescription}
                        onChange={(e) => setNewAdDescription(e.target.value)}
                        style={{ width: 300 }}
                    />
                </Grid>
                <Grid item>
                    <TextField variant="outlined"
                        label="Ad URL (optional)"
                        placeholder="https://company.com/store"
                        value={newAdURL}
                        onChange={(e) => setNewAdURL(e.target.value)}
                        style={{ width: 300 }}
                    />
                </Grid>
            </Grid>
            <Grid item container direction="row" spacing={2}>
                <Grid item>
                    <Autocomplete id="vendor-ad-type-combo-box"
                        style={{ width: 250 }}
                        options={bannerAdTypeSelectOptions}
                        getOptionLabel={(option: BannerAdSelect) => option.name}
                        value={newAdBannerType}
                        onChange={onAdTypeChanged}
                        renderInput={(params) => <TextField {...params} label="Ad Type" variant="outlined" />}
                    />
                </Grid>
            </Grid>
            <Grid item container direction="row" spacing={2}>
                <Grid item>
                    <FileUploadButton id="vendor-ad-upload"
                        accept="image/*"
                        text="Select File"
                        onChange={selectFile}
                        disabled={newAdName.trim() === '' || newAdDescription.trim() === ''}
                    />
                </Grid>
                <Grid item>
                    <Button variant="contained"
                        color="secondary"
                        onClick={createVendorAd}
                        disabled={(newAdName.trim() === '' || newAdDescription.trim() === '') || !rawFile}
                    >
                        Create Ad
                    </Button>
                </Grid>
            </Grid>
            <Grid item>
                {
                    displayImage && newAdBannerType.value === BannerAdType.Poster &&
                    <img src={adImage} width={640} height={100} />
                }
                {
                    displayImage && newAdBannerType.value === BannerAdType.Hero &&
                    <img src={adImage} width={640} height={360} />
                }
                {
                    imageError !== '' && <Typography>{imageError}</Typography>
                }
            </Grid>
            <Grid item>
                <MaterialTable
                    title="Vendor Ads"
                    icons={tableIcons}
                    columns={[
                        { title: "Image", render: renderTableImage },
                        { title: "Name", field: "name" },
                        { title: "Description", field: "description" },
                        { title: "Ad Type", field: "type" },
                        { title: "URL", field: "url" },
                    ]}
                    data={vendorAds}
                    components={{
                        Toolbar: props => (
                            <div className={classes.tableToolBar}>
                                <MTableToolbar {...props} className={classes.tableToolBar} />
                            </div>
                        )
                    }}
                    options={{
                        search: true,
                        exportButton: false,
                        pageSize: 25,
                        pageSizeOptions: [10, 25, 50, 100],
                        actionsColumnIndex: -1
                    }}
                    actions={[
                        // { icon: (() => (<Edit />)), tooltip: 'Edit Product', onClick: editAd }
                    ]}
                />
            </Grid>
        </TabPanel>
    )
}
export interface VendorAdsTabProps {
    tab: number
    index: number
    vendor: Maybe<Vendor>
}