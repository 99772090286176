import React, { useState, useEffect } from 'react';
import { Grid, Typography, FormControlLabel, Checkbox, TextField, Button } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

import useSnackbars from '../../../hooks/useSnackbars';
import * as API from '../../../lib/api';
import { TabPanel } from '../../tabs/TabPanel';

import { UpdateVendorInput, Maybe, Vendor, User, VendorBusinessType } from '../../../types';

import { format } from 'date-fns';

const formatString = 'MMM dd yyyy'

const businessTypeSelectOptions = Object.entries(VendorBusinessType).map(type => {
    return { name: type[0].toString(), value: type[1] }
});

export function VendorDetailsTab(props: VendorDetailsTabProps) {
    const { createSuccessSnack, createErrorSnack } = useSnackbars();
    const [managedByOptions, setManagedByOptions] = useState<Array<User>>([]);
    const [managedBy, setManagedBy] = useState<Maybe<User>>(null);
    const [vendorName, setVendorName] = useState(props.vendor?.name || '');
    const [active, setActive] = useState(props.vendor?.active || false);
    const [businessType, setBusinessType] = useState(businessTypeSelectOptions.find(opt => opt.value === props.vendor?.type) || businessTypeSelectOptions[0]);
    const [pixelTrackingID] = useState(props.vendor?.pixelTrackingID || '');
    const [added, setAdded] = useState('');

    useEffect(() => {
        if (!props.vendor) return;

        let formatted = format(new Date(props.vendor.createdAt), formatString);
        setAdded(formatted);
        setVendorName(props.vendor.name || '');
        setActive(props.vendor.active);

        setBusinessType(businessTypeSelectOptions.find(opt => opt.value === props.vendor?.type) || businessTypeSelectOptions[0]);

        const fetchVoxiAdmins = async () => {
            const voxiAdminsResult = await API.listVoxiAdmins();
            setManagedByOptions(voxiAdminsResult)
            let manager = voxiAdminsResult.find(user => user.id === props.vendor?.salesOwnedByUserID);
            if (manager) setManagedBy(manager);
        }

        fetchVoxiAdmins();
    }, [props.vendor])

    const updateVendor = async () => {
        if (!props.vendor) return;
        if (!managedBy) return;

        const input: UpdateVendorInput = {
            id: props.vendor.id,
            name: vendorName,
            type: businessType.value,
            active,
            salesOwnedByUserID: managedBy?.id,
            pixelTrackingID
        }

        try {
            await API.updateVendor(input);
            createSuccessSnack('Successfully updated vendor');
        } catch (err) {
            createErrorSnack('Failed to update vendor');
        }
    }

    const onManagedByChange = (event, newValue) => {
        setManagedBy(newValue);
    }

    const onBusinessTypeChange = (event, newValue) => {
        setBusinessType(newValue);
    }

    return (
        <TabPanel value={props.tab} index={props.index} spacing={2} direction="column">
            <Grid item>
                <Typography>Added: {added}</Typography>
            </Grid>
            <Grid item>
                <Autocomplete id="voxi-user-combo-box"
                    options={managedByOptions}
                    getOptionLabel={(user) => `${user.given_name} ${user.family_name}`}
                    value={managedBy}
                    onChange={onManagedByChange}
                    style={{ width: '300px' }}
                    renderInput={(params) => <TextField {...params} label="Managed By" variant="outlined" />}
                />
            </Grid>
            <Grid item>
                <Autocomplete id="business-type-combo-box"
                    options={businessTypeSelectOptions}
                    getOptionLabel={(businessType) => businessType.name}
                    value={businessType}
                    onChange={onBusinessTypeChange}
                    style={{ width: '300px' }}
                    renderInput={(params) => <TextField {...params} label="Business Type" variant="outlined" />}
                />
            </Grid>
            <Grid item>
                <TextField variant="outlined"
                    label="Vendor Name"
                    value={vendorName}
                    onChange={(e) => setVendorName(e.target.value)}
                    style={{ width: 300 }}
                />
            </Grid>
            <Grid item>
                <FormControlLabel
                    control={<Checkbox checked={active} onChange={(e) => setActive(e.target.checked)} name="active" />}
                    label="Active"
                />
            </Grid>
            <Grid item>
                <TextField variant="outlined"
                    label="Pixel Tracking ID"
                    value={pixelTrackingID}
                    style={{ width: 300 }}
                    disabled
                />
            </Grid>
            <Grid item>
                <Button variant="contained" color="secondary" onClick={updateVendor}>
                    Update Vendor
                </Button>
            </Grid>
        </TabPanel>
    )
}
export interface VendorDetailsTabProps {
    tab: number
    index: number
    vendor: Maybe<Vendor>
}