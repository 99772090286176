import React, { useState, useEffect } from 'react';
import { Grid, Card } from '@material-ui/core';

import { Line } from 'react-chartjs-2';

const title = 'Streams by User Device Type';

interface data {
    labels: any[]
    datasets: any[]
}

export function StreamUsersDeviceTypesChart(props: StreamUsersDeviceTypesChartProps) {
    const [streamMetricsData, setstreamMetricsData] = useState<data>({
        labels: [],
        datasets: [{
            label: title,
            backgroundColor: 'rgb(0, 0, 90)',
            borderColor: 'rgb(0, 0, 90)',
            data: []
        }]
    });

    const [options] = useState({
        scales: {
            yAxes: [{
                scaleLabel: {
                    labelString: 'Hits',
                    display: true,
                    fontSize: 16
                }
            }]
        }
    });

    useEffect(() => {
        let rawBandwidthData = props.streamDataByBandwidth;
        if (!rawBandwidthData) return;

        let hitsData: any[] = [];

        let labels = rawBandwidthData.map(entry => {
            hitsData.push(entry.totalhits)
            return entry.streamPart
        })

        const streamData = {
            labels: labels,
            datasets: [{
                label: title,
                backgroundColor: 'rgb(0, 0, 90)',
                borderColor: 'rgb(0, 0, 90)',
                data: hitsData
            }]
        }

        setstreamMetricsData(streamData)
    }, [props.streamDataByBandwidth])

    return (
        <Card>
            <Grid container spacing={2} direction="column" xs>
                <Grid item>
                    <Line data={streamMetricsData} options={options} />
                </Grid>
            </Grid>
        </Card>
    )
}

export interface StreamUsersDeviceTypesChartProps {
    streamDataByBandwidth: any
}