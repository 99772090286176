// Got the recursion from here
// https://medium.com/@modularcoder/reactjs-multi-level-sidebar-navigation-menu-with-typescrip-materialui-251943c12dda

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

import {
    Collapse,
    List,
    ListItem,
    ListItemText,
    ListItemIcon,
    Divider
} from '@material-ui/core';

import IconExpandLess from '@material-ui/icons/ExpandLess'
import IconExpandMore from '@material-ui/icons/ExpandMore'

const useStyles = makeStyles(theme => ({
    menuItemIcon: {
        color: 'white',
        minWidth: '0px'
    },
    menuItem: {
        justifyContent: 'left',
        fontSize: '1.02rem',
        '&:hover': {
            color: 'white'
        }
    },
    divider: {
        color: 'white',
        backgroundColor: 'white'
    }
}));

export function SideBarItem(props: SideBarItemProps) {
    const classes = useStyles();
    const { name, Icon, subMenu = [], link, padding } = props
    const isExpandable = subMenu && subMenu.length > 0
    const [open, setOpen] = useState(false);

    function handleClick() {
        setOpen(!open)
    }

    // Check this because if they aren't admin then we don't display the group.
    // TODO: Clean this up? Make it better? IDK
    const MenuItemRoot = name !== 'empty' ? (
        // TODO: fix this - make it a link if link? is not null
        <ListItem button
            className={classes.menuItem}
            onClick={handleClick}
            // @ts-ignore - not sure why this fails with react router types installed
            component={Link}
            to={link}
        >
            {/* Display an icon if any */}
            {!!Icon && (
                <ListItemIcon className={classes.menuItemIcon}>
                    <Icon />
                </ListItemIcon>
            )}

            <ListItemText primary={name} style={padding ? { paddingLeft: padding } : { paddingLeft: '10px' }} />

            {/* Display the expand menu if the item has children */}
            {isExpandable && !open && <IconExpandMore />}
            {isExpandable && open && <IconExpandLess />}
        </ListItem>
    ) : (<></>)

    const MenuItemChildren = isExpandable ? (
        <Collapse in={open} timeout="auto" unmountOnExit>
            <Divider className={classes.divider} />
            <List>
                {subMenu.map((item, index) => {
                    // TODO: Need a better way of handling this stuff...
                    if (item.name !== 'empty') {
                        return (
                            <SideBarItem {...item} key={index} />
                        )
                    }
                })}
            </List>
        </Collapse>
    ) : null

    return (
        <>
            {MenuItemRoot}
            {MenuItemChildren}
        </>
    )
}

export interface SideBarItemProps {
    name: string
    Icon?: any
    subMenu?: any[]
    link?: string
    padding?: string
}