import { StorageProvider } from '@aws-amplify/storage';
import { AxiosHttpHandler } from '@aws-amplify/storage/lib/providers/axios-http-handler';

import { formatUrl } from '@aws-sdk/util-format-url';
import { createRequest } from '@aws-sdk/util-create-request';
import { Hub, Credentials, Parser, getAmplifyUserAgent } from '@aws-amplify/core';
import { S3Client, GetObjectCommand, ListObjectsCommand } from '@aws-sdk/client-s3';
import { S3RequestPresigner } from '@aws-sdk/s3-request-presigner';

const AMPLIFY_SYMBOL = (typeof Symbol !== 'undefined' && typeof Symbol.for === 'function'
    ? Symbol.for('amplify_default')
    : '@@amplify_default') as Symbol;

const dispatchStorageEvent = (track: boolean, event: string, attrs: any, metrics: any, message: string) => {
    if (track) {
        const data = { attrs };
        if (metrics) {
            data['metrics'] = metrics;
        }
        Hub.dispatch(
            'storage',
            { event, data, message },
            'Storage',
            AMPLIFY_SYMBOL
        );
    }
};

export default class MediaContentStorageProvider implements StorageProvider {
    // category and provider name
    static CATEGORY = 'Storage';
    static PROVIDER_NAME = 'MediaContentStorageProvider';

    /**
     * @private
     */
    private _config;

    /**
     * Configure Storage part with aws configuration
     * @param {Object} config - Configuration of the Storage
     * @return {Object} - Current configuration
     */
    public configure(config?): object {
        if (!config) return this._config;
        const amplifyConfig = Parser.parseMobilehubConfig(config);
        this._config = Object.assign({}, this._config, amplifyConfig.Storage);

        return this._config;
    }

    /**
     * Get a presigned URL of the file or the object data when download:true
     *
     * @param {string} key - key of the object
     * @param {Object} [config] - { level : private|protected|public, download: true|false }
     * @return - A promise resolves to Amazon S3 presigned URL on success
     */
    public async get(key: string, config?): Promise<string> {
        const credentialsOK = await this._ensureCredentials();
        if (!credentialsOK) {
            return Promise.reject('No credentials');
        }

        const opt = Object.assign({}, this._config, config);
        const { bucket, cacheControl, contentDisposition, contentEncoding, contentLanguage, contentType, expires, track } = opt;
        const prefix = this._prefix(opt);
        const final_key = prefix + key;
        const s3 = this._createNewS3Client(opt);

        const params: any = {
            Bucket: bucket,
            Key: final_key,
        };

        // See: https://docs.aws.amazon.com/AWSJavaScriptSDK/latest/AWS/S3.html#getObject-property
        if (cacheControl) params.ResponseCacheControl = cacheControl;
        if (contentDisposition)
            params.ResponseContentDisposition = contentDisposition;
        if (contentEncoding) params.ResponseContentEncoding = contentEncoding;
        if (contentLanguage) params.ResponseContentLanguage = contentLanguage;
        if (contentType) params.ResponseContentType = contentType;

        params.Expires = expires || 900; // Default is 15 mins as defined in V2 AWS SDK

        try {
            const signer = new S3RequestPresigner({ ...s3.config });
            // @ts-ignore
            const request = await createRequest(s3, new GetObjectCommand(params));
            const url = formatUrl(
                (await signer.presign(request, { expiresIn: params.Expires })) as any
            );
            dispatchStorageEvent(
                track,
                'getSignedUrl',
                { method: 'get', result: 'success' },
                null,
                `Signed URL: ${url}`
            );
            return url;
        } catch (error) {
            dispatchStorageEvent(
                track,
                'getSignedUrl',
                { method: 'get', result: 'failed' },
                null,
                `Could not get a signed URL for ${key}`
            );
            throw error;
        }
    }

    // upload storage object
    put(key: string, object, options?): Promise<Object> {
        throw new Error('Not implemented');
    };

    // remove object
    remove(key: string, options?): Promise<any> {
        throw new Error('Not implemented');
    };

    /**
     * List bucket objects relative to the level and prefix specified
     * @param {string} path - the path that contains objects
     * @param {Object} [config] - { level : private|protected|public }
     * @return - Promise resolves to list of keys for all objects in path
     */
    public async list(path, config?): Promise<any> {
        const credentialsOK = await this._ensureCredentials();
        if (!credentialsOK) {
            return Promise.reject('No credentials');
        }

        const opt = Object.assign({}, this._config, config);
        const { bucket, track, maxKeys } = opt;

        const prefix = this._prefix(opt);
        const final_path = prefix + path;
        const s3 = this._createNewS3Client(opt);

        const params = {
            Bucket: bucket,
            Prefix: final_path,
            MaxKeys: maxKeys,
        };

        const listObjectsCommand = new ListObjectsCommand(params);

        try {
            // @ts-ignore
            const response = await s3.send(listObjectsCommand);
            let list: any[] = [];
            if (response && response.Contents) {
                list = response.Contents.map(item => {
                    return {
                        key: item.Key?.substr(prefix.length),
                        eTag: item.ETag,
                        lastModified: item.LastModified,
                        size: item.Size,
                    };
                });
            }
            dispatchStorageEvent(
                track,
                'list',
                { method: 'list', result: 'success' },
                null,
                `${list.length} items returned from list operation`
            );
            return list;
        } catch (error) {
            dispatchStorageEvent(
                track,
                'list',
                { method: 'list', result: 'failed' },
                null,
                `Listing items failed: ${error.message}`
            );
            throw error;
        }
    }

    // return 'Storage';
    getCategory(): string {
        return MediaContentStorageProvider.CATEGORY;
    };

    // return the name of you provider
    getProviderName(): string {
        return MediaContentStorageProvider.PROVIDER_NAME
    };

    /**
     * @private
     */
    private _ensureCredentials() {
        return Credentials.get()
            .then(credentials => {
                if (!credentials) return false;
                const cred = Credentials.shear(credentials);
                this._config.credentials = cred;
                return true;
            })
            .catch(error => {
                console.error(error);
                return false;
            });
    }

    /**
     * @private
     */
    private _prefix(config) {
        const { credentials, level } = config;

        const customPrefix = config.customPrefix || {};
        const identityId = config.identityId || credentials.identityId;
        const privatePath = (
            customPrefix.private !== undefined ? customPrefix.private : 'private/'
        ) + identityId + '/';

        const protectedPath = (
            customPrefix.protected !== undefined
                ? customPrefix.protected
                : 'protected/'
        ) + identityId + '/';

        const publicPath = customPrefix.public !== undefined ? customPrefix.public : 'public/';

        switch (level) {
            case 'private':
                return privatePath;
            case 'protected':
                return protectedPath;
            default:
                return '';
        }
    }

    /**
     * @private creates an S3 client with new V3 aws sdk
     */
    private _createNewS3Client(config, emitter?) {
        const { region, credentials } = config;

        const s3client = new S3Client({
            region,
            credentials,
            customUserAgent: getAmplifyUserAgent(),
            requestHandler: new AxiosHttpHandler({}, emitter),
        });
        return s3client;
    }
}