import React, { useState, useEffect, useCallback } from 'react';
import { Grid, TextField, Button, Checkbox, FormControlLabel } from '@material-ui/core';

import useSnackbars from '../../../hooks/useSnackbars';

import { TabPanel } from '../../tabs/TabPanel';

import * as API from '../../../lib/api';
import { Maybe, Store, UpdateStoreInput } from '../../../types';

export function StoreDetailTab(props: StoreDetailTabProps) {
    const { createSuccessSnack, createErrorSnack } = useSnackbars();
    const [active, setActive] = useState(props.store?.active || false);
    const [name, setName] = useState(props.store?.name || '');
    const [domainName, setDomainName] = useState(props.store?.domainName || '');

    const reset = useCallback(() => {
        setActive(props.store?.active || false);
        setName(props.store?.name || '');
        setDomainName(props.store?.domainName || '');
    }, [props.store])

    useEffect(() => {
        if (!props.store) return;
        reset();
    }, [props.store, reset])

    const updateStore = async () => {
        if (!props.store) return;

        const input: UpdateStoreInput = {
            id: props.store.id,
            customerID: props.customerID,
            name,
            domainName,
            active
        }

        try {
            await API.updateStore(input);
            createSuccessSnack('Successfully updated store');
        } catch (err) {
            console.error(err);
            let errorMessage = 'Failed to update store';
            if (err.errors && err.errors[0]) {
                const error = err.errors[0];
                errorMessage = `${error.errorType || 'Error'}: ${error.message}`
            }

            createErrorSnack(errorMessage);
        }
    }

    return (
        <TabPanel value={props.tab} index={props.index} direction="column" spacing={2}>
            <Grid item xs={2}>
                <FormControlLabel
                    control={<Checkbox checked={active} onChange={(e) => setActive(e.target.checked)} name="active" />}
                    label="Active"
                />
            </Grid>
            <Grid item>
                <TextField variant="outlined"
                    label="Store Name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />
            </Grid>
            <Grid item>
                <TextField variant="outlined"
                    label="Store Root Domain"
                    value={domainName}
                    onChange={(e) => setDomainName(e.target.value)}
                />
            </Grid>
            <Grid item>
                <Button variant="contained" color="secondary" onClick={updateStore}>
                    Update Store
                </Button>
            </Grid>
        </TabPanel>
    )
}

export interface StoreDetailTabProps {
    tab: number
    index: number
    customerID: number
    store: Maybe<Store>
}