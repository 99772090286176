import React from "react";
import { ForgotPassword } from 'aws-amplify-react';
import { IAuthPieceProps } from 'aws-amplify-react/lib-esm/Auth/AuthPiece';
import { I18n } from '@aws-amplify/core';
import { auth } from 'aws-amplify-react/lib-esm/Amplify-UI/data-test-attributes';
import {
    FormSection,
    FormField,
    SectionHeader,
    SectionBody,
    SectionFooter,
    Button,
    Link,
    Input,
    SectionFooterPrimaryContent,
    SectionFooterSecondaryContent
} from 'aws-amplify-react/lib-esm/Amplify-UI/Amplify-UI-Components-React';

class CustomForgotPassword extends ForgotPassword {
    constructor(props: IAuthPieceProps) {
        super(props);
        this._validAuthStates = ['forgotPassword'];
        this.state = { delivery: null };

        // this.submitView = this.submitView.bind(this);
        // this.sendView = this.sendView.bind(this);
    }

    sendView() {
        let theme = this.props.theme;
        return (
            <div>
                <FormField theme={theme}>
                    <Input theme={theme}
                        key="username"
                        name="username"
                        type="email"
                        autoFocus={true}
                        placeholder={I18n.get('Enter your email *')}
                        onChange={this.handleInputChange}
                        data-test={auth.genericAttrs.emailInput}
                    />
                </FormField>
            </div>
        )
    }

    submitView() {
        let theme = this.props.theme;
        return (
            <div>
                <FormField theme={theme}>
                    <Input placeholder={I18n.get('Code *')}
                        theme={theme}
                        key="code"
                        name="code"
                        autoComplete="off"
                        onChange={this.handleInputChange} />
                </FormField>
                <FormField theme={theme}>
                    <Input placeholder={I18n.get('New Password *')}
                        theme={theme}
                        key="password"
                        name="password"
                        type="password"
                        autoComplete="off"
                        onChange={this.handleInputChange} />
                </FormField>
            </div>
        )
    }

    showComponent(theme) {
        const authData = this.props.authData || {};
        return (
            <FormSection theme={theme} data-test={auth.forgotPassword.section} >
                <SectionHeader theme={theme} data-test={auth.forgotPassword.headerSection} >
                    {I18n.get('Reset your password')}
                </SectionHeader>
                <SectionBody theme={theme} >
                    {
                        this.state.delivery || authData.username
                            ? this.submitView()
                            : this.sendView()
                    }
                </SectionBody>
                <SectionFooter>
                    <SectionFooterPrimaryContent theme={theme}>
                        {
                            this.state.delivery || authData.username
                                ? <Button theme={theme} onClick={this.submit} data-test={auth.forgotPassword.submitButton}>
                                    {I18n.get('Submit')}
                                </Button>
                                : <Button theme={theme} onClick={this.send} data-test={auth.forgotPassword.sendCodeButton}>
                                    {I18n.get('Send Code')}
                                </Button>
                        }
                    </SectionFooterPrimaryContent>
                    <SectionFooterSecondaryContent theme={theme}>
                        {
                            this.state.delivery || authData.username
                            ? <Link theme={theme} data-test={auth.forgotPassword.resendCodeLink} onClick={this.send}>
                                {I18n.get('Resend Code')}
                              </Link>
                            : <Link theme={theme} data-test={auth.forgotPassword.backToSignInLink} onClick={() => this.changeState('signIn')}>
                                {I18n.get('Back to Sign In')}
                              </Link>
                        }
                    </SectionFooterSecondaryContent>
                </SectionFooter>
            </FormSection>
        )
    }
}

export default CustomForgotPassword;