import React, { useState, useEffect } from 'react';
import { Grid, Typography, TextField, Button } from '@material-ui/core';

import useSnackbars from '../../../../hooks/useSnackbars';

import { TabPanel } from '../../../tabs/TabPanel';

import { Maybe, Show, UpdateShowAppDataInput } from '../../../../types';
import * as API from '../../../../lib/api';

export function SocialMediaTab(props: SocialMediaTabProps) {
    const { createSuccessSnack, createErrorSnack } = useSnackbars();
    const [facebookAppID, setFacebookAppID] = useState(props.show?.appData?.facebookAppID);
    const [facebookPageURL, setFacebookPageURL] = useState(props.show?.appData?.facebookPageURL);
    const [instagramUsername, setInstagramUsername] = useState(props.show?.appData?.instagramUsername);
    const [twitterUsername, setTwitterUsername] = useState(props.show?.appData?.twitterUsername);

    useEffect(() => {
        setFacebookAppID(props.show?.appData?.facebookAppID);
        setInstagramUsername(props.show?.appData?.instagramUsername);
        setTwitterUsername(props.show?.appData?.twitterUsername);
    }, [props.show])

    const updateSocial = async () => {
        if (!props.show) return;

        const input: UpdateShowAppDataInput = {
            id: props.show.id,
            facebookPageURL,
            facebookAppID,
            instagramUsername,
            twitterUsername
        }

        try {
            await API.updateShowAppData(input);
            createSuccessSnack('Successfully updated social media!');
        } catch (err) {
            console.error(err);
            let errorMessage = 'Product created but product link failed';
            if (err.errors && err.errors[0]) {
                const error = err.errors[0];
                errorMessage = `${error.errorType || 'Error'}: ${error.message}`
            }

            createErrorSnack(errorMessage);
        }
    }

    return (
        <TabPanel value={props.tab} index={props.index} spacing={2} direction="column">
            <Grid item>
                <Typography>Facebook App ID</Typography>
            </Grid>
            <Grid item>
                <TextField variant="outlined"
                    value={facebookAppID}
                    onChange={(e) => setFacebookAppID(e.target.value)}
                />
                <Typography variant="subtitle2">
                    <a href="https://developers.facebook.com/docs/apps/">Facebook App Documentation</a>
                </Typography>
            </Grid>
            <Grid item>
                <Typography>Facebook Page URL</Typography>
            </Grid>
            <Grid item>
                <TextField variant="outlined"
                    value={facebookPageURL}
                    onChange={(e) => setFacebookPageURL(e.target.value)}
                />
            </Grid>
            <Grid item>
                <Typography>Instagram Username</Typography>
            </Grid>
            <Grid item>
                <TextField variant="outlined"
                    value={instagramUsername}
                    onChange={(e) => setInstagramUsername(e.target.value)}
                />
            </Grid>
            <Grid item>
                <Typography>Twitter Username</Typography>
            </Grid>
            <Grid item>
                <TextField variant="outlined"
                    value={twitterUsername}
                    onChange={(e) => setTwitterUsername(e.target.value)}
                />
            </Grid>
            <Grid item>
                <Button variant="contained" color="secondary" onClick={updateSocial}>
                    Update App
                </Button>
            </Grid>
        </TabPanel>
    )
}

export interface SocialMediaTabProps {
    tab: number
    index: number
    show: Maybe<Show>
}