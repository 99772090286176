import React from "react";
import { SignIn } from 'aws-amplify-react';
import { ISignInProps } from 'aws-amplify-react/lib-esm/Auth/SignIn';
import { I18n } from '@aws-amplify/core';
import { auth } from 'aws-amplify-react/lib-esm/Amplify-UI/data-test-attributes';
import {
    FormSection,
    FormField,
    SectionHeader,
    SectionBody,
    SectionFooter,
    Button,
    Link,
    Hint,
    Input,
    SectionFooterPrimaryContent
} from 'aws-amplify-react/lib-esm/Amplify-UI/Amplify-UI-Components-React';

class CustomSignIn extends SignIn {
    constructor(props: ISignInProps) {
        super(props);
        this._validAuthStates = ["signIn", "signedOut", "signedUp"];
    }

    showComponent(theme: any) {
        return (
            <FormSection theme={theme} data-test={auth.signIn.section} >
                <SectionHeader theme={theme} data-test={auth.signIn.headerSection} >
                    <img src={process.env.PUBLIC_URL + '/GetVoxi_White_220x70.png'} alt="voxi logo" />
                </SectionHeader>
                <form onSubmit={this.signIn}>
                    <SectionBody theme={theme}>
                        <FormField theme={theme}>
                            <Input theme={theme}
                                key="username"
                                name="username"
                                type="email"
                                autoFocus={true}
                                placeholder={I18n.get('Enter your email *')}
                                onChange={this.handleInputChange}
                                data-test={auth.genericAttrs.emailInput}
                            />
                        </FormField>
                        <FormField theme={theme}>
                            <Input theme={theme}
                                key="password"
                                type="password"
                                name="password"
                                placeholder={I18n.get('Enter your password *')}
                                onChange={this.handleInputChange}
                                data-test={auth.signIn.passwordInput} />
                            <Hint theme={theme}>
                                {I18n.get('Forget your password? ')}
                                <Link onClick={() => { return this.changeState('forgotPassword') }}
                                    data-test={auth.signIn.forgotPasswordLink}>
                                    {I18n.get('Reset password')}
                                </Link>
                            </Hint>
                        </FormField>
                    </SectionBody>
                    <SectionFooter theme={theme} data-test={auth.signIn.footerSection}>
                        <SectionFooterPrimaryContent theme={theme}>
                            <Button type="submit" disabled={this.state.loading} data-test={auth.signIn.signInButton}>
                                {I18n.get('Sign In')}
                            </Button>
                        </SectionFooterPrimaryContent>
                    </SectionFooter>
                </form>
            </FormSection>
        )
    }
}

export default CustomSignIn;