import React, { useState, useEffect, useReducer } from 'react';
import { Grid, Typography, Tabs, Tab, AppBar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { Maybe, Show as ShowType } from '../../types';
import * as API from '../../lib/api';
import { a11yProps } from '../tabs/TabPanel';

import {
    AddEpisodeTab,
    AddExclusiveContentTab,
    ApplicationSettingsTab,
    ChannelsAppInfoTab,
    CreateNamedGroupingTab,
    ExclusiveVideosTab,
    ShowSearchMetadataTab,
    SeasonsEpisodesTab,
    ShowDetailsTab
} from './showTabs';

import { API as AmplifyAPI, graphqlOperation } from 'aws-amplify';
import { onUpdateShow } from '../../graphql/subscriptions';

const useStyles = makeStyles((theme) => ({
    mainBar: {
        backgroundColor: 'black',
    },
    tab: {
        minWidth: 0
    }
}));

interface ShowState {
    show: Maybe<ShowType>
    loading: boolean
    error: boolean
}

const initialState: ShowState = {
    show: undefined,
    loading: true,
    error: false
}

function reducer(state: ShowState, action: any) {
    switch (action.type) {
        case 'fetchShowSuccess':
            return {
                ...state,
                show: action.show,
                loading: false
            }
        case 'updateShowFromSubscription':
            let toUpdate = state.show;
            toUpdate = Object.assign(toUpdate, action.show);
            return {
                ...state,
                show: toUpdate
            }
        default:
            throw new Error();
    }
}

export function Show(props) {
    const classes = useStyles();
    const [showState, dispatch] = useReducer(reducer, initialState)
    const [tab, setTab] = useState(0);

    const handleChange = (event, newValue) => {
        setTab(newValue);
    };

    useEffect(() => {
        if (props.location && props.location.state && props.location.state.showTab) setTab(props.location.state.showTab);

        const fetchShow = async () => {
            const showResult = await API.getShow({ id: props.match.params.showID });
            dispatch({ type: 'fetchShowSuccess', show: showResult })
        }

        fetchShow();

        // @ts-ignore - for some reason it doesn't like the .subscribe.... 
        const updateShowSubscription = AmplifyAPI.graphql(graphqlOperation(onUpdateShow, { id: props.match.params.showID })).subscribe({
            next: (data) => {
                const updatedShow = data.value.data.onUpdateShow;
                dispatch({ type: 'updateShowFromSubscription', show: updatedShow })
            },
            error: (error) => {
                console.warn(error);
            }
        });

        return function cleanup() {
            // Stop receiving data updates from the subscription
            updateShowSubscription.unsubscribe();
        };
    }, [props.match.params.showID])

    const tabs = [
        { key: 'details', label: 'Details' },
        { key: 'app-settings', label: 'Application Settings' },
        {
            key: 'seasons', label: showState.show?.appData?.videoGroupingNamePlural
                ? `${showState.show.appData?.videoGroupingNamePlural} / Episodes`
                : 'Seasons / Episodes'
        },
        { key: 'add-episode', label: 'Add An Episode' },
        { key: 'exclusives', label: 'Exclusive Videos' },
        { key: 'add-exclusive', label: 'Add Exclusive Video' },
        { key: 'create-grouping', label: 'Create Named Grouping' },
        { key: 'channels-app-info', label: 'Channels / App Info' },
        { key: 'search-metadata', label: 'Search Metadata' },
    ]

    return (
        <Grid container spacing={1}>
            <Grid item>
                <Typography variant="h5">{showState.show?.nameLong || ''}</Typography>
            </Grid>
            <br />
            <Grid item xs={12}>
                <AppBar position="static" className={classes.mainBar}>
                    <Tabs value={tab} onChange={handleChange} aria-label="show tabs" variant="scrollable" scrollButtons="on">
                        {
                            tabs.map((tab, index) => {
                                return (
                                    <Tab key={tab.key} label={tab.label} {...a11yProps(index)} className={classes.tab} />
                                )
                            })
                        }
                    </Tabs>
                </AppBar>
            </Grid>
            <ShowDetailsTab tab={tab} index={0} show={showState.show} />
            <ApplicationSettingsTab tab={tab} index={1} show={showState.show} />
            <SeasonsEpisodesTab tab={tab} index={2} show={showState.show} location={props.location} />
            <AddEpisodeTab tab={tab} index={3} showID={showState.show?.id} customerID={showState.show?.customerID || 0} />
            <ExclusiveVideosTab tab={tab} index={4} show={showState.show} location={props.location} />
            <AddExclusiveContentTab tab={tab} index={5} showID={showState.show?.id} customerID={showState.show?.customerID || 0} />
            <CreateNamedGroupingTab tab={tab} index={6} showID={showState.show?.id} customerID={showState.show?.customerID || 0} />
            <ChannelsAppInfoTab tab={tab} index={7} show={showState.show} />
            <ShowSearchMetadataTab tab={tab} index={8} showID={showState.show?.id} />
        </Grid>
    )
}