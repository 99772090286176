import React, { useState, useEffect } from 'react';
import { Grid, Typography, Button, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { TableContainer, Table, TableHead, TableBody, TableRow, TableCell } from '@material-ui/core';

import DeleteIcon from '@material-ui/icons/Delete';

import useSnackbars from '../../../../hooks/useSnackbars';

import { SelectWithLabel } from '../../../shared/SelectWithLabel';
import { TabPanel } from '../../../tabs/TabPanel';
import * as API from '../../../../lib/api';
import { toSentenceCase } from '../../../../lib/util';

import { Maybe, Show, ShowFontV2, FontLocation, FontFamily, FontWeight, FontStyle } from '../../../../types/api';

const useStyles = makeStyles((theme) => ({
    menuTable: {
        borderTopWidth: 2,
        borderColor: '#f9f9f9',
        borderWidth: 2,
        borderStyle: 'solid'
    }
}));

const fontFamilyOptions = Object.keys(FontFamily).map((fontFamily) => { return { name: toSentenceCase(fontFamily), value: FontFamily[fontFamily] } });
const fontLocationOptions = Object.keys(FontLocation).map((fontLocation) => { return { name: toSentenceCase(fontLocation), value: FontLocation[fontLocation] } });
const fontWeightOptions = Object.keys(FontWeight).map((fontWeight) => { return { name: toSentenceCase(fontWeight), value: FontWeight[fontWeight] } });
const fontStyleOptions = Object.keys(FontStyle).map((fontStyle) => { return { name: toSentenceCase(fontStyle), value: FontStyle[fontStyle] } });

export function FontsTabV2(props: FontsTabProps) {
    const classes = useStyles();
    const { createSuccessSnack, createErrorSnack } = useSnackbars();
    const [showFonts, setShowFonts] = useState<Array<Maybe<ShowFontV2>>>([]);
    
    const [newShowFontLocation, setNewShowFontLocation] = useState(fontLocationOptions[0]);
    const [newShowFontFamily, setNewShowFontFamily] = useState(fontFamilyOptions[0]);
    const [newShowFontWeight, setNewShowFontWeight] = useState(fontWeightOptions[0]);
    const [newShowFontStyle, setNewShowFontStyle] = useState(fontStyleOptions[0]);

    useEffect(() => {
        if (!props.show) return;

        const fetchShowFonts = async () => {
            const showFontsResult = await API.listFontsByShow({ showID: props.show!.id });
            setShowFonts(showFontsResult);
        }

        fetchShowFonts();
    }, [props.show])

    const createShowFont = async () => {
        if (!props.show) return;

        let exists = showFonts.find(font => font?.location === newShowFontLocation.value);
        if (exists) {
            createErrorSnack('Font location already customized. Please remove the previous to add a new one.');
            return;
        }

        let input = {
            showID: props.show.id,
            showFontLocationComposite: `${props.show.id}#${newShowFontLocation.value}`,
            family: newShowFontFamily.value,
            location: newShowFontLocation.value,
            weight: newShowFontWeight.value,
            style: newShowFontStyle.value
        }

        try {
            const showFontResult = await API.createShowFontV2(input);
            if (showFontResult) {
                setShowFonts(showFonts.concat(showFontResult));
                createSuccessSnack('Successfully created font customization');
            } else {
                createErrorSnack('Failed to create font customization');
            }
        } catch (err) {
            console.error(err);
            createErrorSnack('Failed to create font customization');
        }
    }

    const removeShowFont = async (fontID) => {
        if (!props.show) return;

        try {
            await API.deleteShowFontV2({ id: fontID });
            let filteredFonts = showFonts.filter(font => font?.id !== fontID);
            setShowFonts(filteredFonts);
            createSuccessSnack('Successfully removed font customization');
        } catch (err) {
            console.error(err);
            createErrorSnack('Failed to delete font');
        }
    }

    return (
        <TabPanel value={props.tab} index={props.index} spacing={2} direction="column">
            <Grid item>
                <Typography>Font Customizations</Typography>
            </Grid>
            `<Grid item>
                <TableContainer>
                    <Table className={classes.menuTable} size="small" aria-label="show fonts">
                        <TableHead>
                            <TableRow style={{ background: "#f9f9f9" }}>
                                <TableCell align="center">Font Location</TableCell>
                                <TableCell align="center">Font Family</TableCell>
                                <TableCell align="center">Font Weight</TableCell>
                                <TableCell align="center">Font Style</TableCell>
                                <TableCell align="center">Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                showFonts.map((showFont: Maybe<ShowFontV2>, index) => {
                                    return (
                                        <TableRow key={showFont?.id} style={index % 2 ? { background: "#f9f9f9" } : { background: "white" }}>
                                            <TableCell align="center">
                                                { toSentenceCase(Object.keys(FontLocation).find((location => { return FontLocation[location] === showFont?.location }))) }
                                            </TableCell>
                                            <TableCell align="center">
                                                { toSentenceCase(Object.keys(FontFamily).find((font => { return FontFamily[font] === showFont?.family }))) }
                                            </TableCell>
                                            <TableCell align="center">
                                                { toSentenceCase(Object.keys(FontWeight).find((font => { return FontWeight[font] === showFont?.weight }))) }
                                            </TableCell>
                                            <TableCell align="center">
                                                { toSentenceCase(Object.keys(FontStyle).find((font => { return FontStyle[font] === showFont?.style }))) }
                                            </TableCell>
                                            <TableCell align="center">
                                                <IconButton aria-label="delete" color="primary" onClick={(e) => removeShowFont(showFont?.id)}>
                                                    <DeleteIcon />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    )
                                })
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>`
            <br />
            <Grid item container spacing={2} alignItems="flex-end">
                <Grid item xs={12}>
                    <Typography variant="h5">Add A Font Customization</Typography>
                </Grid>
                <Grid item>
                    <SelectWithLabel id="font-item-selector"
                        title="Font Location"
                        value={newShowFontLocation}
                        onChange={(e) => setNewShowFontLocation(e.target.value)}
                        selectOptions={fontLocationOptions}
                    />
                </Grid>
                <Grid item>
                    <SelectWithLabel id="style-item-selector"
                        title="Font Family"
                        value={newShowFontFamily}
                        onChange={(e) => setNewShowFontFamily(e.target.value)}
                        selectOptions={fontFamilyOptions}
                    />
                </Grid>
                <Grid item>
                    <SelectWithLabel id="style-item-selector"
                        title="Font Weight"
                        value={newShowFontWeight}
                        onChange={(e) => setNewShowFontWeight(e.target.value)}
                        selectOptions={fontWeightOptions}
                    />
                </Grid>
                <Grid item>
                    <SelectWithLabel id="style-item-selector"
                        title="Font Style"
                        value={newShowFontStyle}
                        onChange={(e) => setNewShowFontStyle(e.target.value)}
                        selectOptions={fontStyleOptions}
                    />
                </Grid>
            </Grid>
            <Grid item container spacing={2} direction="row">
                <Grid item>
                    <Button variant="contained" color="secondary" onClick={createShowFont}>
                        Add Font Customization
                    </Button>
                </Grid>
                <Grid item>
                    <Button variant="outlined">
                        Cancel
                    </Button>
                </Grid>
            </Grid>
        </TabPanel>
    )
}

export interface FontsTabProps {
    tab: number
    index: number
    show: Maybe<Show>
}