import React from 'react';
import Amplify, { Auth, Storage } from 'aws-amplify';
import { MuiThemeProvider, CssBaseline } from '@material-ui/core';
import { Authenticator, SignIn, SignUp, Greetings, ForgotPassword, RequireNewPassword, AmplifyTheme } from 'aws-amplify-react';
import { IAuthPieceProps } from 'aws-amplify-react/lib-esm/Auth/AuthPiece';

import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

import ResponsiveDrawer from './components/navigation/ResponsiveDrawer'

import CustomSignIn from './components/signin/CustomSignIn';
import CustomRequireNewPassword from './components/signin/CustomRequireNewPassword';
import CustomForgotPassword from './components/signin/CustomForgotPassword';

import './App.css';
import theme from './theme';

import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

import MediaContentStorageProvider from './lib/storageProvider';
import aws_exports from './aws-exports.js';

// Force amplify to send the ID Token instead of Access Token. ID Token has our custom claims
// https://stackoverflow.com/questions/51841050/cognito-custom-claims-missing-with-amplify-but-not-with-appsync-console
aws_exports.API = {
  graphql_headers: async () => {
    try {
      //@ts-ignore
      const token = (await Auth.currentSession()).idToken.jwtToken;
      return { Authorization: token }
    }
    catch (e) {
      console.error(e);
      return {};
      // Potentially you can retrieve it from local storage
    }
  }
}

Amplify.configure(aws_exports);

// add the plugin
Storage.addPluggable(new MediaContentStorageProvider());

// send configuration into Amplify
Storage.configure({
  [MediaContentStorageProvider.PROVIDER_NAME]: aws_exports.Storage[MediaContentStorageProvider.PROVIDER_NAME]
});

const version = process.env.REACT_APP_VERSION || '0.0.1';
const stage = process.env.REACT_APP_STAGE || 'dev';

// No need to run the error stuff for local environment
if (stage !== 'local') {
  Sentry.init({
    dsn: 'https://5107ba3ba488491da1377441e55602f5@o462806.ingest.sentry.io/5495095',
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 0.3, // We recommend adjusting this value in production, or using tracesSampler for finer control
    release: stage !== 'prod' ? `voxi-admin@development-${version}` : `voxi-admin@${version}`,
    environment: stage
  });
}

class App extends React.Component<IAuthPieceProps> {
  signOut = async () => {
    await Auth.signOut();

    // Only using sentry if we aren't running locally
    if (stage !== 'local') Sentry.setUser(null);
  }

  componentDidUpdate() {
    if (stage === 'local' || !this.props.authData || this.props.authState !== 'signedIn') return;

    const user: Sentry.User = {
      email: this.props.authData.attributes?.email || 'unknown',
      id: this.props.authData.attributes?.sub
    }
    Sentry.setUser(user);
  }

  render() {
    if (this.props.authState !== 'signedIn') { return null; }

    return (
      <>
        <MuiThemeProvider theme={theme}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <ResponsiveDrawer theme={theme} authData={this.props.authData} onSignOut={this.signOut} />
          </MuiPickersUtilsProvider>
        </MuiThemeProvider>
      </>
    )
  }
}

const myContainer = Object.assign({}, AmplifyTheme.container,
  {
    flex: 1,
    paddingTop: 0,
    paddingBottom: 0,
    marginBottom: -20,
    marginTop: -100,
    height: "105vh",
    backgroundImage: `url(${process.env.PUBLIC_URL}/signin.jpg)`,
    backgroundPosition: "center",
    backgroundSize: "102%"
  }
);

const AuthTheme = {
  container: myContainer,
  formSection: {
    marginTop: 100,
    background: 'transparent'
  },
  sectionHeader: {
    display: 'block',
    textAlign: 'center',
    background: 'transparent',
    color: 'white'
  },
  inputLabel: {
    color: 'white'
  },
  label: {
    color: 'white'
  }
}

class AppWithAuth extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      container: myContainer
    }

    this.onStateChange = this.onStateChange.bind(this);
  }

  onStateChange(state) {
    switch (state) {
      case 'signedIn':
        this.setState({ container: Object.assign({}, AmplifyTheme.container) })
        break;
      default:
        this.setState({ container: Object.assign({}, myContainer) })
        break;
    }
  }

  render() {
    // @ts-ignore
    AuthTheme.container = this.state.container;

    return (
      <div>
        <CssBaseline />
        <Authenticator
          onStateChange={this.onStateChange}
          hide={[SignIn, SignUp, Greetings, ForgotPassword, RequireNewPassword]}
          theme={AuthTheme}>
          <CustomSignIn />
          <CustomRequireNewPassword />
          <CustomForgotPassword />
          <App />
        </Authenticator>
      </div>
    );
  }
}

export default AppWithAuth;