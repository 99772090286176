import React, { useState, useEffect, useReducer, forwardRef } from 'react';
import { Auth } from 'aws-amplify';
import { API as AmplifyAPI, graphqlOperation } from 'aws-amplify';
import { Button, Grid, Typography, LinearProgress, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';

import useSnackbars from '../../../../hooks/useSnackbars';

import { TabPanel } from '../../../tabs/TabPanel';
import { FileUploadButton } from '../../../shared/FileUploadButton';

import path from 'path';
import { uploadPresignedFile, isVoxiAdmin, notEmpty } from '../../../../lib/util';
import * as API from '../../../../lib/api';
import { BannerAdLinkType, BannerAdType, CreateBannerAdInput, Maybe, Show, VendorAd } from '../../../../types';
import { onUpdateBannerAd } from '../../../../graphql/subscriptions';

import MaterialTable, { MTableToolbar } from "material-table";
import { Icons } from 'material-table'; // typescript specific import https://github.com/mbrn/material-table/issues/1150

import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
    appLogo: {
        maxHeight: '100px',
    },
    loginBackground: {
        maxHeight: '100px',
    },
    input: {
        display: 'none'
    },
    tableToolBar: {
        backgroundColor: '#f9f9f9!important'
    }
}));

const tableIcons: Icons = {
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

interface BannerAdSelect {
    name: string;
    value: string;
}

const initialState = {
    bannerAds: [],
    loading: true,
    error: false
}

function reducer(state, action: any) {
    switch (action.type) {
        case 'fetchBannerAdsSuccess':
            return {
                ...state,
                bannerAds: action.bannerAds,
                loading: false
            }
        case 'createBannerAd':
            return {
                ...state,
                bannerAds: state.bannerAds.concat(action.bannerAd),
            }
        case 'onUpdateBannerAd':
            let current = state.bannerAds.filter(banner => banner.id !== action.bannerAd.id);
            current.push(action.bannerAd);
            return {
                ...state,
                bannerAds: current
            }
        case 'deleteBannerAd':
            let filtered = state.bannerAds.filter(banner => banner.id !== action.id);
            return {
                ...state,
                bannerAds: filtered
            }
        default:
            throw new Error();
    }
}

const bannerAdLinkTypeSelectOptions = Object.entries(BannerAdLinkType).map(type => {
    return { name: type[1].toString(), value: type[1] }
});

export function ImagesTab(props: ImagesTabProps) {
    const classes = useStyles();
    const [state, dispatch] = useReducer(reducer, initialState);
    const { createSuccessSnack, createErrorSnack } = useSnackbars();
    const [appLogo, setAppLogo] = useState(props.show?.appData?.appLogoURL);
    const [progress, setProgress] = useState(0);
    const [uploading, setUploading] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);

    const [vendorAds, setVendorAds] = useState<Array<VendorAd>>([]);
    const [selectedVendorAd, setSelectedVendorAd] = useState<Maybe<VendorAd>>(null);
    const [bannerLinkType, setBannerLinkType] = useState(bannerAdLinkTypeSelectOptions[0]);
    const [bannerAdUrl, setBannerAdUrl] = useState<string | null>(null);
    const [disableCreate, setDisableCreate] = useState(false);

    useEffect(() => {
        setAppLogo(props.show?.appData?.appLogoURL);

        const determineIfAdmin = async () => {
            const user = await Auth.currentAuthenticatedUser();
            let idToken = user.signInUserSession?.idToken;
            let groups = idToken?.payload['cognito:groups'];
            let isSuperAdmin = isVoxiAdmin(groups);
            setIsAdmin(isSuperAdmin);
        }

        const fetchBannerAds = async () => {
            if (!props.show) return;

            const bannerAdsResult = await API.listBannerAdsByShow({ showID: props.show.id });
            dispatch({ type: 'fetchBannerAdsSuccess', bannerAds: bannerAdsResult })
        }

        const fetchVendorAds = async () => {
            const vendorAdsResult = await API.listVendorAds({});
            const filtered = vendorAdsResult.filter(notEmpty).filter(vendorAd => vendorAd.type === BannerAdType.Hero);
            setVendorAds(filtered);
        }

        fetchVendorAds()
        fetchBannerAds();
        determineIfAdmin();

        // @ts-ignore - for some reason it doesn't like the .subscribe.... TODO: Fix this because it doesn't work for content admins
        const updateBannerAdSubscription = AmplifyAPI.graphql(graphqlOperation(onUpdateBannerAd)).subscribe({
            next: (provider) => {
                const updatedBanner = provider.value.data.onUpdateBannerAd;
                if (updatedBanner) dispatch({ type: 'onUpdateBannerAd', bannerAd: updatedBanner });
            },
            error: (error) => {
                console.error(error);
            }
        });

        return function cleanup() {
            // Stop receiving data updates from the subscription
            updateBannerAdSubscription.unsubscribe();
        };
    }, [props.show])

    const updateAppLogo = async ({ target }) => {
        if (!props.show) return;

        const file = target.files[0];
        const fileExtension = path.extname(file.name).toUpperCase();

        const now = new Date().getTime();
        const filename = `LOGO-${now}${fileExtension}`;

        const key = `${props.show.customerID}/SHOW/${props.show.id}/${filename}`;

        const presignedUrl = await API.generatePresignedURL({
            operation: 'putObject',
            key: key
        });

        const options = {
            headers: {
                'Content-Type': file.type
            },
            onUploadProgress: function (progressEvent) {
                const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                setProgress(percentCompleted);
            }
        };

        setUploading(true);
        const uploadResponse = await uploadPresignedFile(presignedUrl, file, options);
        if (uploadResponse.status === 200) {
            const newAppLogoUrl = await API.updateShowAppLogo({
                showID: props.show.id,
                customerID: props.show.customerID,
                newFilename: filename
            })

            setAppLogo(newAppLogoUrl);
        }
        setUploading(false);
        setProgress(0);
    }

    const createShowAd = async () => {
        if (!props.show) return;

        if (bannerLinkType.value !== "VENDOR" || !selectedVendorAd) {
            createErrorSnack('URL type ads currently not supported');
            return;
        }

        const input: CreateBannerAdInput = {
            customerID: props.show.customerID,
            showID: props.show.id,
            linkType: bannerLinkType.value,
            type: BannerAdType.Hero,
            bannerAdVendorAdId: selectedVendorAd?.id
        }

        try {
            const createResult = await API.createBannerAd(input);
            if (createResult) {
                dispatch({ type: 'createBannerAd', bannerAd: createResult })
                createSuccessSnack('Successfully created show hero ad.')
            } else {
                createErrorSnack('Failed to create show hero ad.');
            }
        } catch (err) {
            console.error(err);
            createErrorSnack('Failed to create show hero ad.');
        }
    }

    const onAdChanged = (event, ad) => {
        setSelectedVendorAd(ad);
    }

    const onLinkTypeChanged = (event, linkType) => {
        setBannerLinkType(linkType);
    }

    const renderTableImage = (rowData) => {
        const imageUrl = rowData.vendorAd
            ? `https://s3.dualstack.us-east-2.amazonaws.com/${rowData.vendorAd.imageBucket}/${rowData.vendorAd.imageKey}`
            : `https://s3.dualstack.us-east-2.amazonaws.com/${rowData.imageBucket}/${rowData.imageKey}`;

        let width = "320";
        let height = "50";

        if (rowData.type === BannerAdType.Hero) {
            width = "320";
            height = "180";
        }

        return (
            <img src={imageUrl} width={width} height={height} alt={rowData.url} />
        )
    }

    const deleteShowAd = async (_, rowData) => {
        console.log('## DELETING:', rowData);

        try {
            const deleteResult = await API.deleteBannerAd({ id: rowData.id });
            if (deleteResult) {
                dispatch({ type: 'deleteBannerAd', id: deleteResult.id });
                createSuccessSnack('Successfully removed show hero ad')
            } else {
                createSuccessSnack('Failed to delete show hero ad');
            }
        } catch (err) {
            createErrorSnack('Failed to delete show hero ad');
        }
    }

    return (
        <TabPanel value={props.tab} index={props.index} spacing={3} direction="row">
            {
                uploading &&
                <Grid item xs={12}>
                    <LinearProgress variant="determinate" value={progress} color="secondary" />
                </Grid>
            }
            <Grid item container xs={4} direction="column" spacing={2} alignContent="center" alignItems="center">
                <Grid item>
                    <Typography variant="h5">App Logo</Typography>
                </Grid>
                <Grid item>
                    <img src={appLogo || ''} className={classes.appLogo} alt="app logo" />
                </Grid>
                <Grid item>
                    <FileUploadButton id="app-logo-upload-button"
                        accept="image/*"
                        onChange={updateAppLogo}
                        text="Add/Change App Logo"
                    />
                </Grid>
            </Grid>
            <Grid item container xs direction="column" spacing={2} alignContent="center" alignItems="center" justify="center">
                <Grid item>
                    <Typography variant="h5">Show Banner Ads</Typography>
                </Grid>
                {
                    state.bannerAds.length < 3 && isAdmin &&
                    <Grid item container spacing={1} direction="row" justify="center">
                        <Grid item>
                            <Autocomplete id="vendor-ad-link-type-combo-box"
                                options={bannerAdLinkTypeSelectOptions}
                                style={{ width: 200 }}
                                getOptionLabel={(option: BannerAdSelect) => option.name}
                                value={bannerLinkType}
                                onChange={onLinkTypeChanged}
                                renderInput={(params) => <TextField {...params} label="Link Type" variant="outlined" />}
                            />
                        </Grid>
                        {
                            bannerLinkType.value === "URL" &&
                            <>
                                <Grid item>
                                    <TextField id="banner-ad-url"
                                        variant="outlined"
                                        label="Banner URL"
                                        placeholder="https://company.com/store"
                                        value={bannerAdUrl}
                                        style={{ width: 350 }}
                                        onChange={(e) => setBannerAdUrl(e.target.value)}
                                    />
                                </Grid>
                                <Grid item>
                                    <Typography>Preview</Typography>
                                    <img src={bannerAdUrl ?? ''}
                                        style={{ maxWidth: 320, maxHeight: 180 }}
                                    />
                                </Grid>
                            </>
                        }
                        {
                            bannerLinkType.value === "VENDOR" &&
                            <>
                                <Grid item>
                                    <Autocomplete id="vendor-ads-combo-box"
                                        options={vendorAds}
                                        style={{ width: 350 }}
                                        getOptionLabel={(option: VendorAd) => `${option.name} - ${option.description}`}
                                        value={selectedVendorAd}
                                        onChange={onAdChanged}
                                        renderInput={(params) => <TextField {...params} label="Vendor Ad" variant="outlined" />}
                                    />
                                </Grid>
                                <Grid item>
                                    <Typography>Preview</Typography>
                                    <img src={`https://s3.dualstack.us-east-2.amazonaws.com/${selectedVendorAd?.imageBucket}/${selectedVendorAd?.imageKey}`}
                                        style={{ maxWidth: 320, maxHeight: 180 }}
                                    />
                                </Grid>
                            </>
                        }
                        <Grid item>
                            <Button variant="contained" color="secondary" onClick={createShowAd}>
                                Create Category Ad
                            </Button>
                        </Grid>
                    </Grid>
                }
                <Grid item>
                    <MaterialTable
                        title="Vendor Ads"
                        icons={tableIcons}
                        columns={[
                            { title: "Image", render: renderTableImage },
                            { title: "Link Type", field: "linkType" },
                        ]}
                        data={state.bannerAds}
                        components={{
                            Toolbar: props => (
                                <div className={classes.tableToolBar}>
                                    <MTableToolbar {...props} className={classes.tableToolBar} />
                                </div>
                            )
                        }}
                        options={{
                            search: true,
                            exportButton: false,
                            pageSize: 5,
                            pageSizeOptions: [5, 10, 25, 50],
                            actionsColumnIndex: -1
                        }}
                        actions={[
                            { icon: (() => (<DeleteOutline />)), tooltip: 'Delete Ad', onClick: deleteShowAd }
                        ]}
                    />
                </Grid>
            </Grid>
        </TabPanel>
    )
}

export interface ImagesTabProps {
    tab: number
    index: number
    show: Maybe<Show>
}