import React, { useState, useEffect } from 'react';
import { Grid, IconButton, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import ArrowBack from '@material-ui/icons/ArrowBack';

import * as API from '../../lib/api';

import { User as UserModel, Maybe } from '../../types';

export function User(props: UserProps) {
    const history = useHistory();
    const [user, setUser] = useState<Maybe<UserModel>>();

    useEffect(() => {
        const fetchUser = async () => {
            const userResult = await API.getUser({ id: props.match.params.userID });
            setUser(userResult);
        }

        fetchUser();
    }, [props.match.params.userID])

    const onBackClick = () => {
        history.goBack();
    }

    return (
        <Grid container spacing={2} direction="column">
            <Grid item container spacing={1} direction="row" alignItems="center">
                <Grid item>
                    <IconButton onClick={onBackClick}>
                        <ArrowBack />
                    </IconButton>
                </Grid>
                <Grid item>
                    <Typography variant="h5">User: {user?.given_name} {user?.family_name}</Typography>
                </Grid>
            </Grid>
        </Grid>
    )
}

export interface UserProps {
    match: { params: { userID } }
}