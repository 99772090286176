import React, { useState } from 'react';
import { Grid, TextField, Button, Typography, Divider, Box, Link } from '@material-ui/core';
import { CircularProgress } from '@material-ui/core';

import { TabPanel } from '../../tabs/TabPanel';
import * as API from '../../../lib/api';

import { WalmartProduct as WalmartProductType } from '../../../types/api';

export function WalmartProductLookupTab(props: WalmartProductLookupTabProps) {
    const [searchText, setSearchText] = useState('');
    const [products, setProducts] = useState<WalmartProductType[]>([]);
    const [searching, setSearching] = useState(false);

    const searchWalmart = async () => {
        setSearching(true);
        const walmartProducts = await API.walmartSearch({ query: searchText });
        setProducts(walmartProducts);
        setSearching(false);
    }

    return (
        <TabPanel value={props.tab} index={props.index} spacing={2} direction="column">
            <Grid item container spacing={2} direction="row">
                <Grid item xs={6}>
                    <TextField variant="outlined"
                        label="Search"
                        placeholder="Walmart Product Search"
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={2}>
                    <Button variant="contained" color="secondary" onClick={searchWalmart}>
                        Search
                    </Button>
                </Grid>
                {
                    searching &&
                    <Grid item xs>
                        <CircularProgress />
                    </Grid>
                }
            </Grid>
            <Grid item container spacing={2} direction="column">
                {
                    products.map((product, index) => {
                        return (
                            <>
                                <WalmartProduct key={product.id} product={product} selected={props.onProductSelected} />
                                <Divider key={index} />
                            </>
                        )
                    })
                }
            </Grid>
        </TabPanel>
    )
}

export interface WalmartProductLookupTabProps {
    tab: number
    index: number
    onProductSelected: Function
}

function WalmartProduct(props: WalmartProductProps) {
    return (
        <Grid item container spacing={5} direction="row" xs={12}>
            <Grid item container spacing={1} direction="column" xs={2} alignItems="center" alignContent="center">
                <Grid item>
                    <img src={props.product.imageURL || ''} alt={props.product.name} width="180" height="180" />
                </Grid>
                <Grid item>
                    <Typography variant="caption">
                        <Box fontWeight="fontWeightBold">UPC:</Box>
                        <Link component="button" underline="always" color="secondary" onClick={(e) => props.selected(props.product)}>{props.product.upc}</Link>
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="caption">
                        <Box fontWeight="fontWeightBold">Product Link:</Box> <a href={props.product.productURL || ''}>Walmart</a>
                    </Typography>
                </Grid>
            </Grid>
            <Grid item container spacing={1} direction="column" xs>
                <Grid item>
                    <Link component="button"
                        variant="h6"
                        underline="always"
                        color="secondary"
                        onClick={() => props.selected(props.product)}
                    >
                        Select: {props.product.name}
                    </Link>
                </Grid>
                <Grid item>
                    <Typography variant="subtitle2">
                        <Box fontWeight="fontWeightBold">Manufacturer Part Number:</Box> {props.product.modelNumber}
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="subtitle2">
                        <Box fontWeight="fontWeightBold">Category:</Box> {props.product.category}
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="subtitle2">
                        <Box fontWeight="fontWeightBold">Description:</Box> {props.product.description}
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    )
}

interface WalmartProductProps {
    product: WalmartProductType
    selected: Function
}