import React, { useState, useEffect } from 'react';
import { Grid, Paper, Divider, Select, MenuItem, InputLabel, Button, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import * as API from '../../lib/api';

import { 
    StreamMetricsByDateChart, 
    StreamUsersByBandwidthChart, 
    StreamUsersDeviceTypesChart, 
} from './charts';

import { RangeSelect } from '../shared/RangeSelect';

import { subDays } from 'date-fns';

import { Maybe, Show, Episode, EpisodeReport as EpisodeReportModel } from '../../types';

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2)
    },
}));

export function EpisodeReport(props: EpisodeReportProps) {
    const classes = useStyles();
    const [shows, setShows] = useState(props.shows);
    const [selectedShow, setSelectedShow] = useState(1);
    const [episodes, setEpisodes] = useState<Array<Episode>>([]);
    const [selectedEpisode, setSelectedEpisode] = useState(1);
    const [episodeReport, setEpisodeReport] = useState<Maybe<EpisodeReportModel>>();
    const [fromDate, setFromDate] = useState(subDays(new Date(), 7));
    const [toDate, setToDate] = useState(new Date());
    const [loading, setLoading] = useState(false);

    const fetchEpisodeReport = async (showID: number, episodeID: number) => {
        setLoading(true);
        let queryArgs = {
            showID: showID,
            episodeID: episodeID,
            fromDate: fromDate.toISOString(),
            toDate: toDate.toISOString()
        }
        const episodeReportResult = await API.getEpisodeReport(queryArgs);
        setEpisodeReport(episodeReportResult);
        setLoading(false);
    }

    const fetchEpisodes = async (showID: number) => {
        const episodesResult = await API.listEpisodes({ showID: showID });
        setEpisodes(episodesResult);

        if (episodesResult && episodesResult[0] && episodesResult[0].id) setSelectedEpisode(episodesResult[0].id)
    }

    const onSelectShowChange = async (event) => {
        const showID = event.target.value;
        setSelectedShow(showID);

        await fetchEpisodes(showID);
    }

    const onSelectEpisodeChange = async (event) => {
        const episodeID = event.target.value;
        setSelectedEpisode(episodeID);
    }

    const generateReport = async () => {
        await fetchEpisodeReport(selectedShow, selectedEpisode)
    }

    const handleDateChange = async (from, to) => {
        setFromDate(from);
        setToDate(to);
    };

    useEffect(() => {
        setShows(props.shows);
        if (props.shows && props.shows.length > 0) {
            setSelectedShow(props.shows[0].id);
            fetchEpisodes(props.shows[0].id);
        }
    }, [props.shows])

    return (
        <Grid container spacing={2}>
            <Grid item xs>
                <Paper className={classes.paper}>
                    <Grid container spacing={2} direction="row">
                        <Grid item container direction="column" spacing={2}>
                            <Grid item>
                                <InputLabel id="report-show-select">Show</InputLabel>
                                <Select labelId="report-show-select"
                                    value={selectedShow}
                                    onChange={onSelectShowChange}
                                >
                                    {
                                        shows.map(show => {
                                            return (
                                                <MenuItem key={show.id} value={show.id}>{show.nameShort}</MenuItem>
                                            )
                                        })
                                    }

                                </Select>
                            </Grid>
                            <Grid item>
                                <InputLabel id="report-episode-select">Episode</InputLabel>
                                <Select labelId="report-episode-select"
                                    value={selectedEpisode}
                                    onChange={onSelectEpisodeChange}
                                >
                                    {
                                        episodes.map(episode => {
                                            return (
                                                <MenuItem key={episode.id} value={episode.id}>{episode.name}</MenuItem>
                                            )
                                        })
                                    }

                                </Select>
                            </Grid>
                        </Grid>
                        <Grid item container direction="column" spacing={2}>
                            <RangeSelect handleSelectChange={handleDateChange} onApplyClicked={handleDateChange} />
                        </Grid>

                        <Divider />
                        <Grid item>
                            <Button variant="outlined" onClick={generateReport}>
                                Generate Report
                            </Button>
                        </Grid>
                        {
                            loading &&
                            <Grid item>
                                <CircularProgress />
                            </Grid>
                        }
                    </Grid>
                </Paper>
            </Grid>
            <Grid item xs={12}>
                <StreamMetricsByDateChart dateStreams={episodeReport?.dateStreams} />
            </Grid>
            <Grid item sm={6}>
                <StreamUsersByBandwidthChart streamDataByBandwidth={episodeReport?.streamDataByBandwidth} />
            </Grid>
            <Grid item sm={6}>
                <StreamUsersDeviceTypesChart streamDataByBandwidth={episodeReport?.streamDataByBandwidth} />
            </Grid>
        </Grid>
    )
}

export interface EpisodeReportProps {
    authData: any
    shows: Array<Show>
}