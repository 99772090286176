import React from 'react';
import { InputLabel, Select, MenuItem } from '@material-ui/core';

export function SelectWithLabel({ id, title, value, onChange, selectOptions }: SelectWithLabelProps) {
    return (
        <>
            <InputLabel id={id}>{title}</InputLabel>
            <Select labelId={id}
                id={`${id}-select`}
                value={value}
                onChange={onChange}
                variant="outlined"
                margin='dense'
            >
                {
                    selectOptions.map((option) => {
                        return (
                            <MenuItem key={option.name} value={option}>{option.name}</MenuItem>
                        )
                    })
                }
            </Select>
        </>
    )
}

export interface SelectWithLabelProps {
    id: string
    title: string
    value: any
    onChange: Function | any
    selectOptions: any[]
}