import React, { useState, useEffect } from 'react';
import { Grid, Typography, Tabs, Tab, AppBar, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';

import ArrowBack from '@material-ui/icons/ArrowBack';

import { a11yProps } from '../tabs/TabPanel';
import * as API from '../../lib/api';

import { StoreAdminTab, StoreDetailTab, StoreProductsTab } from './storeTabs';

import { Maybe, Store as StoreType } from '../../types/api';

const useStyles = makeStyles((theme) => ({
    mainBar: {
        backgroundColor: 'black',
    },
    tab: {
        minWidth: 0
    }
}));

export function Store(props: StoreProps) {
    const classes = useStyles();
    const history = useHistory();
    const [tab, setTab] = useState(0);
    const [store, setStore] = useState<Maybe<StoreType>>();

    const handleChange = (event, newValue) => {
        setTab(newValue);
    };

    useEffect(() => {
        const fetchStore = async () => {
            const storeResult = await API.getStore({ id: props.match.params.storeID });
            setStore(storeResult);
        }

        fetchStore();
    }, [props.customerID, props.match.params.storeID])

    const tabs = [
        { key: 'storeDetail', label: 'Store Detail', disabled: false },
        { key: 'storeProducts', label: 'Store Products', disabled: false },
        { key: 'storeAdmin', label: 'Store Admin', disabled: true },
    ]

    const onBackClick = () => {
        history.goBack();
    }

    return (
        <Grid container spacing={1}>
            <Grid item container spacing={1} direction="row" alignItems="center">
                <Grid item>
                    <IconButton onClick={onBackClick}>
                        <ArrowBack />
                    </IconButton>
                </Grid>
                <Grid item>
                    <Typography variant="h5">Store Detail: {store?.name}</Typography>
                </Grid>
            </Grid>
            <br />
            <Grid item xs={12}>
                <AppBar position="static" className={classes.mainBar}>
                    <Tabs value={tab} onChange={handleChange} aria-label="product tabs">
                        {
                            tabs.map((tab, index) => {
                                return (
                                    <Tab key={tab.key} label={tab.label} {...a11yProps(index)} className={classes.tab} disabled={tab.disabled} />
                                )
                            })
                        }
                    </Tabs>
                </AppBar>
            </Grid>
            <StoreDetailTab tab={tab} index={0} customerID={props.customerID} store={store} />
            <StoreProductsTab tab={tab} index={1} customerID={props.customerID} store={store} />
            <StoreAdminTab tab={tab} index={2} customerID={props.customerID} store={store} />
        </Grid>
    )
}

interface StoreProps {
    customerID: number
    match: { params: { storeID } }
}