import React, { useState, useEffect } from 'react';
import { Grid, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import {
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell
} from '@material-ui/core';

import * as API from '../../../lib/api';

import { DashboardTopEpisode } from '../../../types';

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
        // width: '320px'
    },
    episodesCell: {
        borderBottom: 'none'
    }
}));

export function TopEpisodeViewsCard(props: TopEpisodeViewsCardProps) {
    const classes = useStyles();
    const [topEpisodes, setTopEpisodes] = useState<Array<DashboardTopEpisode>>([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (!props.customerID) return;

        const fetchTopEpisodes = async () => {
            setLoading(true);

            const queryArgs = {
                customerID: props.customerID,
                fromDate: props.dates.fromDate.toISOString(),
                toDate: props.dates.toDate.toISOString()
            }
            const topTenEpisodesResult = await API.getDashboardTopTenEpisodes(queryArgs);
            setTopEpisodes(topTenEpisodesResult);

            setLoading(false);
        }

        fetchTopEpisodes();
    }, [props.customerID, props.dates])

    const handleImageFail = (event, failover) => {
        event.target.src = failover
    }

    return (
        <Paper className={classes.paper}>
            <Grid container spacing={2} direction="column">
                <Grid item>
                    <Typography>Top {topEpisodes.length} Episode Views</Typography>
                </Grid>
                <Grid item>
                    <TableContainer>
                        <Table size="small" aria-label="seasons">
                            <TableHead>
                                <TableRow>
                                    <TableCell className={classes.episodesCell} align="left">Thumbnail</TableCell>
                                    <TableCell className={classes.episodesCell} align="left">Show / Episode</TableCell>
                                    <TableCell className={classes.episodesCell} align="right">Views</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    topEpisodes && topEpisodes.length === 0
                                        ? <TableRow>
                                            <TableCell className={classes.episodesCell} align="left">No views for this date range.</TableCell>
                                        </TableRow>
                                        : topEpisodes.map((episode: DashboardTopEpisode, index) => {
                                            const episodeThumbnail = episode.EpisodePoster?.replace('ORIGINAL', '480X270') || '';
                                            const alt = episodeThumbnail.replace('PNG', 'JPG');
                                            return (
                                                <TableRow key={episode.EpisodeId} style={index % 2 ? { background: "#f9f9f9" } : { background: "white" }}>
                                                    <TableCell align="left">
                                                        <img src={episodeThumbnail}
                                                            alt={episode.ShowNameShort}
                                                            width="auto"
                                                            height="75"
                                                            onError={(e) => handleImageFail(e, alt)}
                                                        />
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        <Typography>{episode.ShowNameShort}</Typography>
                                                        <Typography variant="caption">
                                                            S{episode.Season}E{episode.EpisodeNo} - {episode.EpisodeName}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        {episode.totalViews || 0}
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        })
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        </Paper>
    )
}

export interface TopEpisodeViewsCardProps {
    customerID: number
    dates: { fromDate: Date, toDate: Date }
}