import React, { useState, useEffect } from 'react';
import { Grid, FormControlLabel, Button, Checkbox, TextField, LinearProgress, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { DatePicker } from '@material-ui/pickers';

import { format } from 'date-fns'
import path from 'path';

import useSnackbars from '../../../../hooks/useSnackbars';
import * as API from '../../../../lib/api';
import { TabPanel } from '../../../tabs/TabPanel';

import { uploadPresignedFile } from '../../../../lib/util';
import { FileUploadButton } from '../../../shared/FileUploadButton';

import { ExclusiveVideoContent, Maybe, Show } from '../../../../types';

const useStyles = makeStyles((theme) => ({
    divider: {
        color: 'white',
        backgroundColor: 'white'
    },
    poster: {
        maxHeight: '250px'
    }
}));

export function GeneralInfoTab(props: GeneralInfoTabProps) {
    const classes = useStyles();
    const { createSuccessSnack, createErrorSnack } = useSnackbars();
    const [name, setName] = useState(props.exclusive?.name || '');
    const [enabled, setEnabled] = useState(props.exclusive?.enabled || false);
    const [enabledForApp, setEnabledForApp] = useState(props.exclusive?.releaseToApp || false);
    const [enabledForOTT, setEnabledForOTT] = useState(props.exclusive?.releaseToOTT || false);
    const [firstRelease, setFirstRelease] = useState(props.exclusive?.firstRelease && format(new Date(props.exclusive.firstRelease), 'MM/dd/yyyy'));
    const [duration, setDuration] = useState(props.exclusive?.duration || 0);
    const [description, setDescription] = useState(props.exclusive?.description || '');
    const [disableProductScroller, setDisableProductScroller] = useState(props.exclusive?.disableProductScroller ?? false);
    const [posterURL, setPosterURL] = useState('');
    const [uploading, setUploading] = useState(false);
    const [progress, setProgress] = useState(0);

    const reset = () => {
        setName(props.exclusive?.name || '');
        setEnabled(props.exclusive?.enabled || false);
        setEnabledForApp(props.exclusive?.releaseToApp || false);
        setEnabledForOTT(props.exclusive?.releaseToOTT || false);
        setFirstRelease(props.exclusive?.firstRelease && format(new Date(props.exclusive?.firstRelease), 'MM/dd/yyyy'));
        setDuration(props.exclusive?.duration || 0);
        setDescription(props.exclusive?.description || '');
        setDisableProductScroller(props.exclusive?.disableProductScroller ?? false);

        const posterURL = `https://s3.dualstack.us-east-2.amazonaws.com/${props.exclusive?.posterBucket}/${props.exclusive?.posterKey}`
        setPosterURL(posterURL);
    }

    useEffect(() => {
        reset();
    }, [props.exclusive])

    const updateExclusivePoster = async ({ target }) => {
        const file = target.files[0];
        if (!file || !props.exclusive) return;

        const fileExtension = path.extname(file.name).toUpperCase();

        const now = new Date().getTime()
        const filename = `EXCLUSIVEPOSTER-${props.show.customerID}-${props.exclusive.legacyShowID}-${now}${fileExtension}`;
        const presignedUrl = await API.generatePresignedRawMediaURL({
            operation: 'putObject',
            key: filename,
            metadata: [
                { name: 'id', value: props.exclusive.id }
            ]
        });

        const options = {
            headers: {
                'Content-Type': file.type
            },
            onUploadProgress: function (progressEvent) {
                const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                setProgress(percentCompleted);
            }
        };

        setUploading(true);
        try {
            await uploadPresignedFile(presignedUrl, file, options);
            createSuccessSnack('Successfully uploaded photo. Photo is being converted now.');
        } catch (err) {
            createErrorSnack('Failed to upload photo');
        }

        setUploading(false);
        setProgress(0);
    }

    const onChangeReleaseDate = (date) => {
        setFirstRelease(date);
    }

    const updateExclusive = async () => {
        if (!props.exclusive) return;

        const input = {
            id: props.exclusive.id,
            firstRelease: new Date(firstRelease || '').toISOString(),
            name,
            description,
            enabled,
            duration,
            disableProductScroller,
            releaseToApp: enabledForApp,
            releaseToOTT: enabledForOTT
        }

        try {
            await API.updateExclusiveVideoContent(input);
            createSuccessSnack('Successfully updated exclusive');
        } catch (err) {
            console.error(err);
            createErrorSnack('Failed to update exclusive');
        }
    }

    return (
        <TabPanel value={props.tab} index={props.index} direction="row" spacing={1}>
            {
                uploading &&
                <Grid item xs={12}>
                    <LinearProgress variant="determinate" value={progress} color="secondary" />
                </Grid>
            }
            <Grid item container spacing={2} direction="column" xs={6} alignItems="center">
                <Grid item>
                    <img src={posterURL} className={classes.poster} alt={`${name} poster`} />
                </Grid>
                <Grid item>
                    <FileUploadButton id="exclusive-poster-upload-button"
                        accept="image/*"
                        onChange={updateExclusivePoster}
                        text="Add/Change Exclusive Poster"
                    />
                </Grid>
            </Grid>
            <Grid item container spacing={2} direction="column" xs={6}>
                <Grid item container direction="row">
                    <Grid item>
                        <FormControlLabel
                            control={<Checkbox checked={enabled} onChange={(e) => setEnabled(e.target.checked)} name="enabled" />}
                            label="Enabled"
                        />
                    </Grid>
                    <Grid item>
                        <FormControlLabel
                            control={<Checkbox checked={enabledForApp} onChange={(e) => setEnabledForApp(e.target.checked)} name="enabledForApp" />}
                            label="Enable for App"
                        />
                    </Grid>
                    <Grid item>
                        <FormControlLabel
                            control={<Checkbox checked={enabledForOTT} onChange={(e) => setEnabledForOTT(e.target.checked)} name="enabledForOTT" />}
                            label="Enable for OTT"
                        />
                    </Grid>
                </Grid>
                <Grid item container spacing={2} direction="row">
                    <Grid item>
                        <Typography>ID: {props.exclusive?.id}</Typography>
                    </Grid>
                </Grid>
                <Grid item container spacing={2} direction="row">
                    <Grid item>
                        <DatePicker label="Realease Date"
                            format="MM/dd/yyyy"
                            value={firstRelease}
                            onChange={onChangeReleaseDate}
                            variant="inline"
                            inputVariant="outlined"
                        />
                    </Grid>
                    <Grid item>
                        <TextField variant="outlined"
                            label="Runtime"
                            value={duration}
                            onChange={(e) => setDuration(parseInt(e.target.value))}
                        />
                    </Grid>
                </Grid>
                <Grid item>
                    <TextField variant="outlined"
                        label="Exclusive Name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        fullWidth
                    />
                </Grid>
                <Grid item>
                    <TextField variant="outlined"
                        label="Exclusive Description"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        fullWidth
                        multiline
                        rows={10}
                    />
                </Grid>
                <Grid item>
                    <FormControlLabel
                        control={<Checkbox checked={disableProductScroller} onChange={(e) => setDisableProductScroller(e.target.checked)} name="disableProductScroller" />}
                        label="Disable Product Scroller"
                    />
                </Grid>
                <Grid item>
                    <Button variant="contained" color="secondary" onClick={updateExclusive}>
                        Update Exclusive
                    </Button>
                </Grid>
            </Grid>
        </TabPanel>
    )
}
export interface GeneralInfoTabProps {
    tab: number
    index: number
    exclusive: Maybe<ExclusiveVideoContent>
    show: Show
}