import React, { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import {
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell
} from '@material-ui/core';

import { TabPanel } from '../../tabs/TabPanel';

import { Maybe, Vendor, Product } from '../../../types/api';

import { format } from 'date-fns';

const formatString = 'MMM dd yyyy';

const useStyles = makeStyles((theme) => ({
    menuTable: {
        borderTopWidth: 2,
        borderColor: '#f9f9f9',
        borderWidth: 2,
        borderStyle: 'solid'
    }
}));

export function VendorProductsTab(props: VendorProductsTabProps) {
    const classes = useStyles();
    const [vendorProducts, setVendorProducts] = useState<Array<Product>>([]);

    useEffect(() => {
        if (!props.vendor) return;

    }, [props.vendor])

    return (
        <TabPanel value={props.tab} index={props.index} spacing={2} direction="column">
            <Grid item>
                <TableContainer>
                    <Table className={classes.menuTable} size="small" aria-label="show metadata tags">
                        <TableHead>
                            <TableRow style={{ background: "#f9f9f9" }}>
                                <TableCell align="center">Brand</TableCell>
                                <TableCell align="center">Product Name</TableCell>
                                <TableCell align="center">Enabled</TableCell>
                                <TableCell align="center">Date Added</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                vendorProducts.map((product: Product, index: number) => {
                                    return (
                                        <ProductRow key={product.id} product={product} index={index} />
                                    )
                                })
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </TabPanel>
    )
}
export interface VendorProductsTabProps {
    tab: number
    index: number
    vendor: Maybe<Vendor>
}

function ProductRow(props: ProductRowProps) {
    return (
        <TableRow style={props.index % 2 ? { background: "#f9f9f9" } : { background: "white" }}>
            <TableCell align="center">{props.product.brand}</TableCell>
            <TableCell align="center">{props.product.name}</TableCell>
            <TableCell align="center">{props.product.active.toString()}</TableCell>
            <TableCell align="center">
                {format(new Date(props.product.added), formatString)}
            </TableCell>
        </TableRow>
    )
}

interface ProductRowProps {
    index: number
    product: Product
}