import React, { useState, useEffect } from 'react';
import { Grid, TextField, Button, IconButton } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';

import {
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell
} from '@material-ui/core';

import DeletIcon from '@material-ui/icons/Delete';

import useSnackbars from '../../../hooks/useSnackbars';

import { TabPanel } from '../../tabs/TabPanel';
import * as API from '../../../lib/api';

import { ProductCategory } from '../../../types';

const useStyles = makeStyles((theme) => ({
    menuTable: {
        borderTopWidth: 2,
        borderColor: '#f9f9f9',
        borderWidth: 2,
        borderStyle: 'solid'
    },
    comboBox: {
        width: '300px'
    },
    urlField: {
        width: '500px'
    }
}));

export function ProductCategoriesTab(props: ProductCategoriesTabProps) {
    const classes = useStyles();
    const { createSuccessSnack, createErrorSnack } = useSnackbars();
    const [categories, setCategories] = useState<Array<ProductCategory>>([]);
    const [categoriesOptions, setCategoriesOptions] = useState<Array<any>>([]);
    const [newCategories, setNewCategories] = useState<Array<any>>([]);

    const fetchProductCategories = async () => {
        const productCategoriesResult = await API.getProductCategories({ productID: props.product.id });
        setCategories(productCategoriesResult);
    }

    useEffect(() => {
        if (!props.product) return;

        const fetchActiveCategories = async () => {
            const categoriesResult = await API.listActiveCategories({ customerID: props.product!.customerID });
            let options = categoriesResult.map(category => {
                return { name: category.name, value: category.id }
            })

            setCategoriesOptions(options);
        }
        
        fetchActiveCategories();
        fetchProductCategories();
    }, [props.product])

    const addCategories = async () => {
        let input = {
            productID: props.product.id,
            categories: newCategories.map(category => category.value)
        }

        await API.createProductCategory(input);
        fetchProductCategories();
    }

    const removeCategory = async (productID: number, categoryID: number) => {
        let success = await API.deleteProductCategory({ productID, categoryID });
        if (success) {
            let filtered = categories.filter(productCategory => productCategory.categoryID !== categoryID);
            setCategories(filtered);
            createSuccessSnack('Successfully removed product category');
        } else {
            createErrorSnack('Failed to remove product category');
        }
    }

    return (
        <TabPanel value={props.tab} index={props.index} direction="column" spacing={2}>
            <Grid item>
                <TableContainer>
                    <Table className={classes.menuTable} size="small" aria-label="seasons">
                        <TableHead>
                            <TableRow style={{ background: "#f9f9f9" }}>
                                <TableCell align="center">Name</TableCell>
                                <TableCell align="center">Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                categories.map((category, index) => {
                                    return (
                                        <TableRow key={category.categoryID} style={index % 2 ? { background: "#f9f9f9" } : { background: "white" }}>
                                            <TableCell align="center">{category.categoryName}</TableCell>
                                            <TableCell align="center">
                                                <IconButton onClick={(e) => removeCategory(category.productID, category.categoryID)}>
                                                    <DeletIcon color="primary" />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    )
                                })
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            <Grid item>
                <Autocomplete
                    multiple
                    id="categories-combo-box"
                    options={categoriesOptions}
                    getOptionLabel={(option) => option.name}
                    onChange={(e, v) => setNewCategories(v)}
                    filterSelectedOptions
                    disableCloseOnSelect={true}
                    style={{width: '350px'}}
                    renderInput={(params) => <TextField {...params} label="Categories" variant="outlined" />}
                />
            </Grid>
            <Grid item>
                <Button variant="contained" color="secondary" onClick={addCategories}>
                    Add Category
                </Button>
            </Grid>
        </TabPanel>
    )
}

export interface ProductCategoriesTabProps {
    tab: number
    index: number
    product: any
}