import React, { useState, useEffect } from 'react';
import { Grid, Card } from '@material-ui/core';

import { Bar } from 'react-chartjs-2';

import { formattedTime } from '../../../lib/util';

const title = 'Stream Metrics By Weekday';
const colorString = 'rgb(108, 165, 188)';

interface data {
    labels: any[]
    datasets: any[]
}

export function StreamMetricsByWeekdayChart(props: StreamMetricsByWeekdayChartProps) {
    const [streamMetricsData, setstreamMetricsData] = useState<data>({
        labels: [],
        datasets: [{
            label: title,
            backgroundColor: colorString,
            borderColor: colorString,
            data: []
        }]
    });

    const [options] = useState({
        scales: {
            yAxes: [{
                scaleLabel: {
                    labelString: 'Video Streamed',
                    display: true,
                    fontSize: 16
                },
                ticks: {
                    callback: formattedTime
                }
            }]
        }
    });

    useEffect(() => {
        let rawData = props.streamDataByWeekday;
        if (!rawData) return;

        let timeData: any[] = [];

        let labels = rawData.map(weekdayEntry => {
            timeData.push(weekdayEntry.totalSecs)
            return weekdayEntry.weekName
        })

        const streamData = {
            labels: labels,
            datasets: [{
                label: title,
                backgroundColor: colorString,
                borderColor: colorString,
                data: timeData
            }]
        }

        setstreamMetricsData(streamData)
    }, [props.streamDataByWeekday])

    return (
        <Card>
            <Grid container spacing={2} direction="column" xs>
                <Grid item>
                    <Bar data={streamMetricsData} options={options} height={105} />
                </Grid>
            </Grid>
        </Card>
    )
}

export interface StreamMetricsByWeekdayChartProps {
    streamDataByWeekday: any
}