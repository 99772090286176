import React, { useState } from 'react';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import { SketchPicker } from 'react-color';

const useStyles = makeStyles((theme) => ({
    colorButton: {
        boxSizing: 'content-box',
        minWidth: '3.8em',
        padding: '7px 0'
    },
    colorBoxDiv: {
        width: '1em',
        border: '15px',
        padding: '7px 0',
        borderColor: '#ccc',
        borderWidth: '1px',
        borderStyle: 'solid'
    },
    popover: {
        position: 'absolute',
        zIndex: 2
    },
    cover: {
        position: 'fixed',
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px'
    }
}));

export function ColorBox(props: ColorBoxProps) {
    const classes = useStyles();
    const [displayColorPicker, setDisplayColorPicker] = useState(false);

    const handleClick = () => {
        setDisplayColorPicker(!displayColorPicker)
    }

    const handleClose = () => {
        setDisplayColorPicker(false)
    }

    return (
        <div>
            <Button variant="outlined" onClick={handleClick} className={classes.colorButton}>
                <div style={{ backgroundColor: props.color }} className={classes.colorBoxDiv} />
                <ArrowDropDownIcon />
            </Button>
            {
                displayColorPicker
                    ? <div className={classes.popover}>
                        <div className={classes.cover} onClick={handleClose} />
                        <SketchPicker
                            disableAlpha
                            color={props.color}
                            onChange={(c) => props.setColor(c.hex)}
                            onChangeComplete={(c) => props.setColor(c.hex)} />
                    </div>
                    : null
            }
        </div>
    )
}

export interface ColorBoxProps {
    color: string
    setColor: Function
}