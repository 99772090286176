import React, { useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import { Grid, Typography, TextField, Button, LinearProgress } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

import * as path from 'path';

import * as API from '../../../lib/api';
import { TabPanel } from '../../tabs/TabPanel';
import { FileUploadButton } from '../../shared/FileUploadButton';
import { SelectWithLabel } from '../../shared/SelectWithLabel';
import { seasonsOptions, episodeNumberOptions, uploadPresignedFile } from '../../../lib/util';

import { Maybe, Episode } from '../../../types/api';

const mediaTypeOptions = [
    { name: 'None', value: 0 },
    { name: 'External URL to Media File or Stream', value: 1},
    { name: 'Upload Media File', value: 2},
];

export function AddEpisodeTab(props: AddEpisodeTabProps) {
    const [showSeasonsOptions, setShowSeasonsOptions] = useState(seasonsOptions);
    const [season, setSeason] = useState(seasonsOptions[0]);
    const [episodeNumber, setEpisodeNumber] = useState(episodeNumberOptions[1]);
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [mediaType, setMediaType] = useState(mediaTypeOptions[0]);
    const [videoURL, setVideoURL] = useState('');
    const [createdEpisode, setCreatedEpisode] = useState<Maybe<Episode>>();
    const [displayVideoUpload, setDisplayVideoUpload] = useState(false);
    const [uploading, setUploading] = useState(false);
    const [progress, setProgress] = useState(0);

    const clear = () => {
        setSeason(showSeasonsOptions[0]);
        setEpisodeNumber(episodeNumberOptions[1]);
        setName('');
        setDescription('');
        setMediaType(mediaTypeOptions[0]);
        setVideoURL('');
        setCreatedEpisode(undefined);
        setUploading(false);
        setProgress(0);
    }

    useEffect(() => {
        clear();

        if (!props.showID) return;

        const fetchShowSeasons = async () => {
            const showSeasonsResult = await API.getShowSeasons({ showID: props.showID });
            let newOptions = showSeasonsResult.filter(showSeason => showSeason.name).map(showSeason => {
                return { name: showSeason.name!, season: showSeason.season }
            })
            newOptions = newOptions.concat(seasonsOptions);

            setShowSeasonsOptions(newOptions);
            setSeason(newOptions[0]);
        }

        fetchShowSeasons();
    }, [props.showID])

    const onSeasonChange = (event, newValue) => {
        setSeason(newValue);
    }

    const onEpisodeNumberChange = (event, newValue) => {
        setEpisodeNumber(newValue);
    }

    const selectVideoFile = async ({ target }) => {
        if (!target) return;
        if (!createdEpisode) return;

        const file = target.files[0];
        if (!file) return;

        const fileExtension = path.extname(file.name).toUpperCase();

        const now = new Date().getTime();
        const filename = `VIDEO-${props.customerID}-${props.showID}-${createdEpisode.id}-${now}${fileExtension}`

        const user = await Auth.currentAuthenticatedUser();

        const presignedUrl = await API.generatePresignedRawVideoURL({
            operation: 'putObject',
            key: filename,
            metadata: [
                { name: 'userid', value: user.attributes.sub },
                { name: 'episodename', value: createdEpisode.name }
            ]
        });

        const options = {
            headers: {
                'Content-Type': file.type
            },
            onUploadProgress: function (progressEvent) {
                var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                setProgress(percentCompleted)
            }
        };

        setUploading(true);
        const uploadResponse = await uploadPresignedFile(presignedUrl, file, options);
        if (uploadResponse.status === 200) {
            clear();
            setDisplayVideoUpload(false);
        }
    }

    const addEpisode = async () => {
        const now = new Date().toISOString();

        const episodeInput = {
            showID: props.showID,
            season: season.season,
            number: parseInt(episodeNumber),
            name: name,
            description: description,
            added: now,
            firstRelease: now,
            video: mediaType.value === 1 ? videoURL : ''
        }

        const createEpisodeResult = await API.createEpisode(episodeInput);
        setCreatedEpisode(createEpisodeResult);

        if (mediaType.value === 2) {
            // Have them upload the video! Should we pop up a dialog? IDKKKK
            setDisplayVideoUpload(true);
        } else {
            clear();
        }
    }

    return (
        <TabPanel value={props.tab} index={props.index} spacing={2}>
            <Grid item xs={3}>
                <Autocomplete id="season-combo-box"
                    options={showSeasonsOptions}
                    getOptionLabel={(option) => option.name}
                    value={season}
                    onChange={onSeasonChange}
                    selectOnFocus
                    clearOnBlur
                    freeSolo
                    renderInput={(params) => <TextField {...params} label="Season" variant="outlined" />}
                />
            </Grid>
            <Grid item xs={2}>
                <Autocomplete id="episode-combo-box"
                    options={episodeNumberOptions}
                    getOptionLabel={(option) => option.toString()}
                    value={episodeNumber}
                    onChange={onEpisodeNumberChange}
                    selectOnFocus
                    clearOnBlur
                    freeSolo
                    renderInput={(params) => <TextField {...params} label="Episode" variant="outlined" />}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField variant="outlined"
                    label="Episode Name"
                    placeholder="Episode Name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    fullWidth
                />
            </Grid>
            <Grid item xs={12}>
                <TextField variant="outlined"
                    label="Episode Description"
                    placeholder="Episode Description"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    fullWidth
                    multiline
                    rows={5}
                />
            </Grid>
            <Grid item container direction="row" xs={12} spacing={2} alignItems="center">
                <Grid item>
                    <SelectWithLabel id="media-type"
                        title="Media Type"
                        value={mediaType}
                        selectOptions={mediaTypeOptions}
                        onChange={(e) => setMediaType(e.target.value)}
                    />
                </Grid>
                {
                    mediaType.value === 1 &&
                    <Grid item xs={6}>
                        <TextField variant="outlined"
                            label="Video URL"
                            placeholder="Video URL"
                            value={videoURL}
                            onChange={(e) => setVideoURL(e.target.value)}
                            fullWidth
                        />
                    </Grid>
                }
                {
                    mediaType.value === 2 &&
                    <Grid item>
                        <Typography>A file upload will display after you create the episode</Typography>
                    </Grid>
                }
            </Grid>
            <Grid item container direction="row" xs={12} spacing={2}>
                <Grid item>
                    <Button variant="contained" color="secondary" onClick={addEpisode}>
                        Add Episode
                    </Button>
                </Grid>
                <Grid item>
                    <Button variant="outlined" onClick={clear}>
                        Cancel
                    </Button>
                </Grid>
            </Grid>
            {
                mediaType.value === 2 && displayVideoUpload &&
                <Grid item>
                    <FileUploadButton
                        id="episode-video-select-button"
                        accept="video/mp4,video/x-m4v,video/*"
                        onChange={selectVideoFile}
                        text="Select Video"
                    />
                </Grid>
            }
            {
                displayVideoUpload && uploading &&
                <>
                    <Grid item xs={12}>
                        <Typography>Upload Progress: Please do not leave this page or refresh while uploading.</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <LinearProgress variant="determinate" value={progress} color="secondary" />
                    </Grid>
                </>
            }
        </TabPanel>
    )
}

export interface AddEpisodeTabProps {
    tab: number
    index: number
    customerID: number
    showID: number
}