import React, { useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import { Grid, TextField, LinearProgress, Typography } from '@material-ui/core';

import useSnackbars from '../../../../hooks/useSnackbars';

import * as path from 'path';

import { TabPanel } from '../../../tabs/TabPanel';
import { SelectWithLabel } from '../../../shared/SelectWithLabel';
import { FileUploadButton } from '../../../shared/FileUploadButton';
import { uploadPresignedFile } from '../../../../lib/util';

import * as API from '../../../../lib/api';
import { Maybe, ExclusiveVideoContent, VideoType } from '../../../../types';


const videoTypeSelectOptions = Object.entries(VideoType).map(type => {
    return { name: type[1].toString(), value: type[1] }
})

const videoResolutionSelectOptions = [
    { name: 'HD - 720p', value: 'HD' },
    { name: 'FHD - 1080p', value: 'FHD' },
    { name: 'UHD - 4k', value: 'UHD' }
];

export function VideoTab(props: VideoTabProps) {
    const { createSuccessSnack, createErrorSnack } = useSnackbars();
    const [videoURL, setVideoURL] = useState(props.exclusive?.videoURL || '');
    const [videoType, setVideoType] = useState(videoTypeSelectOptions.find((option) => option.value === props.exclusive?.videoType));
    const [videoResolution, setVideoResolution] = useState(videoResolutionSelectOptions.find((option) => option.value === props.exclusive?.videoQuality));
    const [uploading, setUploading] = useState(false);
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        setVideoURL(props.exclusive?.videoURL || '');
        setVideoType(videoTypeSelectOptions.find((option) => option.value === props.exclusive?.videoType));
        setVideoResolution(videoResolutionSelectOptions.find((option) => option.value === props.exclusive?.videoQuality));
        setUploading(false);
        setProgress(0);
    }, [props.exclusive])

    const selectVideoFile = async ({ target }) => {
        if (!target || !props.exclusive) return;
        setProgress(0);

        const file = target.files[0];
        if (!file) return;

        const fileExtension = path.extname(file.name).toUpperCase();

        const now = new Date().getTime();
        const filename = `EXCLUSIVE-${props.customerID}-${props.exclusive.legacyShowID}-${now}${fileExtension}`;

        const user = await Auth.currentAuthenticatedUser();

        setUploading(true);

        // TODO: Cleanup Old Video
        // const result = await API.updateEpisodeVideoCleanup(props.exclusive.id);
        // if (!result) {
        //     setUploading(false);
        //     createErrorSnack('Error deleting previous video and fingerprints');
        //     return;
        // }

        const presignedUrl = await API.generatePresignedRawVideoURL({
            operation: 'putObject',
            key: filename,
            metadata: [
                { name: 'userid', value: user.attributes.sub },
                { name: 'id', value: props.exclusive.id },
                { name: 'name', value: props.exclusive.name }
            ]
        });

        var options = {
            headers: {
                'Content-Type': file.type
            },
            onUploadProgress: function (progressEvent) {
                var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                setProgress(percentCompleted)
            }
        };

        let uploadResponse = await uploadPresignedFile(presignedUrl, file, options);
        if (uploadResponse.status !== 200) {
            createErrorSnack('Error uploading new video');
        } else {
            createSuccessSnack('Episode has been uploaded and will now be converted. Check your notifications for updates');
        }
        setUploading(false);
    }

    return (
        <TabPanel value={props.tab} index={props.index} spacing={2}>
            <Grid item xs={12}>
                <TextField variant="outlined"
                    value={videoURL}
                    onChange={(e) => setVideoURL(e.target.value)}
                    label="Video URL"
                    placeholder="https://s3.dualstack.us-east-2.amazonaws.com/xxxxx/xxxxx"
                    fullWidth
                />
            </Grid>
            <Grid item container spacing={2} direction="row" xs={12} alignItems="center" alignContent="center">
                <Grid item xs={2}>
                    <SelectWithLabel id="video-type-select"
                        title="Video Type"
                        value={videoType}
                        onChange={(e) => setVideoType(e.target.value)}
                        selectOptions={videoTypeSelectOptions}
                    />
                </Grid>
                <Grid item xs={2}>
                    <SelectWithLabel id="video-resolution-select"
                        title="Video Resolution"
                        value={videoResolution}
                        onChange={(e) => setVideoResolution(e.target.value)}
                        selectOptions={videoResolutionSelectOptions}
                    />
                </Grid>
            </Grid>
            <Grid item container spacing={2} direction="row" alignItems="center" xs={12}>
                <Grid item>
                    <FileUploadButton
                        id="update-video-select-button"
                        accept="video/mp4,video/x-m4v,video/*"
                        onChange={selectVideoFile}
                        text="Update / Change Video"
                    />
                </Grid>
                <Grid item>
                    <Typography>Warning: If you update/change this video it will replace the current episode, delete fingerprints, and previous video/audio</Typography>
                </Grid>
            </Grid>
            {
                uploading &&
                <Grid item xs={12}>
                    <LinearProgress variant="determinate" value={progress} color="secondary" />
                </Grid>
            }
        </TabPanel>
    )
}
export interface VideoTabProps {
    tab: number
    index: number
    customerID: number
    exclusive: Maybe<ExclusiveVideoContent>
}