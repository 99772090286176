import React, { useState } from 'react';
import { Grid, Typography, Button } from '@material-ui/core';

import { TabPanel } from '../../../tabs/TabPanel';

export function LiveStreamTab(props: LiveStreamTabProps) {
    const [streamStatus] = useState('IDLE');
    // const [endpoints, setEndpoints] = useState(new Array());
    // const [ipWhitelist, setIPWhitelist] = useState(new Array());

    return (
        <TabPanel value={props.tab} index={props.index} spacing={2} direction="column">
            <Grid item>
                <Typography>Status: {streamStatus}</Typography>
            </Grid>
            <Grid item>
                <Typography>End Points:</Typography>
            </Grid>
            <Grid item>
                <Typography>IP Whitelist:</Typography>
            </Grid>
            <Grid item>
                {/* Add IP Whitelist */}
            </Grid>
            <Grid item>
                <Button variant="contained" color="secondary">
                    Start Stream
                </Button>
            </Grid>
            <Grid item>
                <Button variant="contained" color="primary">
                    Stop Stream
                </Button>
            </Grid>
            <Grid item>
                <Typography>Notes</Typography>
            </Grid>
        </TabPanel>
    )
}
export interface LiveStreamTabProps {
    tab: number
    index: number
}