import React, { useEffect } from 'react';

import { TabPanel } from '../../tabs/TabPanel';

import { Maybe, Vendor } from '../../../types';

export function VendorUsersTab(props: VendorUsersTabProps) {

    useEffect(() => {
        if (!props.vendor) return;

    }, [props.vendor])

    return (
        <TabPanel value={props.tab} index={props.index} spacing={2}>
        </TabPanel>
    )
}
export interface VendorUsersTabProps {
    tab: number
    index: number
    vendor: Maybe<Vendor>
}