import React, { useState, useEffect, useCallback } from 'react';
import { Grid, TextField, Button, LinearProgress } from '@material-ui/core';

import { FileUploadButton } from '../../shared/FileUploadButton';
import { TabPanel } from '../../tabs/TabPanel';

import { Maybe, Product } from '../../../types';
import useSnackbars from '../../../hooks/useSnackbars';
import * as API from '../../../lib/api';
import { uploadPresignedFile } from '../../../lib/util';
import path from 'path';

export function OriginalProductTab(props: OriginalProductTabProps) {
    const { createSuccessSnack, createErrorSnack } = useSnackbars();
    const [originalName, setOriginalName] = useState('');
    const [originalDescription, setOriginalDescription] = useState('');
    const [originalLink, setOriginalLink] = useState('');
    const [imageURL, setImageURL] = useState('');
    const [progress, setProgress] = useState(0);
    const [uploading, setUploading] = useState(false);

    const reset = useCallback(() => {
        if (!props.product) return;

        setOriginalName(props.product.originalProductName || '');
        setOriginalDescription(props.product.originalProductDescription || '');
        setOriginalLink(props.product.originalProductURL || '');

        if (props.product.originalProductPhotoBucket && props.product.originalProductPhotoKey) {
            setImageURL(`https://s3.dualstack.us-east-2.amazonaws.com/${props.product.originalProductPhotoBucket}/${props.product.originalProductPhotoKey}`);
        }
    }, [props.product])

    useEffect(() => {
        if (!props.product) return;
        reset();
    }, [props.product, reset])

    const updateProduct = async () => {
        if (!props.product) return;

        let input = {
            id: props.product.id,
            originalProductName: originalName,
            originalProductDescription: originalDescription,
            originalProductURL: originalLink
        }

        try {
            await API.updateProduct(input);
            createSuccessSnack('Successfully updated original product information');
        } catch (err) {
            console.error(err);
            createErrorSnack('Failed to update original product information');
        }
    }

    const cancel = () => {
        setOriginalName(props.product?.name || '');
        setImageURL(props.product?.mainPhotoURL || '');
    }

    const updateProductPhoto = async ({ target }) => {
        if (!props.product) return;

        const file = target.files[0];
        if (!file) return;

        const fileExtension = path.extname(file.name).toUpperCase();

        const now = new Date().getTime();
        const filename = `ORIGINALPRODUCT-${props.product.customerID}-${props.product.id}-${now}${fileExtension}`;

        const presignedUrl = await API.generatePresignedRawMediaURL({
            operation: 'putObject',
            key: filename
        });

        var options = {
            headers: {
                'Content-Type': file.type
            },
            onUploadProgress: function (progressEvent) {
                var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                setProgress(percentCompleted);
            }
        };

        setUploading(true);
        let uploadResponse = await uploadPresignedFile(presignedUrl, file, options);
        if (uploadResponse.status === 200) {
            createSuccessSnack('Successfully updated product photo');
        } else {
            createErrorSnack('Error uploading photo');
        }

        setUploading(false);
    }

    return (
        <TabPanel value={props.tab} index={props.index} direction="row" spacing={2}>
            <Grid item container spacing={2} direction="column" xs={6}>
                <Grid item>
                    <TextField variant="outlined"
                        value={originalName}
                        label="Original Product Name"
                        onChange={(e) => setOriginalName(e.target.value)}
                        placeholder="Original Product Name"
                        fullWidth
                    />
                </Grid>
                <Grid item>
                    <TextField variant="outlined"
                        value={originalDescription}
                        label="Original Product Description"
                        onChange={(e) => setOriginalDescription(e.target.value)}
                        placeholder="Original Product Description"
                        fullWidth
                        multiline
                        rows={5}
                    />
                </Grid>
                <Grid item>
                    <TextField variant="outlined"
                        value={originalLink}
                        label="Original Product Link"
                        onChange={(e) => setOriginalLink(e.target.value)}
                        placeholder="Original Product Link"
                        fullWidth
                    />
                </Grid>
                <Grid item container spacing={2} direction="row">
                    <Grid item>
                        <Button variant="contained" color="secondary" onClick={updateProduct}>
                            Update product
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button variant="contained" color="primary" onClick={cancel}>
                            Cancel
                        </Button>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item container spacing={2} direction="column" xs={6} alignContent="center" alignItems="center">
                {
                    uploading &&
                    <Grid item>
                        <LinearProgress variant="determinate" value={progress} color="secondary" />
                    </Grid>
                }
                <Grid item>
                    <img src={imageURL} alt={originalName} width="400" />
                </Grid>
                <Grid item>
                    <FileUploadButton id="original-product-photo-upload"
                        accept="image/*"
                        text="Update Original Product photo"
                        onChange={updateProductPhoto}
                    />
                </Grid>
            </Grid>
        </TabPanel >
    )
}

export interface OriginalProductTabProps {
    tab: number
    index: number
    product: Maybe<Product>
}