import React, { useState, useEffect } from 'react';
import { Grid, Typography, Box, TextField, Button, Checkbox, FormControlLabel } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { TabPanel } from '../../../tabs/TabPanel';

import useSnackbars from '../../../../hooks/useSnackbars';
import * as API from '../../../../lib/api';
import { Maybe, Show } from '../../../../types/api';

const useStyles = makeStyles((theme) => ({
    titleTextBox: {
        width: '40%'
    },
    introTextBox: {
        width: '75%'
    },
    itemTextBox: {
        width: '50%'
    },
    itemTextSize: {
        fontSize: '13px'
    }
}));

export function ProductRequestTab(props: ProductRequestTabProps) {
    const classes = useStyles();
    const { createSuccessSnack, createErrorSnack } = useSnackbars();
    const [titleText, setTitleText] = useState('');
    const [introText, setIntroText] = useState('');
    const [episodeListEnabled, setEpisodeListEnabled] = useState(false);
    const [episodeListText, setEpisodeListText] = useState('');
    const [photoGroupEnabled, setPhotoGroupEnabled] = useState(false);
    const [photoGroupText, setPhotoGroupText] = useState('');
    const [categoryEnabled, setCategoryEnabled] = useState(false);
    const [categoryText, setCategoryText] = useState('');
    const [emailEnabled, setEmailEnabled] = useState(false);
    const [emailText, setEmailText] = useState('');
    const [requestText, setRequestText] = useState('');
    const [submitThanksText, setSubmitThanksText] = useState('');
    const [notificationsList, setNotificationsList] = useState('');

    const reset = () => {
        if (!props.show) return;

        setTitleText(props.show.productRequestConfiguration?.titleText || '');
        setIntroText(props.show.productRequestConfiguration?.introText || '');
        setEpisodeListEnabled(props.show.productRequestConfiguration?.episodeListEnabled || false);
        setEpisodeListText(props.show.productRequestConfiguration?.episodeListText || '');
        setPhotoGroupEnabled(props.show.productRequestConfiguration?.photoGroupEnabled || false);
        setPhotoGroupText(props.show.productRequestConfiguration?.photoGroupText || '');
        setCategoryEnabled(props.show.productRequestConfiguration?.categoryEnabled || false);
        setCategoryText(props.show.productRequestConfiguration?.categoryText || '');
        setEmailEnabled(props.show.productRequestConfiguration?.emailEnabled || false);
        setEmailText(props.show.productRequestConfiguration?.emailText || '');
        setRequestText(props.show.productRequestConfiguration?.requestText || '');
        setSubmitThanksText(props.show.productRequestConfiguration?.submitThanksText || '');
        setNotificationsList(props.show.productRequestConfiguration?.notificationsList || '');
    }

    useEffect(() => {
        if (!props.show) return;

        reset();
    }, [props.show])

    const cancel = () => {
        reset();
    }

    const updateProductRequestConfiguration = async () => {
        if (!props.show) return;

        let input = {
            id: props.show.id,
            titleText: titleText,
            introText: introText,
            episodeListEnabled: episodeListEnabled,
            episodeListText: episodeListText,
            photoGroupEnabled: photoGroupEnabled,
            photoGroupText: photoGroupText,
            categoryEnabled: categoryEnabled,
            categoryText: categoryText,
            emailEnabled: emailEnabled,
            emailText: emailText,
            requestText: requestText,
            submitThanksText: submitThanksText,
            notificationsList: notificationsList
        }

        const updateResult = await API.updateShowProductRequestConfiguration(input);
        if (!updateResult) {
            createErrorSnack('Error updating product request configuration');
        } else {
            createSuccessSnack('Successfully updated product request configuration');
        }
    }

    return (
        <TabPanel value={props.tab} index={props.index} spacing={2} direction="column">
            <Grid item>
                <Typography>Product Request Page Title</Typography>
            </Grid>
            <Grid item>
                <TextField className={classes.titleTextBox}
                    variant="outlined"
                    value={titleText}
                    onChange={(e) => setTitleText(e.target.value)} />
            </Grid>
            <Grid item>
                <Typography>Product Request Intro Text</Typography>
            </Grid>
            <Grid item>
                <TextField variant="outlined"
                    className={classes.introTextBox}
                    value={introText}
                    onChange={(e) => setIntroText(e.target.value)}
                    multiline
                    rows={4} />
            </Grid>
            <Grid item container>
                <Grid item>
                    <Typography component={'span'}>
                        <Box>
                            Product Request Questions
                            </Box>
                    </Typography>
                </Grid>
                <ProductRequestQuestion title="Episode List"
                    value={episodeListText}
                    setValue={setEpisodeListText}
                    checked={episodeListEnabled}
                    checkChanged={setEpisodeListEnabled} />

                <ProductRequestQuestion title="Photo Group"
                    value={photoGroupText}
                    setValue={setPhotoGroupText}
                    checked={photoGroupEnabled}
                    checkChanged={setPhotoGroupEnabled} />

                <ProductRequestQuestion title="Product Categories"
                    value={categoryText}
                    setValue={setCategoryText}
                    checked={categoryEnabled}
                    checkChanged={setCategoryEnabled} />

                <ProductRequestQuestion title="Email"
                    value={emailText}
                    setValue={setEmailText}
                    checked={emailEnabled}
                    checkChanged={setEmailEnabled} />

                <ProductRequestQuestion title="Request"
                    value={requestText}
                    setValue={setRequestText}
                    checked={true}
                    checkChanged={() => { }} />
            </Grid>
            <Grid item>
                <Typography>Product Request Submission Text</Typography>
            </Grid>
            <Grid item>
                <TextField variant="outlined"
                    className={classes.introTextBox}
                    value={submitThanksText}
                    onChange={(e) => setSubmitThanksText(e.target.value)}
                    multiline
                    rows={4}
                />
            </Grid>
            <Grid item>
                <TextField variant="outlined"
                    className={classes.introTextBox}
                    label="Notifications List: Separate emails with ;"
                    value={notificationsList}
                    onChange={(e) => setNotificationsList(e.target.value)}
                    multiline
                />
            </Grid>
            <Grid item container spacing={2} direction="row">
                <Grid item>
                    <Button variant="contained" color="secondary" onClick={updateProductRequestConfiguration}>
                        Update Product Request Configuration
                    </Button>
                </Grid>
                <Grid item>
                    <Button variant="contained" color="primary" onClick={cancel}>
                        Cancel
                    </Button>
                </Grid>
            </Grid>
        </TabPanel>
    )
}

export interface ProductRequestTabProps {
    tab: number
    index: number
    show: Maybe<Show>
}

function ProductRequestQuestion(props: ProductRequestQuestion) {
    const classes = useStyles();


    return (
        <Grid item container direction="row" spacing={1} alignContent="center" alignItems="center">
            <Grid item xs={2}>
                <Typography component={'span'}>
                    <Box fontSize={13} fontWeight={300}>
                        {props.title}
                    </Box>
                </Typography>
            </Grid>
            <Grid item xs={2}>
                <FormControlLabel
                    control={<Checkbox checked={props.checked} onChange={(e) => props.checkChanged(e.target.checked)} />}
                    label="Enabled"
                />
            </Grid>
            <Grid item xs>
                <TextField variant="outlined"
                    className={classes.itemTextBox}
                    InputProps={{
                        classes: {
                            input: classes.itemTextSize,
                        },
                    }}
                    value={props.value}
                    onChange={(e) => props.setValue(e.target.value)} />
            </Grid>
        </Grid>
    )
}

interface ProductRequestQuestion {
    title: string
    value: any
    setValue: Function
    checked: boolean
    checkChanged: Function
}