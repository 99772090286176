import React, { useState, useEffect } from 'react';
import { Grid, Typography, Checkbox, Button, TextField, FormControlLabel, Tooltip, IconButton } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';

import { TableContainer, Table, TableHead, TableBody, TableRow, TableCell } from '@material-ui/core';

import { ColorBox } from '../../../shared/ColorBox'

import { makeStyles } from '@material-ui/core/styles';

import { TabPanel } from '../../../tabs/TabPanel';
import { SelectWithLabel } from '../../../shared/SelectWithLabel';

import * as API from '../../../../lib/api';
import { Maybe, Show, ShowDesignMenuItem } from '../../../../types/api';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconLookup, IconDefinition, findIconDefinition, library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
library.add(fas, fab);

const iconOptions = getIconOptions(library);

function getIconOptions(library) {
    let options: string[] = [];

    Object.keys(library.definitions).forEach(definition => {
        const prefix = definition;

        Object.keys(library.definitions[definition]).forEach(icon => {
            options.push(`${prefix} fa-${icon}`);
        })
    })

    return options;
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
    menuTable: {
        borderTopWidth: 2,
        borderColor: '#f9f9f9',
        borderWidth: 2,
        borderStyle: 'solid'
    }
}));

const mainMenuTypeOptions = [
    { name: '0 - Full width Horizontal menu item with Icon, Icon background circle and Title/Description', value: 0 },
    { name: '1 - Half width Horizontal menu items with Icon, Icon background circle and Title', value: 1 },
    { name: '2 - Square Horizontal menu itmes with large Icon and Title', value: 2 }
];

const urlSelectOptions = [
    { name: '/episodes', value: '/episodes' },
    { name: '/exclusives', value: '/exclusives' },
    { name: '/helpfaqs', value: '/helpfaqs' },
    { name: '/privacypolicy', value: '/privacypolicy' },
    { name: '/productlist', value: '/productlist' },
    { name: '/productrequest', value: '/productrequest' },
    { name: '/smallbusiness', value: '/smallbusiness' },
    { name: '/textsearch', value: '/textsearch' },
    { name: '/userprofile', value: '/userprofile' },
    { name: '/wishlist', value: '/wishlist' }
    // { name: '/audioid', value: '/audioid' },
    // { name: '/voicesearch', value: '/voicesearch' },
];

const orderOptions = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 
    11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 
    21, 22, 23, 24, 25, 26, 27, 28, 29, 
    30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 
    40
];

export function MenusTab(props: MenusTabProps) {
    const classes = useStyles();
    const [mainMenuType, setMainMenuType] = useState(mainMenuTypeOptions[props.show?.mainMenuType || 0]);
    const [menuItems, setMenuItems] = useState<Array<ShowDesignMenuItem>>([]);
    const [newMenuItemOrder, setNewMenuItemOrder] = useState(10);
    const [newMenuItemTitle, setNewMenuItemTitle] = useState('');
    const [newMenuItemDescription, setNewMenuItemDescription] = useState('');
    const [newMenuItemURL, setNewMenuItemURL] = useState(urlSelectOptions[0])
    const [newMenuItemShowInPullOutMenu, setNewMenuItemShowInPullOutMenu] = useState(true);
    const [newMenuItemShowInHomeMenu, setNewMenuItemShowInHomeMenu] = useState(true);
    const [newMenuItemIconString, setNewMenuItemIconString] = useState('fas fa-tv');
    const [newMenuItemIcon, setNewMenuItemIcon] = useState<IconDefinition>(findIconDefinition({ prefix: 'fas', iconName: 'tv' }));
    const [newMenuItemColor, setNewMenuItemColor] = useState('#000000');

    const fetchDesignMenu = async () => {
        if (!props.show) return;

        const designMenuItems = await API.getShowDesignMenu({ showID: props.show.id })
        setMenuItems(designMenuItems);
    }

    useEffect(() => {
        fetchDesignMenu();
        setMainMenuType(mainMenuTypeOptions[props.show?.mainMenuType || 0]);
    }, [props.show])

    const handleMainMenuTypeChange = async (event: any) => {
        if (!props.show) return;

        let mainMenuType = event.target.value;
        setMainMenuType(mainMenuType);

        const input = {
            id: props.show.id,
            mainMenuType: mainMenuType.value
        }

        await API.updateShow(input);
    };

    const onOrderChange = (_, newValue) => {
        setNewMenuItemOrder(newValue);
    }

    const onNewURLChange = (_, newValue) => {
        setNewMenuItemURL(newValue);
    }

    const onNewIconChange = (_, newValue) => {
        setNewMenuItemIconString(newValue);

        const iconInfo: any[] = newValue.split(' ');
        const iconLookup: IconLookup = { prefix: iconInfo[0], iconName: iconInfo[1].replace('fa-', '') }
        const iconDefinition: IconDefinition = findIconDefinition(iconLookup)

        setNewMenuItemIcon(iconDefinition);
    }

    const addMenuItem = async () => {
        if (!props.show) return;

        const input = {
            showID: props.show.id,
            orderID: newMenuItemOrder,
            title: newMenuItemTitle,
            description: newMenuItemDescription,
            icon: newMenuItemIconString,
            iconColor: newMenuItemColor,
            url: newMenuItemURL.value,
            showInPullOutMenu: newMenuItemShowInPullOutMenu,
            showInHomeMenu: newMenuItemShowInHomeMenu,
            enabled: true
        }

        // TODO: Add the result to the list
        await API.createShowDesignMenuItem(input);
        await fetchDesignMenu()
    }

    const deleteMenuItem = (id) => {
        let updated = menuItems.filter(menuItem => menuItem.id !== id);
        setMenuItems(updated);
    }

    return (
        <TabPanel value={props.tab} index={props.index} spacing={2} direction="column">
            <Grid item>
                <SelectWithLabel id="main-menu-type"
                    title="Main Menu Type"
                    value={mainMenuType}
                    onChange={handleMainMenuTypeChange}
                    selectOptions={mainMenuTypeOptions}
                />
            </Grid>
            <Grid item>
                <TableContainer>
                    <Table className={classes.menuTable} size="medium" aria-label="show menu items">
                        <TableHead>
                            <TableRow style={{ background: "#f9f9f9" }}>
                                <TableCell align="center">Order Id</TableCell>
                                <TableCell align="center">Icon</TableCell>
                                <TableCell align="center">Title</TableCell>
                                <TableCell align="center">Description</TableCell>
                                <TableCell align="center">Icon</TableCell>
                                <TableCell align="center">Icon Color</TableCell>
                                <TableCell align="center">URL</TableCell>
                                <TableCell align="center">Pull Out Menu</TableCell>
                                <TableCell align="center">Home Menu</TableCell>
                                <TableCell align="center">Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                menuItems.map((menuItem: ShowDesignMenuItem, index) => {
                                    return <MenuItemRow key={menuItem.id} menuItem={menuItem} index={index} onDelete={deleteMenuItem} />
                                })
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            <Grid item container spacing={2} direction="column">
                <Grid item>
                    <Typography variant="h6">Add New Menu Item</Typography>
                </Grid>
                <Grid item>
                    <Autocomplete id="order-combo-box"
                        options={orderOptions}
                        getOptionLabel={(option) => option.toString()}
                        value={newMenuItemOrder}
                        onChange={onOrderChange}
                        style={{ width: '100px' }}
                        renderInput={(params) => <TextField {...params} label="Order" variant="outlined" />}
                    />
                </Grid>
                <Grid item>
                    <TextField variant="outlined"
                        label="Title"
                        placeholder="Title"
                        value={newMenuItemTitle}
                        onChange={(e) => setNewMenuItemTitle(e.target.value)}
                    />
                </Grid>
                <Grid item>
                    <TextField variant="outlined"
                        label="Description"
                        placeholder="Description"
                        value={newMenuItemDescription}
                        onChange={(e) => setNewMenuItemDescription(e.target.value)}
                    />
                </Grid>
                <Grid item container spacing={2} direction="row" alignItems="center">
                    <Grid item>
                        <Autocomplete id="icon-combo-box"
                            options={iconOptions}
                            getOptionLabel={(option) => option}
                            value={newMenuItemIconString}
                            onChange={onNewIconChange}
                            style={{ width: '200px' }}
                            renderInput={(params) => <TextField {...params} label="Icon" variant="outlined" />}
                        />
                    </Grid>
                    <Grid item>
                        <FontAwesomeIcon icon={newMenuItemIcon} color={newMenuItemColor} />
                    </Grid>
                </Grid>
                <Grid item>
                    <Typography>Icon Color</Typography>
                    <ColorBox color={newMenuItemColor} setColor={setNewMenuItemColor} />
                </Grid>
                <Grid item>
                    <Autocomplete
                        id="url-combo-box"
                        options={urlSelectOptions}
                        getOptionLabel={(option) => option.name}
                        value={newMenuItemURL}
                        onChange={onNewURLChange}
                        style={{ width: '200px' }}
                        renderInput={(params) => <TextField {...params} label="Menu Item URL" variant="outlined" />}
                    />
                </Grid>
                <Grid item>
                    <FormControlLabel
                        control={<Checkbox checked={newMenuItemShowInPullOutMenu} name="showInPullOutMenu" onChange={(e) => setNewMenuItemShowInPullOutMenu(e.target.checked)} />}
                        label="Display on the Pull Out Menu"
                    />
                </Grid>
                <Grid item>
                    <FormControlLabel
                        control={<Checkbox checked={newMenuItemShowInHomeMenu} name="showInHomeMenu" onChange={(e) => setNewMenuItemShowInHomeMenu(e.target.checked)} />}
                        label="Display on the Home Menu"
                    />
                </Grid>
                <Grid item>
                    <Button variant="contained" color="secondary" onClick={addMenuItem}>
                        Add Menu Item
                    </Button>
                </Grid>
            </Grid>
        </TabPanel>
    )
}

export interface MenusTabProps {
    tab: number
    index: number
    show: Maybe<Show>
}

function MenuItemRow(props: MenuItemRowProps) {
    const [showInPullOutMenu, setShowInPullOutMenu] = useState(props.menuItem.showInPullOutMenu);
    const [showInHomeMenu, setShowInHomeMenu] = useState(props.menuItem.showInHomeMenu);

    const iconInfo: any[] = props.menuItem.icon.split(' ');
    const iconLookup: IconLookup = { prefix: iconInfo[0], iconName: iconInfo[1].replace('fa-', '') }
    const iconDefinition: IconDefinition = findIconDefinition(iconLookup)

    const updateMenuItem = async () => {
        let input = Object.assign({}, props.menuItem);
        input.showInPullOutMenu = showInPullOutMenu;
        input.showInHomeMenu = showInHomeMenu;

        await API.updateShowDesignMenuItem(input);
    }

    const deleteMenuItem = async () => {
        await API.deleteShowDesignMenuItem(props.menuItem.id);
        props.onDelete(props.menuItem.id);
    }

    return (
        <TableRow key={props.menuItem.id} style={props.index % 2 ? { background: "#f9f9f9" } : { background: "white" }}>
            <TableCell align="center">{props.menuItem.orderID}</TableCell>
            <TableCell align="center">
                <FontAwesomeIcon icon={iconDefinition} color={props.menuItem.iconColor} />
            </TableCell>
            <TableCell align="center">{props.menuItem.title}</TableCell>
            <TableCell align="center">{props.menuItem.description}</TableCell>
            <TableCell align="center">{props.menuItem.icon}</TableCell>
            <TableCell align="center">{props.menuItem.iconColor}</TableCell>
            <TableCell align="center">{props.menuItem.url}</TableCell>
            <TableCell align="center">
                <Checkbox checked={showInPullOutMenu} onChange={(e) => setShowInPullOutMenu(e.target.checked)} />
            </TableCell>
            <TableCell align="center">
                <Checkbox checked={showInHomeMenu} onChange={(e) => setShowInHomeMenu(e.target.checked)} />
            </TableCell>
            <TableCell align="center">
                <Tooltip title="Save Changes">
                    <IconButton onClick={updateMenuItem}>
                        <SaveIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Delete" onClick={deleteMenuItem}>
                    <IconButton color="primary">
                        <DeleteIcon />
                    </IconButton>
                </Tooltip>
            </TableCell>
        </TableRow>
    )
}

interface MenuItemRowProps {
    menuItem: ShowDesignMenuItem
    index: number
    onDelete: Function
}