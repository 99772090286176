import React, { createContext, useReducer } from 'react';

const initialState: any = {
    products: [],
    customerID: -1,
    searchText: '',
    pageNumber: 0,
    loading: true,
    error: false
}

const ProductStore = createContext(initialState);
const { Provider } = ProductStore;

const reducer = (state, action) => {
    switch (action.type) {
        case 'fetchProductsSuccess':
            return {
                ...state,
                products: action.products,
                loading: false,
                customerID: action.customerID
            }
        case 'deleteProductSuccess':
            const updated = state.products.filter(product => product.id !== action.productID);
            return {
                ...state,
                products: updated
            }
        case 'onSearchChange':
            return {
                ...state,
                searchText: action.searchText
            }
        case 'onPageChange':
            return {
                ...state,
                pageNumber: action.page
            }
        default:
            throw new Error();
    }
}

const ProductsStateProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    return <Provider value={{state, dispatch}}>{children}</Provider>
};

export { ProductStore, ProductsStateProvider }