import React, { useState, useEffect } from 'react';
import { Grid, Typography, FormControlLabel, Checkbox } from '@material-ui/core';

import { TabPanel } from '../../tabs/TabPanel';

import { Maybe, Show } from '../../../types/api';

export function ChannelsAppInfoTab(props: ChannelsAppInfoTabProps) {
    const [channel, setChannel] = useState(props.show?.channel);
    const feedURL = window.location.hostname === 'admin.getvoxi.com' 
        ? 'https://feedcontroller.getvoxi.com'
        : 'https://feedcontroller.dev.getvoxi.com'

    useEffect(() => {
        if (props.show?.channel) setChannel(props.show.channel);
    }, [props.show])

    return props.show ? (
        <TabPanel value={props.tab} index={props.index} spacing={2} direction="column">
            <Grid item>
                <FormControlLabel
                    control={<Checkbox checked={channel?.rokuChannelEnabled || false} name="rokuChannelEnabled" />}
                    label="Roku Channel"
                />
                {
                    channel?.rokuChannelEnabled
                        ? <>
                            <Typography>
                                Install URL: <a href={channel.rokuChannelInstallURL || ''}>{channel.rokuChannelInstallURL}</a>
                            </Typography>
                            <Typography>
                                Old Feed URL: <a href={`https://${props.show.appID}.getvoxi.app/video/roku`}>{`https://${props.show.appID}.getvoxi.app/video/roku`}</a>
                            </Typography>
                            <Typography>
                                New Feed URL: <a href={`${feedURL}/roku?appID=${props.show.appID}`}>{`${feedURL}/roku?appID=${props.show.appID}`}</a>
                            </Typography>
                        </>
                        : <Typography variant="subtitle2">A Roku Channel is not currently setup, please contact us to set one up.</Typography>
                }
            </Grid>
            <Grid item>
                <FormControlLabel
                    control={<Checkbox checked={channel?.fireTVChannelEnabled || false} name="fireTVChannelEnabled" />}
                    label="Amazon Fire TV Channel"
                />
                {
                    channel?.fireTVChannelEnabled
                        ? <>
                            <Typography>
                                Test URL: <a href={channel.fireTVDevURL || ''}>{channel.fireTVDevURL}</a>
                            </Typography>
                            <Typography>
                                Install URL: {channel.fireTVInstallURL || 'Not Published Yet'}
                            </Typography>
                            <Typography>
                                Old Feed URL: <a href={`https://${props.show.appID}.getvoxi.app/video/mrss`}>{`https://${props.show.appID}.getvoxi.app/video/mrss`}</a>
                            </Typography>
                            <Typography>
                                New Feed URL: <a href={`${feedURL}/mrss?appID=${props.show.appID}`}>{`${feedURL}/mrss?appID=${props.show.appID}`}</a>
                            </Typography>
                        </>
                        : <Typography variant="subtitle2">An Amazon Fire TV Channel is not currently setup, please contact us to set one up.</Typography>
                }
            </Grid>
            <Grid item>
                <FormControlLabel
                    control={<Checkbox checked={props.show.appData?.googlePlayAppEnabled || false} name="googlePlayAppEnabled" />}
                    label="Google Play Android App"
                />
                {
                    props.show.appData?.googlePlayAppEnabled
                        ? <>
                            <Typography>
                                Google Play Store URL: <a href={props.show.appData?.googlePlayAppURL || ''}>{props.show.appData?.googlePlayAppURL || ''}</a>
                            </Typography>
                            {/* <Typography>
                                TODO: Add Google assetlinks.json file
                            </Typography> */}
                        </>
                        : <Typography variant="subtitle2">An Android App is not currently setup, please contact us to set one up.</Typography>
                }
            </Grid>
        </TabPanel>
    ) : (<></>)
}

export interface ChannelsAppInfoTabProps {
    tab: Number
    index: Number
    show: Maybe<Show>
}