import React, { useState, useEffect } from 'react';
import { Grid, Paper, Typography, LinearProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { formatSeconds } from '../../../lib/util';

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column'
    }
}));

export function HoursStreamedCard(props: HoursStreamedCardProps) {
    const classes = useStyles();
    const [totalSeconds, setTotalSeconds] = useState(props.totalSeconds || 0);
    const [previousTotalSeconds, setPreviousTotalSeconds] = useState(props.previousTotalSeconds || 0);
    const [percentChange, setPercentChange] = useState(0);
    const [percentChangeText, setPercentChangeText] = useState('No Change');
    const [isIncrease, setIsIncrease] = useState(true);

    const calculatePercentChange = (current, previous) => {
        let percent = 0;
        let text = 'No Change';
        if (previous === 0 && current !== 0) {
            percent = 100;
            text = `100% more`
            setIsIncrease(true)
        } else if (previous > current) {
            percent = 100 - Math.round(((current / previous) + Number.EPSILON) * 100);
            if (percent > 100) percent = 100;
            if (percent < 0) percent = 0;
            text = `${percent}% less`
            setIsIncrease(false)
        } else if (current > previous) {
            percent = Math.round(100 - ((previous / current) + Number.EPSILON) * 100);
            if (percent > 100) percent = 100;
            if (percent < 0) percent = 0;
            text = `${percent}% more`
            setIsIncrease(true)
        }

        setPercentChange(percent);
        setPercentChangeText(text);
    }

    useEffect(() => {
        setTotalSeconds(props.totalSeconds || 0);
        setPreviousTotalSeconds(props.previousTotalSeconds || 0);
        calculatePercentChange(props.totalSeconds, props.previousTotalSeconds);
    }, [props])

    return (
        <Paper className={classes.paper}>
            <Grid container spacing={2} direction="column">
                <Grid item container direction="row" xs>
                    <Grid item xs>
                        <Typography>Hours Streamed</Typography>
                        <Typography variant="caption">Total Hours Streamed</Typography>
                    </Grid>
                    <Grid item>
                        <Typography>
                            {formatSeconds(totalSeconds)}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item>
                    <LinearProgress variant="determinate" value={percentChange} color={isIncrease ? "secondary" : "primary" } />
                </Grid>
                <Grid item container direction="row" xs>
                    <Grid item xs>
                        <Typography variant="caption">{percentChangeText}</Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="caption">
                            {formatSeconds(previousTotalSeconds)}
                        </Typography>
                    </Grid>
                </Grid>

            </Grid>
        </Paper>
    )
}

export interface HoursStreamedCardProps {
    totalSeconds: number
    previousTotalSeconds: number
}