import React, { useState, useEffect, useReducer } from 'react';
import { Grid, Typography, Tabs, Tab, AppBar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { a11yProps } from '../../tabs/TabPanel';

import {
    EpisodeOptionsTab,
    GeneralInfoTab,
    LiveStreamTab,
    PhotoProductsTab,
    PhotosTab,
    SearchMetadataTab,
    VideoProductsTab,
    VideoTab
} from './episodeTabs';

import * as API from '../../../lib/api';
import { Episode as EpisodeModel, Product, Show } from '../../../types';

import { API as AmplifyAPI, graphqlOperation } from 'aws-amplify';
import { onUpdateEpisode } from '../../../graphql/subscriptions';

const useStyles = makeStyles((theme) => ({
    mainBar: {
        backgroundColor: 'black'
    },
    divider: {
        color: 'white',
        backgroundColor: 'white'
    },
    poster: {
        maxHeight: '100px',
        maxWidth: '400px'
    },
    tab: {
        minWidth: 0,
        fontSize: 12
    }
}));

const defaultTabs = [
    { key: 'info', label: 'General Info', disabled: false },
    { key: 'live-stream', label: 'Live Stream Management', disabled: true },
    { key: 'episode-options', label: 'Episode Options', disabled: false },
    { key: 'search-metadata', label: 'Search Metadata', disabled: false },
    { key: 'video', label: 'Video', disabled: false },
    { key: 'video-products', label: 'Video Products', disabled: false },
    { key: 'photos', label: 'Photos', disabled: true },
    { key: 'photo-products', label: 'Photo Products', disabled: true },
]

const initialState = {
    episode: { season: '', number: '', name: '' },
    tabs: defaultTabs,
    error: false
}

function reducer(state, action) {
    switch (action.type) {
        case 'setEpisode':
            return {
                ...state,
                episode: action.episode,
            }
        case 'updateEpisodeFromSubscription':
            return {
                ...state,
                episode: action.episode
            }
        case 'setTabs':
            return {
                ...state,
                tabs: action.tabs
            }
        default:
            throw new Error();
    }
}

export function Episode(props: EpisodeProps) {
    const classes = useStyles();
    const [tab, setTab] = useState(0);
    const [episodeState, dispatch] = useReducer(reducer, initialState)
    const [products, setProducts] = useState<Array<Product>>([]);

    const setTabs = (episode: EpisodeModel) => {
        let episodeTabs = defaultTabs;
        if (episode.liveChannelID && episode.liveChannelID > 0) {
            episodeTabs[1].disabled = false; // Live stream tab
        }

        switch (episode.posterType) {
            case 2: // Video
                episodeTabs[4].disabled = false; // Video
                episodeTabs[5].disabled = false; // Video Products
                episodeTabs[6].disabled = true; // Photos
                episodeTabs[7].disabled = true; // Photo Products
                break;
            case 4: // Photos
                episodeTabs[4].disabled = true; // Video
                episodeTabs[5].disabled = true; // Video Products
                episodeTabs[6].disabled = false; // Photos
                episodeTabs[7].disabled = false; // Photo Products
                break;
            default: // Poster
                episodeTabs[4].disabled = true; // Video
                episodeTabs[5].disabled = true; // Video Products
                episodeTabs[6].disabled = true; // Photos
                episodeTabs[7].disabled = true; // Photo Products
                break;
        }

        dispatch({ type: 'setTabs', tabs: episodeTabs })
    }

    useEffect(() => {
        const fetchEpisode = async () => {
            const episodeResult = await API.getEpisode({ id: props.episode.id });
            dispatch({ type: 'setEpisode', episode: episodeResult });

            if (!episodeResult) return;

            setTabs(episodeResult);
        }

        fetchEpisode();

        if (props.location && props.location.state && props.location.state.episodeTab) setTab(props.location.state.episodeTab);

        // @ts-ignore - for some reason it doesn't like the .subscribe.... 
        const onUpdateEpisodeSubscription = AmplifyAPI.graphql(graphqlOperation(onUpdateEpisode, { id: props.episode.id })).subscribe({
            next: (data) => {
                let updatedEpisode = data.value.data.onUpdateEpisode;
                dispatch({
                    type: 'updateEpisodeFromSubscription',
                    episode: updatedEpisode
                })

                setTabs(updatedEpisode);
            },
            error: (error) => {
                console.error(error);
            }
        });

        return function cleanup() {
            // Stop receiving data updates from the subscription
            onUpdateEpisodeSubscription.unsubscribe();
        };
    }, [props.episode])

    useEffect(() => {
        if (!props.show.customerID) return;

        const fetchCustomerProducts = async () => {
            const productsResult = await API.listProducts({ customerID: props.show.customerID });
            setProducts(productsResult);
        }

        fetchCustomerProducts();
    }, [props.show])

    const handleTabChange = (_, newValue) => {
        setTab(newValue);
    };

    return episodeState.episode ? (
        <>
            <Grid item xs={12}>
                <Typography variant="h6">
                    {`S${episodeState.episode.season}E${episodeState.episode.number}: ${episodeState.episode.name}`}
                </Typography>
                <AppBar position="static" className={classes.mainBar}>
                    <Tabs value={tab} onChange={handleTabChange} aria-label="episode-tabs" variant="scrollable" scrollButtons="on">
                        {
                            episodeState.tabs.map((tab, index) => {
                                return (
                                    <Tab key={tab.key} label={tab.label} {...a11yProps(`${index}-episode`)} className={classes.tab} disabled={tab.disabled} />
                                )
                            })
                        }
                    </Tabs>
                </AppBar>
            </Grid>
            <GeneralInfoTab tab={tab} index={0} episode={episodeState.episode} show={props.show} />
            <LiveStreamTab tab={tab} index={1} />
            <EpisodeOptionsTab tab={tab} index={2} episode={episodeState.episode} />
            <SearchMetadataTab tab={tab} index={3} episodeID={episodeState.episode.id} showID={episodeState.episode.showID} />
            {
                episodeState.episode.posterType === 2 &&
                <>
                    <VideoTab tab={tab} index={4} episode={episodeState.episode} customerID={props.show.customerID} />
                    <VideoProductsTab tab={tab} index={5} episode={episodeState.episode} products={products} />
                </>
            }
            {
                episodeState.episode.posterType === 4 &&
                <>
                    <PhotosTab tab={tab} index={6} episode={episodeState.episode} customerID={props.show.customerID} />
                    <PhotoProductsTab tab={tab} index={7} episode={episodeState.episode} products={products} />
                </>
            }
        </>
    ) : (<></>)
}

export interface EpisodeProps {
    show: Show
    episode: EpisodeModel
    location: any
}