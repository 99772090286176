import React, { useState, useEffect, useReducer } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { Collapse, List, ListItem, ListItemText, Divider } from '@material-ui/core';

import IconExpandLess from '@material-ui/icons/ExpandLess'
import IconExpandMore from '@material-ui/icons/ExpandMore'

import { Maybe, Show } from '../../../types';
import * as API from '../../../lib/api';
import { TabPanel } from '../../tabs/TabPanel';
import { Episode } from '../episode/Episode';

import { API as AmplifyAPI, graphqlOperation } from 'aws-amplify';
import { onCreateEpisode, onUpdateShowEpisode } from '../../../graphql/subscriptions';

const useStyles = makeStyles((theme) => ({
    divider: {
        color: 'white',
        backgroundColor: 'white'
    }
}));

const initialState = {
    seasons: [],
    episodes: [],
    loading: true,
    error: false
}

function reducer(state, action) {
    switch (action.type) {
        case 'fetchSeasonsSuccess':
            return {
                ...state,
                seasons: action.seasons
            }
        case 'fetchEpisodesSuccess':
            return {
                ...state,
                episodes: action.episodes
            }
        case 'addEpisodeFromSubscription':
            let seasons = state.seasons;
            let seasonExists = seasons.find(season => season.season === action.episode.season);
            if (!seasonExists) seasons.push({ season: action.episode.season, name: '' });

            return {
                ...state,
                seasons: seasons,
                episodes: [
                    action.episode,
                    ...state.episodes
                ]
            }
        case 'updateEpisodeFromSubscription':
            let toUpdate = state.episodes.find(episode => episode.id === action.episode.id);
            toUpdate = Object.assign(toUpdate, action.episode);

            let currentSeasons = state.seasons;
            let episodeSeasonExists = currentSeasons.find(season => season.season === action.episode.season);
            if (!episodeSeasonExists) currentSeasons.push({ season: action.episode.season, name: '' });

            let episodes = state.episodes.filter(episode => episode.id !== action.episode.id);
            episodes.push(toUpdate);

            return {
                ...state,
                seasons: currentSeasons,
                episodes: episodes
            }
        default:
            throw new Error();
    }
}

export function SeasonsEpisodesTab(props: SeasonsEpisodesTabProps) {
    const [selectedEpisode, setSelectedEpisode] = useState<any>();
    const [seasonsEpisodesState, dispatch] = useReducer(reducer, initialState)

    const clear = () => {
        setSelectedEpisode(undefined);
    }

    useEffect(() => {
        clear();
        if (!props.show?.id) return;

        // TODO: I think we can just get episodes and get seasons from there... instead of this seasons call!
        const fetchShowSeasons = async () => {
            const showSeasonsResult = await API.getShowSeasons({ showID: props.show!.id });
            showSeasonsResult.push({ season: -1, name: 'Commercials' }) // TODO: Fix thissss
            showSeasonsResult.push({ season: -2, name: 'Live Stream' }) // TODO: Fix thissss

            dispatch({ type: 'fetchSeasonsSuccess', seasons: showSeasonsResult })
        }

        const fetchEpisodes = async () => {
            const showEpisodesResult = await API.listEpisodes({ showID: props.show!.id });
            dispatch({ type: 'fetchEpisodesSuccess', episodes: showEpisodesResult })

            if (props.location.state && props.location.state.episodeID) {
                let episode = showEpisodesResult.find(episode => episode.id === props.location.state.episodeID);
                if (episode) setSelectedEpisode(episode);
            }
        }

        fetchShowSeasons();
        fetchEpisodes();

        // @ts-ignore - for some reason it doesn't like the .subscribe.... 
        const createEpisodeSubscription = AmplifyAPI.graphql(graphqlOperation(onCreateEpisode, { showID: props.show.id })).subscribe({
            next: (data) => {
                let newEpisode = data.value.data.onCreateEpisode;
                if (newEpisode) {
                    dispatch({
                        type: 'addEpisodeFromSubscription',
                        episode: newEpisode
                    })
                } else {
                    console.error('Something went wrong... but the episode got created');
                }
            },
            error: (error) => {
                console.warn(error);
            }
        });

        // @ts-ignore - for some reason it doesn't like the .subscribe.... 
        const updateShowEpisodeSubscription = AmplifyAPI.graphql(graphqlOperation(onUpdateShowEpisode, { showID: props.show.id })).subscribe({
            next: (data) => {
                let updatedEpisode = data.value.data.onUpdateShowEpisode;
                if (updatedEpisode) {
                    dispatch({
                        type: 'updateEpisodeFromSubscription',
                        episode: updatedEpisode
                    })
                } else {
                    console.error('Something went wrong... but an episode was updated');
                }
            },
            error: (error) => {
                console.warn(error);
            }
        });

        return function cleanup() {
            // Stop receiving data updates from the subscription
            createEpisodeSubscription.unsubscribe();
            updateShowEpisodeSubscription.unsubscribe();
        };
    }, [props.show])

    const onSelectEpisode = (episode) => {
        setSelectedEpisode(episode);
    }

    return (
        <TabPanel value={props.tab} index={props.index} spacing={2}>
            <Grid container spacing={2} direction="row">
                <Grid item container spacing={2} direction="column" xs={2}>
                    <Grid item>
                        {
                            seasonsEpisodesState.seasons.length === 0
                                ? <Typography>No seasons/episodes have been added. Add an episode to get started</Typography>
                                : seasonsEpisodesState.seasons.map((season: any, index) => {
                                    let seasonEpisodes = seasonsEpisodesState.episodes.filter(episode => episode.season === season.season);
                                    seasonEpisodes = seasonEpisodes.sort(episodeCompare);
                                    if (seasonEpisodes.length > 0) {
                                        return (
                                            <CollapsibleSeason key={index}
                                                groupingName={props.show?.appData?.videoGroupingName || 'Season'}
                                                seasonNumber={season.season}
                                                seasonName={season.name || ''}
                                                episodes={seasonEpisodes}
                                                onSelectEpisode={onSelectEpisode}
                                            />
                                        )
                                    }
                                })
                        }
                    </Grid>
                </Grid>
                <Grid item container spacing={2} direction="row" alignItems="flex-start" alignContent="flex-start" justify="flex-start" xs>
                    {
                        !selectedEpisode || !props.show
                            ? <Typography>No Episode Selected</Typography>
                            : <Episode show={props.show} episode={selectedEpisode!} location={props.location} />
                    }
                </Grid>
            </Grid>
        </TabPanel>
    )
}
export interface SeasonsEpisodesTabProps {
    tab: number
    index: number
    show: Maybe<Show>
    location: any
}

function CollapsibleSeason(props: CollapsibleSeasonProps) {
    const classes = useStyles();
    const { seasonNumber, seasonName, episodes, onSelectEpisode } = props
    const [open, setOpen] = useState(false);

    function handleClick() {
        setOpen(!open)
    }

    return (
        <>
            <ListItem button onClick={handleClick}>
                <ListItemText primary={seasonName ? seasonName : `${props.groupingName} ${seasonNumber}`} />
                {/* Display the expand menu if the item has children */}
                {!open && <IconExpandMore />}
                {open && <IconExpandLess />}
            </ListItem>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <Divider className={classes.divider} />
                <List>
                    {
                        episodes.map((episode, subIndex) => (
                            <ListItem key={`${episode.number}-${subIndex}`} button onClick={(e) => onSelectEpisode(episode)}>
                                <ListItemText
                                    primary={seasonNumber > 0 ? `S${seasonNumber}E${episode.number}: ${episode.name}` : episode.name}
                                    primaryTypographyProps={{ variant: "caption" }}
                                />
                            </ListItem>
                        ))
                    }
                </List>
            </Collapse>
        </>
    )
}

interface CollapsibleSeasonProps {
    groupingName: string
    seasonNumber: number
    seasonName?: string
    episodes: any[]
    onSelectEpisode: Function
}

function episodeCompare(a, b) {
    if (a.number < b.number) return -1;
    if (a.number > b.number) return 1;
    return 0;
}