import React, { useState, useEffect, forwardRef } from 'react';
import { useHistory } from 'react-router-dom';
import {
    Grid, Button, TextField, Popover,
    Dialog, DialogActions, DialogTitle, DialogContent, DialogContentText
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import MaterialTable, { MTableToolbar, Icons } from 'material-table'; // typescript specific import https://github.com/mbrn/material-table/issues/1150

import DeleteIcon from '@material-ui/icons/Delete'
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import LastPage from '@material-ui/icons/LastPage';// import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';

import * as API from '../../lib/api';
import useSnackbars from '../../hooks/useSnackbars';

import { Category } from '../../types/api';
import Maybe from 'graphql/tsutils/Maybe';

const useStyles = makeStyles((theme) => ({
    tableToolBar: {
        backgroundColor: '#f9f9f9!important'
    },
    addCategoryButton: {
        backgroundColor: "#282733",
        "&:hover": {
            backgroundColor: "black"
        }
    },
    deleteButton: {
        color: "red"
    },
    checkmark: {
        color: 'green'
    }
}));

const tableIcons: Icons = {
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteIcon {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

export function ProductCategories(props: ProductCategoriesProps) {
    const classes = useStyles();
    const history = useHistory();
    const { createSuccessSnack, createErrorSnack } = useSnackbars();
    const [categories, setCategories] = useState<Array<Category>>([]);
    const [newCategoryName, setNewCategoryName] = useState('');
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [toDelete, setToDelete] = useState<Maybe<Category>>(null);

    useEffect(() => {
        if (!props.customerID) return;

        fetchCategories();
    }, [props.customerID])

    const fetchCategories = async () => {
        const categoriesResult = await API.listCategories({ customerID: props.customerID });
        setCategories(categoriesResult)
    }

    const addCategory = async () => {
        const input = {
            customerID: props.customerID,
            name: newCategoryName
        }

        const categoryResult = await API.createCategory(input);
        if (categoryResult) {
            createSuccessSnack('Successfully created category');
            setCategories(categories.concat(categoryResult));
        } else {
            createErrorSnack('Failed to create category');
        }
    }

    const editCategory = async (_, rowData) => {
        history.push(`/products/categories/${rowData.id}`);
    }

    const deleteCategory = async (_, rowData) => {
        setConfirmOpen(true);
        setToDelete(rowData);
    }

    const onConfirmDelete = async () => {
        if (!toDelete) return;

        try {
            const success = await API.deleteCategory({ id: toDelete.id, customerID: toDelete.customerID });
            if (success) {
                const filtered = categories.filter(category => category.id !== toDelete.id);
                setCategories(filtered);
                onConfirmClose();
            } else {
                createErrorSnack('Failed to delete category');
            }
        } catch (err) {
            console.error(err);
            createErrorSnack('Failed to delete category');
        }
    }

    const onConfirmClose = () => {
        setConfirmOpen(false);
        setToDelete(null);
    }

    const renderTableImage = (rowData) => {
        return (
            <CategoryImage category={rowData} />
        )
    }

    const renderActiveColor = (event, rowData) => {
        return rowData.active === true ? { color: "green" } : { color: "red" };
    }

    return (
        <Grid item container spacing={2} direction="column">
            <Dialog open={confirmOpen}
                onClose={onConfirmClose}
                aria-labelledby="confirm-product-delete-dialog"
                aria-describedby="confirm-product-delete"
            >
                <DialogTitle id="reply-dialog">{`Confirm Deletion Of Category: "${toDelete?.name}"`}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="reply-dialog-information">
                        Warning: Deleting this category will remove all photos, and unlink products from the category. Are you SURE you want to delete this category?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onConfirmDelete} color="primary">
                        Delete
                    </Button>
                    <Button onClick={onConfirmClose} color="secondary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
            <Grid item container spacing={2} direction="row">
                <Grid item>
                    <TextField label="Category Name"
                        value={newCategoryName}
                        onChange={(e) => setNewCategoryName(e.target.value)}
                    />
                </Grid>
                <Grid item>
                    <Button variant="contained" color="secondary" onClick={addCategory}>
                        Add New Category
                    </Button>
                </Grid>
            </Grid>
            <Grid item>
                <MaterialTable
                    title="Categories"
                    icons={tableIcons}
                    columns={[
                        { title: "Thumbnail", field: "thumbnailURL", filtering: false, render: renderTableImage },
                        { title: "Category Name", field: "name", filterPlaceholder: "filter", hideFilterIcon: true, filtering: false },
                        { title: "Active", field: "active", type: "boolean", cellStyle: renderActiveColor },
                    ]}
                    data={categories}
                    components={{
                        Toolbar: props => (
                            <div className={classes.tableToolBar}>
                                <MTableToolbar {...props} className={classes.tableToolBar} />
                            </div>
                        )
                    }}
                    options={{
                        pageSize: 25,
                        pageSizeOptions: [10, 25, 50],
                        filtering: true,
                        actionsColumnIndex: -1
                    }}
                    actions={[
                        { icon: (() => (<Edit />)), tooltip: 'Edit Category', onClick: editCategory },
                        { icon: (() => (<DeleteIcon className={classes.deleteButton} />)), tooltip: 'Delete Category', onClick: deleteCategory }
                    ]}
                />
            </Grid>
        </Grid>
    )
}

function CategoryImage(props: CategoriesImageProps) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleImageClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleImageClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? props?.category?.name : undefined;

    return (
        <>
            <Button onClick={handleImageClick}>
                <img src={props.category?.thumbnailURL || ''}
                    height="50px"
                    width="50px"
                    alt="small product" />
            </Button>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleImageClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <img src={props.category?.thumbnailURL || ''} height="300" alt="large product" />
            </Popover>
        </>
    )
}


export interface ProductCategoriesProps {
    authData: any
    customerID: number
}

export interface CategoriesImageProps {
    category: Category
}