import React, { useState, useEffect } from 'react';
import { Grid, Card } from '@material-ui/core';

import { Bar } from 'react-chartjs-2';

import { formattedTime } from '../../../lib/util';

const title = 'Stream Metrics By Hour';
const colorString = 'rgb(128, 0, 128)';

interface data {
    labels: any[]
    datasets: any[]
}

export function StreamMetricsByHourChart(props: StreamMetricsByHourChartProps) {
    const [streamMetricsData, setstreamMetricsData] = useState<data>({
        labels: [],
        datasets: [{
            label: title,
            backgroundColor: colorString,
            borderColor: colorString,
            data: []
        }]
    });
    const [options] = useState({
        scales: {
            yAxes: [{
                scaleLabel: {
                    labelString: 'Video Streamed',
                    display: true,
                    fontSize: 16
                },
                ticks: {
                    callback: formattedTime
                }
            }]
        }
    });

    useEffect(() => {
        let rawData = props.streamDataByHour;
        if (!rawData) return;

        let timeData: any[] = [];

        let labels = rawData.map(hourEntry => {

            timeData.push(hourEntry.totalSecs)

            return hourEntry.hourNumber
        })

        const streamData = {
            labels: labels,
            datasets: [{
                label: title,
                backgroundColor: colorString,
                borderColor: colorString,
                data: timeData
            }]
        }

        // const streamDataOptions = 

        setstreamMetricsData(streamData)
    }, [props.streamDataByHour])

    return (
        <Card>
            <Grid container spacing={2} direction="column" xs>
                <Grid item>
                    <Bar data={streamMetricsData} options={options} height={50} />
                </Grid>
            </Grid>
        </Card>
    )
}

export interface StreamMetricsByHourChartProps {
    streamDataByHour: any
}