/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getDashboardReport = /* GraphQL */ `
  query GetDashboardReport(
    $customerID: Int!
    $fromDate: AWSDateTime!
    $toDate: AWSDateTime!
  ) {
    getDashboardReport(
      customerID: $customerID
      fromDate: $fromDate
      toDate: $toDate
    ) {
      totalSeconds
      totalBytes
      previousTotalSeconds
      previousTotalBytes
      ipAddressCount
      previousIPAddressCount
      totalViews
      totalClicks
    }
  }
`;
export const getDashboardTopTenEpisodes = /* GraphQL */ `
  query GetDashboardTopTenEpisodes(
    $customerID: Int!
    $fromDate: AWSDateTime!
    $toDate: AWSDateTime!
  ) {
    getDashboardTopTenEpisodes(
      customerID: $customerID
      fromDate: $fromDate
      toDate: $toDate
    ) {
      items {
        ShowId
        EpisodeId
        ShowNameShort
        Season
        EpisodeNo
        EpisodeName
        EpisodePoster
        PosterFileId
        totalViews
      }
    }
  }
`;
export const getDashboardTopTenProductsImpressions = /* GraphQL */ `
  query GetDashboardTopTenProductsImpressions(
    $customerID: Int!
    $fromDate: AWSDateTime!
    $toDate: AWSDateTime!
  ) {
    getDashboardTopTenProductsImpressions(
      customerID: $customerID
      fromDate: $fromDate
      toDate: $toDate
    ) {
      items {
        productId
        BrandName
        Name
        MainPhotoURL
        MainPhotoFileId
        MainPhotoFileExt
        totalViews
      }
    }
  }
`;
export const getDashboardTopTenProductsViews = /* GraphQL */ `
  query GetDashboardTopTenProductsViews(
    $customerID: Int!
    $fromDate: AWSDateTime!
    $toDate: AWSDateTime!
  ) {
    getDashboardTopTenProductsViews(
      customerID: $customerID
      fromDate: $fromDate
      toDate: $toDate
    ) {
      items {
        productId
        BrandName
        Name
        MainPhotoURL
        MainPhotoFileId
        MainPhotoFileExt
        totalViews
      }
    }
  }
`;
export const getDashboardTopTenProductsClicks = /* GraphQL */ `
  query GetDashboardTopTenProductsClicks(
    $customerID: Int!
    $fromDate: AWSDateTime!
    $toDate: AWSDateTime!
  ) {
    getDashboardTopTenProductsClicks(
      customerID: $customerID
      fromDate: $fromDate
      toDate: $toDate
    ) {
      items {
        productId
        BrandName
        Name
        MainPhotoURL
        MainPhotoFileId
        MainPhotoFileExt
        totalViews
      }
    }
  }
`;
export const listCustomers = /* GraphQL */ `
  query ListCustomers($limit: Int) {
    listCustomers(limit: $limit) {
      items {
        id
        shortName
        fullName
        active
        added
        adminType
        isVendor
        emailAddressIdentity
        streamingContent
        displayShowReports
        displayEpisodeReports
      }
    }
  }
`;
export const getCustomer = /* GraphQL */ `
  query GetCustomer($id: Int!) {
    getCustomer(id: $id) {
      id
      shortName
      fullName
      active
      added
      adminType
      isVendor
      emailAddressIdentity
      streamingContent
      displayShowReports
      displayEpisodeReports
    }
  }
`;
export const listShows = /* GraphQL */ `
  query ListShows($customerID: Int!) {
    listShows(customerID: $customerID) {
      items {
        id
        customerID
        nameShort
        nameLong
        description
        poster
        posterURL
        added
        active
        hideInAdmin
        appID
        mainMenuType
        faqContent
        faviconsURL
        heroImageBucket
        heroImageKey
        appData {
          appLogo
          appLogoURL
          primaryColor
          textPrimaryColor
          secondaryColor
          tertiaryColor
          accentColor
          infoColor
          headBackgroundColor
          headTextColor
          menuTextColor
          productCaroselBackgroundColor
          productButTextColor
          productButBackColor
          productBut2TextColor
          productBut2BackColor
          mobileTextPrimaryColor
          mobileTextSecondaryColor
          mobileAccentColor
          mobileButtonColor
          autoplayEnabled
          autoplayAfterFingerprint
          copyright
          privacyPolicy
          appPosterDisplayType
          episodeTileType
          productTileType
          witAIAppID
          witAIAccessToken
          witAILastUpdate
          googleTrackingID
          googlePlayAppEnabled
          googlePlayAppURL
          facebookAppID
          facebookPageURL
          instagramUsername
          twitterUsername
          iOSAppStoreID
          videoGroupingName
          videoGroupingNamePlural
          featuredProductsLabel
        }
        channel {
          rokuChannelEnabled
          rokuChannelType
          rokuChannelInstallURL
          fireTVChannelEnabled
          fireTVInstallURL
          fireTVDevURL
        }
        demographicConfiguration {
          introText
          ageEnabled
          genderEnabled
          ethnicityEnabled
          educationEnabled
          employmentEnabled
          maritalStatusEnabled
          incomeEnabled
          zipcodeEnabled
          emailEnabled
        }
        productRequestConfiguration {
          enabled
          titleText
          introText
          episodeListEnabled
          episodeListText
          photoGroupEnabled
          photoGroupText
          categoryEnabled
          categoryText
          emailEnabled
          emailText
          requestText
          submitThanksText
          notificationsList
        }
      }
    }
  }
`;
export const getShow = /* GraphQL */ `
  query GetShow($id: Int!) {
    getShow(id: $id) {
      id
      customerID
      nameShort
      nameLong
      description
      poster
      posterURL
      added
      active
      hideInAdmin
      appID
      mainMenuType
      faqContent
      faviconsURL
      heroImageBucket
      heroImageKey
      appData {
        appLogo
        appLogoURL
        primaryColor
        textPrimaryColor
        secondaryColor
        tertiaryColor
        accentColor
        infoColor
        headBackgroundColor
        headTextColor
        menuTextColor
        productCaroselBackgroundColor
        productButTextColor
        productButBackColor
        productBut2TextColor
        productBut2BackColor
        mobileTextPrimaryColor
        mobileTextSecondaryColor
        mobileAccentColor
        mobileButtonColor
        autoplayEnabled
        autoplayAfterFingerprint
        copyright
        privacyPolicy
        appPosterDisplayType
        episodeTileType
        productTileType
        witAIAppID
        witAIAccessToken
        witAILastUpdate
        googleTrackingID
        googlePlayAppEnabled
        googlePlayAppURL
        facebookAppID
        facebookPageURL
        instagramUsername
        twitterUsername
        iOSAppStoreID
        videoGroupingName
        videoGroupingNamePlural
        featuredProductsLabel
      }
      channel {
        rokuChannelEnabled
        rokuChannelType
        rokuChannelInstallURL
        fireTVChannelEnabled
        fireTVInstallURL
        fireTVDevURL
      }
      demographicConfiguration {
        introText
        ageEnabled
        genderEnabled
        ethnicityEnabled
        educationEnabled
        employmentEnabled
        maritalStatusEnabled
        incomeEnabled
        zipcodeEnabled
        emailEnabled
      }
      productRequestConfiguration {
        enabled
        titleText
        introText
        episodeListEnabled
        episodeListText
        photoGroupEnabled
        photoGroupText
        categoryEnabled
        categoryText
        emailEnabled
        emailText
        requestText
        submitThanksText
        notificationsList
      }
    }
  }
`;
export const getShowByHostname = /* GraphQL */ `
  query GetShowByHostname($hostname: String!) {
    getShowByHostname(hostname: $hostname) {
      id
      customerID
      nameShort
      nameLong
      description
      poster
      posterURL
      added
      active
      hideInAdmin
      appID
      mainMenuType
      faqContent
      faviconsURL
      heroImageBucket
      heroImageKey
      appData {
        appLogo
        appLogoURL
        primaryColor
        textPrimaryColor
        secondaryColor
        tertiaryColor
        accentColor
        infoColor
        headBackgroundColor
        headTextColor
        menuTextColor
        productCaroselBackgroundColor
        productButTextColor
        productButBackColor
        productBut2TextColor
        productBut2BackColor
        mobileTextPrimaryColor
        mobileTextSecondaryColor
        mobileAccentColor
        mobileButtonColor
        autoplayEnabled
        autoplayAfterFingerprint
        copyright
        privacyPolicy
        appPosterDisplayType
        episodeTileType
        productTileType
        witAIAppID
        witAIAccessToken
        witAILastUpdate
        googleTrackingID
        googlePlayAppEnabled
        googlePlayAppURL
        facebookAppID
        facebookPageURL
        instagramUsername
        twitterUsername
        iOSAppStoreID
        videoGroupingName
        videoGroupingNamePlural
        featuredProductsLabel
      }
      channel {
        rokuChannelEnabled
        rokuChannelType
        rokuChannelInstallURL
        fireTVChannelEnabled
        fireTVInstallURL
        fireTVDevURL
      }
      demographicConfiguration {
        introText
        ageEnabled
        genderEnabled
        ethnicityEnabled
        educationEnabled
        employmentEnabled
        maritalStatusEnabled
        incomeEnabled
        zipcodeEnabled
        emailEnabled
      }
      productRequestConfiguration {
        enabled
        titleText
        introText
        episodeListEnabled
        episodeListText
        photoGroupEnabled
        photoGroupText
        categoryEnabled
        categoryText
        emailEnabled
        emailText
        requestText
        submitThanksText
        notificationsList
      }
    }
  }
`;
export const getShowReport = /* GraphQL */ `
  query GetShowReport(
    $showID: Int!
    $fromDate: AWSDateTime!
    $toDate: AWSDateTime!
  ) {
    getShowReport(showID: $showID, fromDate: $fromDate, toDate: $toDate) {
      totalSeconds
      totalBytes
      ipCount
      appUsers
      totalParts
      averageViews
      minPartViews
      maxPartViews
      deviceInfo {
        deviceType
        OS
        usercount
        totalbytes
      }
      topEpisodes {
        id
        showID
        showName
        season
        seasonName
        number
        firstRelease
        added
        name
        description
        disclaimer
        enabled
        posterType
        poster
        posterURL
        posterFileID
        video
        videoBucket
        videoKey
        videoQuality
        videoType
        videoWidth
        videoHeight
        mediaConvertJobID
        mediaConvertJobStatus
        audioFingerprintID
        duration
        rokuAddBreaks
        releaseToOTT
        releaseToApp
        liveChannelID
        enableHeaderButton
        headerButtonText
        headerButtonURL
        disableProductScroller
        liveStreamID
        liveStreamEnabledOnStartMenuID
        videoToken
      }
      dateStreams {
        logDate
        logday
        totalSecs
        totalbytes
      }
      streamDataByBandwidth {
        streamKbps
        streamPart
        totalhits
      }
      streamDataByWeekday {
        dayNumber
        weekName
        totalSecs
        totalbytes
      }
      streamDataByHour {
        hourNumber
        totalSecs
        totalbytes
      }
    }
  }
`;
export const getShowSeasons = /* GraphQL */ `
  query GetShowSeasons($showID: Int!) {
    getShowSeasons(showID: $showID) {
      items {
        showID
        season
        name
      }
    }
  }
`;
export const getShowDesignMenu = /* GraphQL */ `
  query GetShowDesignMenu($showID: Int!) {
    getShowDesignMenu(showID: $showID) {
      items {
        id
        showID
        orderID
        title
        description
        icon
        iconColor
        url
        showInPullOutMenu
        showInHomeMenu
        enabled
      }
    }
  }
`;
export const getShowMetadata = /* GraphQL */ `
  query GetShowMetadata($showID: Int!) {
    getShowMetadata(showID: $showID) {
      items {
        id
        showID
        name
        value
      }
    }
  }
`;
export const listEpisodes = /* GraphQL */ `
  query ListEpisodes($showID: Int!) {
    listEpisodes(showID: $showID) {
      items {
        id
        showID
        showName
        season
        seasonName
        number
        firstRelease
        added
        name
        description
        disclaimer
        enabled
        posterType
        poster
        posterURL
        posterFileID
        video
        videoBucket
        videoKey
        videoQuality
        videoType
        videoWidth
        videoHeight
        mediaConvertJobID
        mediaConvertJobStatus
        audioFingerprintID
        duration
        rokuAddBreaks
        releaseToOTT
        releaseToApp
        liveChannelID
        enableHeaderButton
        headerButtonText
        headerButtonURL
        disableProductScroller
        liveStreamID
        liveStreamEnabledOnStartMenuID
        videoToken
      }
    }
  }
`;
export const listAppEpisodes = /* GraphQL */ `
  query ListAppEpisodes($showID: Int!, $limit: Int) {
    listAppEpisodes(showID: $showID, limit: $limit) {
      items {
        id
        showID
        showName
        season
        seasonName
        number
        firstRelease
        added
        name
        description
        disclaimer
        enabled
        posterType
        poster
        posterURL
        posterFileID
        video
        videoBucket
        videoKey
        videoQuality
        videoType
        videoWidth
        videoHeight
        mediaConvertJobID
        mediaConvertJobStatus
        audioFingerprintID
        duration
        rokuAddBreaks
        releaseToOTT
        releaseToApp
        liveChannelID
        enableHeaderButton
        headerButtonText
        headerButtonURL
        disableProductScroller
        liveStreamID
        liveStreamEnabledOnStartMenuID
        videoToken
      }
    }
  }
`;
export const listOttEpisodes = /* GraphQL */ `
  query ListOttEpisodes($showID: Int!, $limit: Int) {
    listOttEpisodes(showID: $showID, limit: $limit) {
      items {
        id
        showID
        showName
        season
        seasonName
        number
        firstRelease
        added
        name
        description
        disclaimer
        enabled
        posterType
        poster
        posterURL
        posterFileID
        video
        videoBucket
        videoKey
        videoQuality
        videoType
        videoWidth
        videoHeight
        mediaConvertJobID
        mediaConvertJobStatus
        audioFingerprintID
        duration
        rokuAddBreaks
        releaseToOTT
        releaseToApp
        liveChannelID
        enableHeaderButton
        headerButtonText
        headerButtonURL
        disableProductScroller
        liveStreamID
        liveStreamEnabledOnStartMenuID
        videoToken
      }
    }
  }
`;
export const listCustomerEpisodes = /* GraphQL */ `
  query ListCustomerEpisodes($customerID: Int!) {
    listCustomerEpisodes(customerID: $customerID) {
      items {
        id
        showID
        showName
        season
        seasonName
        number
        firstRelease
        added
        name
        description
        disclaimer
        enabled
        posterType
        poster
        posterURL
        posterFileID
        video
        videoBucket
        videoKey
        videoQuality
        videoType
        videoWidth
        videoHeight
        mediaConvertJobID
        mediaConvertJobStatus
        audioFingerprintID
        duration
        rokuAddBreaks
        releaseToOTT
        releaseToApp
        liveChannelID
        enableHeaderButton
        headerButtonText
        headerButtonURL
        disableProductScroller
        liveStreamID
        liveStreamEnabledOnStartMenuID
        videoToken
      }
    }
  }
`;
export const listCustomerEpisodesWithPhotoGroups = /* GraphQL */ `
  query ListCustomerEpisodesWithPhotoGroups($customerID: Int!) {
    listCustomerEpisodesWithPhotoGroups(customerID: $customerID) {
      items {
        id
        showID
        showName
        season
        seasonName
        number
        firstRelease
        added
        name
        description
        disclaimer
        enabled
        posterType
        poster
        posterURL
        posterFileID
        video
        videoBucket
        videoKey
        videoQuality
        videoType
        videoWidth
        videoHeight
        mediaConvertJobID
        mediaConvertJobStatus
        audioFingerprintID
        duration
        rokuAddBreaks
        releaseToOTT
        releaseToApp
        liveChannelID
        enableHeaderButton
        headerButtonText
        headerButtonURL
        disableProductScroller
        liveStreamID
        liveStreamEnabledOnStartMenuID
        videoToken
      }
    }
  }
`;
export const getEpisode = /* GraphQL */ `
  query GetEpisode($id: Int!) {
    getEpisode(id: $id) {
      id
      showID
      showName
      season
      seasonName
      number
      firstRelease
      added
      name
      description
      disclaimer
      enabled
      posterType
      poster
      posterURL
      posterFileID
      video
      videoBucket
      videoKey
      videoQuality
      videoType
      videoWidth
      videoHeight
      mediaConvertJobID
      mediaConvertJobStatus
      audioFingerprintID
      duration
      rokuAddBreaks
      releaseToOTT
      releaseToApp
      liveChannelID
      enableHeaderButton
      headerButtonText
      headerButtonURL
      disableProductScroller
      liveStreamID
      liveStreamEnabledOnStartMenuID
      videoToken
    }
  }
`;
export const listEpisodeCommercials = /* GraphQL */ `
  query ListEpisodeCommercials($episodeID: Int!) {
    listEpisodeCommercials(episodeID: $episodeID) {
      items {
        id
        episodeID
        commercialEpisodeID
        type
        spliceTime
        showID
        episodeName
        episodePoster
        episodePosterURL
        enabled
        duration
      }
    }
  }
`;
export const listCommercials = /* GraphQL */ `
  query ListCommercials($showID: Int!) {
    listCommercials(showID: $showID) {
      items {
        id
        showID
        showName
        season
        seasonName
        number
        firstRelease
        added
        name
        description
        disclaimer
        enabled
        posterType
        poster
        posterURL
        posterFileID
        video
        videoBucket
        videoKey
        videoQuality
        videoType
        videoWidth
        videoHeight
        mediaConvertJobID
        mediaConvertJobStatus
        audioFingerprintID
        duration
        rokuAddBreaks
        releaseToOTT
        releaseToApp
        liveChannelID
        enableHeaderButton
        headerButtonText
        headerButtonURL
        disableProductScroller
        liveStreamID
        liveStreamEnabledOnStartMenuID
        videoToken
      }
    }
  }
`;
export const getEpisodeMetadata = /* GraphQL */ `
  query GetEpisodeMetadata($episodeID: Int!) {
    getEpisodeMetadata(episodeID: $episodeID) {
      items {
        id
        episodeID
        name
        value
      }
    }
  }
`;
export const getEpisodeReport = /* GraphQL */ `
  query GetEpisodeReport(
    $showID: Int!
    $episodeID: Int!
    $fromDate: AWSDateTime!
    $toDate: AWSDateTime!
  ) {
    getEpisodeReport(
      showID: $showID
      episodeID: $episodeID
      fromDate: $fromDate
      toDate: $toDate
    ) {
      totalSeconds
      totalBytes
      ipCount
      appUsers
      totalParts
      averageViews
      minPartViews
      maxPartViews
      dateStreams {
        logDate
        logday
        totalSecs
        totalbytes
      }
      streamDataByBandwidth {
        streamKbps
        streamPart
        totalhits
      }
    }
  }
`;
export const getEpisodeProducts = /* GraphQL */ `
  query GetEpisodeProducts($episodeID: Int!, $active: Boolean) {
    getEpisodeProducts(episodeID: $episodeID, active: $active) {
      items {
        id
        episodeID
        productID
        startTimecode
        startTimeSeconds
        startTimeMilliseconds
        endTimecode
        endTimeSeconds
        endTimeMilliseconds
        brandName
        product {
          id
          customerID
          name
          description
          upc
          brand
          brandID
          sku
          active
          added
          deleted
          approved
          replacementProduct
          mainPhotoURL
          mainPhotoFileID
          mainPhotoFileExt
          mainPhotoSize
          issueCount
          photoBucket
          photoKey
          warnings
          originalProductName
          originalProductDescription
          originalProductURL
          originalProductPhotoBucket
          originalProductPhotoKey
          featured
          price
          vendorID
          vendorLogo
          vendorLogoBucket
          vendorLogoKey
        }
        vendorLogo
        vendorLogoBucket
        vendorLogoKey
      }
    }
  }
`;
export const getEpisodePhotoGroups = /* GraphQL */ `
  query GetEpisodePhotoGroups($episodeID: Int!) {
    getEpisodePhotoGroups(episodeID: $episodeID) {
      items {
        id
        episodeID
        name
        order
        enabled
      }
    }
  }
`;
export const getEpisodePhotos = /* GraphQL */ `
  query GetEpisodePhotos($episodeID: Int!) {
    getEpisodePhotos(episodeID: $episodeID) {
      items {
        id
        episodeID
        groupID
        groupName
        order
        title
        description
        fileID
        extension
        url
        thumbnailURL
        enabled
        added
      }
    }
  }
`;
export const getEpisodePhotosByGroup = /* GraphQL */ `
  query GetEpisodePhotosByGroup($groupID: Int!) {
    getEpisodePhotosByGroup(groupID: $groupID) {
      items {
        id
        episodeID
        groupID
        groupName
        order
        title
        description
        fileID
        extension
        url
        thumbnailURL
        enabled
        added
      }
    }
  }
`;
export const getEpisodePhotoProducts = /* GraphQL */ `
  query GetEpisodePhotoProducts($episodeID: Int!) {
    getEpisodePhotoProducts(episodeID: $episodeID) {
      items {
        id
        groupID
        productID
        episodeID
        brandName
        product {
          id
          customerID
          name
          description
          upc
          brand
          brandID
          sku
          active
          added
          deleted
          approved
          replacementProduct
          mainPhotoURL
          mainPhotoFileID
          mainPhotoFileExt
          mainPhotoSize
          issueCount
          photoBucket
          photoKey
          warnings
          originalProductName
          originalProductDescription
          originalProductURL
          originalProductPhotoBucket
          originalProductPhotoKey
          featured
          price
          vendorID
          vendorLogo
          vendorLogoBucket
          vendorLogoKey
        }
        vendorLogo
        vendorLogoBucket
        vendorLogoKey
      }
    }
  }
`;
export const getEpisodePhotoProductsByGroup = /* GraphQL */ `
  query GetEpisodePhotoProductsByGroup($groupID: Int!) {
    getEpisodePhotoProductsByGroup(groupID: $groupID) {
      items {
        id
        groupID
        productID
        episodeID
        brandName
        product {
          id
          customerID
          name
          description
          upc
          brand
          brandID
          sku
          active
          added
          deleted
          approved
          replacementProduct
          mainPhotoURL
          mainPhotoFileID
          mainPhotoFileExt
          mainPhotoSize
          issueCount
          photoBucket
          photoKey
          warnings
          originalProductName
          originalProductDescription
          originalProductURL
          originalProductPhotoBucket
          originalProductPhotoKey
          featured
          price
          vendorID
          vendorLogo
          vendorLogoBucket
          vendorLogoKey
        }
        vendorLogo
        vendorLogoBucket
        vendorLogoKey
      }
    }
  }
`;
export const getEpisodeStreams = /* GraphQL */ `
  query GetEpisodeStreams($episodeID: Int!, $showID: Int!) {
    getEpisodeStreams(episodeID: $episodeID, showID: $showID) {
      items {
        id
        episodeID
        enabled
        type
        bandwidth
        averageBandwidth
        codecs
        resolution
        frameRate
        targetDuration
        targetBandwidth
        filename
        partDuration
        partCount
        videoRoot
        videoToken
      }
    }
  }
`;
export const getEpisodeStream = /* GraphQL */ `
  query GetEpisodeStream(
    $episodeID: Int!
    $streamID: Int
    $targetBandwidth: Int
  ) {
    getEpisodeStream(
      episodeID: $episodeID
      streamID: $streamID
      targetBandwidth: $targetBandwidth
    ) {
      id
      episodeID
      enabled
      type
      bandwidth
      averageBandwidth
      codecs
      resolution
      frameRate
      targetDuration
      targetBandwidth
      filename
      partDuration
      partCount
      videoRoot
      videoToken
    }
  }
`;
export const listProducts = /* GraphQL */ `
  query ListProducts($customerID: Int!) {
    listProducts(customerID: $customerID) {
      items {
        id
        customerID
        name
        description
        upc
        brand
        brandID
        sku
        active
        added
        deleted
        approved
        replacementProduct
        mainPhotoURL
        mainPhotoFileID
        mainPhotoFileExt
        mainPhotoSize
        issueCount
        photoBucket
        photoKey
        warnings
        originalProductName
        originalProductDescription
        originalProductURL
        originalProductPhotoBucket
        originalProductPhotoKey
        featured
        price
        vendorID
        vendorLogo
        vendorLogoBucket
        vendorLogoKey
      }
    }
  }
`;
export const getProduct = /* GraphQL */ `
  query GetProduct($id: Int!) {
    getProduct(id: $id) {
      id
      customerID
      name
      description
      upc
      brand
      brandID
      sku
      active
      added
      deleted
      approved
      replacementProduct
      mainPhotoURL
      mainPhotoFileID
      mainPhotoFileExt
      mainPhotoSize
      issueCount
      photoBucket
      photoKey
      warnings
      originalProductName
      originalProductDescription
      originalProductURL
      originalProductPhotoBucket
      originalProductPhotoKey
      featured
      price
      vendorID
      vendorLogo
      vendorLogoBucket
      vendorLogoKey
    }
  }
`;
export const listRelatedProducts = /* GraphQL */ `
  query ListRelatedProducts($categoryID: Int!, $showID: Int!) {
    listRelatedProducts(categoryID: $categoryID, showID: $showID) {
      items {
        id
        customerID
        name
        description
        upc
        brand
        brandID
        sku
        active
        added
        deleted
        approved
        replacementProduct
        mainPhotoURL
        mainPhotoFileID
        mainPhotoFileExt
        mainPhotoSize
        issueCount
        photoBucket
        photoKey
        warnings
        originalProductName
        originalProductDescription
        originalProductURL
        originalProductPhotoBucket
        originalProductPhotoKey
        featured
        price
        vendorID
        vendorLogo
        vendorLogoBucket
        vendorLogoKey
      }
    }
  }
`;
export const listShowFeaturedProducts = /* GraphQL */ `
  query ListShowFeaturedProducts($showID: Int!, $limit: Int) {
    listShowFeaturedProducts(showID: $showID, limit: $limit) {
      items {
        id
        customerID
        name
        description
        upc
        brand
        brandID
        sku
        active
        added
        deleted
        approved
        replacementProduct
        mainPhotoURL
        mainPhotoFileID
        mainPhotoFileExt
        mainPhotoSize
        issueCount
        photoBucket
        photoKey
        warnings
        originalProductName
        originalProductDescription
        originalProductURL
        originalProductPhotoBucket
        originalProductPhotoKey
        featured
        price
        vendorID
        vendorLogo
        vendorLogoBucket
        vendorLogoKey
      }
    }
  }
`;
export const listAvailableProducts = /* GraphQL */ `
  query ListAvailableProducts {
    listAvailableProducts {
      items {
        product {
          id
          customerID
          name
          description
          upc
          brand
          brandID
          sku
          active
          added
          deleted
          approved
          replacementProduct
          mainPhotoURL
          mainPhotoFileID
          mainPhotoFileExt
          mainPhotoSize
          issueCount
          photoBucket
          photoKey
          warnings
          originalProductName
          originalProductDescription
          originalProductURL
          originalProductPhotoBucket
          originalProductPhotoKey
          featured
          price
          vendorID
          vendorLogo
          vendorLogoBucket
          vendorLogoKey
        }
        customerName
      }
    }
  }
`;
export const getProductLinks = /* GraphQL */ `
  query GetProductLinks($productID: Int!) {
    getProductLinks(productID: $productID) {
      items {
        id
        productID
        storeID
        storeName
        type
        fullURL
        status
        statusLastChecked
      }
    }
  }
`;
export const getProductCategories = /* GraphQL */ `
  query GetProductCategories($productID: Int!) {
    getProductCategories(productID: $productID) {
      items {
        productID
        categoryID
        categoryName
      }
    }
  }
`;
export const getProductSearchMetadata = /* GraphQL */ `
  query GetProductSearchMetadata($productID: Int!) {
    getProductSearchMetadata(productID: $productID) {
      productID
      searchData
      witAISearchData
      searchColor
      searchPeople
      lastUpdate
    }
  }
`;
export const getProductMetadata = /* GraphQL */ `
  query GetProductMetadata($productID: Int!) {
    getProductMetadata(productID: $productID) {
      items {
        id
        productID
        name
        value
      }
    }
  }
`;
export const getProductEpisodes = /* GraphQL */ `
  query GetProductEpisodes($productID: Int!) {
    getProductEpisodes(productID: $productID) {
      items {
        id
        episodeID
        productID
        startTimecode
        startTimeMilliseconds
        startTimeSeconds
        endTimecode
        endTimeMilliseconds
        endTimeSeconds
        showName
        episodeName
        episodeSeasonName
        episodeSeason
        episodeNumber
      }
    }
  }
`;
export const getProductFeaturedEpisodes = /* GraphQL */ `
  query GetProductFeaturedEpisodes($productID: Int!) {
    getProductFeaturedEpisodes(productID: $productID) {
      items {
        id
        episodeID
        productID
        startTimecode
        startTimeMilliseconds
        startTimeSeconds
        endTimecode
        endTimeMilliseconds
        endTimeSeconds
        showName
        episodeName
        episodeSeasonName
        episodeSeason
        episodeNumber
      }
    }
  }
`;
export const getProductEpisodePhotoGroups = /* GraphQL */ `
  query GetProductEpisodePhotoGroups($productID: Int!) {
    getProductEpisodePhotoGroups(productID: $productID) {
      items {
        id
        groupID
        groupName
        showName
        episode {
          id
          showID
          showName
          season
          seasonName
          number
          firstRelease
          added
          name
          description
          disclaimer
          enabled
          posterType
          poster
          posterURL
          posterFileID
          video
          videoBucket
          videoKey
          videoQuality
          videoType
          videoWidth
          videoHeight
          mediaConvertJobID
          mediaConvertJobStatus
          audioFingerprintID
          duration
          rokuAddBreaks
          releaseToOTT
          releaseToApp
          liveChannelID
          enableHeaderButton
          headerButtonText
          headerButtonURL
          disableProductScroller
          liveStreamID
          liveStreamEnabledOnStartMenuID
          videoToken
        }
      }
    }
  }
`;
export const getProductVendor = /* GraphQL */ `
  query GetProductVendor($productID: Int!) {
    getProductVendor(productID: $productID) {
      id
      name
      type
      active
      salesOwnedByUserID
      balance
      balanceUpdated
      logo
      logoFileID
      logoFileExtension
      logoBucket
      logoKey
      pixelTrackingID
      createdAt
      updatedAt
    }
  }
`;
export const getProductRequestsByShow = /* GraphQL */ `
  query GetProductRequestsByShow($showID: Int!, $status: Int) {
    getProductRequestsByShow(showID: $showID, status: $status) {
      items {
        id
        showID
        appInstallID
        episodeID
        episodeSeason
        episodeNumber
        episodeName
        photoGroup
        category
        email
        request
        added
        ip
        status
        lastChangedByAUID
        userSub
        response
      }
    }
  }
`;
export const getProductRequestsByUser = /* GraphQL */ `
  query GetProductRequestsByUser($userID: ID) {
    getProductRequestsByUser(userID: $userID) {
      items {
        id
        showID
        appInstallID
        episodeID
        episodeSeason
        episodeNumber
        episodeName
        photoGroup
        category
        email
        request
        added
        ip
        status
        lastChangedByAUID
        userSub
        response
      }
    }
  }
`;
export const getProductsReport = /* GraphQL */ `
  query GetProductsReport(
    $customerID: Int!
    $fromDate: AWSDateTime!
    $toDate: AWSDateTime!
  ) {
    getProductsReport(
      customerID: $customerID
      fromDate: $fromDate
      toDate: $toDate
    )
  }
`;
export const getVendorProductsReport = /* GraphQL */ `
  query GetVendorProductsReport(
    $vendorID: ID!
    $fromDate: AWSDateTime!
    $toDate: AWSDateTime!
  ) {
    getVendorProductsReport(
      vendorID: $vendorID
      fromDate: $fromDate
      toDate: $toDate
    )
  }
`;
export const getAdminAdReport = /* GraphQL */ `
  query GetAdminAdReport($fromDate: AWSDateTime!, $toDate: AWSDateTime!) {
    getAdminAdReport(fromDate: $fromDate, toDate: $toDate)
  }
`;
export const getEngagementDeviceInfo = /* GraphQL */ `
  query GetEngagementDeviceInfo(
    $customerID: Int!
    $fromDate: AWSDateTime!
    $toDate: AWSDateTime!
  ) {
    getEngagementDeviceInfo(
      customerID: $customerID
      fromDate: $fromDate
      toDate: $toDate
    ) {
      items {
        deviceType
        os
        userCount
      }
    }
  }
`;
export const getEngagementTopTwentyLocations = /* GraphQL */ `
  query GetEngagementTopTwentyLocations(
    $customerID: Int!
    $fromDate: AWSDateTime!
    $toDate: AWSDateTime!
  ) {
    getEngagementTopTwentyLocations(
      customerID: $customerID
      fromDate: $fromDate
      toDate: $toDate
    ) {
      items {
        country
        state
        totalVisits
      }
    }
  }
`;
export const getEngagementEpisodeViews = /* GraphQL */ `
  query GetEngagementEpisodeViews(
    $customerID: Int!
    $fromDate: AWSDateTime!
    $toDate: AWSDateTime!
  ) {
    getEngagementEpisodeViews(
      customerID: $customerID
      fromDate: $fromDate
      toDate: $toDate
    ) {
      items {
        ShowId
        EpisodeId
        ShowNameShort
        Season
        EpisodeNo
        EpisodeName
        EpisodePoster
        PosterFileId
        totalViews
      }
    }
  }
`;
export const getEngagementActivityByWeekday = /* GraphQL */ `
  query GetEngagementActivityByWeekday(
    $customerID: Int!
    $fromDate: AWSDateTime!
    $toDate: AWSDateTime!
  ) {
    getEngagementActivityByWeekday(
      customerID: $customerID
      fromDate: $fromDate
      toDate: $toDate
    ) {
      items {
        dayNumber
        day
        totalCount
      }
    }
  }
`;
export const getEngagementActivityByDate = /* GraphQL */ `
  query GetEngagementActivityByDate(
    $customerID: Int!
    $fromDate: AWSDateTime!
    $toDate: AWSDateTime!
  ) {
    getEngagementActivityByDate(
      customerID: $customerID
      fromDate: $fromDate
      toDate: $toDate
    ) {
      items {
        date
        totalCount
      }
    }
  }
`;
export const getMobileActivityReport = /* GraphQL */ `
  query GetMobileActivityReport(
    $fromDate: AWSDateTime!
    $toDate: AWSDateTime!
  ) {
    getMobileActivityReport(fromDate: $fromDate, toDate: $toDate) {
      items {
        eventType
        totalCount
      }
    }
  }
`;
export const getMobilePageViewReport = /* GraphQL */ `
  query GetMobilePageViewReport(
    $fromDate: AWSDateTime!
    $toDate: AWSDateTime!
  ) {
    getMobilePageViewReport(fromDate: $fromDate, toDate: $toDate) {
      items {
        screen
        appid
        totalCount
      }
    }
  }
`;
export const checkIfProductExists = /* GraphQL */ `
  query CheckIfProductExists($customerID: Int!, $productURL: String!) {
    checkIfProductExists(customerID: $customerID, productURL: $productURL) {
      product {
        id
        customerID
        name
        description
        upc
        brand
        brandID
        sku
        active
        added
        deleted
        approved
        replacementProduct
        mainPhotoURL
        mainPhotoFileID
        mainPhotoFileExt
        mainPhotoSize
        issueCount
        photoBucket
        photoKey
        warnings
        originalProductName
        originalProductDescription
        originalProductURL
        originalProductPhotoBucket
        originalProductPhotoKey
        featured
        price
        vendorID
        vendorLogo
        vendorLogoBucket
        vendorLogoKey
      }
      storeID
    }
  }
`;
export const scrapeProductUrl = /* GraphQL */ `
  query ScrapeProductUrl($productURL: String!) {
    scrapeProductURL(productURL: $productURL) {
      title
      description
      keywords
      brand
      sku
      imageURL
      domainName
      site_name
      url
      imageURLs
    }
  }
`;
export const walmartSearch = /* GraphQL */ `
  query WalmartSearch($query: String!) {
    walmartSearch(query: $query) {
      items {
        id
        parentID
        name
        msrp
        salePrice
        upc
        modelNumber
        description
        category
        productURL
        affiliateURL
        thumbnailImageURL
        imageURL
      }
    }
  }
`;
export const listStores = /* GraphQL */ `
  query ListStores($customerID: Int!) {
    listStores(customerID: $customerID) {
      items {
        id
        customerID
        name
        active
        added
        domainName
        searchURL
        productCount
      }
    }
  }
`;
export const listActiveStores = /* GraphQL */ `
  query ListActiveStores($customerID: Int!) {
    listActiveStores(customerID: $customerID) {
      items {
        id
        customerID
        name
        active
        added
        domainName
        searchURL
        productCount
      }
    }
  }
`;
export const getStore = /* GraphQL */ `
  query GetStore($id: Int!) {
    getStore(id: $id) {
      id
      customerID
      name
      active
      added
      domainName
      searchURL
      productCount
    }
  }
`;
export const listCategories = /* GraphQL */ `
  query ListCategories($customerID: Int!) {
    listCategories(customerID: $customerID) {
      items {
        id
        customerID
        name
        image
        imageURL
        imageFileSize
        active
        thumbnail
        thumbnailURL
        thumbnailFileSize
        tag
        description
      }
    }
  }
`;
export const listActiveCategories = /* GraphQL */ `
  query ListActiveCategories($customerID: Int!) {
    listActiveCategories(customerID: $customerID) {
      items {
        id
        customerID
        name
        image
        imageURL
        imageFileSize
        active
        thumbnail
        thumbnailURL
        thumbnailFileSize
        tag
        description
      }
    }
  }
`;
export const listActiveCategoriesByShow = /* GraphQL */ `
  query ListActiveCategoriesByShow($showID: Int!) {
    listActiveCategoriesByShow(showID: $showID) {
      items {
        id
        customerID
        name
        image
        imageURL
        imageFileSize
        active
        thumbnail
        thumbnailURL
        thumbnailFileSize
        tag
        description
      }
    }
  }
`;
export const getCategory = /* GraphQL */ `
  query GetCategory($id: Int!) {
    getCategory(id: $id) {
      id
      customerID
      name
      image
      imageURL
      imageFileSize
      active
      thumbnail
      thumbnailURL
      thumbnailFileSize
      tag
      description
    }
  }
`;
export const listBrands = /* GraphQL */ `
  query ListBrands($customerID: Int!) {
    listBrands(customerID: $customerID) {
      items {
        id
        customerID
        name
        productCount
      }
    }
  }
`;
export const getBrand = /* GraphQL */ `
  query GetBrand($id: Int!) {
    getBrand(id: $id) {
      id
      customerID
      name
      productCount
    }
  }
`;
export const listBrandProducts = /* GraphQL */ `
  query ListBrandProducts($id: Int!, $customerID: Int!) {
    listBrandProducts(id: $id, customerID: $customerID) {
      items {
        id
        customerID
        name
        description
        upc
        brand
        brandID
        sku
        active
        added
        deleted
        approved
        replacementProduct
        mainPhotoURL
        mainPhotoFileID
        mainPhotoFileExt
        mainPhotoSize
        issueCount
        photoBucket
        photoKey
        warnings
        originalProductName
        originalProductDescription
        originalProductURL
        originalProductPhotoBucket
        originalProductPhotoKey
        featured
        price
        vendorID
        vendorLogo
        vendorLogoBucket
        vendorLogoKey
      }
    }
  }
`;
export const listActiveFontTargets = /* GraphQL */ `
  query ListActiveFontTargets {
    listActiveFontTargets {
      items {
        id
        styleClassName
        displayName
        description
        active
        added
      }
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers {
    listUsers {
      items {
        id
        email
        enabled
        status
        created
        email_verified
        given_name
        family_name
        gender
        phone_number
        customerID
        vendorID
      }
    }
  }
`;
export const listVoxiAdmins = /* GraphQL */ `
  query ListVoxiAdmins {
    listVoxiAdmins {
      items {
        id
        email
        enabled
        status
        created
        email_verified
        given_name
        family_name
        gender
        phone_number
        customerID
        vendorID
      }
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      email
      enabled
      status
      created
      email_verified
      given_name
      family_name
      gender
      phone_number
      customerID
      vendorID
    }
  }
`;
export const listEmailReportSettings = /* GraphQL */ `
  query ListEmailReportSettings {
    listEmailReportSettings {
      items {
        id
        userID
        reportType
        daily
        weekly
        monthly
        yearly
        customerID
        customerName
        showID
        showName
        lastRun
        lastRunTime
      }
    }
  }
`;
export const textSearch = /* GraphQL */ `
  query TextSearch($appID: String!, $query: String!) {
    textSearch(appID: $appID, query: $query) {
      items {
        id
        customerID
        name
        description
        upc
        brand
        brandID
        sku
        active
        added
        deleted
        approved
        replacementProduct
        mainPhotoURL
        mainPhotoFileID
        mainPhotoFileExt
        mainPhotoSize
        issueCount
        photoBucket
        photoKey
        warnings
        originalProductName
        originalProductDescription
        originalProductURL
        originalProductPhotoBucket
        originalProductPhotoKey
        featured
        price
        vendorID
        vendorLogo
        vendorLogoBucket
        vendorLogoKey
      }
    }
  }
`;
export const getShowManifest = /* GraphQL */ `
  query GetShowManifest($appID: String!) {
    getShowManifest(appID: $appID) {
      name
      short_name
      start_url
      display
      background_color
      theme_color
      permissions
      icons {
        sizes
        src
      }
    }
  }
`;
export const getEpisodeMetaTags = /* GraphQL */ `
  query GetEpisodeMetaTags($episodeID: Int!) {
    getEpisodeMetaTags(episodeID: $episodeID) {
      episodeID
      OG_TITLE
      OG_DESCRIPTION
      OG_IMAGE
      FAVICON
      MANIFEST
    }
  }
`;
export const getProductMetaTags = /* GraphQL */ `
  query GetProductMetaTags($productID: Int!, $appID: String!) {
    getProductMetaTags(productID: $productID, appID: $appID) {
      productID
      OG_TITLE
      OG_DESCRIPTION
      OG_IMAGE
      FAVICON
      MANIFEST
    }
  }
`;
export const getShowFeed = /* GraphQL */ `
  query GetShowFeed($appID: String!) {
    getShowFeed(appID: $appID) {
      showName
      description
      imageURL
      lastUpdated
      providerName
      language
      categories {
        playlistName
        name
        order
      }
      playlists {
        name
        itemIds
      }
      movies {
        id
      }
      series {
        id
      }
      shortFormVideos {
        id
        name
        season
        title
        content {
          dateAdded
          videos {
            url
            quality
            videoType
          }
          duration
          adBreaks
        }
        thumbnail
        shortDescription
        releaseDate
        tags
      }
      tvSpecials {
        id
      }
    }
  }
`;
export const getUserWishlist = /* GraphQL */ `
  query GetUserWishlist($userID: ID!, $showID: Int) {
    getUserWishlist(userID: $userID, showID: $showID) {
      items {
        id
        customerID
        name
        description
        upc
        brand
        brandID
        sku
        active
        added
        deleted
        approved
        replacementProduct
        mainPhotoURL
        mainPhotoFileID
        mainPhotoFileExt
        mainPhotoSize
        issueCount
        photoBucket
        photoKey
        warnings
        originalProductName
        originalProductDescription
        originalProductURL
        originalProductPhotoBucket
        originalProductPhotoKey
        featured
        price
        vendorID
        vendorLogo
        vendorLogoBucket
        vendorLogoKey
      }
    }
  }
`;
export const productInWishlist = /* GraphQL */ `
  query ProductInWishlist($productID: Int!) {
    productInWishlist(productID: $productID)
  }
`;
export const getExclusiveVideoContent = /* GraphQL */ `
  query GetExclusiveVideoContent(
    $id: ID!
    $filter: GetExclusiveVideoContentFilterInput
  ) {
    getExclusiveVideoContent(id: $id, filter: $filter) {
      id
      legacyCustomerID
      legacyCustomerIDString
      legacyShowID
      name
      description
      enabled
      firstRelease
      posterBucket
      posterKey
      videoBucket
      videoKey
      videoURL
      videoQuality
      videoType
      videoWidth
      videoHeight
      mediaConvertJobID
      mediaConvertJobStatus
      duration
      releaseToOTT
      releaseToApp
      videoToken
      disableProductScroller
      productDefinitions {
        productID
        startTimeMilliseconds
        endTimeMilliseconds
      }
      videoProducts {
        brandName
        product {
          id
          customerID
          name
          description
          upc
          brand
          brandID
          sku
          active
          added
          deleted
          approved
          replacementProduct
          mainPhotoURL
          mainPhotoFileID
          mainPhotoFileExt
          mainPhotoSize
          issueCount
          photoBucket
          photoKey
          warnings
          originalProductName
          originalProductDescription
          originalProductURL
          originalProductPhotoBucket
          originalProductPhotoKey
          featured
          price
          vendorID
          vendorLogo
          vendorLogoBucket
          vendorLogoKey
        }
        startTimeMilliseconds
        endTimeMilliseconds
      }
      createdAt
      updatedAt
    }
  }
`;
export const listShowSmallBusinessVendors = /* GraphQL */ `
  query ListShowSmallBusinessVendors($showID: Int!) {
    listShowSmallBusinessVendors(showID: $showID) {
      items {
        id
        name
        type
        active
        salesOwnedByUserID
        balance
        balanceUpdated
        logo
        logoFileID
        logoFileExtension
        logoBucket
        logoKey
        pixelTrackingID
        createdAt
        updatedAt
      }
    }
  }
`;
export const listVendorProducts = /* GraphQL */ `
  query ListVendorProducts($vendorID: ID!) {
    listVendorProducts(vendorID: $vendorID) {
      items {
        vendorID
        productID
        owned
        assigned
        unassigned
        product {
          id
          customerID
          name
          description
          upc
          brand
          brandID
          sku
          active
          added
          deleted
          approved
          replacementProduct
          mainPhotoURL
          mainPhotoFileID
          mainPhotoFileExt
          mainPhotoSize
          issueCount
          photoBucket
          photoKey
          warnings
          originalProductName
          originalProductDescription
          originalProductURL
          originalProductPhotoBucket
          originalProductPhotoKey
          featured
          price
          vendorID
          vendorLogo
          vendorLogoBucket
          vendorLogoKey
        }
      }
    }
  }
`;
export const listVendorShowProducts = /* GraphQL */ `
  query ListVendorShowProducts($vendorID: ID!, $showID: Int!) {
    listVendorShowProducts(vendorID: $vendorID, showID: $showID) {
      items {
        vendorID
        productID
        owned
        assigned
        unassigned
        product {
          id
          customerID
          name
          description
          upc
          brand
          brandID
          sku
          active
          added
          deleted
          approved
          replacementProduct
          mainPhotoURL
          mainPhotoFileID
          mainPhotoFileExt
          mainPhotoSize
          issueCount
          photoBucket
          photoKey
          warnings
          originalProductName
          originalProductDescription
          originalProductURL
          originalProductPhotoBucket
          originalProductPhotoKey
          featured
          price
          vendorID
          vendorLogo
          vendorLogoBucket
          vendorLogoKey
        }
      }
    }
  }
`;
export const getNotification = /* GraphQL */ `
  query GetNotification($id: ID!) {
    getNotification(id: $id) {
      id
      userID
      description
      time
      createdAt
      updatedAt
    }
  }
`;
export const listNotifications = /* GraphQL */ `
  query ListNotifications(
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNotifications(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userID
        description
        time
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCubejsDashboardItem = /* GraphQL */ `
  query GetCubejsDashboardItem($id: ID!) {
    getCubejsDashboardItem(id: $id) {
      id
      userID
      layout
      vizState
      name
      createdAt
      updatedAt
    }
  }
`;
export const listCubejsDashboardItems = /* GraphQL */ `
  query ListCubejsDashboardItems(
    $filter: ModelCubejsDashboardItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCubejsDashboardItems(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        layout
        vizState
        name
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listUserDashboardItems = /* GraphQL */ `
  query ListUserDashboardItems(
    $userID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelCubejsDashboardItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserDashboardItems(
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        layout
        vizState
        name
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listExclusiveVideoContents = /* GraphQL */ `
  query ListExclusiveVideoContents(
    $filter: ModelExclusiveVideoContentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listExclusiveVideoContents(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        legacyCustomerID
        legacyCustomerIDString
        legacyShowID
        name
        description
        enabled
        firstRelease
        posterBucket
        posterKey
        videoBucket
        videoKey
        videoURL
        videoQuality
        videoType
        videoWidth
        videoHeight
        mediaConvertJobID
        mediaConvertJobStatus
        duration
        releaseToOTT
        releaseToApp
        videoToken
        disableProductScroller
        productDefinitions {
          productID
          startTimeMilliseconds
          endTimeMilliseconds
        }
        videoProducts {
          brandName
          product {
            id
            customerID
            name
            description
            upc
            brand
            brandID
            sku
            active
            added
            deleted
            approved
            replacementProduct
            mainPhotoURL
            mainPhotoFileID
            mainPhotoFileExt
            mainPhotoSize
            issueCount
            photoBucket
            photoKey
            warnings
            originalProductName
            originalProductDescription
            originalProductURL
            originalProductPhotoBucket
            originalProductPhotoKey
            featured
            price
            vendorID
            vendorLogo
            vendorLogoBucket
            vendorLogoKey
          }
          startTimeMilliseconds
          endTimeMilliseconds
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listExclusiveContentsByCustomer = /* GraphQL */ `
  query ListExclusiveContentsByCustomer(
    $legacyCustomerID: Int
    $sortDirection: ModelSortDirection
    $filter: ModelExclusiveVideoContentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listExclusiveContentsByCustomer(
      legacyCustomerID: $legacyCustomerID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        legacyCustomerID
        legacyCustomerIDString
        legacyShowID
        name
        description
        enabled
        firstRelease
        posterBucket
        posterKey
        videoBucket
        videoKey
        videoURL
        videoQuality
        videoType
        videoWidth
        videoHeight
        mediaConvertJobID
        mediaConvertJobStatus
        duration
        releaseToOTT
        releaseToApp
        videoToken
        disableProductScroller
        productDefinitions {
          productID
          startTimeMilliseconds
          endTimeMilliseconds
        }
        videoProducts {
          brandName
          product {
            id
            customerID
            name
            description
            upc
            brand
            brandID
            sku
            active
            added
            deleted
            approved
            replacementProduct
            mainPhotoURL
            mainPhotoFileID
            mainPhotoFileExt
            mainPhotoSize
            issueCount
            photoBucket
            photoKey
            warnings
            originalProductName
            originalProductDescription
            originalProductURL
            originalProductPhotoBucket
            originalProductPhotoKey
            featured
            price
            vendorID
            vendorLogo
            vendorLogoBucket
            vendorLogoKey
          }
          startTimeMilliseconds
          endTimeMilliseconds
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listExclusiveContentsByShow = /* GraphQL */ `
  query ListExclusiveContentsByShow(
    $legacyShowID: Int
    $sortDirection: ModelSortDirection
    $filter: ModelExclusiveVideoContentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listExclusiveContentsByShow(
      legacyShowID: $legacyShowID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        legacyCustomerID
        legacyCustomerIDString
        legacyShowID
        name
        description
        enabled
        firstRelease
        posterBucket
        posterKey
        videoBucket
        videoKey
        videoURL
        videoQuality
        videoType
        videoWidth
        videoHeight
        mediaConvertJobID
        mediaConvertJobStatus
        duration
        releaseToOTT
        releaseToApp
        videoToken
        disableProductScroller
        productDefinitions {
          productID
          startTimeMilliseconds
          endTimeMilliseconds
        }
        videoProducts {
          brandName
          product {
            id
            customerID
            name
            description
            upc
            brand
            brandID
            sku
            active
            added
            deleted
            approved
            replacementProduct
            mainPhotoURL
            mainPhotoFileID
            mainPhotoFileExt
            mainPhotoSize
            issueCount
            photoBucket
            photoKey
            warnings
            originalProductName
            originalProductDescription
            originalProductURL
            originalProductPhotoBucket
            originalProductPhotoKey
            featured
            price
            vendorID
            vendorLogo
            vendorLogoBucket
            vendorLogoKey
          }
          startTimeMilliseconds
          endTimeMilliseconds
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listExclusiveContentsByShowSorted = /* GraphQL */ `
  query ListExclusiveContentsByShowSorted(
    $legacyShowID: Int
    $firstRelease: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelExclusiveVideoContentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listExclusiveContentsByShowSorted(
      legacyShowID: $legacyShowID
      firstRelease: $firstRelease
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        legacyCustomerID
        legacyCustomerIDString
        legacyShowID
        name
        description
        enabled
        firstRelease
        posterBucket
        posterKey
        videoBucket
        videoKey
        videoURL
        videoQuality
        videoType
        videoWidth
        videoHeight
        mediaConvertJobID
        mediaConvertJobStatus
        duration
        releaseToOTT
        releaseToApp
        videoToken
        disableProductScroller
        productDefinitions {
          productID
          startTimeMilliseconds
          endTimeMilliseconds
        }
        videoProducts {
          brandName
          product {
            id
            customerID
            name
            description
            upc
            brand
            brandID
            sku
            active
            added
            deleted
            approved
            replacementProduct
            mainPhotoURL
            mainPhotoFileID
            mainPhotoFileExt
            mainPhotoSize
            issueCount
            photoBucket
            photoKey
            warnings
            originalProductName
            originalProductDescription
            originalProductURL
            originalProductPhotoBucket
            originalProductPhotoKey
            featured
            price
            vendorID
            vendorLogo
            vendorLogoBucket
            vendorLogoKey
          }
          startTimeMilliseconds
          endTimeMilliseconds
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getShowMetaTags = /* GraphQL */ `
  query GetShowMetaTags($appID: ID!) {
    getShowMetaTags(appID: $appID) {
      appID
      showID
      OG_TITLE
      OG_DESCRIPTION
      OG_IMAGE
      FAVICON
      MANIFEST
      createdAt
      updatedAt
    }
  }
`;
export const listShowMetaTagss = /* GraphQL */ `
  query ListShowMetaTagss(
    $appID: ID
    $filter: ModelShowMetaTagsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listShowMetaTagss(
      appID: $appID
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        appID
        showID
        OG_TITLE
        OG_DESCRIPTION
        OG_IMAGE
        FAVICON
        MANIFEST
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getVendor = /* GraphQL */ `
  query GetVendor($id: ID!) {
    getVendor(id: $id) {
      id
      name
      type
      active
      salesOwnedByUserID
      balance
      balanceUpdated
      logo
      logoFileID
      logoFileExtension
      logoBucket
      logoKey
      pixelTrackingID
      createdAt
      updatedAt
    }
  }
`;
export const listVendors = /* GraphQL */ `
  query ListVendors(
    $filter: ModelVendorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVendors(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        type
        active
        salesOwnedByUserID
        balance
        balanceUpdated
        logo
        logoFileID
        logoFileExtension
        logoBucket
        logoKey
        pixelTrackingID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listVendorsByType = /* GraphQL */ `
  query ListVendorsByType(
    $type: VendorBusinessType
    $sortDirection: ModelSortDirection
    $filter: ModelVendorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVendorsByType(
      type: $type
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        type
        active
        salesOwnedByUserID
        balance
        balanceUpdated
        logo
        logoFileID
        logoFileExtension
        logoBucket
        logoKey
        pixelTrackingID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listVendorAds = /* GraphQL */ `
  query ListVendorAds(
    $filter: ModelVendorAdFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVendorAds(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        vendorID
        name
        description
        imageBucket
        imageKey
        url
        type
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getVendorAd = /* GraphQL */ `
  query GetVendorAd($id: ID!) {
    getVendorAd(id: $id) {
      id
      vendorID
      name
      description
      imageBucket
      imageKey
      url
      type
      createdAt
      updatedAt
    }
  }
`;
export const listAdsByVendor = /* GraphQL */ `
  query ListAdsByVendor(
    $vendorID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelVendorAdFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAdsByVendor(
      vendorID: $vendorID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        vendorID
        name
        description
        imageBucket
        imageKey
        url
        type
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getBannerAd = /* GraphQL */ `
  query GetBannerAd($id: ID!) {
    getBannerAd(id: $id) {
      id
      customerID
      showID
      episodeID
      categoryID
      imageBucket
      imageKey
      url
      linkType
      type
      createdAt
      updatedAt
      vendorAd {
        id
        vendorID
        name
        description
        imageBucket
        imageKey
        url
        type
        createdAt
        updatedAt
      }
    }
  }
`;
export const listBannerAds = /* GraphQL */ `
  query ListBannerAds(
    $filter: ModelBannerAdFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBannerAds(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        customerID
        showID
        episodeID
        categoryID
        imageBucket
        imageKey
        url
        linkType
        type
        createdAt
        updatedAt
        vendorAd {
          id
          vendorID
          name
          description
          imageBucket
          imageKey
          url
          type
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const listBannerAdsByShow = /* GraphQL */ `
  query ListBannerAdsByShow(
    $showID: Int
    $sortDirection: ModelSortDirection
    $filter: ModelBannerAdFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBannerAdsByShow(
      showID: $showID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customerID
        showID
        episodeID
        categoryID
        imageBucket
        imageKey
        url
        linkType
        type
        createdAt
        updatedAt
        vendorAd {
          id
          vendorID
          name
          description
          imageBucket
          imageKey
          url
          type
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const listBannerAdsByEpisode = /* GraphQL */ `
  query ListBannerAdsByEpisode(
    $episodeID: Int
    $sortDirection: ModelSortDirection
    $filter: ModelBannerAdFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBannerAdsByEpisode(
      episodeID: $episodeID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customerID
        showID
        episodeID
        categoryID
        imageBucket
        imageKey
        url
        linkType
        type
        createdAt
        updatedAt
        vendorAd {
          id
          vendorID
          name
          description
          imageBucket
          imageKey
          url
          type
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const listBannerAdsByCategory = /* GraphQL */ `
  query ListBannerAdsByCategory(
    $categoryID: Int
    $sortDirection: ModelSortDirection
    $filter: ModelBannerAdFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBannerAdsByCategory(
      categoryID: $categoryID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customerID
        showID
        episodeID
        categoryID
        imageBucket
        imageKey
        url
        linkType
        type
        createdAt
        updatedAt
        vendorAd {
          id
          vendorID
          name
          description
          imageBucket
          imageKey
          url
          type
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getShowFontV2 = /* GraphQL */ `
  query GetShowFontV2($id: ID!) {
    getShowFontV2(id: $id) {
      id
      showFontLocationComposite
      showID
      family
      location
      weight
      style
      createdAt
      updatedAt
    }
  }
`;
export const listShowFontV2s = /* GraphQL */ `
  query ListShowFontV2s(
    $filter: ModelShowFontV2FilterInput
    $limit: Int
    $nextToken: String
  ) {
    listShowFontV2s(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        showFontLocationComposite
        showID
        family
        location
        weight
        style
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listFontsByShow = /* GraphQL */ `
  query ListFontsByShow(
    $showID: Int
    $sortDirection: ModelSortDirection
    $filter: ModelShowFontV2FilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFontsByShow(
      showID: $showID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        showFontLocationComposite
        showID
        family
        location
        weight
        style
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getDigitalAssetLinks = /* GraphQL */ `
  query GetDigitalAssetLinks($appID: String!) {
    getDigitalAssetLinks(appID: $appID) {
      appID
      legacyShowID
      legacyCustomerID
      android
      createdAt
      updatedAt
    }
  }
`;
export const listDigitalAssetLinkss = /* GraphQL */ `
  query ListDigitalAssetLinkss(
    $appID: String
    $filter: ModelDigitalAssetLinksFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listDigitalAssetLinkss(
      appID: $appID
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        appID
        legacyShowID
        legacyCustomerID
        android
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getHomeScreenSettings = /* GraphQL */ `
  query GetHomeScreenSettings($showID: Int!) {
    getHomeScreenSettings(showID: $showID) {
      showID
      legacyCustomerID
      legacyCustomerIDString
      featuredProductsLabel
      smallBusinessLabel
      recentEpisodesLabel
      recommendedProductsLabel
      createdAt
      updatedAt
    }
  }
`;
export const listHomeScreenSettingss = /* GraphQL */ `
  query ListHomeScreenSettingss(
    $showID: Int
    $filter: ModelHomeScreenSettingsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listHomeScreenSettingss(
      showID: $showID
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        showID
        legacyCustomerID
        legacyCustomerIDString
        featuredProductsLabel
        smallBusinessLabel
        recentEpisodesLabel
        recommendedProductsLabel
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
