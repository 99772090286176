import React, { useState } from 'react';
import { Grid, TextField, Button, Typography } from '@material-ui/core';
import { TabPanel } from '../../tabs/TabPanel';

const googleList = [
    { name: 'Google Search', search: 'http://www.google.com/search?q=' },
    { name: 'Google Images', search: 'http://www.google.com/search?tbm=isch&q=' },
    { name: 'Google Shopping', search: 'http://www.google.com/search?tbm=shop&q=' }
]

const bingList = [
    { name: 'Bing Search', search: 'https://www.bing.com/search?q=' },
    { name: 'Bing Images', search: 'https://www.bing.com/images/search?FORM=HDRSC2&q=' },
    { name: 'Bing Shopping', search: 'https://www.bing.com/shop?FORM=SHOPTB&q=' },
]

const yahooList = [
    { name: 'Yahoo Search', search: 'https://search.yahoo.com/search?p=' },
    { name: 'Yahoo Shopping', search: 'https://shopping.yahoo.com/search?p=' }
]

const storesList = [
    { name: 'At Home', search: 'https://www.athome.com/search/?q=', params: '&lang=default' },
    { name: 'Amazon', search: 'https://www.amazon.com/s?k=' },
    { name: 'Bed Bath & Beyond', search: 'https://www.bedbathandbeyond.com/store/s/', params: '?ta=typeahead' },
    { name: 'Ikea', search: 'https://www.ikea.com/us/en/search/products/?q=' },
    { name: 'Kohls', search: 'https://www.kohls.com/search.jsp?search=' },
    { name: 'Macy\'s', search: 'https://www.macys.com/shop/featured/' },
    { name: 'Overstock', search: 'https://www.overstock.com/', params: ',/k,/results.html?SearchType=Header' },
    { name: 'Target', search: 'https://www.target.com/s?searchTerm=' },
    { name: 'Walmart', search: 'https://www.walmart.com/search/?query=' },
    { name: 'Wayfair', search: 'https://www.wayfair.com/keyword.php?keyword=', params: '&command=dosearch&new_keyword_search=true&class_id=' }
]

export function SearchHelperTab(props: SearchHelperTabProps) {
    const [searchText, setSearchText] = useState('');
    const [displayLinks, setDisplayLinks] = useState(false);

    const generateSearchLinks = async () => {
        setDisplayLinks(true);
    }

    return (
        <TabPanel value={props.tab} index={props.index} spacing={2} direction="column">
            <Grid item container spacing={2} direction="row">
                <Grid item xs={6}>
                    <TextField variant="outlined"
                        label="Search"
                        placeholder="Product Name / UPC"
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                        fullWidth
                    />
                </Grid>
                <Grid item xs>
                    <Button variant="contained" color="secondary" onClick={generateSearchLinks}>
                        Generate Search Links
                    </Button>
                </Grid>
            </Grid>
            {
                searchText !== '' && displayLinks &&
                <>
                    <Grid item container direction="row" alignItems="center" spacing={2}>
                        <Grid item xs={1}>
                            <Typography variant="h6">Google:</Typography>
                        </Grid>
                        {googleList.map(provider => {
                            return (
                                <Grid item>
                                    <Button variant="outlined" href={`${provider.search}${searchText}`} target="_blank">
                                        {provider.name}
                                    </Button>
                                </Grid>
                            )
                        })}
                    </Grid>
                    <Grid item container direction="row" alignItems="center" spacing={2}>
                        <Grid item xs={1}>
                            <Typography variant="h6">Bing:</Typography>
                        </Grid>
                        {bingList.map(provider => {
                            return (
                                <Grid item>
                                    <Button variant="outlined" href={`${provider.search}${searchText}`} target="_blank">
                                        {provider.name}
                                    </Button>
                                </Grid>
                            )
                        })}
                    </Grid>
                    <Grid item container direction="row" alignItems="center" spacing={2}>
                        <Grid item xs={1}>
                            <Typography variant="h6">Yahoo:</Typography>
                        </Grid>
                        {yahooList.map(provider => {
                            return (
                                <Grid item>
                                    <Button variant="outlined" href={`${provider.search}${searchText}`} target="_blank">
                                        {provider.name}
                                    </Button>
                                </Grid>
                            )
                        })}
                    </Grid>
                    <Grid item container direction="row" alignItems="center" spacing={2}>
                        <Grid item xs={1}>
                            <Typography variant="h6">Stores:</Typography>
                        </Grid>
                        {storesList.map(store => {
                            return (
                                <Grid item>
                                    <Button variant="outlined" href={`${store.search}${searchText}${store.params || ''}`} target="_blank">
                                        {store.name}
                                    </Button>
                                </Grid>
                            )
                        })}
                    </Grid>
                </>
            }
        </TabPanel>
    )
}

export interface SearchHelperTabProps {
    tab: Number
    index: Number
}