import React, { useState, useEffect, forwardRef } from 'react';
import { Grid, Paper, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import useSnackbars from '../../../hooks/useSnackbars';

import { subDays } from 'date-fns';

import MaterialTable, { MTableToolbar } from "material-table";
import { Icons } from 'material-table'; // typescript specific import https://github.com/mbrn/material-table/issues/1150

import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';

import { RangeSelect } from '../../shared/RangeSelect';
import { MobileAnalyticsEvent } from '../../../types/api';
import * as API from '../../../lib/api';
import { notEmpty } from '../../../lib/util';

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2)
    },
    tableToolBar: {
        backgroundColor: '#f9f9f9!important'
    }
}));

const tableIcons: Icons = {
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

export function AdminMobileAnalyticsReport(props: AdminMobileAnalyticsReportProps) {
    const classes = useStyles();
    const { createErrorSnack } = useSnackbars();
    const [activityReport, setActivityReport] = useState<Array<MobileAnalyticsEvent>>();
    const [loading, setLoading] = useState(true);

    const fetchMobileAnalyticsEventReport = async (from: Date, to: Date) => {
        setLoading(true);

        let queryArgs = {
            fromDate: from.toISOString(),
            toDate: to.toISOString()
        }

        try {
            const mobileActivityResult = await API.getMobileActivityReport(queryArgs);

            console.log(mobileActivityResult);

            setActivityReport(mobileActivityResult.filter(notEmpty))
            setLoading(false);
        } catch (err) {
            console.error(err);
            setLoading(false);
            createErrorSnack('Failed to get report');
        }
    }

    useEffect(() => {
        let from = subDays(new Date(), 7);
        let to = new Date();

        fetchMobileAnalyticsEventReport(from, to);
    }, [])

    const handleDateChange = async (from, to) => {
        await fetchMobileAnalyticsEventReport(from, to);
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs>
                <Paper className={classes.paper}>
                    <Grid container spacing={2} direction="row">
                        <Grid item container direction="column" spacing={2}>
                            <RangeSelect handleSelectChange={handleDateChange} onApplyClicked={handleDateChange} />
                            {
                                loading &&
                                <CircularProgress />
                            }
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
            <Grid item xs={12}>
                {
                    activityReport &&
                    <MaterialTable
                        title="Activity Report"
                        icons={tableIcons}
                        columns={[
                            { title: "Event Type", field: "eventType" },
                            { title: "Count", field: "totalCount" },
                        ]}
                        data={activityReport}
                        components={{
                            Toolbar: props => (
                                <div className={classes.tableToolBar}>
                                    <MTableToolbar {...props} className={classes.tableToolBar} />
                                </div>
                            )
                        }}
                        options={{
                            exportButton: true,
                            exportAllData: true,
                            pageSize: 25,
                            pageSizeOptions: [10, 25, 50, 100, 200, 300],
                            filtering: true
                        }}
                    />
                }


            </Grid>
        </Grid>
    )
}

export interface AdminMobileAnalyticsReportProps {
    authData: any
}