import React, { useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import { Grid, Typography, TextField, Button, LinearProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import QRCode from 'react-qr-code';
import path from 'path';

import { TabPanel } from '../../tabs/TabPanel';
import { FileUploadButton } from '../../shared/FileUploadButton';

import useSnackbars from '../../../hooks/useSnackbars';
import * as API from '../../../lib/api';

import { uploadPresignedFile } from '../../../lib/util';
import { Maybe, Show } from '../../../types';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
    poster: {
        maxHeight: '100px',
        maxWidth: '400px'
    },
    qrCode: {
        width: '250px',
        height: '250px'
    }
}));

export function ShowDetailsTab(props: ShowDetailsTabProps) {
    const classes = useStyles();
    const { createSuccessSnack, createErrorSnack } = useSnackbars();
    const [showPoster, setShowPoster] = useState(props.show?.posterURL || '');
    const [showHeroImage, setShowHeroImage] = useState('');
    const [showName, setShowName] = useState(props.show?.nameLong || '');
    const [showDescription, setShowDescription] = useState(props.show?.description || '');
    const [uploading, setUploading] = useState(false);
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        if (!props.show) return;

        setShowPoster(props.show.posterURL || '');
        setShowName(props.show.nameLong || '');
        setShowDescription(props.show.description);

        const showHeroImage = props.show.heroImageBucket && props.show.heroImageKey
            ? `https://s3.dualstack.us-east-2.amazonaws.com/${props.show.heroImageBucket}/${props.show.heroImageKey}`
            : '';

        setShowHeroImage(showHeroImage);
    }, [props.show])

    const updateShowPoster = async ({ target }) => {
        if (!props.show) return;

        const file = target.files[0];
        if (!file) return;

        const fileExtension = path.extname(file.name).toUpperCase();

        const now = new Date().getTime()
        const filename = `POSTER-${now}${fileExtension}`

        const key = `${props.show.customerID}/SHOW/${props.show.id}/${filename}`

        try {
            const presignedUrl = await API.generatePresignedURL({
                operation: 'putObject',
                key: key
            });

            const options = {
                headers: {
                    'Content-Type': file.type
                },
                onUploadProgress: function (progressEvent) {
                    var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    setProgress(percentCompleted);
                }
            };

            setUploading(true);
            const uploadResponse = await uploadPresignedFile(presignedUrl, file, options);
            if (uploadResponse.status === 200) {
                const newPosterUrl = await API.updateShowPoster({
                    showID: props.show.id,
                    customerID: props.show.customerID,
                    newFilename: filename
                })

                if (newPosterUrl) setShowPoster(newPosterUrl);
            }
        } catch (err) {
            console.error(err);
            createErrorSnack('Failed to upload file');
        }

        setUploading(false);
        setProgress(0);
    }

    const updateShowHeroImage = async ({ target }) => {
        if (!props.show || !target) return;

        const file = target.files[0];
        if (!file) return;

        const fileExtension = path.extname(file.name).toUpperCase();

        const now = new Date().getTime();
        const filename = `SHOWHERO-${props.show.customerID}-${props.show.id}-${now}${fileExtension}`;

        const user = await Auth.currentAuthenticatedUser();

        const presignedUrl = await API.generatePresignedRawMediaURL({
            operation: 'putObject',
            key: filename,
            metadata: [
                { name: 'userid', value: user.attributes.sub }
            ]
        });

        const options = {
            headers: {
                'Content-Type': file.type
            },
            onUploadProgress: function (progressEvent) {
                const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                setProgress(percentCompleted);
            }
        };

        setUploading(true);
        const uploadResponse = await uploadPresignedFile(presignedUrl, file, options);
        if (uploadResponse.status === 200) {
            createSuccessSnack('Successfully uploaded show hero image');
        } else {
            createErrorSnack('Error uploading show hero image');
        }

        setUploading(false);
        setProgress(0);
    }

    const updateShow = async () => {
        if (!props.show) return;

        let input = {
            id: props.show.id,
            nameShort: showName,
            nameLong: showName,
            description: showDescription
        }

        try {
            const show = await API.updateShow(input);
            if (show) {
                setShowName(show.nameLong)
                setShowDescription(show.description);
                createSuccessSnack('Sucessfully updated show');
            } else {
                createErrorSnack('Failed to update show');
            }
        } catch (err) {
            console.error(err);
            let errorMessage = 'Failed to update show';
            if (err.errors && err.errors[0]) {
                const error = err.errors[0];
                errorMessage = `${error.errorType || 'Error'}: ${error.message}`
            }

            createErrorSnack('Failed to update show:', errorMessage);
        }
    }

    const cancelUpdate = () => {
        if (!props.show) return;

        setShowName(props.show?.nameLong);
        setShowDescription(props.show?.description);
    }

    return props.show ? (
        <TabPanel value={props.tab} index={props.index} direction="row" spacing={0}>
            <Grid item container xs={4} direction="column" spacing={2} alignContent="center" alignItems="center" justify="center">
                {
                    uploading &&
                    <Grid item>
                        <LinearProgress value={progress} variant="determinate" color="primary" />
                    </Grid>
                }
                <Grid item>
                    <img src={showPoster} className={classes.poster} alt="show poster" />
                </Grid>
                <Grid item>
                    <FileUploadButton id="show-poster-upload-button"
                        accept="image/*"
                        onChange={updateShowPoster}
                        text="Add/Change Show Poster"
                    />
                </Grid>
                <br />
                <Grid item>
                    <img src={showHeroImage} className={classes.poster} alt="show hero image" />
                </Grid>
                <Grid item>
                    <FileUploadButton id="show-hero-image-upload-button"
                        accept="image/*"
                        onChange={updateShowHeroImage}
                        text="Add/Change Show Hero Image"
                    />
                </Grid>
                <br />
                <Grid item>
                    <Typography variant="h5">Show Website QR Code</Typography>
                </Grid>
                <Grid item>
                    <QRCode value={`https://${props.show.appID}.getvoxi.app`} className={classes.qrCode} />
                </Grid>
            </Grid>
            <Grid item container xs direction="column" spacing={2} >
                <Grid item xs>
                    <Typography>Show Name</Typography>
                    <TextField variant="outlined"
                        value={showName}
                        onChange={(e) => setShowName(e.target.value)}
                        fullWidth />
                </Grid>
                <Grid item xs>
                    <Typography>Show Description</Typography>
                    <TextField variant="outlined"
                        value={showDescription}
                        onChange={(e) => setShowDescription(e.target.value)}
                        placeholder="Show Description"
                        multiline
                        rows={5}
                        fullWidth />
                </Grid>
                <Grid item>
                    <Button variant="contained" color="secondary" onClick={updateShow}>
                        Update
                    </Button>
                    <Button variant="contained" onClick={cancelUpdate}>
                        Cancel
                    </Button>
                </Grid>
            </Grid>
        </TabPanel>
    ) : (<></>)
}

export interface ShowDetailsTabProps {
    tab: number
    index: number
    show: Maybe<Show>
}