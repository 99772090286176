import React, { useState, useEffect, forwardRef } from 'react';
import { useHistory } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import MaterialTable, { MTableToolbar, Icons } from 'material-table'; // typescript specific import https://github.com/mbrn/material-table/issues/1150

import DeleteIcon from '@material-ui/icons/Delete'
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import LastPage from '@material-ui/icons/LastPage';// import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';


import * as API from '../../lib/api';

import { Brand } from '../../types/api';

const useStyles = makeStyles((theme) => ({
    tableToolBar: {
        backgroundColor: '#f9f9f9!important'
    }
}));

const tableIcons: Icons = {
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteIcon {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

export function Brands(props: BrandsProps) {
    const classes = useStyles();
    const history = useHistory();
    const [brands, setBrands] = useState<Array<Brand>>([]);

    useEffect(() => {
        if (!props.customerID) return;

        fetchBrands();
    }, [props.customerID])

    const fetchBrands = async () => {
        const brandsResult = await API.listBrands({ customerID: props.customerID });
        setBrands(brandsResult)
    }

    const editBrand = (event, rowData) => {
        history.push(`/products/brands/${rowData.id}`)
    }

    return (
        <Grid container spacing={2} direction="column">
            <Grid item>
                <MaterialTable
                    title="Brands"
                    icons={tableIcons}
                    columns={[
                        { title: "Brand Name", field: "name", filterPlaceholder: "filter" },
                        { title: "Product Count", field: "productCount", filterPlaceholder: "filter" }
                    ]}
                    data={brands}
                    components={{
                        Toolbar: props => (
                            <div className={classes.tableToolBar}>
                                <MTableToolbar {...props} className={classes.tableToolBar} />
                            </div>
                        )
                    }}
                    options={{
                        exportButton: true,
                        exportAllData: true,
                        pageSize: 25,
                        pageSizeOptions: [10, 25, 50, 100],
                        filtering: true,
                        actionsColumnIndex: -1
                    }}
                    actions={[
                        { icon: (() => (<Edit />)), tooltip: 'Edit Brand', onClick: editBrand }
                    ]}
                />
            </Grid>
        </Grid>)
}


export interface BrandsProps {
    authData: any
    customerID: number
}