import React, { useState, useEffect } from 'react';
import { Grid, Typography, TextField, FormControlLabel, Checkbox, Button, Tooltip, IconButton } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';

import {
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell
} from '@material-ui/core';

import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';

import * as API from '../../lib/api';

import { Maybe, Customer, Show, User, EmailReportSetting, EmailReportType } from '../../types';

const useStyles = makeStyles((theme) => ({
    menuTable: {
        borderTopWidth: 2,
        borderColor: '#f9f9f9',
        borderWidth: 2,
        borderStyle: 'solid'
    }
}));

const reportTypeOptions = Object.keys(EmailReportType).map(key => EmailReportType[key]);

export function EmailReportSettings(props: EmailReportSettingsProps) {
    const classes = useStyles();
    const [customers, setCustomers] = useState<Array<Customer>>([]);
    const [selectedCustomer, setSelectedCustomer] = useState<Maybe<Customer>>(null);
    const [shows, setShows] = useState<Array<Show>>([]);
    const [selectedShow, setSelectedShow] = useState<Maybe<Show>>(null);
    const [users, setUsers] = useState<Array<User>>([]);
    const [selectedUser, setSelectedUser] = useState<Maybe<User>>(null);
    const [reportType, setReportType] = useState<EmailReportType>(EmailReportType.Dashboard);

    const [dailyEnabled, setDailyEnabled] = useState(false);
    const [weeklyEnabled, setWeeklyEnabled] = useState(false);
    const [monthlyEnabled, setMonthlyEnabled] = useState(false);
    const [yearlyEnabled, setYearlyEnabled] = useState(false);
    const [reportSettings, setReportSettings] = useState<Array<EmailReportSetting>>([]);

    useEffect(() => {
        const fetchCustomers = async () => {
            const customersResult = await API.listCustomers();
            setCustomers(customersResult);
            if (customersResult && customersResult.length > 0) {
                let customer = customersResult[0];
                setSelectedCustomer(customer);

                const customerShows = await API.listShowsSideBar({ customerID: customer.id });
                setShows(customerShows);
                if (customerShows && customerShows.length > 0) setSelectedShow(customerShows[0]);
            }
        }

        const fetchUsers = async () => {
            const usersResult = await API.listUsers();
            setUsers(usersResult);
            if (usersResult && usersResult.length > 0) setSelectedUser(usersResult[0]);
        }

        const fetchEmailReportSettings = async () => {
            const emailReportSettingsResult = await API.listEmailReportSettings();
            setReportSettings(emailReportSettingsResult);
        }

        fetchCustomers();
        fetchUsers();
        fetchEmailReportSettings();
    }, [])

    const onUserChanged = (event, user) => {
        setSelectedUser(user);
    }

    const onCustomerChanged = async (event, customer) => {
        setSelectedCustomer(customer);

        const customerShows = await API.listShowsSideBar({ customerID: customer.id });
        setShows(customerShows);
        if (customerShows && customerShows.length > 0) setSelectedShow(customerShows[0]);
        else setSelectedShow(null);
    }

    const onShowChanged = (event, show) => {
        setSelectedShow(show)
    }

    const onReportTypeChange = (event, reportType) => {
        setReportType(reportType);
    }

    const onDeleteReportSetting = async (id) => {

    }

    return (
        <Grid container spacing={2} direction="column">
            <Grid item>
                <Typography variant="h6">Email Report Settings</Typography>
            </Grid>
            <Grid item xs={6}>
                <Autocomplete id="user-combo-box"
                    options={users}
                    getOptionLabel={(option: User) => `${option.given_name} ${option.family_name}`}
                    value={selectedUser}
                    onChange={onUserChanged}
                    renderInput={(params) => <TextField {...params} label="User" variant="outlined" />}
                />
            </Grid>
            <Grid item xs={6}>
                <Autocomplete
                    id="customer-combo-box"
                    options={customers}
                    getOptionLabel={(option: Customer) => option.shortName}
                    value={selectedCustomer}
                    onChange={onCustomerChanged}
                    renderInput={(params) => <TextField {...params} label="Customer" variant="outlined" />}
                />
            </Grid>
            <Grid item xs={6}>
                <Autocomplete
                    id="show-combo-box"
                    options={shows}
                    getOptionLabel={(option: Show) => option.nameShort}
                    value={selectedShow}
                    onChange={onShowChanged}
                    renderInput={(params) => <TextField {...params} label="Show" variant="outlined" />}
                />
            </Grid>
            <Grid item xs={6}>
                <Autocomplete
                    id="report-type-combo-box"
                    options={reportTypeOptions}
                    getOptionLabel={(option) => option}
                    value={reportType}
                    onChange={onReportTypeChange}
                    renderInput={(params) => <TextField {...params} label="Report Type" variant="outlined" />}
                />
            </Grid>
            <Grid item container spacing={2} direction="row">
                <Grid item xs={1}>
                    <FormControlLabel
                        control={<Checkbox checked={dailyEnabled} onChange={(e) => setDailyEnabled(e.target.checked)} />}
                        label="Daily"
                    />
                </Grid>
                <Grid item xs={1}>
                    <FormControlLabel
                        control={<Checkbox checked={weeklyEnabled} onChange={(e) => setWeeklyEnabled(e.target.checked)} />}
                        label="Weekly"
                    />
                </Grid>
                <Grid item xs={1}>
                    <FormControlLabel
                        control={<Checkbox checked={monthlyEnabled} onChange={(e) => setMonthlyEnabled(e.target.checked)} />}
                        label="Monthly"
                    />
                </Grid>
                <Grid item xs={1}>
                    <FormControlLabel
                        control={<Checkbox checked={yearlyEnabled} onChange={(e) => setYearlyEnabled(e.target.checked)} />}
                        label="Yearly"
                    />
                </Grid>
            </Grid>
            <Grid item>
                <Button variant="contained" color="secondary">
                    Subscribe User to Emails
                </Button>
            </Grid>
            <Grid item>
                <TableContainer>
                    <Table className={classes.menuTable} size="small" aria-label="seasons">
                        <TableHead>
                            <TableRow style={{ background: "#f9f9f9" }}>
                                <TableCell align="center">User</TableCell>
                                <TableCell align="center">Report Type</TableCell>
                                <TableCell align="center">Daily</TableCell>
                                <TableCell align="center">Weekly</TableCell>
                                <TableCell align="center">Monthly</TableCell>
                                <TableCell align="center">Yearly</TableCell>
                                <TableCell align="center">Customer</TableCell>
                                <TableCell align="center">Show</TableCell>
                                <TableCell align="center">Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                reportSettings.map((reportSetting: EmailReportSetting, index: number) => {
                                    return (
                                        <EmailReportSettingRow key={reportSetting.id} emailReportSetting={reportSetting} index={index} onDelete={onDeleteReportSetting} />
                                    )
                                })
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </Grid>
    )
}

export interface EmailReportSettingsProps {
    authData: any
}

function EmailReportSettingRow(props: EmailReportSettingRowProps) {
    const [dailyEnabled, setDailyEnabled] = useState(props.emailReportSetting.daily);
    const [weeklyEnabled, setWeeklyEnabled] = useState(props.emailReportSetting.weekly);
    const [monthlyEnabled, setMonthlyEnabled] = useState(props.emailReportSetting.monthly);
    const [yearlyEnabled, setYearlyEnabled] = useState(props.emailReportSetting.yearly);

    const update = async () => {
        const input = {
            id: props.emailReportSetting.id,
            daily: dailyEnabled,
            weekly: weeklyEnabled,
            monthly: monthlyEnabled,
            yearly: yearlyEnabled
        }

        await API.updateEmailReportSetting(input);
    }

    const deleteSetting = async () => {
        await API.deleteEmailReportSetting(props.emailReportSetting.id);
        props.onDelete(props.emailReportSetting.id);
    }
    
    return (
        <TableRow key={props.emailReportSetting.id} style={props.index % 2 ? { background: "#f9f9f9" } : { background: "white" }}>
            <TableCell align="center">{props.emailReportSetting.userID}</TableCell>
            <TableCell align="center">{props.emailReportSetting.reportType}</TableCell>
            <TableCell align="center">
                <Checkbox checked={dailyEnabled} onChange={(e) => setDailyEnabled(e.target.checked)} />
            </TableCell>
            <TableCell align="center">
                <Checkbox checked={weeklyEnabled} onChange={(e) => setWeeklyEnabled(e.target.checked)} />
            </TableCell>
            <TableCell align="center">
                <Checkbox checked={monthlyEnabled} onChange={(e) => setMonthlyEnabled(e.target.checked)} />
            </TableCell>
            <TableCell align="center">
                <Checkbox checked={yearlyEnabled} onChange={(e) => setYearlyEnabled(e.target.checked)} />
            </TableCell>
            <TableCell align="center">{props.emailReportSetting.customerName}</TableCell>
            <TableCell align="center">{props.emailReportSetting.showName}</TableCell>
            <TableCell align="center">
                <Tooltip title="Save">
                    <IconButton aria-label="save" onClick={update}>
                        <SaveIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Remove">
                    <IconButton aria-label="disable user" color="primary" onClick={deleteSetting}>
                        <DeleteIcon />
                    </IconButton>
                </Tooltip>
            </TableCell>
        </TableRow>
    )
}

interface EmailReportSettingRowProps {
    emailReportSetting: EmailReportSetting
    index: number
    onDelete: Function
}