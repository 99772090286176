import React, { useState, useEffect } from 'react';
import { Grid, Paper, Typography, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { TableContainer, Table, TableBody, TableRow, TableCell } from '@material-ui/core';

import * as API from '../../../lib/api';
import { DashboardProductImpression } from '../../../types';

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    productsTable: {
        borderTopWidth: 2,
        borderColor: '#f9f9f9',
        borderWidth: 2,
        borderStyle: 'solid'
    },
    emptyCell: {
        borderBottom: 'none'
    }
}));

export function TopProductsCard(props: TopProductsCardProps) {
    const classes = useStyles();
    const [impressionsTable, setImpressionsTable] = useState(<EmptyRow />);
    const [viewsTable, setViewsTable] = useState(<EmptyRow />);
    const [clicksTable, setClicksTable] = useState(<EmptyRow />);
    const [currentTable, setCurrentTable] = useState(impressionsTable);
    const [selectedButton, setSelectedButton] = useState('impressions');

    const buildTable = (products) => {
        return (
            <>
                {
                    products && products.length > 0
                        ? products.map((product: DashboardProductImpression, index) => {
                            return (
                                <ProductRow key={product.productId} product={product} index={index} />
                            )
                        })
                        : (
                            <EmptyRow />
                        )
                }
            </>
        )
    }

    const onImpressionsClicked = () => {
        setCurrentTable(impressionsTable);
        setSelectedButton('impressions')
    }

    const onViewsClicked = () => {
        setCurrentTable(viewsTable);
        setSelectedButton('views')
    }

    const onClicksClicked = () => {
        setCurrentTable(clicksTable);
        setSelectedButton('clicks')
    }

    useEffect(() => {
        if (!props.customerID) return;

        const fetchTopTenProductsImpressions = async () => {
            const queryArgs = {
                customerID: props.customerID,
                fromDate: props.dates.fromDate.toISOString(),
                toDate: props.dates.toDate.toISOString()
            }

            const topTenProductsImpressionsResult = await API.getDashboardTopTenProductsImpressions(queryArgs);
            const impressionsTableRef = buildTable(topTenProductsImpressionsResult);
            setImpressionsTable(impressionsTableRef);
            setCurrentTable(impressionsTableRef);
        }

        const fetchTopTenProductsViews = async () => {
            const queryArgs = {
                customerID: props.customerID,
                fromDate: props.dates.fromDate.toISOString(),
                toDate: props.dates.toDate.toISOString()
            }
            const topTenProductsViewsResult = await API.getDashboardTopTenProductsViews(queryArgs);
            const viewsTableRef = buildTable(topTenProductsViewsResult);
            setViewsTable(viewsTableRef);
        }

        const fetchTopTenProductsClicks = async () => {
            const queryArgs = {
                customerID: props.customerID,
                fromDate: props.dates.fromDate.toISOString(),
                toDate: props.dates.toDate.toISOString()
            }
            const topTenProductsClicksResult = await API.getDashboardTopTenProductsClicks(queryArgs);
            const clicksTableRef = buildTable(topTenProductsClicksResult);
            setClicksTable(clicksTableRef);
        }

        fetchTopTenProductsImpressions();
        fetchTopTenProductsViews();
        fetchTopTenProductsClicks();
    }, [props.customerID, props.dates])

    return (
        <Paper className={classes.paper}>
            <Grid container spacing={2} direction="column">
                <Grid item container direction="row" xs>
                    <Grid item xs={6}>
                        <Typography>Top Products</Typography>
                    </Grid>
                    <Grid item container alignItems="center" xs>
                        <Button onClick={onImpressionsClicked}
                            color={selectedButton === 'impressions' ? "secondary" : "default"}
                            variant={selectedButton === 'impressions' ? "contained" : "text"}
                        >
                            Impressions
                        </Button>
                        <Button onClick={onViewsClicked}
                            color={selectedButton === 'views' ? "secondary" : "default"}
                            variant={selectedButton === 'views' ? "contained" : "text"}
                        >
                            Views
                        </Button>
                        <Button onClick={onClicksClicked}
                            color={selectedButton === 'clicks' ? "secondary" : "default"}
                            variant={selectedButton === 'clicks' ? "contained" : "text"}
                        >
                            Clicks
                        </Button>
                    </Grid>
                </Grid>
                <Grid item>
                    <TableContainer>
                        <Table className={classes.productsTable} size="small" aria-label="dashboard products">
                            <TableBody>
                                {currentTable}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        </Paper>
    )
}

export interface TopProductsCardProps {
    customerID: number
    dates: { fromDate: Date, toDate: Date }
}

function ProductRow(props: ProductRowProps) {
    const [thumbnail] = useState(props.product?.MainPhotoURL?.replace('ORIGINAL', '200') || '');

    return (
        <TableRow style={props.index % 2 ? { background: "#f9f9f9" } : { background: "white" }}>
            <TableCell align="center">
                <img src={thumbnail} alt={props.product.Name} width="auto" height="75" />
            </TableCell>
            <TableCell align="left">
                <Typography>{props.product.Name}</Typography>
            </TableCell>
            <TableCell align="right">
                {props.product.totalViews}
            </TableCell>
        </TableRow>
    )
}

interface ProductRowProps {
    product: DashboardProductImpression
    index: number
}

function EmptyRow() {
    const classes = useStyles();

    return (
        <TableRow>
            <TableCell className={classes.emptyCell} align="left">No data for this date range.</TableCell>
        </TableRow>
    )
}