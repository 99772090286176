import React, { useState, useEffect } from 'react';
import { Grid, LinearProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import path from 'path';

import { TabPanel } from '../../tabs/TabPanel';
import { FileUploadButton } from '../../shared/FileUploadButton';

import { uploadPresignedFile } from '../../../lib/util';
import * as API from '../../../lib/api';

import { Maybe, Vendor } from '../../../types';

const useStyles = makeStyles((theme) => ({
    logo: {
        maxHeight: '100px',
        maxWidth: '400px'
    },
}));

export function VendorImagesTab(props: VendorImagesTabProps) {
    const classes = useStyles();
    const [logo, setLogo] = useState('');
    const [uploading, setUploading] = useState(false);
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        if (!props.vendor) return;

        if (props.vendor.logoBucket && props.vendor.logoKey) {
            setLogo(`https://s3.dualstack.us-east-2.amazonaws.com/${props.vendor.logoBucket}/${props.vendor.logoKey}`);
        }

    }, [props.vendor])

    const updateVendorLogo = async ({ target }) => {
        if (!props.vendor) return;

        const file = target.files[0];
        if (!file) return;

        const fileExtension = path.extname(file.name).toUpperCase();

        const now = new Date().getTime()
        const filename = `VENDORLOGO-${now}${fileExtension}`;

        const presignedUrl = await API.generatePresignedRawMediaURL({
            operation: 'putObject',
            key: filename,
            metadata: [
                { name: 'vendor', value: props.vendor.id }
            ]
        })

        var options = {
            headers: {
                'Content-Type': file.type
            },
            onUploadProgress: function (progressEvent) {
                var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                setProgress(percentCompleted);
            }
        };

        setUploading(true);
        await uploadPresignedFile(presignedUrl, file, options);
        setUploading(false);

        setProgress(0);
    }

    return (
        <TabPanel value={props.tab} index={props.index} spacing={2}>
            {
                uploading &&
                <Grid item xs={12}>
                    <LinearProgress variant="determinate" value={progress} color="secondary" />
                </Grid>
            }
            <Grid item container spacing={2} direction="column" xs={6} sm={6}>
                <Grid item>
                    <img src={logo} className={classes.logo} alt="vendor logo" />
                </Grid>
                <Grid item>
                    <FileUploadButton
                        id="vendor-logo-upload-button"
                        accept="image/*"
                        onChange={updateVendorLogo}
                        text="Add/Change Vendor Logo"
                    />
                </Grid>
            </Grid>
            <Grid item container spacing={2} direction="column" xs={6} sm={6}>

            </Grid>

        </TabPanel>
    )
}
export interface VendorImagesTabProps {
    tab: number
    index: number
    vendor: Maybe<Vendor>
}