import React, { useState, useEffect, useReducer } from 'react';
import { Grid, Typography, Tabs, Tab, AppBar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { a11yProps } from '../../tabs/TabPanel';

import { GeneralInfoTab, VideoProductsTab, VideoTab } from './exclusiveTabs';

import * as API from '../../../lib/api';
import { ExclusiveVideoContent, Product, Show } from '../../../types';

import { API as AmplifyAPI, graphqlOperation } from 'aws-amplify';
import { onUpdateExclusiveVideoContent } from '../../../graphql/customSubscriptions';

const useStyles = makeStyles((theme) => ({
    mainBar: {
        backgroundColor: 'black'
    },
    divider: {
        color: 'white',
        backgroundColor: 'white'
    },
    poster: {
        maxHeight: '100px',
        maxWidth: '400px'
    },
    tab: {
        minWidth: 0,
        fontSize: 12
    }
}));

const initialState = {
    exclusive: undefined,
    error: false,
    loading: false
}

function reducer(state, action) {
    switch (action.type) {
        case 'setExclusive':
            return {
                ...state,
                exclusive: action.exclusive
            }
        case 'updateExclusiveFromSubscription':
            if (action.exclusive.id !== state.exclusive.id) return state;

            let toUpdate = state.exclusive;
            toUpdate = Object.assign(toUpdate, action.exclusive);
            return {
                ...state,
                exclusive: toUpdate
            }
        default:
            throw new Error();
    }
}

export function Exclusive(props: ExclusiveProps) {
    const classes = useStyles();
    const [tab, setTab] = useState(0);
    const [exclusiveState, dispatch] = useReducer(reducer, initialState)
    const [products, setProducts] = useState<Array<Product>>([]);

    const tabs = [
        { key: 'info', label: 'General Info', disabled: false },
        { key: 'video', label: 'Video', disabled: false },
        { key: 'video-products', label: 'Video Products', disabled: false },
    ];

    useEffect(() => {
        const fetchExclusiveVideo = async () => {
            const exclusiveResult = await API.getExclusiveVideoContent({ 
                id: props.exclusive.id,
                filter: {
                    onlyActiveProducts: false
                }
            });
            dispatch({ type: 'setExclusive', exclusive: exclusiveResult });
        }

        fetchExclusiveVideo();

        // TODO - this is BROKEN BROKEN BROKEN
        // @ts-ignore - for some reason it doesn't like the .subscribe.... 
        const onUpdateExclusiveSubscription = AmplifyAPI.graphql(graphqlOperation(onUpdateExclusiveVideoContent)).subscribe({
            next: (provider) => {
                const updatedExclusive = provider?.value?.data?.onUpdateExclusiveVideoContent;
                if (updatedExclusive) {
                    dispatch({ type: 'updateExclusiveFromSubscription', exclusive: updatedExclusive })
                } else {
                    console.error('Something went wrong... Exlusive was updated though')
                }
            },
            error: (error) => {
                console.error(error);
            }
        });

        return function cleanup() {
            // Stop receiving data updates from the subscription
            onUpdateExclusiveSubscription.unsubscribe();
        };
    }, [props.exclusive])

    useEffect(() => {
        if (!props.show.customerID) return;

        const fetchCustomerProducts = async () => {
            const productsResult = await API.listProducts({ customerID: props.show.customerID });
            setProducts(productsResult);
        }

        fetchCustomerProducts();
    }, [props.show])

    const handleTabChange = (event, newValue) => {
        setTab(newValue);
    };

    return (
        <>
            <Grid item xs={12}>
                <Typography variant="h6">{exclusiveState.exclusive?.name}</Typography>
                <AppBar position="static" className={classes.mainBar}>
                    <Tabs value={tab} onChange={handleTabChange} aria-label="exclusive-tabs" variant="scrollable" scrollButtons="on">
                        {
                            tabs.map((tab, index) => {
                                return (
                                    <Tab key={tab.key}
                                        label={tab.label}
                                        {...a11yProps(`${index}-exclusive`)}
                                        className={classes.tab}
                                        disabled={tab.disabled}
                                    />
                                )
                            })
                        }
                    </Tabs>
                </AppBar>
            </Grid>
            <GeneralInfoTab tab={tab} index={0} exclusive={exclusiveState.exclusive} show={props.show} />
            <VideoTab tab={tab} index={1} exclusive={exclusiveState.exclusive} customerID={props.show.customerID} />
            <VideoProductsTab tab={tab} index={2} exclusive={exclusiveState.exclusive} products={products} />
        </>
    )
}

export interface ExclusiveProps {
    show: Show
    exclusive: ExclusiveVideoContent
    location: any
}