import React, { useState, useEffect, useReducer } from 'react';
import { Grid, Typography, Tabs, Tab, AppBar, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';

import ArrowBack from '@material-ui/icons/ArrowBack';

import { a11yProps } from '../tabs/TabPanel';
import * as API from '../../lib/api';

import { BrandDetailTab, BrandProductsTab } from './brandTabs';

const useStyles = makeStyles((theme) => ({
    mainBar: {
        backgroundColor: 'black',
    },
    tab: {
        minWidth: 0
    }
}));

const initialState = {
    brand: undefined,
    customerID: undefined,
    loading: true,
    error: false
}

function reducer(state, action) {
    switch (action.type) {
        case 'fetchBrandSuccess':
            return {
                ...state,
                brand: action.brand,
                loading: false
            }
        case 'updateBrandFromSubscription':
            return {
                ...state,
                brand: action.brand
            }
        case 'setCustomerID':
            return {
                ...state,
                customerID: action.customerID
            }
        default:
            throw new Error();
    }
}

export function Brand(props: BrandProps) {
    const classes = useStyles();
    const history = useHistory();
    const [tab, setTab] = useState(0);
    const [brandState, dispatch] = useReducer(reducer, initialState);

    const handleChange = (event, newValue) => {
        setTab(newValue);
    };

    useEffect(() => {
        if (!props.customerID) return;

        if (!brandState.customerID) {
            dispatch({
                type: 'setCustomerID',
                customerID: props.customerID
            })

            return;
        }

        if (brandState.customerID !== props.customerID) history.push('/products/brands');
        if (brandState.product && brandState.brand.customerID !== props.customerID) history.push('/products/brands');
    }, [props.customerID])

    useEffect(() => {
        const fetchBrand = async () => {
            const brandResult = await API.getBrand({ id: props.match.params.brandID });
            dispatch({
                type: 'fetchBrandSuccess',
                brand: brandResult
            })
        }

        fetchBrand();
    }, [props.match.params.brandID])

    const tabs = [
        { key: 'brandDetail', label: 'Brand Detail', disabled: false },
        { key: 'brandProducts', label: 'Brand Products', disabled: false }
    ]

    const onBackClick = () => {
        history.push('/products/brands');
    }

    return (
        <Grid container spacing={1}>
            <Grid item container spacing={1} direction="row" alignItems="center">
                <Grid item>
                    <IconButton onClick={onBackClick}>
                        <ArrowBack />
                    </IconButton>
                </Grid>
                <Grid item>
                    <Typography variant="h5">Brand Detail: {brandState.brand?.name}</Typography>
                </Grid>
            </Grid>
            <br />
            <Grid item xs={12}>
                <AppBar position="static" className={classes.mainBar}>
                    <Tabs value={tab} onChange={handleChange} aria-label="brand tabs" variant="scrollable" scrollButtons="on">
                        {
                            tabs.map((tab, index) => {
                                return (
                                    <Tab key={tab.key} label={tab.label} {...a11yProps(index)} className={classes.tab} disabled={tab.disabled} />
                                )
                            })
                        }
                    </Tabs>
                </AppBar>
            </Grid>
            <BrandDetailTab tab={tab} index={0} brand={brandState.brand} />
            <BrandProductsTab tab={tab} index={1} brand={brandState.brand} />
        </Grid>
    )
}

interface BrandProps {
    customerID: number
    match: { params: { brandID } }
}