import axios from 'axios';

export async function uploadPresignedFile(presignedUrl, file, options) {
    return await axios.put(presignedUrl, file, options);
}

export function toSentenceCase(camelCase) {
    return camelCase.replace(/([A-Z])/g, (match) => ` ${match}`)
        .replace(/^./, (match) => match.toUpperCase())
        .trim();
}

export function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export function formatSeconds(seconds, decimals = 2) {
    if (seconds === 0) return '0 Seconds';

    const k = 60;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Seconds', 'Min', 'Hours'];

    let i = Math.floor(Math.log(seconds) / Math.log(k));
    if (i >= sizes.length) i = sizes.length - 1

    return parseFloat((seconds / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export function formattedTime(value, index, values) {
    var hours = ("0" + Math.floor(value / (60 * 60))).slice(-2);
    var divisor_for_minutes = value % (60 * 60);
    var minutes = ("0" + Math.floor(divisor_for_minutes / 60)).slice(-2);

    var divisor_for_seconds = divisor_for_minutes % 60;
    var seconds = ("0" + Math.ceil(divisor_for_seconds)).slice(-2);

    return `${hours}h:${minutes}m:${seconds}s`
}

const VOXI_ADMINS_GROUP_NAME = 'VoxiAdmins';
const CONTENT_ADMINS_GROUP_NAME = 'ContentAdmins';

export function isVoxiAdmin(groups) {
    let groupFound = groups.find(group => group === VOXI_ADMINS_GROUP_NAME)
    return groupFound ? true : false;
}

export function isContentAdmin(groups) {
    let groupFound = groups.find(group => group === CONTENT_ADMINS_GROUP_NAME)
    return groupFound ? true : false;
}

export const seasonsOptions = [
    { name: 'Commercial', season: -1 },
    { name: 'Live Stream', season: -2 },
    { name: '0', season: 0 },
    { name: '1', season: 1 },
    { name: '2', season: 2 },
    { name: '3', season: 3 },
    { name: '4', season: 4 },
    { name: '5', season: 5 },
    { name: '6', season: 6 },
    { name: '7', season: 7 },
    { name: '8', season: 8 },
    { name: '9', season: 9 },
    { name: '10', season: 10 },
    { name: '11', season: 11 },
    { name: '12', season: 12 },
    { name: '13', season: 13 },
    { name: '14', season: 14 },
    { name: '15', season: 15 },
    { name: '16', season: 16 },
    { name: '17', season: 17 },
    { name: '18', season: 18 },
    { name: '19', season: 19 },
    { name: '20', season: 20 }
]

export const episodeNumberOptions = [
    '0',
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    '10',
    '11',
    '12',
    '13',
    '14',
    '15',
    '16',
    '17',
    '18',
    '19',
    '20'
]

export const timecodeHourOptions = [
    { name: '00', value: '00' },
    { name: '01', value: '01' },
    { name: '02', value: '02' },
    { name: '03', value: '03' },
    { name: '04', value: '04' },
    { name: '05', value: '05' },
    { name: '06', value: '06' },
    { name: '07', value: '07' },
    { name: '08', value: '08' },
    { name: '09', value: '09' },
    { name: '10', value: '10' },
    { name: '11', value: '11' },
    { name: '12', value: '12' },
    { name: '13', value: '13' },
    { name: '14', value: '14' },
    { name: '15', value: '15' },
    { name: '16', value: '16' },
    { name: '17', value: '17' },
    { name: '18', value: '18' },
    { name: '19', value: '19' },
    { name: '20', value: '20' },
    { name: '21', value: '21' },
    { name: '22', value: '22' },
    { name: '23', value: '23' }
]

export const timeCodeMinuteSecondsOptions = [
    { name: '00', value: '00' },
    { name: '01', value: '01' },
    { name: '02', value: '02' },
    { name: '03', value: '03' },
    { name: '04', value: '04' },
    { name: '05', value: '05' },
    { name: '06', value: '06' },
    { name: '07', value: '07' },
    { name: '08', value: '08' },
    { name: '09', value: '09' },
    { name: '10', value: '10' },
    { name: '11', value: '11' },
    { name: '12', value: '12' },
    { name: '13', value: '13' },
    { name: '14', value: '14' },
    { name: '15', value: '15' },
    { name: '16', value: '16' },
    { name: '17', value: '17' },
    { name: '18', value: '18' },
    { name: '19', value: '19' },
    { name: '20', value: '20' },
    { name: '21', value: '21' },
    { name: '22', value: '22' },
    { name: '23', value: '23' },
    { name: '24', value: '24' },
    { name: '25', value: '25' },
    { name: '26', value: '26' },
    { name: '27', value: '27' },
    { name: '28', value: '28' },
    { name: '29', value: '29' },
    { name: '30', value: '30' },
    { name: '31', value: '31' },
    { name: '32', value: '32' },
    { name: '33', value: '33' },
    { name: '34', value: '34' },
    { name: '35', value: '35' },
    { name: '36', value: '36' },
    { name: '37', value: '37' },
    { name: '38', value: '38' },
    { name: '39', value: '39' },
    { name: '40', value: '40' },
    { name: '41', value: '41' },
    { name: '42', value: '42' },
    { name: '43', value: '43' },
    { name: '44', value: '44' },
    { name: '45', value: '45' },
    { name: '46', value: '46' },
    { name: '47', value: '47' },
    { name: '48', value: '48' },
    { name: '49', value: '49' },
    { name: '50', value: '50' },
    { name: '51', value: '51' },
    { name: '52', value: '52' },
    { name: '53', value: '53' },
    { name: '54', value: '54' },
    { name: '55', value: '55' },
    { name: '56', value: '56' },
    { name: '57', value: '57' },
    { name: '58', value: '58' },
    { name: '59', value: '59' }
]

export const linkTypeOptions = [
    { name: 'Store', value: 0 },
    { name: 'Manufacturer', value: 1 },
    { name: 'Review', value: 2 }
]

export const linkStatusTypes = [
    { name: 'Not Checked', value: 0 },
    { name: 'Valid', value: 1 },
    { name: '404 Not Found', value: 404 },
    { name: 'Invalid URL', value: -10 },
    { name: 'Amazon 503', value: -15 },
    { name: '404 Error', value: -20 },
    { name: 'Page Timeout', value: -30 },
    { name: 'General Error', value: -100 }
]

export const colorOptions = [
    { name: 'Blue', value: 'blue' },
    { name: 'Black', value: 'black' },
    { name: 'Brown', value: 'brown' },
    { name: 'Camouflage', value: 'camo' },
    { name: 'Green', value: 'green' },
    { name: 'Grey', value: 'grey' },
    { name: 'Rainbow', value: 'multicolor' },
    { name: 'Orange', value: 'orange' },
    { name: 'Pink', value: 'pink' },
    { name: 'Purple', value: 'purple' },
    { name: 'Red', value: 'red' },
    { name: 'Tan', value: 'tan' },
    { name: 'White', value: 'white' },
    { name: 'Yellow', value: 'yellow' },
    { name: 'Other', value: 'other' },
]

export function convertTimecodeToMilliseconds(timecode: string) {
    let vals = timecode.split(':');
    let totalSeconds = 0;

    totalSeconds += parseInt(vals[0]) * 60 * 60;
    totalSeconds += parseInt(vals[1]) * 60;
    totalSeconds += parseInt(vals[2]);
    totalSeconds = totalSeconds * 1000

    return totalSeconds
}

export function convertTimecodeToSeconds(timecode: string) {
    let vals = timecode.split(':');
    let totalSeconds = 0;

    totalSeconds += parseInt(vals[0]) * 60 * 60;
    totalSeconds += parseInt(vals[1]) * 60;
    totalSeconds += parseInt(vals[2]);

    return totalSeconds
}

export function convertSecondsToTimecode(seconds: number) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds - (hours * 3600)) / 60);
    const secs = seconds - (hours * 3600) - (minutes * 60);

    let hoursString = `${hours}`;
    let minutesString = `${minutes}`;
    let secondsString = `${secs}`;

    if (hours < 10) { hoursString = "0" + hours; }
    if (minutes < 10) { minutesString = "0" + minutes; }
    if (secs < 10) { secondsString = "0" + secs; }
    return hoursString + ':' + minutesString + ':' + secondsString;
}

const millisecondsInHour = 3600000;
const millisecondsInMinute = 60000

export function convertMillisecondsToTimecode(milliseconds: number) {
    const hours = Math.floor(milliseconds / millisecondsInHour);
    const minutes = Math.floor((milliseconds - (hours * millisecondsInHour)) / millisecondsInMinute);
    const secs = (milliseconds - (hours * millisecondsInHour) - (minutes * millisecondsInMinute)) / 1000;

    let hoursString = `${hours}`;
    let minutesString = `${minutes}`;
    let secondsString = `${secs}`;

    if (hours < 10) { hoursString = "0" + hours; }
    if (minutes < 10) { minutesString = "0" + minutes; }
    if (secs < 10) { secondsString = "0" + secs; }
    return hoursString + ':' + minutesString + ':' + secondsString;
}

export function convertSecondsToTime(timeInSeconds: any) {
    const time = parseFloat(timeInSeconds).toFixed(3);
    const hours = Math.floor(parseFloat(time) / 60 / 60);
    const minutes = Math.floor(parseFloat(time) / 60) % 60;
    const seconds = Math.floor(parseFloat(time) - minutes * 60);

    return { hours, minutes, seconds };
}

export function convertMillisecondsToTime(timeInMilliseconds: any) {
    const time = (parseFloat(timeInMilliseconds) / 1000).toFixed(3);
    const hours = Math.floor(parseFloat(time) / 60 / 60);
    const minutes = Math.floor(parseFloat(time) / 60) % 60;
    const seconds = Math.floor(parseFloat(time) - minutes * 60);

    return { hours, minutes, seconds };
}

export function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
    return value !== null && value !== undefined;
}