import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Grid, TextField, Button, Tooltip, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import EditIcon from '@material-ui/icons/Edit';

import * as API from '../../lib/api';

import { TableContainer, Table, TableHead, TableBody, TableRow, TableCell } from '@material-ui/core';

import { Customer } from '../../types';

const useStyles = makeStyles((theme) => ({
    menuTable: {
        borderTopWidth: 2,
        borderColor: '#f9f9f9',
        borderWidth: 2,
        borderStyle: 'solid'
    }
}));

export function Customers(props: CustomersProps) {
    const classes = useStyles();
    const history = useHistory();
    const [addCustomerName, setAddCustomerName] = useState('');
    const [customers, setCustomers] = useState<Array<Customer>>([]);

    useEffect(() => {
        const fetchCustomers = async () => {
            const customerList = await API.listCustomers();
            setCustomers(customerList);
        }

        fetchCustomers();
    }, [])

    const createCustomer = async () => {
        let input = {
            shortName: addCustomerName,
            fullName: addCustomerName,
            active: true,
            isVendor: false
        }

        const newCustomer = await API.createCustomer(input);
        if (newCustomer) setCustomers(customers.concat(newCustomer));

        setAddCustomerName('');
    }

    const onEditClick = (id) => {
        history.push(`/superadmin/customers/${id}`);
    }

    return (
        <Grid container spacing={3} direction="column">
            <Grid item container spacing={3} direction="row">
                <Grid item>
                    <TextField variant="outlined"
                        value={addCustomerName}
                        onChange={(e) => setAddCustomerName(e.target.value)}
                        label="Customer Name"
                        placeholder="Customer Name" />
                </Grid>
                <Grid item>

                </Grid>
                <Grid item>
                    <Button variant="contained" color="secondary" onClick={createCustomer}>
                        Add Customer
                    </Button>
                </Grid>
            </Grid>
            <Grid item>
                <TableContainer>
                    <Table className={classes.menuTable} size="small" aria-label="seasons">
                        <TableHead>
                            <TableRow style={{ background: "#f9f9f9" }}>
                                <TableCell align="center">Customer ID</TableCell>
                                <TableCell align="center">Short Name</TableCell>
                                <TableCell align="center">Full Name</TableCell>
                                <TableCell align="center">Active</TableCell>
                                <TableCell align="center">Added</TableCell>
                                <TableCell align="center">AdminType</TableCell>
                                <TableCell align="center">Is Vendor</TableCell>
                                <TableCell align="center">Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                customers.map((customer: Customer, index) => {
                                    return (
                                        <TableRow key={customer.id} style={index % 2 ? { background: "#f9f9f9" } : { background: "white" }}>
                                            <TableCell align="center">{customer.id}</TableCell>
                                            <TableCell align="center">{customer.shortName}</TableCell>
                                            <TableCell align="center">{customer.fullName}</TableCell>
                                            <TableCell align="center">{customer.active.toString()}</TableCell>
                                            <TableCell align="center">{customer.added}</TableCell>
                                            <TableCell align="center">{customer.adminType}</TableCell>
                                            <TableCell align="center">{customer.isVendor.toString()}</TableCell>
                                            <TableCell align="center">
                                                <Tooltip title="Edit">
                                                    <IconButton onClick={() => onEditClick(customer.id)}>
                                                        <EditIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </TableCell>
                                        </TableRow>
                                    )
                                })
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </Grid>
    )
}

export interface CustomersProps {
    authData: any
}