import React, { useState, useEffect, forwardRef } from 'react';
import ReactPlayer from 'react-player'
import { Grid, Typography, Button, TextField, FormControlLabel, Checkbox } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

import DeleteIcon from '@material-ui/icons/Delete';

import useSnackbars from '../../../../hooks/useSnackbars';
import * as API from '../../../../lib/api';
import { TabPanel } from '../../../tabs/TabPanel';

import MaterialTable from "material-table";
import { Icons } from 'material-table'; // typescript specific import https://github.com/mbrn/material-table/issues/1150

import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';

import { timecodeHourOptions, timeCodeMinuteSecondsOptions, convertTimecodeToMilliseconds, convertSecondsToTime, convertMillisecondsToTimecode } from '../../../../lib/util';
import { ExclusiveVideoContent, ExclusiveVideoContentProductDefinition, ExclusiveVideoProduct, Maybe, UpdateExclusiveVideoContentInput } from '../../../../types';

const tableIcons: Icons = {
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

const defaultOption = { name: '', value: -1 };

export function VideoProductsTab(props: VideoProductsTabProps) {
    const { createSuccessSnack, createErrorSnack } = useSnackbars();
    const [exclusive, setExclusive] = useState(props.exclusive);
    const [playing] = useState(false);
    const [videoProducts, setVideoProducts] = useState<Array<ExclusiveVideoProduct>>([]);
    const [productOptions, setProductOptions] = useState<Array<any>>([]);
    const [newVideoProduct, setNewVideoProduct] = useState(defaultOption);
    const [lockStartTimecode, setLockStartTimecode] = useState(false);
    const [lockEndTimecode, setLockEndTimecode] = useState(false);
    const [newProductStartHour, setNewProductStartHour] = useState(timecodeHourOptions[0]);
    const [newProductStartMinutes, setNewProductStartMinutes] = useState(timeCodeMinuteSecondsOptions[0]);
    const [newProductStartSeconds, setNewProductStartSeconds] = useState(timeCodeMinuteSecondsOptions[0]);
    const [newProductEndHour, setNewProductEndHour] = useState(timecodeHourOptions[0]);
    const [newProductEndMinutes, setNewProductEndMinutes] = useState(timeCodeMinuteSecondsOptions[0]);
    const [newProductEndSeconds, setNewProductEndSeconds] = useState(timeCodeMinuteSecondsOptions[0]);

    useEffect(() => {
        if (!props.exclusive) return;

        const options = props.products.map(product => {
            return { name: product.name, value: product.id }
        })
        if (options) setProductOptions(options)

        if (props.exclusive.videoProducts) {
            setVideoProducts(props.exclusive.videoProducts);
            setExclusive(props.exclusive);
        }
    }, [props.exclusive, props.products])

    const addProduct = async () => {
        if (!exclusive) return;

        const startTimecode = [newProductStartHour.value, newProductStartMinutes.value, newProductStartSeconds.value].join(':');
        const endTimecode = [newProductEndHour.value, newProductEndMinutes.value, newProductEndSeconds.value].join(':');

        const newProductDefinition: ExclusiveVideoContentProductDefinition = {
            productID: newVideoProduct?.value,
            startTimeMilliseconds: convertTimecodeToMilliseconds(startTimecode),
            endTimeMilliseconds: convertTimecodeToMilliseconds(endTimecode),
        }

        const input: UpdateExclusiveVideoContentInput = {
            id: exclusive.id,
            productDefinitions: [
                ...exclusive.productDefinitions ?? [],
                newProductDefinition
            ]
        }

        try {
            const exclusiveUpdateResult = await API.updateExclusiveVideoContent(input);
            if (!exclusiveUpdateResult) {
                createErrorSnack('Failed to add product');
                return;
            }

            setExclusive(exclusiveUpdateResult);

            const product = props.products.find(product => product.id === newProductDefinition.productID);
            if (product) {
                videoProducts.push({
                    product,
                    startTimeMilliseconds: newProductDefinition.startTimeMilliseconds,
                    endTimeMilliseconds: newProductDefinition.endTimeMilliseconds
                })
            }

            createSuccessSnack('Successfully added product');
        } catch (err) {
            console.error(err)
            createErrorSnack('Failed to add product');
        }
    }

    const onProductChange = (event, newValue) => {
        setNewVideoProduct(newValue);
    }

    const onStartHourChange = (event, newValue) => {
        setNewProductStartHour(newValue);
    }

    const onStartMinutesChange = (event, newValue) => {
        setNewProductStartMinutes(newValue);
    }

    const onStartSecondsChange = (event, newValue) => {
        setNewProductStartSeconds(newValue);
    }

    const onEndHourChange = (event, newValue) => {
        setNewProductEndHour(newValue);
    }

    const onEndMinutesChange = (event, newValue) => {
        setNewProductEndMinutes(newValue);
    }

    const onEndSecondsChange = (event, newValue) => {
        setNewProductEndSeconds(newValue);
    }

    const onVideoProgress = (event) => {
        const time = convertSecondsToTime(event.playedSeconds);

        if (!lockStartTimecode) {
            setNewProductStartHour(timecodeHourOptions[time.hours]);
            setNewProductStartMinutes(timeCodeMinuteSecondsOptions[time.minutes]);
            setNewProductStartSeconds(timeCodeMinuteSecondsOptions[time.seconds]);
        }

        if (!lockEndTimecode) {
            setNewProductEndHour(timecodeHourOptions[time.hours]);
            setNewProductEndMinutes(timeCodeMinuteSecondsOptions[time.minutes]);
            setNewProductEndSeconds(timeCodeMinuteSecondsOptions[time.seconds]);
        }
    }

    const onVideoDuration = (event) => {
        const time = convertSecondsToTime(event);
        setNewProductEndHour(timecodeHourOptions[time.hours]);
        setNewProductEndMinutes(timeCodeMinuteSecondsOptions[time.minutes]);
        setNewProductEndSeconds(timeCodeMinuteSecondsOptions[time.seconds]);
    }

    const renderProductImage = (rowData) => {
        return (
            <img src={rowData.product.mainPhotoURL || ''} width="50" height="50" alt={rowData.product.name} />
        )
    }

    const renderProductBrandAndName = (rowData) => {
        return (
            `${rowData.brandName || ''} - ${rowData.product.name}`
        )
    }

    const renderStartTimecode = (rowData) => {
        return convertMillisecondsToTimecode(rowData.startTimeMilliseconds);
    }

    const renderEndTimecode = (rowData) => {
        return convertMillisecondsToTimecode(rowData.endTimeMilliseconds);
    }

    const removeItem = async (event, rowData) => {
        if (!exclusive) return;

        console.log(rowData);

        const filtered = exclusive.productDefinitions?.filter(definition => definition.productID !== rowData.product.id);

        console.log('PREVIOUS LENGTH:', exclusive.productDefinitions?.length);
        console.log('FILTERED LENGTH:', filtered?.length);

        const input: UpdateExclusiveVideoContentInput = {
            id: exclusive.id,
            productDefinitions: [
                ...filtered ?? [],
            ]
        }

        try {
            const exclusiveUpdateResult = await API.updateExclusiveVideoContent(input);
            if (!exclusiveUpdateResult) {
                createErrorSnack('Failed to remove product');
                return;
            }

            setExclusive(exclusiveUpdateResult);

            const filtered = videoProducts.filter(product => product.product.id !== rowData.product.id);
            setVideoProducts(filtered);
            createSuccessSnack('Successfully removed product');
        } catch (err) {
            console.error(err)
            createErrorSnack('Failed to add product');
        }
    }

    return (
        <TabPanel value={props.tab} index={props.index} spacing={2} direction="column">
            <Grid item container spacing={2}>
                <Grid item xs={6}>
                    <ReactPlayer url={exclusive?.videoURL || ''}
                        playing={playing}
                        height={300}
                        width={533}
                        controls
                        onProgress={onVideoProgress}
                        onDuration={onVideoDuration}
                        config={{
                            file: {
                                attributes: {
                                    preload: 'metadata'
                                },
                                hlsOptions: {
                                    xhrSetup: function (xhr: any, url: string) {
                                        // https://github.com/cookpete/react-player/issues/442
                                        xhr.open('GET', url + exclusive?.videoToken);
                                    }
                                }
                            }
                        }}
                    />
                </Grid>
                <Grid item container spacing={2} direction="column" xs={12} sm={12} md={12} lg={6}>
                    <Grid item>
                        <Autocomplete id="product-combo-box"
                            options={productOptions}
                            value={newVideoProduct}
                            getOptionLabel={(option) => option.name}
                            onChange={onProductChange}
                            renderInput={(params) => <TextField {...params} label="Product" variant="outlined" />}
                        />
                    </Grid>
                    <Grid item container spacing={1} direction="row" alignItems="center" alignContent="center">
                        <Grid item xs={3}>
                            <Typography>Start Timecode:</Typography>
                        </Grid>
                        <Grid item>
                            <Autocomplete id="start-hour-combo-box"
                                options={timecodeHourOptions}
                                value={newProductStartHour}
                                getOptionLabel={(option) => option.name}
                                onChange={onStartHourChange}
                                renderInput={(params) => <TextField {...params} label="Hour" variant="outlined" />}
                            />
                        </Grid>
                        <Grid item>
                            <Autocomplete id="start-minutes-combo-box"
                                options={timeCodeMinuteSecondsOptions}
                                value={newProductStartMinutes}
                                getOptionLabel={(option) => option.name}
                                onChange={onStartMinutesChange}
                                renderInput={(params) => <TextField {...params} label="Minutes" variant="outlined" />}
                            />
                        </Grid>
                        <Grid item>
                            <Autocomplete id="start-seconds-combo-box"
                                options={timeCodeMinuteSecondsOptions}
                                value={newProductStartSeconds}
                                getOptionLabel={(option) => option.name}
                                onChange={onStartSecondsChange}
                                renderInput={(params) => <TextField {...params} label="Seconds" variant="outlined" />}
                            />
                        </Grid>
                    </Grid>
                    <Grid item container spacing={1} direction="row" alignItems="center" alignContent="center">
                        <Grid item xs={3}>
                            <Typography>End Timecode:</Typography>
                        </Grid>
                        <Grid item>
                            <Autocomplete id="end-hour-combo-box"
                                options={timecodeHourOptions}
                                value={newProductEndHour}
                                getOptionLabel={(option) => option.name}
                                onChange={onEndHourChange}
                                renderInput={(params) => <TextField {...params} label="Hour" variant="outlined" />}
                            />
                        </Grid>
                        <Grid item>
                            <Autocomplete id="end-minutes-combo-box"
                                options={timeCodeMinuteSecondsOptions}
                                value={newProductEndMinutes}
                                getOptionLabel={(option) => option.name}
                                onChange={onEndMinutesChange}
                                renderInput={(params) => <TextField {...params} label="Minutes" variant="outlined" />}
                            />
                        </Grid>
                        <Grid item>
                            <Autocomplete id="end-seconds-combo-box"
                                options={timeCodeMinuteSecondsOptions}
                                value={newProductEndSeconds}
                                getOptionLabel={(option) => option.name}
                                onChange={onEndSecondsChange}
                                renderInput={(params) => <TextField {...params} label="Seconds" variant="outlined" />}
                            />
                        </Grid>
                    </Grid>
                    <Grid item container spacing={1} direction="row" alignItems="center" alignContent="center">
                        <Grid item>
                            <FormControlLabel
                                control={<Checkbox checked={lockStartTimecode} onChange={(e) => setLockStartTimecode(e.target.checked)} name="lockStartTimecode" />}
                                label="Lock Start Timecode"
                            />
                        </Grid>
                        <Grid item>
                            <FormControlLabel
                                control={<Checkbox checked={lockEndTimecode} onChange={(e) => setLockEndTimecode(e.target.checked)} name="lockEndTimecode" />}
                                label="Lock End Timecode"
                            />
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Button variant="contained" color="secondary" onClick={addProduct}>
                            Add Product
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item container spacing={2} direction="row">
                <Grid item>
                    <MaterialTable title="Products"
                        icons={tableIcons}
                        columns={[
                            { title: "Image", render: renderProductImage },
                            { title: "Product Brand / Product Name", render: renderProductBrandAndName },
                            { title: "Start", field: "startTimecode", render: renderStartTimecode },
                            { title: "End", field: "endTimecode", render: renderEndTimecode },
                            { title: "Status", field: "product.active", type: "boolean" },
                        ]}
                        data={videoProducts}
                        options={{
                            exportButton: true,
                            exportAllData: true,
                            pageSize: 10,
                            pageSizeOptions: [10, 25, 50],
                            filtering: true,
                            actionsColumnIndex: -1
                        }}
                        actions={[
                            { icon: (() => (<DeleteIcon />)), tooltip: 'Remove Product', onClick: removeItem }
                        ]}
                    />
                </Grid>
            </Grid>
        </TabPanel>
    )
}
export interface VideoProductsTabProps {
    tab: number
    index: number
    exclusive: Maybe<ExclusiveVideoContent>
    products: any[]
}