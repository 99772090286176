import React from 'react';
import { Grid, Typography, Button } from '@material-ui/core';

import { TabPanel } from '../../../tabs/TabPanel';
import { Maybe, Show } from '../../../../types';

export function SearchTab(props: SearchTabProps) {
    return (
        <TabPanel value={props.tab} index={props.index} spacing={2} direction="column">
            <Grid item>
                <Button variant="contained" color="secondary">
                    Setup AI Search
                </Button>
            </Grid>
            <Grid item>
                <Typography variant="h5">Last Update:</Typography>
                <Typography>{props.show?.appData?.witAILastUpdate}</Typography>
            </Grid>
            <Grid item>
                <Typography variant="h5">App ID:</Typography>
                <Typography>{props.show?.appData?.witAIAppID}</Typography>
            </Grid>
            <Grid item>
                <Typography variant="h5">Access Token:</Typography>
                <Typography>{props.show?.appData?.witAIAccessToken}</Typography>
            </Grid>
            <Grid item>
                <Button variant="contained" color="secondary">
                    Add/Update All Entities
                </Button>
            </Grid>
        </TabPanel>
    )
}

export interface SearchTabProps {
    tab: number
    index: number
    show: Maybe<Show>
}