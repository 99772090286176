import React, { useState, useEffect } from 'react';
import { Grid, Typography, TextField, IconButton, Button, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';

import DeleteIcon from '@material-ui/icons/Delete';

import {
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell
} from '@material-ui/core';

import { TabPanel } from '../../tabs/TabPanel';

import * as API from '../../../lib/api';
import { Maybe, Product, ProductSearchMetadata, ProductMetadataItem } from '../../../types/api';

import { colorOptions } from '../../../lib/util';

const useStyles = makeStyles((theme) => ({
    menuTable: {
        borderTopWidth: 2,
        borderColor: '#f9f9f9',
        borderWidth: 2,
        borderStyle: 'solid'
    }
}));

const searchMetaTagOptions = [
    'Color',
    'Person',
    'Search Keyword(s)'
]

export function ProductSearchMetadataTab(props: ProductSearchMetadataTabProps) {
    const classes = useStyles();
    const [productSearchMetadata, setProductSearchMetadata] = useState<Maybe<ProductSearchMetadata>>();
    const [productMetadata, setProductMetadata] = useState<Array<ProductMetadataItem>>([]);
    const [searchMetaTag, setSearchMetaTag] = useState('Color')
    const [newColor, setNewColor] = useState(colorOptions[0]);
    const [searchMetaValue, setSearchMetaValue] = useState('');

    const fetchProductSearchMetadata = async () => {
        if (!props.product) return;

        const productSearchMetadataResult = await API.getProductSearchMetadata({ productID: props.product!.id });
        setProductSearchMetadata(productSearchMetadataResult);
    }

    const fetchProductMetadata = async () => {
        if (!props.product) return;

        const productMetadataResult = await API.getProductMetadata({ productID: props.product!.id });
        setProductMetadata(productMetadataResult);
    }

    useEffect(() => {
        if (!props.product) return;        

        fetchProductSearchMetadata();
        fetchProductMetadata();
    }, [props.product])

    const onMetaTagChange = (event, newValue) => {
        setSearchMetaTag(newValue);
    }

    const onColorChange = (event, newValue) => {
        setNewColor(newValue);
    }

    const addMetadataItem = async () => {
        if (!props.product) return;

        let value = searchMetaValue;
        if (searchMetaTag === 'Color') value = newColor.value;

        let input = {
            productID: props.product.id,
            name: searchMetaTag,
            value: value
        }

        await API.createProductMetadataItem(input);
        await fetchProductMetadata();
    }

    const deleteProductMetadataItem = async (id) => {
        await API.deleteProductMetadataItem(id);
        await fetchProductMetadata();
    }

    return (
        <TabPanel value={props.tab} index={props.index} direction="column" spacing={2}>
            <Grid item>
                <Typography variant="h4">Raw Search Data</Typography>
            </Grid>
            <Grid item>
                <Typography>Standard Keywords: {productSearchMetadata?.searchData || ''}</Typography>
            </Grid>
            <Grid item>
                <Typography>AI Search Keywords: {productSearchMetadata?.witAISearchData || ''}</Typography>
            </Grid>
            <Grid item>
                <TableContainer>
                    <Table className={classes.menuTable} size="small" aria-label="seasons">
                        <TableHead>
                            <TableRow style={{ background: "#f9f9f9" }}>
                                <TableCell align="center">Meta Tag Name</TableCell>
                                <TableCell align="center">Meta Value</TableCell>
                                <TableCell align="center">Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                productMetadata.map((productMetadataItem: ProductMetadataItem, index) => {
                                    return (
                                        <TableRow key={productMetadataItem.id} style={index % 2 ? { background: "#f9f9f9" } : { background: "white" }}>
                                            <TableCell align="center">{productMetadataItem.name}</TableCell>
                                            <TableCell align="center">{productMetadataItem.value}</TableCell>
                                            <TableCell align="center">
                                                <Tooltip title="Remove">
                                                    <IconButton color="primary" onClick={() => deleteProductMetadataItem(productMetadataItem.id)}>
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </TableCell>
                                        </TableRow>
                                    )
                                })
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            <Grid item>
                <Typography variant="h5">Add New Metadata</Typography>
            </Grid>
            <Grid item>
                <Autocomplete
                    id="search-meta-tag-combo-box"
                    options={searchMetaTagOptions}
                    getOptionLabel={(option) => option}
                    value={searchMetaTag}
                    onChange={onMetaTagChange}
                    style={{ width: '250px' }}
                    renderInput={(params) => <TextField {...params} label="Search Meta Tag" variant="outlined" />}
                />
            </Grid>
            {
                searchMetaTag === 'Color' &&
                <Grid item>
                    <Autocomplete
                        id="color-meta-value-combo-box"
                        options={colorOptions}
                        getOptionLabel={(option) => option.name}
                        value={newColor}
                        onChange={onColorChange}
                        style={{ width: '250px' }}
                        renderInput={(params) => <TextField {...params} label="Color" variant="outlined" />}
                    />
                </Grid>
            }
            {
                searchMetaTag === 'Person' &&
                <Grid item>
                    <TextField id="search-meta-value"
                        variant="outlined"
                        label="Person"
                        value={searchMetaValue}
                        style={{ width: '500px' }}
                        onChange={(e) => setSearchMetaValue(e.target.value)}
                    />
                </Grid>
            }
            {
                searchMetaTag === 'Search Keyword(s)' &&
                <Grid item>
                    <TextField id="search-meta-value"
                        variant="outlined"
                        label="Search Keywords (please, sperate keywords by a comma)"
                        value={searchMetaValue}
                        fullWidth
                        multiline
                        onChange={(e) => setSearchMetaValue(e.target.value)}
                        rowsMax={4}
                    />
                </Grid>
            }
            <Grid item>
                <Button variant="contained" color="secondary" onClick={addMetadataItem}>
                    Add Metadata
                </Button>
            </Grid>
        </TabPanel>
    )
}

export interface ProductSearchMetadataTabProps {
    tab: number
    index: number
    product: Maybe<Product>
}