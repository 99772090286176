import React from 'react';

import { TabPanel } from '../../tabs/TabPanel';
import { Maybe, Store } from '../../../types/api';

export function StoreProductsTab(props: StoreProductsTabProps) {
    return (
        <TabPanel value={props.tab} index={props.index} direction="column" spacing={2}>
        </TabPanel>
    )
}

export interface StoreProductsTabProps {
    tab: number
    index: number
    customerID: number
    store: Maybe<Store>
}