export const onCreateExclusiveVideoContent = /* GraphQL */ `
  subscription OnCreateExclusiveVideoContent {
    onCreateExclusiveVideoContent {
      id
      legacyCustomerID
      legacyShowID
      name
      description
      enabled
      firstRelease
      posterBucket
      posterKey
      videoBucket
      videoKey
      videoURL
      videoQuality
      videoType
      videoWidth
      videoHeight
      mediaConvertJobID
      mediaConvertJobStatus
      duration
      releaseToOTT
      releaseToApp
      videoToken
      disableProductScroller
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateExclusiveVideoContent = /* GraphQL */ `
  subscription OnUpdateExclusiveVideoContent {
    onUpdateExclusiveVideoContent {
      id
      legacyCustomerID
      legacyShowID
      name
      description
      enabled
      firstRelease
      posterBucket
      posterKey
      videoBucket
      videoKey
      videoURL
      videoQuality
      videoType
      videoWidth
      videoHeight
      mediaConvertJobID
      mediaConvertJobStatus
      duration
      releaseToOTT
      releaseToApp
      videoToken
      disableProductScroller
      createdAt
      updatedAt
    }
  }
`;