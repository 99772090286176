import React, { useState, useEffect, useCallback } from 'react';
import { Grid, Button, TextField } from '@material-ui/core';

import useSnackbars from '../../../../hooks/useSnackbars';

import { TabPanel } from '../../../tabs/TabPanel';

import { CreateHomeScreenSettingsInput, Show, UpdateHomeScreenSettingsInput } from '../../../../types';
import * as API from '../../../../lib/api';

const featuredProductsDefault = 'Featured Products';
const smallBusinessDefault = 'Small Business';
const recentEpisodesDefault = 'Recent Episodes';
const recommendedProductsDefault = 'Recommended Products'

export function HomeScreenSettingsTab(props: HomeScreenSettingsTabProps) {
    const { createSuccessSnack, createErrorSnack } = useSnackbars();
    const [featuredProductsLabel, setFeaturedProductsLabel] = useState(featuredProductsDefault);
    const [smallBusinessLabel, setSmallBusinessLabel] = useState(smallBusinessDefault);
    const [recentEpisodesLabel, setRecentEpisodesLabel] = useState(recentEpisodesDefault);
    const [recommendedProductsLabel, setRecommendedProductsLabel] = useState(recommendedProductsDefault);

    const reset = useCallback(async () => {
        if (!props.show) return;

        const createDefaultHomeScreenSettings = async () => {
            const input: CreateHomeScreenSettingsInput = {
                showID: props.show.id,
                legacyCustomerID: props.show.customerID,
                legacyCustomerIDString: `${props.show.customerID}`,
                featuredProductsLabel: featuredProductsDefault,
                smallBusinessLabel: smallBusinessDefault,
                recentEpisodesLabel: recentEpisodesDefault,
                recommendedProductsLabel: recommendedProductsDefault,
            }

            await API.createHomeScreenSettings(input);
        }
        
        const getHomeScreenSettings = async () => {
            const homeScreenSettingsResult = await API.getHomeScreenSettings({ showID: props.show.id });
            if (!homeScreenSettingsResult) {
                await createDefaultHomeScreenSettings();
            } else {
                setFeaturedProductsLabel(homeScreenSettingsResult.featuredProductsLabel ?? featuredProductsDefault);
                setSmallBusinessLabel(homeScreenSettingsResult.smallBusinessLabel ?? smallBusinessDefault);
                setRecentEpisodesLabel(homeScreenSettingsResult.recentEpisodesLabel ?? recentEpisodesDefault);
                setRecommendedProductsLabel(homeScreenSettingsResult.recommendedProductsLabel ?? recommendedProductsDefault);
            }
        }

        try {
            await getHomeScreenSettings();
        } catch (err) {
            console.log("ERR:", err);
            try {
                await createDefaultHomeScreenSettings();
                await getHomeScreenSettings();
            } catch (subError) {
                console.error(subError);
            }
            
        }
        
    }, [props.show])

    useEffect(() => {
        reset();
    }, [props.show, reset])

    const updateHomeScreenSettings = async () => {
        const input: UpdateHomeScreenSettingsInput = {
            showID: props.show.id,
            legacyCustomerID: props.show.customerID,
            legacyCustomerIDString: `${props.show.customerID}`,
            featuredProductsLabel,
            smallBusinessLabel,
            recentEpisodesLabel,
            recommendedProductsLabel,
        }

        try {
            await API.updateHomeScreenSettings(input);
            createSuccessSnack('Successfully updated home screen settings');
        } catch (err) {
            console.error(err);
            createErrorSnack('Failed to update home screen settings');
        }
    }

    return (
        <TabPanel value={props.tab} index={props.index} spacing={2} direction="row">
            <Grid item container spacing={2} direction="column" xs={6}>
                <Grid item>
                    <TextField id="featured-products-label-field"
                        variant="outlined"
                        label="Featured Products Label"
                        value={featuredProductsLabel}
                        onChange={(e) => setFeaturedProductsLabel(e.target.value)}
                        style={{ width: '350px' }}
                    />
                </Grid>
                <Grid item>
                    <TextField id="small-business-label-field"
                        variant="outlined"
                        label="Small Business Label"
                        value={smallBusinessLabel}
                        onChange={(e) => setSmallBusinessLabel(e.target.value)}
                        style={{ width: '350px' }}
                    />
                </Grid>
                <Grid item>
                    <TextField id="recent-episodes-label-field"
                        variant="outlined"
                        label="Recent Episodes Label"
                        value={recentEpisodesLabel}
                        onChange={(e) => setRecentEpisodesLabel(e.target.value)}
                        style={{ width: '350px' }}
                    />
                </Grid>
                <Grid item>
                    <TextField id="recommended-products-label-field"
                        variant="outlined"
                        label="Recommended Products Label"
                        value={recommendedProductsLabel}
                        onChange={(e) => setRecommendedProductsLabel(e.target.value)}
                        style={{ width: '350px' }}
                    />
                </Grid>
                <Grid item>
                    <Button variant="contained" color="secondary" onClick={updateHomeScreenSettings}>
                        Update
                    </Button>
                </Grid>
                <Grid item>
                    <Button variant="contained" color="primary" onClick={reset}>
                        Cancel
                    </Button>
                </Grid>
            </Grid>
        </TabPanel>
    )
}

export interface HomeScreenSettingsTabProps {
    tab: number
    index: number
    show: Show
}