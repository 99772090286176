import React, { useState, useEffect } from 'react';
import { Grid, Typography, TextField, Button, Tooltip, IconButton } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';

import DeleteIcon from '@material-ui/icons/Delete';

import {
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell
} from '@material-ui/core';

import useSnackbars from '../../../hooks/useSnackbars';
import { TabPanel } from '../../tabs/TabPanel';
import * as API from '../../../lib/api';

import { timecodeHourOptions, timeCodeMinuteSecondsOptions, convertTimecodeToMilliseconds } from '../../../lib/util';

import { Maybe, Product, Episode, ProductEpisode } from '../../../types';

const useStyles = makeStyles((theme) => ({
    menuTable: {
        borderTopWidth: 2,
        borderColor: '#f9f9f9',
        borderWidth: 2,
        borderStyle: 'solid'
    },
    comboBox: {
        width: '300px'
    }
}));

export function ProductEpisodesTab(props: ProductEpisodesTabProps) {
    const classes = useStyles();
    const { createSuccessSnack, createErrorSnack } = useSnackbars();
    const [customerEpisodes, setCustomerEpisodes] = useState<Array<Episode>>([]);
    const [episodeOptions, setEpisodeOptions] = useState([{ name: '', value: 0 }]);
    const [productEpisodes, setProductEpisodes] = useState<Array<ProductEpisode>>([]);
    const [newProductEpisode, setNewProductEpisode] = useState({ name: '', value: 0 });
    const [newProductStartHour, setNewProductStartHour] = useState(timecodeHourOptions[0]);
    const [newProductStartMinutes, setNewProductStartMinutes] = useState(timeCodeMinuteSecondsOptions[0]);
    const [newProductStartSeconds, setNewProductStartSeconds] = useState(timeCodeMinuteSecondsOptions[0]);
    const [newProductEndHour, setNewProductEndHour] = useState(timecodeHourOptions[0]);
    const [newProductEndMinutes, setNewProductEndMinutes] = useState(timeCodeMinuteSecondsOptions[0]);
    const [newProductEndSeconds, setNewProductEndSeconds] = useState(timeCodeMinuteSecondsOptions[0]);

    const fetchProductEpisodes = async () => {
        if (!props.product) return;

        const productEpisodesResult = await API.getProductEpisodes({ productID: props.product.id });
        setProductEpisodes(productEpisodesResult);
    }

    useEffect(() => {
        if (!props.product) return;

        const fetchCustomerEpisodes = async () => {
            const customerEpisodesResult = await API.listCustomerEpisodes({ customerID: props.product!.customerID });
            setCustomerEpisodes(customerEpisodesResult);

            if (customerEpisodesResult) {
                let options = customerEpisodesResult.map(episode => {
                    if (episode) {
                        let displayName = `${episode.showName} - ${episode.name}`
                        return { name: displayName, value: episode.id }
                    }

                    return { name: '', value: -1 } //TODO: Fix thissssss types can be annoying
                })

                setEpisodeOptions(options);
                setNewProductEpisode(options[0])
            }
        }

        fetchProductEpisodes();
        fetchCustomerEpisodes();
    }, [props.product])

    const addProductEpisode = async () => {
        if (!props.product) return;
        
        let startTimecode = [newProductStartHour.value, newProductStartMinutes.value, newProductStartSeconds.value].join(':');
        let endTimecode = [newProductEndHour.value, newProductEndMinutes.value, newProductEndSeconds.value].join(':');

        let input = {
            episodeID: newProductEpisode?.value,
            productID: props.product.id,
            startTimecode: startTimecode,
            startTimeMilliseconds: convertTimecodeToMilliseconds(startTimecode),
            endTimecode: endTimecode,
            endTimeMilliseconds: convertTimecodeToMilliseconds(endTimecode)
        }

        // TODO: Get result and add it instead of fetching
        try {
            await API.createEpisodeProduct(input);
            createSuccessSnack('Successfully added product episode');
        } catch (err) {
            console.error(err)
            createErrorSnack('Failed to add product episode');
        }
        
        await fetchProductEpisodes();
    }

    const onEpisodeChange = (event, newValue) => {
        setNewProductEpisode(newValue);
    }

    const onStartHourChange = (event, newValue) => {
        setNewProductStartHour(newValue);
    }

    const onStartMinutesChange = (event, newValue) => {
        setNewProductStartMinutes(newValue);
    }

    const onStartSecondsChange = (event, newValue) => {
        setNewProductStartSeconds(newValue);
    }

    const onEndHourChange = (event, newValue) => {
        setNewProductEndHour(newValue);
    }

    const onEndMinutesChange = (event, newValue) => {
        setNewProductEndMinutes(newValue);
    }

    const onEndSecondsChange = (event, newValue) => {
        setNewProductEndSeconds(newValue);
    }

    const onDeleteProductEpisode = async (id, episodeID) => {
        const deleteResult = await API.deleteEpisodeProduct({ id: id, episodeID: episodeID });
        if (deleteResult) {
            const filtered = productEpisodes.filter(productEpisode => productEpisode.id !== id);
            setProductEpisodes(filtered);
        } else {
            createErrorSnack('Failed to remove product from episode');
        }
    }

    return (
        <TabPanel value={props.tab} index={props.index} direction="column" spacing={2}>
            <Grid item>
                <TableContainer>
                    <Table className={classes.menuTable} size="small" aria-label="seasons">
                        <TableHead>
                            <TableRow style={{ background: "#f9f9f9" }}>
                                <TableCell align="center">Show Name</TableCell>
                                <TableCell align="center">Episode</TableCell>
                                <TableCell align="center">Timecode Start</TableCell>
                                <TableCell align="center">Timecode End</TableCell>
                                <TableCell align="center">Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                productEpisodes.map((productEpisode: ProductEpisode, index) => {
                                    return (
                                        <TableRow key={productEpisode.id} style={index % 2 ? { background: "#f9f9f9" } : { background: "white" }}>
                                            <TableCell align="center">{productEpisode.showName}</TableCell>
                                            <TableCell align="center">{productEpisode.episodeName}</TableCell>
                                            <TableCell align="center">{productEpisode.startTimecode}</TableCell>
                                            <TableCell align="center">{productEpisode.endTimecode}</TableCell>
                                            <TableCell align="center">
                                                <Tooltip title="Delete">
                                                    <IconButton color="primary" onClick={() => onDeleteProductEpisode(productEpisode.id, productEpisode.episodeID)}>
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </TableCell>
                                        </TableRow>
                                    )
                                })
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            <br />
            <Grid item>
                <Autocomplete
                    id="episode-combo-box"
                    options={episodeOptions}
                    value={newProductEpisode}
                    getOptionLabel={(option) => option.name}
                    onChange={onEpisodeChange}
                    renderInput={(params) => <TextField {...params} label="Episode" variant="outlined" />}
                />
            </Grid>
            <Grid item container spacing={1} direction="row" alignItems="center" alignContent="center">
                <Grid item xs={2}>
                    <Typography>Start Timecode:</Typography>
                </Grid>
                <Grid item>
                    <Autocomplete
                        id="start-hour-combo-box"
                        options={timecodeHourOptions}
                        value={newProductStartHour}
                        getOptionLabel={(option) => option.name}
                        onChange={onStartHourChange}
                        renderInput={(params) => <TextField {...params} label="Hour" variant="outlined" />}
                    />
                </Grid>
                <Grid item>
                    <Autocomplete
                        id="start-minutes-combo-box"
                        options={timeCodeMinuteSecondsOptions}
                        value={newProductStartMinutes}
                        getOptionLabel={(option) => option.name}
                        onChange={onStartMinutesChange}
                        renderInput={(params) => <TextField {...params} label="Minutes" variant="outlined" />}
                    />
                </Grid>
                <Grid item>
                    <Autocomplete
                        id="start-seconds-combo-box"
                        options={timeCodeMinuteSecondsOptions}
                        value={newProductStartSeconds}
                        getOptionLabel={(option) => option.name}
                        onChange={onStartSecondsChange}
                        renderInput={(params) => <TextField {...params} label="Seconds" variant="outlined" />}
                    />
                </Grid>
            </Grid>
            <Grid item container spacing={1} direction="row" alignItems="center" alignContent="center">
                <Grid item xs={2}>
                    <Typography>End Timecode:</Typography>
                </Grid>
                <Grid item>
                    <Autocomplete
                        id="end-hour-combo-box"
                        options={timecodeHourOptions}
                        value={newProductEndHour}
                        getOptionLabel={(option) => option.name}
                        onChange={onEndHourChange}
                        renderInput={(params) => <TextField {...params} label="Hour" variant="outlined" />}
                    />
                </Grid>
                <Grid item>
                    <Autocomplete
                        id="end-minutes-combo-box"
                        options={timeCodeMinuteSecondsOptions}
                        value={newProductEndMinutes}
                        getOptionLabel={(option) => option.name}
                        onChange={onEndMinutesChange}
                        renderInput={(params) => <TextField {...params} label="Minutes" variant="outlined" />}
                    />
                </Grid>
                <Grid item>
                    <Autocomplete
                        id="end-seconds-combo-box"
                        options={timeCodeMinuteSecondsOptions}
                        value={newProductEndSeconds}
                        getOptionLabel={(option) => option.name}
                        onChange={onEndSecondsChange}
                        renderInput={(params) => <TextField {...params} label="Seconds" variant="outlined" />}
                    />
                </Grid>
            </Grid>
            <Grid item>
                <Button variant="contained" color="secondary" onClick={addProductEpisode}>
                    Add Product to Episode
                </Button>
            </Grid>
        </TabPanel>
    )
}

export interface ProductEpisodesTabProps {
    tab: number
    index: number
    product: Maybe<Product>
}