import React, { useState, useEffect } from 'react';
import { Grid, TextField, IconButton, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import DeleteIcon from '@material-ui/icons/Delete';

import {
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell
} from '@material-ui/core';

import * as API from '../../../../lib/api';
import { TabPanel } from '../../../tabs/TabPanel';

import { EpisodeMetadataItem } from '../../../../types';

const useStyles = makeStyles((theme) => ({
    menuTable: {
        borderTopWidth: 2,
        borderColor: '#f9f9f9',
        borderWidth: 2,
        borderStyle: 'solid'
    }
}));

export function SearchMetadataTab(props: SearchMetadataTabProps) {
    const classes = useStyles();
    const [metadata, setMetadata] = useState<Array<EpisodeMetadataItem>>([]);
    const [metaTagName, setMetaTagName] = useState('');
    const [metaTagValue, setMetaTagValue] = useState('');

    useEffect(() => {
        if (!props.episodeID) return;

        const fetchEpisodeMetadata = async () => {
            const episodeMetadataResult = await API.getEpisodeMetadata({ episodeID: props.episodeID });
            setMetadata(episodeMetadataResult);
        }

        fetchEpisodeMetadata();
    }, [props.episodeID])

    const clear = () => {
        setMetaTagName('');
        setMetaTagValue('');
    }

    // TODO: Implement
    const addEpisodeMetadataItem = async () => {
        // const addShowMetadataResult = await API.addEpisodeMetadata({
        //     showID: props.showID,
        //     episodeID: props.episodeID,
        //     name: metaTagName,
        //     value: metaTagValue
        // })

        // const newItem = addShowMetadataResult.addShowMetadata;
        // setMetadata(metadata.concat(newItem));
        clear();
    }

    const onItemRemoved = (id) => {
        let updated = metadata.filter((item: any) => {
            return item.id !== id
        })
        setMetadata(updated);
    }

    return (
        <TabPanel value={props.tab} index={props.index} spacing={1}>
            <Grid item xs={12}>
                <TableContainer>
                    <Table className={classes.menuTable} size="small" aria-label="episode metadata tags">
                        <TableHead>
                            <TableRow style={{ background: "#f9f9f9" }}>
                                <TableCell align="center">Tag Name</TableCell>
                                <TableCell align="center">Value</TableCell>
                                <TableCell align="center">Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                metadata.length === 0
                                    ? <>
                                        <TableRow>
                                            No metadata has been added.
                                        </TableRow>
                                    </>
                                    : metadata.map((metadata: any, index) => {
                                        return (
                                            <MetadataItemRow key={metadata.name} metadata={metadata} index={index} onItemRemoved={onItemRemoved} />
                                        )
                                    })
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            <Grid item>
                <TextField variant="outlined"
                    label="Meta Tag"
                    placeholder="Meta Tag Name"
                    value={metaTagName}
                    onChange={(e) => setMetaTagName(e.target.value)}
                />
            </Grid>
            <Grid item>
                <TextField variant="outlined"
                    label="Meta Value"
                    placeholder="Meta Tag Value"
                    value={metaTagValue}
                    onChange={(e) => setMetaTagValue(e.target.value)}
                />
            </Grid>
            <Grid item>
                <Button variant="contained" color="secondary" onClick={addEpisodeMetadataItem}>
                    Add Metadata
                </Button>
            </Grid>
        </TabPanel>
    )
}
export interface SearchMetadataTabProps {
    tab: number
    index: number
    showID: number
    episodeID: number
}

function MetadataItemRow(props: MetadataItemRowProps) {
    const remove = async () => {
        console.log(props.metadata)

        // TODO: Implement
        // const episodeMetadata = await API.deleteEpisodeMetadata({
        //     id: props.metadata.id
        // });

        props.onItemRemoved(props.metadata.id);
    }

    return (
        <TableRow style={props.index % 2 ? { background: "#f9f9f9" } : { background: "white" }}>
            <TableCell align="center">{props.metadata.name}</TableCell>
            <TableCell align="center">{props.metadata.value}</TableCell>
            <TableCell align="center">
                <IconButton aria-label="delete" color="primary" onClick={remove}>
                    <DeleteIcon />
                </IconButton>
            </TableCell>
        </TableRow>
    )
}

interface MetadataItemRowProps {
    metadata: any
    index: number
    onItemRemoved: Function
}