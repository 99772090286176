import React, { useState, useEffect } from 'react';
import { Grid, Typography, TextField, Button, IconButton, Tooltip } from '@material-ui/core';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';

import useSnackbars from '../../../hooks/useSnackbars';

import DeleteIcon from '@material-ui/icons/Delete';
import ErrorIcon from '@material-ui/icons/ErrorOutline';

import {
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell
} from '@material-ui/core';

import { TabPanel } from '../../tabs/TabPanel';
import * as API from '../../../lib/api';

import { linkTypeOptions, linkStatusTypes } from '../../../lib/util';

import { ProductLink } from '../../../types';

const useStyles = makeStyles((theme) => ({
    menuTable: {
        borderTopWidth: 2,
        borderColor: '#f9f9f9',
        borderWidth: 2,
        borderStyle: 'solid'
    },
    comboBox: {
        width: '300px'
    },
    urlField: {
        width: '500px'
    }
}));

const filter = createFilterOptions<any>();

export function ProductLinksTab(props: ProductLinksTabProps) {
    const classes = useStyles();
    const { createErrorSnack } = useSnackbars();
    const [productLinks, setProductLinks] = useState<Array<ProductLink>>([]);
    const [storeOptions, setStoreOptions] = useState<Array<any>>([]);
    const [newLinkType, setNewLinkType] = useState(linkTypeOptions[0]);
    const [store, setStore] = useState({ name: '', value: -1 });
    const [productURL, setProductURL] = useState('');

    const reset = () => {
        setNewLinkType(linkTypeOptions[0]);
        setProductURL('');
    }

    const fetchProductLinks = async () => {
        const productLinksResult = await API.getProductLinks({ productID: props.product.id });
        setProductLinks(productLinksResult);
    }

    useEffect(() => {
        if (!props.product) return;

        const fetchStores = async () => {
            const storesResult = await API.listActiveStores({ customerID: props.product!.customerID });
            let options = storesResult.map(store => {
                return { name: store.name, value: store.id }
            })

            setStoreOptions(options);
        }

        fetchProductLinks();
        fetchStores();
    }, [props.product])

    const onStoreChange = (event, newValue) => {
        // Handle the case that someone inserted their own value
        if (typeof (newValue) === 'string') {
            newValue = { name: newValue, value: -1 }
        }

        setStore(newValue);
    }

    const addProductLink = async () => {
        if (!props.product) return;

        let input = {
            customerID: props.product.customerID,
            productID: props.product.id,
            storeID: store.value,
            storeName: store.name,
            type: newLinkType.value,
            fullURL: productURL,
            status: linkStatusTypes[0].value
        }

        const productLinkResult = await API.createProductLink(input);
        if (productLinkResult) {
            const currentLinks = productLinks;
            setProductLinks(currentLinks.concat(productLinkResult));

            if ((!store.value || store.value! < 1) && productLinkResult.storeID) {
                const currStoreOptions = storeOptions;
                const newStore = { name: store.name, value: productLinkResult.storeID };
                setStoreOptions(currStoreOptions.concat(newStore));
                setStore(newStore);
            }

            reset();
        } else {
            createErrorSnack('Failed to add product link');
        }
    }

    const onLinkTypeChange = (event, newValue) => {
        setNewLinkType(newValue);
    }

    const removeLink = async (id: number, productID: number) => {
        try {
            const success = await API.deleteProductLink({ id, productID });
            if (success) {
                const filtered = productLinks.filter(link => link.id !== id);
                setProductLinks(filtered);
                return;
            }
            
            createErrorSnack('Failed to add product link');
        } catch (err) {
            console.error(err);
            createErrorSnack('Failed to remove product link');
        }
    }

    return (
        <TabPanel value={props.tab} index={props.index} direction="column" spacing={2}>
            <Grid item>
                <TableContainer>
                    <Table className={classes.menuTable} size="small" aria-label="seasons">
                        <TableHead>
                            <TableRow style={{ background: "#f9f9f9" }}>
                                <TableCell align="center">Link Type</TableCell>
                                <TableCell align="center">Store</TableCell>
                                <TableCell align="center">URL</TableCell>
                                <TableCell align="center">Status</TableCell>
                                <TableCell align="center">Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                productLinks.map((link, index) => {
                                    const linkStatus = linkStatusTypes.find(option => option.value === link.status)?.name || `${link.status}`;
                                    return (
                                        <TableRow key={link.id} style={index % 2 ? { background: "#f9f9f9" } : { background: "white" }}>
                                            <TableCell align="center">
                                                {linkTypeOptions.find(option => option.value === link.type)?.name}
                                            </TableCell>
                                            <TableCell align="center">{link.storeName}</TableCell>
                                            <TableCell align="center">
                                                <a href={link.fullURL ?? ''} target="_blank">{link.storeName || link.fullURL}</a>
                                            </TableCell>
                                            <TableCell align="right">
                                                {
                                                    linkStatus &&
                                                    <Grid item container alignItems="center" alignContent="center" justify="center">
                                                        {linkStatus !== 'Valid' && <ErrorIcon />}
                                                        {linkStatus}
                                                    </Grid>
                                                }
                                            </TableCell>
                                            <TableCell align="center">
                                                <Tooltip title="Remove Link">
                                                    <IconButton color="primary" onClick={() => removeLink(link.id, link.productID)}>
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </TableCell>
                                        </TableRow>
                                    )
                                })
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            <Grid item>
                <Typography variant="h6">Add Link</Typography>
            </Grid>
            <Grid item>
                <Autocomplete
                    id="link-type-combo-box"
                    className={classes.comboBox}
                    options={linkTypeOptions}
                    getOptionLabel={(option) => option.name}
                    value={newLinkType}
                    onChange={onLinkTypeChange}
                    renderInput={(params) => <TextField {...params} label="Link Type" variant="outlined" />}
                />
            </Grid>
            <Grid item>
                <Autocomplete
                    id="store-combo-box"
                    className={classes.comboBox}
                    options={storeOptions}
                    value={store}
                    selectOnFocus
                    freeSolo
                    clearOnBlur
                    aria-required
                    onChange={onStoreChange}
                    renderInput={(params) => <TextField {...params} label="Store" variant="outlined" required />}
                    filterOptions={(options, params) => {
                        const filtered = filter(options, params);

                        // Suggest the creation of a new value
                        if (params.inputValue !== '') {
                            filtered.push({
                                inputValue: params.inputValue,
                                name: params.inputValue,
                                title: `Add Store "${params.inputValue}"`,
                                value: -1
                            });
                        }

                        return filtered;
                    }}
                    renderOption={(option) => option.inputValue ? option.title : option.name}
                    getOptionLabel={(option) => {
                        // Value selected with enter, right from the input
                        if (typeof option === 'string') {
                            return option;
                        }

                        // Regular option
                        return option.name;
                    }}
                />
            </Grid>
            <Grid item>
                <TextField variant="outlined"
                    className={classes.urlField}
                    value={productURL}
                    onChange={(e) => setProductURL(e.target.value)}
                    label="URL"
                    placeholder="Full Product URL"
                    required
                />
            </Grid>
            <Grid item>
                <Button variant="contained" color="secondary" onClick={addProductLink} disabled={productURL === ''}>
                    Add Product Link
                </Button>
            </Grid>
        </TabPanel>
    )
}

export interface ProductLinksTabProps {
    tab: number
    index: number
    product: any
}