import React, { useState, useEffect } from 'react';
import { Grid, TextField, IconButton, Button } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';

import { TableContainer, Table, TableHead, TableBody, TableRow, TableCell } from '@material-ui/core';

import EditIcon from '@material-ui/icons/Edit';

import useSnackbars from '../../hooks/useSnackbars';

import * as API from '../../lib/api';
import { Maybe, Vendor, User } from '../../types';

import { format } from 'date-fns';

const formatString = 'MMM dd yyyy'

const useStyles = makeStyles((theme) => ({
    menuTable: {
        borderTopWidth: 2,
        borderColor: '#f9f9f9',
        borderWidth: 2,
        borderStyle: 'solid'
    }
}));

export function VendorList(props: VendorListProps) {
    const classes = useStyles();
    const { createSuccessSnack, createErrorSnack } = useSnackbars();
    const [vendors, setVendors] = useState<Array<Maybe<Vendor>>>([]);
    const [managedByOptions, setManagedByOptions] = useState<Array<User>>([]);
    const [newVendorName, setNewVendorName] = useState('');
    const [managedBy, setManagedBy] = useState<Maybe<User>>();

    useEffect(() => {
        const fetchVendors = async () => {
            const vendorsResult = await API.listVendors();
            if (vendorsResult) setVendors(vendorsResult);
        }

        const fetchVoxiAdmins = async () => {
            const voxiAdminsResult = await API.listVoxiAdmins();
            setManagedByOptions(voxiAdminsResult)
        }

        fetchVendors();
        fetchVoxiAdmins();
    }, [])

    const onManagedByChange = (event, newValue) => {
        setManagedBy(newValue);
    }

    const createVendor = async () => {
        if (!managedBy) return;

        const input = {
            name: newVendorName,
            active: true,
            salesOwnedByUserID: managedBy.id,
            balance: 0.00,
            balanceUpdated: new Date().toISOString()
        }

        const createVendorResult = await API.createVendor(input);
        if (createVendorResult) {
            const currentVendors = vendors;
            setVendors(currentVendors.concat(createVendorResult));
            createSuccessSnack('Successfully added vendor');
        } else {
            createErrorSnack('Failed to add vendor');
        }
    }

    return (
        <Grid item container spacing={2} direction="column">
            <Grid item container spacing={2} direction="row">
                <Grid item>
                    <TextField variant="outlined"
                        label="Vendor Name"
                        value={newVendorName}
                        onChange={(e) => setNewVendorName(e.target.value)}
                    />
                </Grid>
                <Grid item>
                    <Autocomplete
                        id="voxi-user-combo-box"
                        options={managedByOptions}
                        getOptionLabel={(user) => `${user.given_name} ${user.family_name}`}
                        value={managedBy}
                        onChange={onManagedByChange}
                        style={{ width: '200px' }}
                        renderInput={(params) => <TextField {...params} label="Managed By" variant="outlined" />}
                    />
                </Grid>
                <Grid item>
                    <Button variant="contained" color="secondary" onClick={createVendor}>
                        Add New Vendor
                    </Button>
                </Grid>
            </Grid>
            <Grid item>
                <TableContainer>
                    <Table className={classes.menuTable} size="small" aria-label="show metadata tags">
                        <TableHead>
                            <TableRow style={{ background: "#f9f9f9" }}>
                                <TableCell align="center">Vendor Name</TableCell>
                                <TableCell align="center">Enabled</TableCell>
                                <TableCell align="center">Date Added</TableCell>
                                <TableCell align="center">Voxi Manager</TableCell>
                                <TableCell align="center">Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                vendors.map((vendor, index) => {
                                    return (
                                        <VendorRow key={vendor?.id || -1}
                                            vendor={vendor}
                                            manager={managedByOptions.find(user => user.id === vendor?.salesOwnedByUserID)}
                                            index={index}
                                        />
                                    )
                                })
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </Grid>
    )
}

interface VendorListProps {
    authData: any
}

function VendorRow(props: VendorRowProps) {
    const history = useHistory();

    const onEditClick = () => {
        history.push(`/vendoradmin/${props.vendor?.id}`)
    }

    return (
        <TableRow style={props.index % 2 ? { background: "#f9f9f9" } : { background: "white" }}>
            <TableCell align="center">{props.vendor?.name}</TableCell>
            <TableCell align="center">{props.vendor?.active.toString()}</TableCell>
            <TableCell align="center">
                {format(new Date(props.vendor?.createdAt || ''), formatString)}
            </TableCell>
            <TableCell align="center">{props.manager?.family_name}, {props.manager?.given_name}</TableCell>
            <TableCell align="center">
                <IconButton aria-label="edit" color="default" onClick={onEditClick}>
                    <EditIcon />
                </IconButton>
            </TableCell>
        </TableRow>
    )
}

interface VendorRowProps {
    index: number
    vendor: Maybe<Vendor>
    manager: Maybe<User>
}