import React, { useState, useEffect } from 'react';
import { Grid, CircularProgress } from '@material-ui/core';

import { RangeSelect } from '../shared/RangeSelect';

import {
    BandwidthUsedCard,
    ClickThroughRateCard,
    HoursStreamedCard,
    TopEpisodeViewsCard,
    TopProductsCard,
    UniqueAppVisitorsCard
} from './cards';

import { subDays, startOfDay, endOfDay } from 'date-fns';

import * as API from '../../lib/api';

import { Maybe, DashboardReport, Customer } from '../../types/api';

export function Dashboard(props) {
    const [dates, setDates] = useState({ fromDate: startOfDay(subDays(new Date(), 8)), toDate: endOfDay(subDays(new Date(), 1)) })
    const [dashboardReport, setDashboardReport] = useState<Maybe<DashboardReport>>();
    const [loading, setLoading] = useState(false);

    const fetchDashboard = async (customerID: number, from: Date, to: Date) => {
        setLoading(true);
        let queryArgs = {
            customerID: customerID,
            fromDate: from.toISOString(),
            toDate: to.toISOString()
        }

        const dashboardReportResult = await API.getDashboardReport(queryArgs)
        if (dashboardReportResult) setDashboardReport(dashboardReportResult)

        setLoading(false);
    }

    useEffect(() => {
        if (!props.customer) return;
        fetchDashboard(props.customer.id, dates.fromDate, dates.toDate);
    }, [props.customer])

    const handleDateChange = async (from: Date, to: Date) => {
        setDates({
            fromDate: from,
            toDate: to
        });

        await fetchDashboard(props.customer.id, from, to);
    };

    return (
        <div>
            <Grid container spacing={2}>
                <RangeSelect handleSelectChange={handleDateChange} onApplyClicked={handleDateChange} />
                {
                    loading &&
                    <Grid item>
                        <CircularProgress />
                    </Grid>
                }

                <Grid item container spacing={2} direction="row">
                    {
                        props.customer?.streamingContent &&
                        <>
                            <Grid item xs>
                                <HoursStreamedCard totalSeconds={dashboardReport?.totalSeconds || 0} previousTotalSeconds={dashboardReport?.previousTotalSeconds || 0} />
                            </Grid>
                            <Grid item xs>
                                <BandwidthUsedCard totalBytes={dashboardReport?.totalBytes || 0} previousTotalBytes={dashboardReport?.previousTotalBytes || 0} />
                            </Grid>
                        </>
                    }
                    <Grid item xs>
                        <UniqueAppVisitorsCard ipAddressCount={dashboardReport?.ipAddressCount || 0} previousIPAddressCount={dashboardReport?.previousIPAddressCount || 0} />
                    </Grid>
                    <Grid item xs>
                        <ClickThroughRateCard totalViews={dashboardReport?.totalViews || 0} totalClicks={dashboardReport?.totalClicks || 0} />
                    </Grid>
                </Grid>
                <Grid item container spacing={2} direction="row">
                    <Grid item xs={6}>
                        <TopEpisodeViewsCard customerID={props.customer?.id} dates={dates} />
                    </Grid>
                    <Grid item xs={6}>
                        <TopProductsCard customerID={props.customer?.id} dates={dates} />
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}

export interface DashboardProps {
    authData: any
    customer: Maybe<Customer>
}