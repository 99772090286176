import React, { useState, useEffect } from 'react';
import { Grid, Button, TextField, Typography } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';

import { TabPanel } from '../../../tabs/TabPanel';

import useSnackbars from '../../../../hooks/useSnackbars';

import * as API from '../../../../lib/api';
import { Maybe, Show } from '../../../../types/api';

const useStyles = makeStyles((theme) => ({
    multiLineBox: {
        maxWidth: '100%',
        wordWrap: "break-word"
    },
    boxHeaders: {
        textAlign: "center",
        fontWeight: 600
    },
    faqPreview: {
        border: '1px solid rgba(0,0,0,0.23)',
        borderRadius: '5px',
        padding: theme.spacing(1),
        fontSize: "16px",
        wordWrap: "break-word",
        minHeight: "40px",
        width: 0,
        minWidth: "100%",
        "&:hover": {
            border: "1px solid black",
            borderRadius: '5px'
        }
    },
    updateButtonContainer: {
        display: "flex",
        justifyContent: "center",
    },
}));

export function DigitalAssetLinksTab(props: DigitalAssetLinksTabProps) {
    const classes = useStyles();
    const { createSuccessSnack, createErrorSnack } = useSnackbars();
    const [androidDigitalAssetLinkJSON, setAndroidDigitalAssetLinkJSON] = useState('');
    const [exists, setExists] = useState(false);

    useEffect(() => {
        if (!props.show) return;

        const fetchDigitalAssetLinks = async () => {
            if (!props.show) return;

            const digitalAssetLinksResult = await API.getDigitalAssetLinks({ appID: props.show?.appID });
            if (!digitalAssetLinksResult) {
                setAndroidDigitalAssetLinkJSON('');
                setExists(false);
                return;
            }

            setAndroidDigitalAssetLinkJSON(digitalAssetLinksResult.android);
            setExists(true);
        }

        fetchDigitalAssetLinks();
    }, [props.show])

    const updateDigitalAssetLinks = async () => {
        if (!props.show) return;

        let input = {
            appID: props.show.appID,
            legacyShowID: props.show.id,
            legacyCustomerID: props.show.customerID,
            android: JSON.stringify(androidDigitalAssetLinkJSON)
        }

        try {
            let result: any = undefined;
            if (exists) {
                result = await API.updateDigitalAssetLinks(input);
            } else {
                result = await API.createDigitalAssetLinks(input);
            }

            if (!result) {
                createErrorSnack('Error updating digital asset links');
            } else {
                createSuccessSnack('Successfully digital asset links');
            }
        } catch (err) {
            createErrorSnack('Error updating digital asset links');
        }
    }

    return (
        <TabPanel value={props.tab} index={props.index} spacing={2} direction="row">
            <Grid item container spacing={2}>
                <Grid item xs={6}>
                    <Typography variant={"h6"} className={classes.boxHeaders}>Edit Digital Asset Links</Typography>
                    <TextField variant="outlined"
                        className={classes.multiLineBox}
                        value={androidDigitalAssetLinkJSON}
                        onChange={(e) => setAndroidDigitalAssetLinkJSON(e.target.value)}
                        multiline
                        placeholder="Android Digital Asset Links JSON"
                        fullWidth={true}
                    />
                </Grid>
            </Grid>
            <Grid item xs={12} className={classes.updateButtonContainer}>
                <Button variant="contained" color="secondary" onClick={updateDigitalAssetLinks}>Update Digital Asset Links</Button>
            </Grid>
        </TabPanel>
    )
}

export interface DigitalAssetLinksTabProps {
    tab: number
    index: number
    show: Maybe<Show>
}