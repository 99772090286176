import React, { useState, useEffect } from 'react';
import { Grid, TextField, Button } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

import useSnackbars from '../../../hooks/useSnackbars';

import { TabPanel } from '../../tabs/TabPanel';

import * as API from '../../../lib/api';
import { Maybe, Product, Vendor } from '../../../types';

export function ProductVendorTab(props: ProductVendorTabProps) {
    const { createSuccessSnack, createErrorSnack } = useSnackbars();
    const [vendorOptions, setVendorOptions] = useState<Array<Maybe<Vendor>>>([]);
    const [selectedVendor, setSelectedVendor] = useState<Maybe<Vendor>>(null);
    const [previousVendorID, setPreviousVendorID] = useState<Maybe<string>>(null);

    useEffect(() => {
        if (!props.product) return;

        const fetchProductVendor = async () => {
            const vendorsResult = await API.listVendors();
            setVendorOptions(vendorsResult);

            let productVendorResult = await API.getProductVendor({ productID: props.product!.id });
            if (productVendorResult) {
                setPreviousVendorID(productVendorResult.id);
                let option = vendorsResult.find(vendor => vendor?.id === productVendorResult!.id);
                if (option) setSelectedVendor(option);
            }
        }

        fetchProductVendor();
    }, [props.product])

    const assignProductVendor = async () => {
        if (!props.product || !selectedVendor) return;
        
        const input = {
            vendorID: selectedVendor.id,
            productID: props.product.id,
            previousVendorID: previousVendorID
        }

        try {
            await API.createVendorProduct(input);
            createSuccessSnack('Successfully added product to vendor');
        } catch (err) {
            console.error(err);
            createErrorSnack('Failed to add product to vendor');
        }
    }

    const onVendorChange = (event, newValue) => {
        setSelectedVendor(newValue);
    }

    const unassignVendor = async () => {
        if (!selectedVendor || !props.product) return;

        const queryArgs = {
            vendorID: selectedVendor.id,
            productID: props.product.id
        }

        try {
            const success = await API.deleteVendorProduct(queryArgs);
            if (success) {
                createSuccessSnack('Successfully unassigned vendor from product')
                setSelectedVendor(null);
            } else {
                createErrorSnack('Failed to unassign vendor');    
            }
        } catch (err) {
            console.error(err);
            createErrorSnack('Failed to unassign vendor');
        }
    }

    return (
        <TabPanel value={props.tab} index={props.index} direction="column" spacing={2}>
            <Grid item>
                <Autocomplete id="vendor-combo-box"
                    options={vendorOptions}
                    value={selectedVendor}
                    getOptionLabel={(option) => option?.name || ''}
                    onChange={onVendorChange}
                    style={{width: '300px'}}
                    renderInput={(params) => <TextField {...params} label="Vendor" variant="outlined" />}
                />
            </Grid>
            <Grid item>
                <Button variant="contained" color="secondary" onClick={assignProductVendor}>
                    Assign Product to Vendor
                </Button>
            </Grid>
            <Grid item>
                <Button variant="contained" color="primary" onClick={unassignVendor}>
                    Unassign Product From Vendor
                </Button>
            </Grid>
        </TabPanel>
    )
}

export interface ProductVendorTabProps {
    tab: number
    index: number
    product: Maybe<Product>
}