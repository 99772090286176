import React, { useState, useEffect, forwardRef } from 'react';
import { Auth } from 'aws-amplify';
import { Grid, TextField, LinearProgress, Typography, Button } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';

import useSnackbars from '../../../../hooks/useSnackbars';

import * as path from 'path';

import { TabPanel } from '../../../tabs/TabPanel';
import { SelectWithLabel } from '../../../shared/SelectWithLabel';
import { FileUploadButton } from '../../../shared/FileUploadButton';
import { uploadPresignedFile, convertSecondsToTimecode, convertTimecodeToSeconds, timecodeHourOptions, timeCodeMinuteSecondsOptions } from '../../../../lib/util';

import * as API from '../../../../lib/api';
import { Maybe, Episode, EpisodeCommercial } from '../../../../types';

import MaterialTable, { MTableToolbar } from "material-table";
import { Icons } from 'material-table'; // typescript specific import https://github.com/mbrn/material-table/issues/1150

import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import Delete from '@material-ui/icons/Delete';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';

const tableIcons: Icons = {
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <Delete {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

const videoTypeSelectOptions = [
    { name: 'MP4', value: 'MP4' },
    { name: 'HLS', value: 'HLS' }
];
const videoResolutionSelectOptions = [
    { name: 'HD - 720p', value: 'HD' },
    { name: 'FHD - 1080p', value: 'FHD' },
    { name: 'UHD - 4k', value: 'UHD' }
];

const commercialTypeOptions = [
    { name: 'Pre-Roll', value: 0 },
    { name: 'Mid-Roll', value: 1 },
    { name: 'Post-Roll', value: 2 },
    { name: 'Other', value: 100 },
]

const useStyles = makeStyles((theme) => ({
    tableToolBar: {
        backgroundColor: '#f9f9f9!important'
    }
}));

export function VideoTab(props: VideoTabProps) {
    const classes = useStyles();
    const { createSuccessSnack, createErrorSnack } = useSnackbars();
    const [videoURL, setVideoURL] = useState(props.episode.video || '');
    const [videoType, setVideoType] = useState(videoTypeSelectOptions.find((option) => option.value === props.episode.videoType));
    const [videoResolution, setVideoResolution] = useState(videoResolutionSelectOptions.find((option) => option.value === props.episode.videoQuality));
    const [audioFingerprintID, setAudioFingerprintID] = useState(props.episode.audioFingerprintID || '');
    const [rokuAddBreaks, setRokuAddBreaks] = useState(props.episode.rokuAddBreaks || '');
    const [episodeCommercials, setEpisodeCommercials] = useState<Array<EpisodeCommercial>>([]);
    const [commercialEpisodes, setCommercialEpisodes] = useState<Array<Episode>>([]);
    const [newEpisodeCommercial, setNewEpisodeCommercial] = useState<Maybe<Episode>>(null);
    const [newEpisodeCommercialType, setNewEpisodeCommercialType] = useState<any>(commercialTypeOptions[0]);
    const [newCommercialSpliceTimeHours, setNewCommercialSpliceTimeHours] = useState(timecodeHourOptions[0]);
    const [newCommercialSpliceTimeMinutes, setNewCommercialSpliceTimeMinutes] = useState(timeCodeMinuteSecondsOptions[0]);
    const [newCommercialSpliceTimeSeconds, setNewCommercialSpliceTimeSeconds] = useState(timeCodeMinuteSecondsOptions[0]);
    const [uploading, setUploading] = useState(false);
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        setVideoURL(props.episode.video || '');
        setVideoType(videoTypeSelectOptions.find((option) => option.value === props.episode.videoType));
        setVideoResolution(videoResolutionSelectOptions.find((option) => option.value === props.episode.videoQuality));
        setAudioFingerprintID(props.episode.audioFingerprintID || '');
        setRokuAddBreaks(props.episode.rokuAddBreaks || '');
        setUploading(false);
        setProgress(0);

        const fetchEpisodeCommercials = async () => {
            const commercialsResult = await API.listEpisodeCommercials({ episodeID: props.episode.id });
            setEpisodeCommercials(commercialsResult);
        }

        const fetchShowCommercials = async () => {
            const commercials = await API.listCommercials({ showID: props.episode.showID });
            setCommercialEpisodes(commercials);
        }

        fetchEpisodeCommercials();
        fetchShowCommercials();
    }, [props.episode])

    const selectVideoFile = async ({ target }) => {
        if (!target) return;
        setProgress(0);

        const file = target.files[0];
        if (!file) return;

        const fileExtension = path.extname(file.name).toUpperCase();

        const now = new Date().getTime();
        const filename = `VIDEO-${props.customerID}-${props.episode.showID}-${props.episode.id}-${now}${fileExtension}`

        const user = await Auth.currentAuthenticatedUser();
        
        setUploading(true);
        let result = await API.updateEpisodeVideoCleanup(props.episode.id);
        if (!result) {
            setUploading(false);
            createErrorSnack('Error deleting previous video and fingerprints');
            return;
        }

        // TODO: Revert back to the presignedRawVideoURL when we deploy the video service
        const presignedUrl = await API.generatePresignedRawVideoURL({
            operation: 'putObject',
            key: filename,
            metadata: [
                { name: 'userid', value: user.attributes.sub },
                { name: 'episodename', value: props.episode.name }
            ]
        })

        var options = {
            headers: {
                'Content-Type': file.type
            },
            onUploadProgress: function (progressEvent) {
                var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                setProgress(percentCompleted)
            }
        };

        let uploadResponse = await uploadPresignedFile(presignedUrl, file, options);
        if (uploadResponse.status !== 200) {
            createErrorSnack('Error uploading new video');
        } else {
            createSuccessSnack('Episode has been uploaded and will now be converted. Check your notifications for updates');
        }
        setUploading(false);
    }

    const renderTableImage = (rowData) => {
        return (
            <img src={rowData.episodePosterURL} height="50" alt={rowData.episodeName} />
        )
    }

    const renderCommercialType = (rowData) => {
        switch (rowData.type) {
            case 0:
                return "Pre-Roll";
            case 1:
                return "Mid-Roll";
            case 2:
                return "Post-Roll";
            default:
                return "Other";
        }
    }

    const renderSpliceTime = (rowData) => {
        if (rowData.type !== 1) return ''; // return blank if it's no mid-roll (1 is mid-roll);
        return convertSecondsToTimecode(rowData.spliceTime);
    }

    const removeCommercial = async (_, rowData) => {
        try {
            const result = await API.deleteEpisodeCommercial(rowData.id, rowData.episodeID);
            if (result) {
                const filteredCommercials = episodeCommercials.filter(commercial => commercial.id !== rowData.id);
                setEpisodeCommercials(filteredCommercials);
            } else {
                createErrorSnack('Failed to remove commercial');
            }
        } catch (err) {
            console.error(err);
            createErrorSnack('Failed to remove commercial');
        }
    }

    const onNewEpisodeCommercialChanged = (_, episode) => {
        setNewEpisodeCommercial(episode);
    }

    const onNewEpisodeCommercialTypeChanged = (_, type) => {
        setNewEpisodeCommercialType(type);
    }

    const onSpliceTimeHourChange = (_, newValue) => {
        setNewCommercialSpliceTimeHours(newValue);
    }

    const onSpliceTimeMinutesChange = (_, newValue) => {
        setNewCommercialSpliceTimeMinutes(newValue);
    }

    const onSpliceTimeSecondsChange = (_, newValue) => {
        setNewCommercialSpliceTimeSeconds(newValue);
    }

    const addNewCommercial = async () => {
        if (!newEpisodeCommercial) return;

        const spliceTimecode = [newCommercialSpliceTimeHours.value, newCommercialSpliceTimeMinutes.value, newCommercialSpliceTimeSeconds.value].join(':');
        const input = {
            episodeID: props.episode.id,
            commercialEpisodeID: newEpisodeCommercial.id,
            type: newEpisodeCommercialType.value,
            spliceTime: convertTimecodeToSeconds(spliceTimecode)
        }

        console.log(input);

        try {
            const createResult = await API.createEpisodeCommercial(input);
            if (createResult) {
                setEpisodeCommercials(episodeCommercials.concat(createResult));
                createSuccessSnack('Successfully added episode commercial');
            } else {
                createErrorSnack('Failed to add episode commercial');
            }
        } catch (err) {
            console.error(err);
            createErrorSnack('Failed to add episode commercial');
        }
    }

    return (
        <TabPanel value={props.tab} index={props.index} spacing={2}>
            <Grid item xs={12}>
                <TextField variant="outlined"
                    value={videoURL}
                    onChange={(e) => setVideoURL(e.target.value)}
                    label="Video URL"
                    placeholder="https://s3.dualstack.us-east-2.amazonaws.com/xxxxx/xxxxx"
                    fullWidth
                />
            </Grid>
            <Grid item container spacing={2} direction="row" xs={12} alignItems="center" alignContent="center">
                <Grid item xs={2}>
                    <SelectWithLabel id="video-type-select"
                        title="Video Type"
                        value={videoType}
                        onChange={(e) => setVideoType(e.target.value)}
                        selectOptions={videoTypeSelectOptions}
                    />
                </Grid>
                <Grid item xs={2}>
                    <SelectWithLabel id="video-resolution-select"
                        title="Video Resolution"
                        value={videoResolution}
                        onChange={(e) => setVideoResolution(e.target.value)}
                        selectOptions={videoResolutionSelectOptions}
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField variant="outlined"
                        value={audioFingerprintID}
                        label="Audio Fingerprint ID (Read Only):"
                        inputProps={{ readOnly: true }}
                        fullWidth
                    />
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <TextField variant="outlined"
                    value={rokuAddBreaks}
                    onChange={(e) => setRokuAddBreaks(e.target.value)}
                    label="Roku Add Breaks: format (HH:MM:SS), if multiple, seperate by a comma(,). ex: 00:15:00, 00:30:20 will have a Add break at 15 minutes and 30 minutes and 20 seconds."
                    inputProps={{ readOnly: true }}
                    fullWidth
                    multiline
                />
            </Grid>
            <Grid item container spacing={2} direction="row" alignItems="center" xs={12}>
                <Grid item>
                    <FileUploadButton
                        id="update-video-select-button"
                        accept="video/mp4,video/x-m4v,video/*"
                        onChange={selectVideoFile}
                        text="Update / Change Video"
                    />
                </Grid>
                <Grid item>
                    <Typography>Warning: If you update/change this video it will replace the current episode, delete fingerprints, and previous video/audio</Typography>
                </Grid>
            </Grid>
            {
                uploading &&
                <Grid item xs={12}>
                    <LinearProgress variant="determinate" value={progress} color="secondary" />
                </Grid>
            }
            <Grid item container spacing={2} direction="column">
                <Grid item container spacing={2}>
                    <Grid item>
                        <MaterialTable
                            title="Roku/FireTV Playlist Editor"
                            icons={tableIcons}
                            columns={[
                                { title: "Commercial Poster", render: renderTableImage },
                                { title: "Type", field: "type", render: renderCommercialType },
                                { title: "Splice Time", field: "spliceTime", render: renderSpliceTime },
                                { title: "Commercial Name", field: "episodeName" },
                                { title: "Length (seconds)", field: "duration" }
                            ]}
                            data={episodeCommercials}
                            components={{
                                Toolbar: props => (
                                    <div className={classes.tableToolBar}>
                                        <MTableToolbar {...props} className={classes.tableToolBar} />
                                    </div>
                                )
                            }}
                            options={{
                                search: false,
                                exportButton: false,
                                pageSize: 5,
                                pageSizeOptions: [5, 10],
                                filtering: false,
                                actionsColumnIndex: -1
                            }}
                            actions={[
                                { icon: (() => (<Delete />)), tooltip: 'Delete Product', onClick: removeCommercial }
                            ]}
                        />
                    </Grid>
                    <Grid item container spacing={2} direction="row" alignItems="center">
                        <Grid item>
                            <Autocomplete id="commercial-combo-box"
                                options={commercialEpisodes}
                                getOptionLabel={(option: Episode) => `${option.name}`}
                                value={newEpisodeCommercial}
                                onChange={onNewEpisodeCommercialChanged}
                                renderInput={(params) => <TextField {...params} label="Commercial" variant="outlined" />}
                                style={{ width: 325 }}
                            />
                        </Grid>
                        <Grid item>
                            <Autocomplete id="commercial-type-combo-box"
                                options={commercialTypeOptions}
                                getOptionLabel={(option: any) => `${option.name}`}
                                value={newEpisodeCommercialType}
                                onChange={onNewEpisodeCommercialTypeChanged}
                                renderInput={(params) => <TextField {...params} label="Type" variant="outlined" />}
                                style={{ width: 150 }}
                            />
                        </Grid>
                        {
                            newEpisodeCommercialType.value === 1 &&
                            <>
                                <Grid item>
                                    <Typography>Splice Time</Typography>
                                </Grid>
                                <Grid item>
                                    <Autocomplete
                                        id="splice-time-hour-combo-box"
                                        options={timecodeHourOptions}
                                        value={newCommercialSpliceTimeHours}
                                        getOptionLabel={(option) => option.name}
                                        onChange={onSpliceTimeHourChange}
                                        renderInput={(params) => <TextField {...params} label="Hour" variant="outlined" />}
                                    />
                                </Grid>
                                <Grid item>
                                    <Autocomplete
                                        id="splice-time-minutes-combo-box"
                                        options={timeCodeMinuteSecondsOptions}
                                        value={newCommercialSpliceTimeMinutes}
                                        getOptionLabel={(option) => option.name}
                                        onChange={onSpliceTimeMinutesChange}
                                        renderInput={(params) => <TextField {...params} label="Minutes" variant="outlined" />}
                                    />
                                </Grid>
                                <Grid item>
                                    <Autocomplete
                                        id="splice-time-seconds-combo-box"
                                        options={timeCodeMinuteSecondsOptions}
                                        value={newCommercialSpliceTimeSeconds}
                                        getOptionLabel={(option) => option.name}
                                        onChange={onSpliceTimeSecondsChange}
                                        renderInput={(params) => <TextField {...params} label="Seconds" variant="outlined" />}
                                    />
                                </Grid>
                            </>
                        }
                    </Grid>
                    <Grid item>
                        <Button variant="contained" color="secondary" onClick={addNewCommercial}>
                            Add New Commercial
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </TabPanel>
    )
}
export interface VideoTabProps {
    tab: number
    index: number
    customerID: number
    episode: any
}