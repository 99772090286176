import React, { useEffect, useContext, forwardRef } from 'react';
import { useHistory } from 'react-router-dom';
import { Grid, Typography, IconButton, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { format } from 'date-fns';

import MaterialTable, { MTableToolbar } from "material-table";
import { Icons } from 'material-table'; // typescript specific import https://github.com/mbrn/material-table/issues/1150

import { ProductStore } from './ProductsStateProvider';

import RefreshIcon from '@material-ui/icons/Refresh';

import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
// import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';

import * as API from '../../lib/api';

const useStyles = makeStyles((theme) => ({
    tableToolBar: {
        backgroundColor: '#f9f9f9!important'
    }
}));

const tableIcons: Icons = {
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

const formatString = 'MMM dd yyyy';

export function ProductList(props: ProductListProps) {
    const classes = useStyles();
    const history = useHistory();
    const { state, dispatch } = useContext(ProductStore);

    const fetchProducts = async () => {
        const productsResult = await API.listProducts({ customerID: props.customerID });
        dispatch({
            type: 'fetchProductsSuccess',
            products: productsResult,
            customerID: props.customerID
        })
    }

    useEffect(() => {
        if (!props.customerID) return;

        // Don't refetch. Eventually, need a subscription...
        if (state.customerID === props.customerID && state.products && state.products.length > 0) return;

        fetchProducts();
    }, [props.customerID])

    const renderTableImage = (rowData) => {
        return (
            <img src={rowData.mainPhotoURL} width="50" height="50" alt={rowData.name} />
        )
    }

    const renderDateAdded = (rowData) => {
        const date = new Date(rowData.added);
        return format(date, formatString);
    }

    const editProduct = (event, rowData) => {
        history.push(`/products/list/${rowData.id}`)
    }

    const refreshProductList = async () => {
        await fetchProducts();
    }

    const renderWarnings = (rowData) => {
        const warnings: string[] = [];
        if (rowData.mainPhotoFileId === '') warnings.push('Needs Product Image');
        if (!rowData.brand || rowData.brand.length < 1 || rowData.brand === '' ||  rowData.brand.toLowerCase() === 'n/a') warnings.push('Needs Brand Name');
        if (rowData.name.toUpperCase() === rowData.name) warnings.push('Product Name is in all caps');
        if (!rowData.description || rowData.description === '') warnings.push('Needs Description');

        if (rowData.issueCount > warnings.length) warnings.push('See product for additional warnings');

        return (
            warnings.map(warning => {
                return <p>{warning}</p>
            })
        )
    }

    const onSearchChange = (event) => {
        dispatch({ type: 'onSearchChange', searchText: event })
    }

    const onChangePage = (pageNumber) => {
        dispatch({ type: 'onPageChange', page: pageNumber })
    }

    const tableTitle = (
        <Grid container spacing={2} direction="row" alignItems="center">
            <Grid item>
                <Typography variant="h5">Product List</Typography>
            </Grid>
            <Grid item>
                <Tooltip title="Refresh product list">
                    <IconButton onClick={refreshProductList}>
                        <RefreshIcon />
                    </IconButton>
                </Tooltip>
            </Grid>
        </Grid>
    )

    return (
        <div>
            <Grid container spacing={2} direction="column">
                <Grid item>
                    <MaterialTable
                        title={tableTitle}
                        icons={tableIcons}
                        columns={[
                            { title: "Image", field: "mainPhotoURL", export: false, filtering: false, render: renderTableImage },
                            { title: "Brand", field: "brand" },
                            { title: "Name", field: "name" },
                            { title: "Approved", field: "approved", type: "boolean" },
                            { title: "Enabled", field: "active", type: "boolean" },
                            { title: "Featured", field: "featured", type: "boolean" },
                            { title: "Is Replacement", field: "replacementProduct", type: "boolean" },
                            { title: "Date Added", field: "added", filtering: false, render: renderDateAdded, type: "datetime" },
                            { title: "Warnings", field: "issueCount", filtering: false, render: renderWarnings }
                        ]}
                        data={state.products}
                        components={{
                            Toolbar: props => (
                                <div className={classes.tableToolBar}>
                                    <MTableToolbar {...props} className={classes.tableToolBar} />
                                </div>
                            )
                        }}
                        options={{
                            search: true,
                            searchText: `${state.searchText}`,
                            initialPage: state.pageNumber || 0,
                            searchAutoFocus: true,
                            exportButton: true,
                            exportAllData: true,
                            pageSize: 25,
                            pageSizeOptions: [10, 25, 50, 100],
                            filtering: true,
                            actionsColumnIndex: -1
                        }}
                        onSearchChange={onSearchChange}
                        onChangePage={onChangePage}
                        actions={[
                            { icon: (() => (<Edit />)), tooltip: 'Edit Product', onClick: editProduct }
                        ]}
                    />
                </Grid>
            </Grid>
        </div>
    )
}

export interface ProductListProps {
    authData: any
    customerID: number
}