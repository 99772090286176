import React, { useState, useEffect, useReducer } from 'react';
import { Grid, Tooltip, IconButton, TextField, LinearProgress } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';

import DeleteIcon from '@material-ui/icons/Delete';

import {
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell
} from '@material-ui/core';

import path from 'path';

import useSnackBars from '../../../../hooks/useSnackbars';

import * as API from '../../../../lib/api';
import { TabPanel } from '../../../tabs/TabPanel';
import { FileUploadButton } from '../../../shared/FileUploadButton';

import { Episode, EpisodePhoto } from '../../../../types';

import { uploadPresignedFile } from '../../../../lib/util';

import { API as AmplifyAPI, graphqlOperation } from 'aws-amplify';
import { onCreateEpisodePhoto } from '../../../../graphql/subscriptions';

const useStyles = makeStyles((theme) => ({
    menuTable: {
        borderTopWidth: 2,
        borderColor: '#f9f9f9',
        borderWidth: 2,
        borderStyle: 'solid'
    },
    comboBox: {
        width: '300px'
    },
}));

const defaultOption = { name: '', value: -1 };

const initialState = {
    episodePhotoGroups: [defaultOption],
    episodePhotos: [],
    loading: true,
    error: false
}

function reducer(state, action) {
    switch (action.type) {
        case 'fetchEpisodePhotoGroupsSuccess':
            let options = action.episodePhotoGroups.map(photoGroup => {
                return { name: photoGroup.name, value: photoGroup.id }
            })
            return {
                ...state,
                episodePhotoGroups: [defaultOption].concat(options)
            }
        case 'fetchEpisodePhotosSuccess':
            return {
                ...state,
                episodePhotos: action.episodePhotos,
                loading: false
            }
        case 'addEpisodePhotoFromSubscription':
            const newPhoto = action.episodePhoto
            let groups = state.episodePhotoGroups
            let groupExists = state.episodePhotoGroups.find(group => group.value === newPhoto.groupID);
            if (!groupExists) groups.push({ name: newPhoto.groupName, value: newPhoto.groupID })
            return {
                ...state,
                episodePhotoGroups: groups,
                episodePhotos: [
                    newPhoto,
                    ...state.episodePhotos
                ]
            }
        case 'removePhotoSuccess':
            let photos = state.episodePhotos.filter(photo => photo.id !== action.id);
            return {
                ...state,
                episodePhotos: photos,
            }
        default:
            throw new Error();
    }
}

export function PhotosTab(props: PhotosTabProps) {
    const classes = useStyles();
    const { createErrorSnack } = useSnackBars();
    const [photosState, dispatch] = useReducer(reducer, initialState)
    const [newEpisodePhotoGroup, setNewEpisodePhotoGroup] = useState(defaultOption);
    const [newEpisodePhotoOrder, setNewEpisodePhotoOrder] = useState('100');
    const [newEpisodePhotoTitle, setNewEpisodePhotoTitle] = useState('');

    const [uploading, setUploading] = useState(false);
    const [progress, setProgress] = useState(0);

    const reset = () => {
        setNewEpisodePhotoGroup(defaultOption);
        setNewEpisodePhotoOrder('100');
        setNewEpisodePhotoTitle('');
    }

    useEffect(() => {
        const fetchEpisodePhotoGroups = async () => {
            const photoGroupsResult = await API.getEpisodePhotoGroups({ episodeID: props.episode.id });
            dispatch({
                type: 'fetchEpisodePhotoGroupsSuccess',
                episodePhotoGroups: photoGroupsResult
            })
        }

        const fetchEpisodePhotos = async () => {
            const photosResult = await API.getEpisodePhotos({ episodeID: props.episode.id });
            dispatch({
                type: 'fetchEpisodePhotosSuccess',
                episodePhotos: photosResult
            })
        }

        fetchEpisodePhotoGroups();
        fetchEpisodePhotos();

        // @ts-ignore
        const onCreateEpisodePhotoSubscription = AmplifyAPI.graphql(graphqlOperation(onCreateEpisodePhoto, { episodeID: props.episode.id })).subscribe({
            next: (data) => {
                let newPhoto = data.value.data?.onCreateEpisodePhoto;
                if (newPhoto) {
                    dispatch({
                        type: 'addEpisodePhotoFromSubscription',
                        episodePhoto: newPhoto
                    })
                } else {
                    console.error('Error with photo subscription!')
                    console.error(data)
                }
            },
            error: (error) => {
                console.error(error);
            }
        });

        return function cleanup() {
            // Stop receiving data updates from the subscription
            onCreateEpisodePhotoSubscription.unsubscribe();
        };
    }, [props.episode])

    const photoSelect = async ({ target }) => {
        if (!target) return;

        const files = target.files;
        if (!files) return;

        for (var i = 0; i < files.length; i++) {
            const file = files[i];
            if (!file) break;

            if (!newEpisodePhotoGroup.name || newEpisodePhotoGroup.name === '') return;

            const fileExtension = path.extname(file.name).toUpperCase();

            const now = new Date().getTime()
            const filename = `PHOTO-${props.customerID}-${props.episode.showID}-${props.episode.id}-${now}${fileExtension}`

            const presignedUrl = await API.generatePresignedRawMediaURL({
                operation: 'putObject',
                key: filename,
                metadata: [
                    { name: 'customer', value: props.customerID.toString() },
                    { name: 'show', value: props.episode.showID.toString() },
                    { name: 'groupid', value: newEpisodePhotoGroup.value.toString() },
                    { name: 'groupname', value: newEpisodePhotoGroup.name },
                    { name: 'title', value: newEpisodePhotoTitle }
                ]
            })

            var options = {
                headers: {
                    'Content-Type': file.type
                },
                onUploadProgress: function (progressEvent) {
                    var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                    setProgress(percentCompleted)
                }
            };

            setUploading(true);
            let uploadResponse = await uploadPresignedFile(presignedUrl, file, options);
            if (uploadResponse.status !== 200) {
                createErrorSnack('Error adding photo');
            }

            setUploading(false);
            setProgress(0);

            // Sleep for 600ms
            await new Promise(r => setTimeout(r, 600));
        }

        reset();
    }

    const onPhotoGroupChange = (event, newValue) => {
        // Handle the case that someone inserted their own value
        if (typeof (newValue) === 'string') {
            newValue = { name: newValue, value: -1 }
        }

        setNewEpisodePhotoGroup(newValue);
    }

    const removePhoto = async (id: number) => {
        try {
            await API.deleteEpisodePhoto(id);
            dispatch({
                type: 'removePhotoSuccess',
                id: id
            })
        } catch (err) {
            createErrorSnack('Failed to remove photo');
        }
    }

    return (
        <TabPanel value={props.tab} index={props.index} spacing={2} direction="column">
            <Grid item>
                {
                    uploading &&
                    <LinearProgress variant="determinate" value={progress} color="secondary" />
                }
            </Grid>
            <Grid item container spacing={2} direction="row" alignItems="center">
                <Grid item>
                    <Autocomplete
                        id="photo-group-combo-box"
                        options={photosState.episodePhotoGroups}
                        value={newEpisodePhotoGroup}
                        getOptionLabel={(option) => option.name}
                        onChange={onPhotoGroupChange}
                        style={{ width: '250px' }}
                        selectOnFocus
                        clearOnBlur
                        freeSolo
                        renderInput={(params) => <TextField {...params} label="Photo Group" variant="outlined" />}
                    />
                </Grid>
                <Grid item>
                    <TextField variant="outlined"
                        label="Photo Order"
                        value={newEpisodePhotoOrder}
                        onChange={(e) => setNewEpisodePhotoOrder(e.target.value)}
                    />
                </Grid>
                <Grid item>
                    <TextField variant="outlined"
                        label="Photo Title"
                        value={newEpisodePhotoTitle}
                        onChange={(e) => setNewEpisodePhotoTitle(e.target.value)}
                    />
                </Grid>
                <Grid item>
                    <FileUploadButton id="photo-group-upload"
                        accept="image/*"
                        text="Select Photo"
                        onChange={photoSelect}
                        multiple
                    />
                </Grid>
            </Grid>
            <Grid item>
                <TableContainer>
                    <Table className={classes.menuTable} size="small" aria-label="seasons">
                        <TableHead>
                            <TableRow style={{ background: "#f9f9f9" }}>
                                <TableCell align="center">Photo</TableCell>
                                <TableCell align="center">Group</TableCell>
                                <TableCell align="center">Order</TableCell>
                                <TableCell align="center">Title</TableCell>
                                <TableCell align="center">Description</TableCell>
                                <TableCell align="center">Enabled</TableCell>
                                <TableCell align="center">Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                photosState.episodePhotos.map((photo: EpisodePhoto, index: number) => {
                                    return (
                                        <TableRow key={photo.id} style={index % 2 ? { background: "#f9f9f9" } : { background: "white" }}>
                                            <TableCell align="center">
                                                <img src={photo.thumbnailURL || ''} alt={photo.title || ''} />
                                            </TableCell>
                                            <TableCell align="center">{photo.groupName}</TableCell>
                                            <TableCell align="center">{photo.order}</TableCell>
                                            <TableCell align="center">{photo.title}</TableCell>
                                            <TableCell align="center">{photo.description}</TableCell>
                                            <TableCell align="center">{photo.enabled}</TableCell>
                                            <TableCell align="center">
                                                <Tooltip title="Remove" onClick={() => removePhoto(photo.id)}>
                                                    <IconButton>
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </TableCell>
                                        </TableRow>
                                    )
                                })
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </TabPanel>
    )
}
export interface PhotosTabProps {
    tab: number
    index: number
    episode: Episode
    customerID: number
}