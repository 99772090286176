import React, { useState, useEffect } from 'react';
import { Grid, Paper, Typography, LinearProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column'
    }
}));

export function ClickThroughRateCard(props: ClickThroughRateCardProps) {
    const classes = useStyles();
    const [totalViews, setTotalViews] = useState(props.totalViews || 0);
    const [totalClicks, setTotalClicks] = useState(props.totalClicks || 0);
    const [percentClicked, setPercentClicked] = useState(0);
    const [percentChangeText, setPercentChangeText] = useState('0%');
    const [isIncrease, setIsIncrease] = useState(true);

    const calculatePercentChange = (views, clicks) => {
        let percent = 0;
        let text = '0%';
        if (clicks === 0 && views !== 0) {
            setIsIncrease(false)
        } else if (clicks > views) {
            percent = 100 - Math.round(((views / clicks) + Number.EPSILON) * 100);
            if (percent > 100) percent = 100;
            if (percent < 0) percent = 0;
            text = `${percent}% less`
            setIsIncrease(false)
        } else if (views > clicks) {
            percent = Math.round(((clicks / views) + Number.EPSILON) * 100);
            if (percent > 100) percent = 100;
            if (percent < 0) percent = 0;
            text = `${percent}%`
            setIsIncrease(true)
        }

        setPercentClicked(percent);
        setPercentChangeText(text);
    }

    useEffect(() => {
        setTotalViews(props.totalViews || 0);
        setTotalClicks(props.totalClicks || 0);
        calculatePercentChange(props.totalViews, props.totalClicks);
    }, [props])

    return (
        <Paper className={classes.paper}>
            <Grid container spacing={2} direction="column">
                <Grid item container direction="row" xs>
                    <Grid item xs>
                        <Typography>Click Through Rate</Typography>
                        <Typography variant="caption">Total Product Views / Clicks</Typography>
                    </Grid>
                    <Grid item>
                        <Typography>{totalViews} / {totalClicks}</Typography>
                    </Grid>
                </Grid>
                <Grid item>
                    <LinearProgress variant="determinate" value={percentClicked} color={isIncrease ? "secondary" : "primary" } />
                </Grid>
                <Grid item container direction="row" xs>
                    <Grid item xs>
                        <Typography variant="caption">{isIncrease ? "Success" : ""}</Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="caption">{percentChangeText}</Typography>
                    </Grid>
                </Grid>

            </Grid>
        </Paper>
    )
}

export interface ClickThroughRateCardProps {
    totalViews: number
    totalClicks: number
}