import React, { useState } from 'react';
import { Grid, Typography, Paper, Divider, Button, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { format, subDays, startOfDay, endOfDay } from 'date-fns';

import * as API from '../../lib/api';

import { EpisodeViewsCard } from './EpisodeViewsCard';
import { DateActivityChart, TopLocationsChart, UsersDeviceTypesChart, WeekdayActivityChart } from './charts';

import { RangeSelect } from '../shared/RangeSelect';

import { Maybe, EngagementReport as EngagementReportType } from '../../types';

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2)
    },
    divider: {
        color: 'gray',
        backgroundColor: 'gray'
    }
}));

export function EngagementReport(props: EngagementReportProps) {
    const classes = useStyles();
    const [reportDateString, setReportDateString] = useState('');
    const [engagementReport, setEngagementReport] = useState<Maybe<EngagementReportType>>();
    const [dates, setDates] = useState({ fromDate: startOfDay(subDays(new Date(), 7)), toDate: endOfDay(subDays(new Date(), 1)) })
    const [loading, setLoading] = useState(false);

    const fetchEngagementReport = async () => {
        setLoading(true);
        let queryArgs = {
            customerID: props.customerID,
            fromDate: dates.fromDate.toISOString(),
            toDate: dates.toDate.toISOString()
        }

        // const engagementReportResult = await API.getShowReport(queryArgs);
        // setShowReport(showReportResult);
        setLoading(false);
    }

    const generateReport = async () => {
        await fetchEngagementReport();
        setReportDateString(`${format(dates.fromDate, 'M/dd/yyyy')} through ${format(dates.toDate, 'M/dd/yyyy')}`);
    }

    const handleDateChange = async (from, to) => {
        setDates({
            fromDate: from,
            toDate: to
        });
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Paper className={classes.paper}>
                    <Grid container spacing={2} direction="row">
                        <Grid item container direction="column" spacing={2}>
                            <RangeSelect handleSelectChange={handleDateChange} onApplyClicked={handleDateChange} />
                        </Grid>
                        <Grid item>
                            <Button variant="outlined" onClick={generateReport}>
                                Generate Report
                            </Button>
                        </Grid>
                        {
                            loading &&
                            <Grid item>
                                <CircularProgress />
                            </Grid>
                        }
                        {
                            engagementReport &&
                            <>
                                <Grid item xs={12}>
                                    <Divider className={classes.divider} />
                                </Grid>
                                <Grid item container direction="row">
                                    <Grid item xs>
                                        <Typography>Report Date Range</Typography>
                                        <Typography>{reportDateString}</Typography>
                                    </Grid>
                                </Grid>
                                <Grid item container direction="row" spacing={1}>
                                    <Grid item xs={2}>
                                        <Typography variant="subtitle2">Total Views</Typography>
                                        <Typography>{engagementReport.totalViews || 0}</Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Typography variant="subtitle2">App User Count</Typography>
                                        <Typography variant="subtitle2">{engagementReport.appUsers || 0}</Typography>
                                    </Grid>
                                </Grid>
                            </>
                        }
                    </Grid>
                </Paper>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
                <UsersDeviceTypesChart customerID={props.customerID} dates={dates} />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
                <TopLocationsChart customerID={props.customerID} dates={dates} />
            </Grid>
            <Grid item xs={12}>
                <DateActivityChart customerID={props.customerID} dates={dates} />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
                <WeekdayActivityChart customerID={props.customerID} dates={dates} />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
                <EpisodeViewsCard customerID={props.customerID} dates={dates} />
            </Grid>
        </Grid>
    )
}

export interface EngagementReportProps {
    authData: any
    customerID: number
}